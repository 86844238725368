import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function HeadersMeta(props) {
  const { title } = props;
  return (
    <Helmet>
      <title>
        {`Fusion Windows - ${title}`}
      </title>
    </Helmet>
  );
}

export default HeadersMeta;
HeadersMeta.propTypes = {
  title: PropTypes.string,
};
HeadersMeta.defaultProps = {
  title: '',
};
