import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal/Modal';
import Input from '../form/Input';
import Button from '../form/Button';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone_new.svg';

function CallModal(props) {
  const { open, onCancel, onStart } = props;
  const [number, setNumber] = useState('');

  const handleStart = useCallback(() => {
    onStart(number);
  }, [number]);

  return (
    <Modal open={open} className="callModal" onClose={onCancel}>
      <p className="title">Please enter phone number</p>
      <Input
        label="Phone"
        mask="+1 999-999-9999"
        value={number || ''}
        onChange={(ev) => setNumber(ev.target.value)}
      />
      <div className="actions">
        <Button title="Cancel" onClick={onCancel} />
        <Button
          title={(
            <div>
              <PhoneIcon />
              <span>Start call</span>
            </div>
          )}
          className="call"
          onClick={handleStart}
        />
      </div>
    </Modal>
  );
}

export default CallModal;
CallModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
};
