import leads from './leads';
import account from './account';
import contacts from './contacts';
import users from './users';
import menu from './menu';
import tasks from './tasks';
import pipelines from './pipelines';
import automation from './automation';
import leadSources from './leadSources';
import permissions from './permissions';
import jobs from './jobs';
import invoices from './invoices';
import templates from './templates';
import call from './call';
import notification from './notification';
import notes from './notes';

export default {
  leads,
  account,
  tasks,
  pipelines,
  contacts,
  users,
  menu,
  automation,
  permissions,
  leadSources,
  jobs,
  invoices,
  templates,
  call,
  notification,
  notes,
};
