import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import RightModal from '../modal/RightModal';
import Input from '../form/Input';
import Button from '../form/Button';
import { getContactsRequest, getSingleContactRequest } from '../../store/actions/contacts';
import Utils from '../../helpers/Utils';
import Api from '../../Api';
import { useQuery } from '../../helpers/hooks/useQuery';
import AutocompleteInput from '../form/AutocompleteInput';
import Textarea from '../form/Textarea';

const contactDefaultData = {
  name: '',
  company: '',
  phone: '',
  email: '',
  address: '',
};

function AddContactModal() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { query } = useQuery();

  const [contactInfo, setContactInfo] = useState(contactDefaultData);
  const [errors, setErrors] = useState({});

  const [saveLoading, saveLoadingToggle] = useState(false);
  const contactId = searchParams.get('con-id');

  useEffect(() => {
    if (contactId) {
      (async () => {
        const { payload: { contacts } } = await dispatch(getSingleContactRequest({ id: contactId }));
        if (contacts) {
          const data = {
            name: contacts[0].name,
            company: contacts[0].company,
            phone: contacts[0].phone,
            email: contacts[0].email,
            address: contacts[0].address,
            note: contacts[0].note,
            id: +contactId,
          };
          setContactInfo({ ...data });
        }
      })();
    }
  }, [contactId]);

  useEffect(() => {
    const val = searchParams.get('val');
    const phone = searchParams.get('phone');
    if (val) {
      changeContact('name', val);
    }
    if (phone) {
      changeContact('phone', phone);
    }
  }, [searchParams]);

  const saveContact = useCallback(async (e) => {
    e.preventDefault();

    saveLoadingToggle(true);

    try {
      const data = Utils.deleteEmptyKeys(Utils.trimObjValues(contactInfo));

      const { data: d } = await Api.createContact(data);
      await dispatch(getContactsRequest({
        page: +query.page || 1,
        limit: +query.limit || 10,
      }));

      closeContactModal(d.contactId);

      toast.success(`${contactInfo.id ? 'Successfully updated' : 'Successfully created'}`);
    } catch (err) {
      if (err.response.data.errors) {
        setErrors(Utils.normalizeErrors(err.response.data.errors));
      } else {
        setErrors((prev) => ({
          ...prev,
          email: err.response.data.message,
        }));
      }
    }

    saveLoadingToggle(false);
  }, [contactInfo, query]);

  const closeContactModal = useCallback((id) => {
    const q = { ...Object.fromEntries(searchParams) };
    delete q.create;
    delete q['con-id'];
    delete q.val;
    delete q.from;
    if (_.isNumber(id)) {
      q['c-id'] = id;
    }
    setSearchParams(q);
    setContactInfo(contactDefaultData);
    setErrors({});
  }, [searchParams]);

  const changeContact = useCallback((path, value) => {
    setContactInfo((prev) => ({ ...prev, [path]: value }));

    setErrors((prev) => {
      delete prev[path];

      return prev;
    });
  }, []);

  const getEmailError = useCallback((error) => {
    if (!error) {
      return null;
    } if (error !== 'Email already been taken') {
      return error;
    }
    return 'User already exists';
  }, []);
  return (
    <RightModal
      open={searchParams.get('create') === 'contact'}
      onClose={closeContactModal}
      headerText={`${contactInfo?.id ? 'Edit' : 'New'} customer`}
    >
      <form onSubmit={saveContact}>
        <div className="new__contact__input__wrapper">
          <Input
            label="Full Name *"
            placeholder="Full Name"
            value={contactInfo.name}
            onChange={(ev) => changeContact('name', ev.target.value)}
            error={errors.name}
          />
        </div>
        <div className="new__contact__input__wrapper">
          <Input
            label="Company name"
            placeholder="Company name"
            value={contactInfo.company}
            onChange={(ev) => changeContact('company', ev.target.value)}
            error={errors.company}
          />
        </div>
        <div className="new__contact__input__wrapper">
          <Input
            label="Phone number"
            placeholder="Phone number"
            value={contactInfo.phone}
            onChange={(ev) => changeContact('phone', ev.target.value)}
            error={errors.phone}
            mask="+1 999-999-9999"
          />
        </div>
        <div className="new__contact__input__wrapper">
          <Input
            label="Email"
            placeholder="Email"
            value={contactInfo.email}
            onChange={(ev) => changeContact('email', ev.target.value)}
            error={getEmailError(errors.email)}
          />
        </div>
        <div className="new__contact__input__wrapper">
          <AutocompleteInput
            label="Address"
            placeholder="Address"
            error={errors.address}
            onSelectOption={(val) => changeContact('address', val)}
            defaultValue={contactInfo.address}
          />
        </div>
        <div className="new__contact__input__wrapper">
          <Textarea
            label="Note"
            placeholder="Note"
            onChange={(ev) => changeContact('note', ev.target.value)}
            value={contactInfo.note}
          />
        </div>

        <div className="new__contact__buttons__wrapper">
          <Button
            title={`${contactInfo?.id ? 'Edit' : 'Add new'} customer`}
            loading={saveLoading}
            type="submit"
          />

          <Button
            title="Cancel"
            noBorder
            type="button"
            onClick={closeContactModal}
          />
        </div>
      </form>
    </RightModal>
  );
}

export default AddContactModal;
