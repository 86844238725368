import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const createAutomationRequest = createAsyncThunk('automation/createAutomation', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.createAutomation(payload);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const deleteAutomationRequest = createAsyncThunk('automation/deleteAutomationRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.deleteAutomation(payload);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const getAutomationsListRequest = createAsyncThunk('automation/getAutomationsListRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.getAutomationsList(payload);
    return { data, type: payload };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});
