import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Input from '../form/Input';
import Select from '../form/Select';
import DatePicker from '../form/DatePicker';
import Editor from '../form/Editor';
import Button from '../form/Button';
import { getUserContactsFromGoogleRequest } from '../../store/actions/account';
import { sendJobEmailRequest } from '../../store/actions/jobs';
import Utils from '../../helpers/Utils';
import { sendLeadEmailRequest } from '../../store/actions/leads';
import { getEmailsRequest } from '../../store/actions/pipelines';

function EmailForm(props) {
  const {
    setData,
    emails,
    onClose,
    email
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [localeErrors, setLocaleErrors] = useState({});
  const [searchParams] = useSearchParams();

  const { leadId, jobId } = useParams();
  const errors = useSelector((state) => state.jobs.errors) || {};
  const profile = useSelector((state) => state.account.profile);
  const templates = useSelector((state) => state.templates.email.list);

  useEffect(() => {
    handleChange('emails[0][to]', searchParams.get('dest'));
  }, [searchParams.get('dest')]);

  const handleChange = useCallback((key, value) => {
    setData({
      key,
      value,
    });
    _.forEach(localeErrors, (v, k) => {
      if (key.includes(k)) {
        delete localeErrors[k];
      }
    });
    setLocaleErrors(localeErrors);
  }, [localeErrors]);

  const handleSendEmail = useCallback(async () => {
    const data = { ...emails[0] } || {};
    const from = profile.googleData?.email || profile.fromEmail;
    if (!data.to){
      data.to = email || searchParams.get('dest')
    }
    setLoading(true);
    if (jobId) {
      const { payload } = await dispatch(sendJobEmailRequest({ ...data, jobId, from }));
      if (payload.data.status === 'ok') {
        toast.success('Successfully sent');
        await dispatch(getEmailsRequest({ id: jobId, pipeline: 'job' }));
      } else {
        setLocaleErrors(Utils.normalizeErrors(payload.data.errors));
      }
    }
    if (leadId) {
      const { payload } = await dispatch(sendLeadEmailRequest({ ...data, leadId, from }));
      if (payload.data.status === 'ok') {
        toast.success('Successfully sent');
        await dispatch(getEmailsRequest({ id: leadId, pipeline: 'lead' }));
      } else {
        setLocaleErrors(Utils.normalizeErrors(payload.data.errors));
      }
    }
    setLoading(false);
    if (onClose) {
      onClose();
    }
  }, [emails, jobId, leadId, profile]);

  const handleLoadOptions = useCallback(async (val) => {
    const { payload } = await dispatch(getUserContactsFromGoogleRequest(val));
    return payload.result.map((r) => ({
      value: r.email,
      label: r.email,
    }));
  }, []);

  return (
    <div>
      <Input
        placeholder="From"
        value={profile.googleData?.email || profile.fromEmail}
        readOnly
      />
      <Select
        isCreatableAsync
        placeholder={email || searchParams.get('dest') || 'To'}
        onChange={(val) => handleChange('emails[0][to]', val.value)}
        loadOptions={handleLoadOptions}
        error={_.get(errors, 'emails.0.to') || localeErrors.to}
      />
      <Input
        placeholder="Subject"
        onChange={(ev) => handleChange('emails[0][subject]', ev.target.value)}
        value={_.get(emails, '[0][subject]')}
        error={_.get(errors, 'emails.0.subject') || localeErrors.subject}
      />
      <Select
        options={templates}
        placeholder="Template"
        onChange={(val) => {
          handleChange('emails[0][templateId]', val.id);
          handleChange('emails[0][subject]', val.subject);
          handleChange('emails[0][text]', val.html);
        }}
        getOptionLabel={(o) => o.name}
        getOptionValue={(o) => o.id}
        value={templates.find((t) => t.id === _.get(emails, '[0][templateId]'))}
        error={_.get(errors, 'emails.0.templateId')}
        isClearable
      />
      <DatePicker
        setDate={(date) => handleChange('emails[0][date]', date)}
        date={_.get(emails, '[0][date]')}
        placeholder="Execution date"
      />

      <Editor
        onChange={(val) => handleChange('emails[0][text]', val)}
        value={_.get(emails, '[0][text]', '')}
        error={_.get(errors, 'emails.0.text') || localeErrors.text}
        placeholder="Note"
      />
      {leadId || jobId ? (
        <div className="action">
          <Button title="Send" onClick={handleSendEmail} loading={loading} />
        </div>
      ) : null}
    </div>
  );
}

export default EmailForm;

EmailForm.propTypes = {
  emails: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  email: PropTypes.string,
};

EmailForm.defaultProps = {
  onClose: undefined,
  email: '',
};
