import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../components/Wrapper';
import Loader from '../components/loader/Loader';
import Utils from '../helpers/Utils';
import { deleteLeadRequest, getLeadsRequest } from '../store/actions/leads';
import { deleteJobRequest, getJobsRequest } from '../store/actions/jobs';
import { deleteTaskRequest, getTasksRequest } from '../store/actions/tasks';
import Pagination from '../components/pagination/Pagination';
import Select from '../components/form/Select';
import { ReactComponent as EditIcon } from '../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg';
import AddTaskModal from '../components/tasks/AddTaskModal';
import DeleteModal from '../components/modal/DeleteModal';

const typeOptions = [
  { label: 'Leads', value: 'leads' },
  { label: 'Jobs', value: 'jobs' },
  { label: 'Tasks', value: 'tasks' },
];

function Archive() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [archivedData, setArchivedData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [type, setType] = useState('leads');
  const [id, setId] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await handleGetData();
      setLoading(false);
    })();
  }, [type, page, limit]);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    if (type === 'leads') {
      await dispatch(deleteLeadRequest(id));
    }
    if (type === 'jobs') {
      await dispatch(deleteJobRequest(id));
    }
    if (type === 'tasks') {
      await dispatch(deleteTaskRequest(id));
    }
    await handleGetData();
    setLoadingDelete(false);
    setId(null);
  }, [id, type]);

  const handleGetData = useCallback(async () => {
    let d;
    if (type === 'leads') {
      const { payload: { data } } = await dispatch(getLeadsRequest({ page, limit, archive: true }));
      d = data;
    }
    if (type === 'jobs') {
      const { payload: { data } } = await dispatch(getJobsRequest({ page, limit, archive: true }));
      d = data;
    }
    if (type === 'tasks') {
      const { payload: { data } } = await dispatch(getTasksRequest({ page, limit, archive: true }));
      d = data;
    }
    setArchivedData(d[type]);
    setTotalPages(d.totalPages);
  }, [page, limit, type]);

  return (
    <Wrapper title="Archive">
      <div className="leadsList archive">
        <div className="typeSelect">
          <Select
            options={typeOptions}
            onChange={(v) => {
              setType(v.value);
              setPage(1);
            }}
            label="Type"
            value={typeOptions.find((o) => o.value === type)}
          />
        </div>
        <div className="table__wrapper leadsTable">
          {loading ? (
            <div className="loader__wrapper">
              <Loader size={60} borderWidth={4} />
            </div>
          ) : null}

          {!loading && !_.isEmpty(archivedData) ? (
            <table
              style={{ minWidth: 165 }}
              className="table"
            >
              <thead className="thead">
                <tr className="tr">
                  <th className="th">Status</th>
                  {['leads', 'jobs'].includes(type) ? (
                    <>
                      <th className="th">Email</th>
                      <th className="th">Phone</th>
                    </>
                  ) : null}
                  {type === 'tasks' ? <th className="th">Type</th> : null}
                  <th style={{ width: 165 }} className="th">Actions</th>
                </tr>
              </thead>

              <tbody className="tbody">
                {archivedData.map((d) => (
                  <tr
                    className="tr"
                    key={d.id}
                  >
                    <td className="td">
                      {_.startCase(d.status)}
                    </td>
                    {['leads', 'jobs'].includes(type) ? (
                      <>
                        <td className="td">
                          {d.email || '-'}
                        </td>
                        <td className="td">
                          {Utils.formatPhoneNumber(d.phone)}
                        </td>
                      </>
                    ) : null}
                    {type === 'tasks' ? <td className="td">{_.startCase(d.type)}</td> : null}

                    <td style={{ width: 165 }} className="th actions__wrapper">
                      <EditIcon
                        className="action__edit__icon"
                        onClick={() => {
                          if (type === 'tasks') {
                            navigate(`?create=task&id=${d.id}&from=archive`);
                          } else {
                            navigate(`/${type}/edit/${d.id}?from=archive`);
                          }
                        }}
                      />
                      <DeleteIcon onClick={() => setId(d.id)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
          {_.isEmpty(archivedData) && !loading ? <div className="noData">No data yet.</div> : null}
        </div>
        {!_.isEmpty(archivedData) ? (
          <Pagination
            page={page}
            totalPages={totalPages}
            limit={limit}
            onChange={(k, v) => {
              if (k === 'page') {
                setPage(v);
              } else {
                setLimit(v);
                setPage(1);
              }
            }}
          />
        ) : null}
        <DeleteModal
          loading={loadingDelete}
          open={!!id}
          text="Are you sure want to delete?"
          onClose={() => setId(null)}
          onDelete={handleDelete}
        />
      </div>
      <AddTaskModal />
    </Wrapper>
  );
}

export default Archive;
