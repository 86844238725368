import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HeadersMeta from '../HeadersMeta';
import HeaderLogout from './HeaderLogout';

function WrapperLogout(props) {
  const { children, title } = props;
  const token = useSelector((state) => state.account.token);
  const [searchParams] = useSearchParams({});

  if (token) {
    return searchParams.get('path') ? <Navigate to={searchParams.get('path')} /> : <Navigate to="/" />;
  }

  return (
    <div className="wrapperLogout">
      <HeaderLogout />
      <HeadersMeta title={title} />
      {children}
    </div>
  );
}

export default WrapperLogout;

WrapperLogout.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
};

WrapperLogout.defaultProps = {
  title: 'Log in',
};
