import React from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

function Tabs(props) {
  const { tabs, children } = props;
  const { tab = 'pipeline' } = useParams();

  return (
    <div className="tabs">
      <div className="nav">
        {tabs.map((t) => (
          <Link to={t.path} className={tab === t.tab ? 'active' : ''} key={t.tab}>{_.upperFirst(t.tab)}</Link>
        ))}
      </div>
      {children}
    </div>
  );
}

export default Tabs;
Tabs.propTypes = {
  children: PropTypes.element.isRequired,
  tabs: PropTypes.array.isRequired,
};
