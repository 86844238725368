import { createReducer } from '@reduxjs/toolkit';
import { getEmailTemplatesRequest } from '../actions/templates';

const initialState = {
  email: {
    list: [],
    totalPages: 1,
  },
  notifications: {
    list: [],
    totalPages: 1,
  },
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getEmailTemplatesRequest.fulfilled, (store, action) => {
      const { path, data } = action.payload;
      if (!path) {
        store.email.list = data.templates;
        store.email.totalPages = data.totalPages;
      }
    });
  // .addCase(getNotificationTemplatesRequest.fulfilled, (store, action) => {
  //   store.notifications.list = action.payload.templates;
  //   store.notifications.totalPages = action.payload.totalPages;
  // });
});

export default reducer;
