import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DraggableContainer from '../pipeline/DraggableContainer';
import { getLeadsRequest } from '../../store/actions/leads';

function LeadsPipeline() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const leadsGrouped = useSelector((state) => state.leads.leadsGrouped);
  const leads = useSelector((state) => state.leads.leads);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getLeadsRequest({
        pipeline: true,
      }));
      setLoading(false);
    })();
  }, []);
  return (
    <DraggableContainer loading={loading} pipeline="lead" pipelineData={leadsGrouped} initialData={leads} />
  );
}

export default LeadsPipeline;
