import Events from 'events';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import memoizeOne from 'memoize-one';
import { toast } from 'react-toastify';
import Api from '../Api';
import notificationAudio from '../assets/mp3/notification.mp3';

const firebaseConfig = {
  apiKey: 'AIzaSyAVpgofFTR_vmSwokvITTv_ZvBs9cbrF7w',
  authDomain: 'fusion-it-5e146.firebaseapp.com',
  projectId: 'fusion-it-5e146',
  storageBucket: 'fusion-it-5e146.appspot.com',
  messagingSenderId: '767582332935',
  appId: '1:767582332935:web:0a727d95ed561f164462a2',
};
const audio = new Audio(notificationAudio);

class NotificationsManager {
  static event = new Events();

  static async init() {
    const app = initializeApp(firebaseConfig);
    this.messaging = getMessaging(app);

    onMessage(this.messaging, this.handleMessage);
  }

  static handleMessage = (d) => {
    toast.success(<span dangerouslySetInnerHTML={{ __html: d.notification.body }} />);
    this.event.emit('message', d);
    audio.muted = false;
    audio.pause();
    audio.currentTime = 0;
    audio.play().catch(console.error);
  };

  static enable = async () => {
    if (!this.messaging) {
      return;
    }
    if (this.fbToken) {
      await Api.setFirebaseToken(this.fbToken);
    }

    this.fbToken = await getToken(this.messaging)
      .catch(console.error);

    if (this.fbToken) {
      await Api.setFirebaseToken(this.fbToken);
    }
  };

  static reload = memoizeOne((token) => {
    if (token) {
      return this.enable(token);
    }
    return this.disable();
  });

  static disable = () => {
    if (!this.fbToken) {
      return {};
    }
    return Api.deleteFirebaseToken(this.fbToken).catch(console.error);
  };
}

export default NotificationsManager;
