import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';

function HeaderLogout() {
  return (
    <div className="headerLogout">
      <Link className="logo" to="login">
        <LogoIcon />
        <p className="title">Fusion Windows</p>
      </Link>

      <div className="actions">
        {/* <NavLink className="mainButton outlined" to="/login"> */}
        {/*   Log in */}
        {/* </NavLink> */}

        {/* <NavLink className="mainButton" to="/signup"> */}
        {/*   Sign up */}
        {/* </NavLink> */}
      </div>
    </div>
  );
}

export default HeaderLogout;
