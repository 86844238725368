import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import RightModal from '../modal/RightModal';
import NotificationInfo from './NotificationInfo';
import { getNotificationListRequest } from '../../store/actions/notification';

const NotificationModal = ({ open, close }) => {
  const dispatch = useDispatch();
  const notificationRef = useRef();
  const currentPage = useSelector((store) => store.notification.currentPage);
  const totalPage = useSelector((store) => store.notification.totalPage);
  const [loading, setLoading] = useState(false);

  const onScroll = useCallback(async (e) => {
    if (e.target.scrollTop + e.target.getBoundingClientRect().height + 30 >= e.target.scrollHeight) {
      if ((+totalPage >= +currentPage + 1) && !loading) {
        setLoading(true);
        await dispatch(getNotificationListRequest({ page: +currentPage + 1, scroll: true }));
        setLoading(false);
      }
    }
  }, [totalPage, currentPage, loading]);

  return (
    <RightModal open={open} onClose={close} className="notification_modal">
      <div
        ref={notificationRef}
        className="notification_scroll_block"
        onScroll={onScroll}
      >
        <NotificationInfo type scrollLoad={loading} onClose={close} />
      </div>
    </RightModal>
  );
};

NotificationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
export default NotificationModal;
