import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import Input from './form/Input';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
import { ReactComponent as RemoveIcon } from '../assets/icons/remove.svg';
import UserSettingsDropdown from './UserSettingsDropdown';
import Notification from './notification/Notification';
import { checkUnreadNotificationRequest } from '../store/actions/notification';
import { globalSearchRequest } from '../store/actions/account';
import { getLeadsRequest } from '../store/actions/leads';
import { getJobsRequest } from '../store/actions/jobs';
import { getInvoicesRequest } from '../store/actions/invoices';
import { getContactsRequest } from '../store/actions/contacts';
import { getTasksRequest } from '../store/actions/tasks';
import { getEmailTemplatesRequest } from '../store/actions/templates';
import { getLeadSourcesRequest } from '../store/actions/leadSources';
import { getUsersRequest } from '../store/actions/users';
import { getPermissionsGroupRequest } from '../store/actions/permissions';
import Utils from '../helpers/Utils';

function Header(props) {
  const { title } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [, path, subPath] = pathname.split('/');

  const [search, setSearch] = useState('');
  const [dropdown, setDropdown] = useState(false);
  const [loading, setLoading] = useState(false);

  const globalSearchResult = useSelector((state) => state.account.globalSearchResult);

  useEffect(() => {
    dispatch(checkUnreadNotificationRequest());
  }, [title]);

  const handleSearch = useCallback(async (ev) => {
    setSearch(ev.target.value);

    if (ev.target.value) {
      setDropdown(true);
      setLoading(true);
      await dispatch(getSearchAction()({ s: ev.target.value, path }));
      setLoading(false);
    } else {
      setDropdown(false);
    }
  }, [path]);

  const getSearchAction = useCallback(() => ({
    dashboard: globalSearchRequest,
    'call-reports': globalSearchRequest,
    goto: globalSearchRequest,
    leads: getLeadsRequest,
    jobs: getJobsRequest,
    invoices: getInvoicesRequest,
    contacts: getContactsRequest,
    tasks: getTasksRequest,
    'email-templates': getEmailTemplatesRequest,
    'lead-sources': getLeadSourcesRequest,
    users: getUsersRequest,
    permissions: getPermissionsGroupRequest,
  })[path === 'settings' ? subPath : path], [path, subPath]);

  const getTitle = useCallback((type, data) => ({
    leads: [data.contacts?.name || '-', `${data.responsiblePersons?.firstName || ''} ${data.responsiblePersons?.lastName || ''}`].join(', '),
    jobs: [data.contact?.name || '-', `${data.responsiblePersons?.firstName || ''} ${data.responsiblePersons?.lastName || ''}`].join(', '),
    invoices: data.invoiceNumber,
    contacts: data.name,
    tasks: _.startCase(data.type),
    templates: data.name,
    leadSources: data.name,
    users: `${data.firstName} ${data.lastName}`,
    permissions: data.title,
  })[type], []);

  const handleRedirect = useCallback((type, data) => {
    if (type === 'tasks') {
      navigate(`/tasks?create=task&id=${data.id}`);
    }
    if (type === 'leads') {
      navigate(`/leads/edit/${data.id}`);
    }
    if (type === 'jobs') {
      navigate(`/jobs/edit/${data.id}`);
    }
    if (type === 'contacts') {
      navigate(`/contacts?create=contact&con-id=${data.id}`);
    }
    if (type === 'invoices') {
      navigate(`/invoices/${data.jobId}/${data.id}`);
    }
    if (type === 'templates') {
      navigate(`/settings/email-templates/${data.id}`);
    }
    if (type === 'leadSources') {
      navigate(`/settings/lead-sources/?create=lead-source&id=${data.id}`);
    }
    if (type === 'users') {
      navigate(`/settings/users/?create=user&id=${data.id}`);
    }
    if (type === 'permissions') {
      navigate(`/settings/permissions/${data.id}`);
    }
    setDropdown(false);
  }, []);

  return (
    <div className="header">
      <p className="title">{title}</p>
      {dropdown ? <div className="overlay" onClick={() => setDropdown(false)} /> : null}

      <div className="searchBlock">
        <Input
          leftIcon={<SearchIcon />}
          rightIcon={search ? <RemoveIcon onClick={() => setSearch('')} /> : undefined}
          placeholder="Search"
          value={search}
          onChange={handleSearch}
          onFocus={handleSearch}
        />
        {dropdown && !_.isEmpty(globalSearchResult) ? (
          <div className="dropdown">
            {_.map(globalSearchResult, (val, type) => (
              !_.isEmpty(val) ? (
                <div key={type}>
                  {pathname.includes('dashboard') || pathname.includes('goto')
                    ? <p className="title">{_.startCase(type)}</p>
                    : null}
                  {val.map((v) => (
                    <div
                      key={v.id}
                      className="item"
                      onClick={() => handleRedirect(type, v)}
                    >
                      <div className="leadTitle">
                        {type === 'leads' ? <span>{`#${v.leadId || '-'}`}</span> : null}
                        {type === 'leads' || type === 'jobs' ? <div className="contactTitle">Contacts</div> : null}
                        <span
                          className="contact"
                        >
                          <span dangerouslySetInnerHTML={Utils.formatHtml(getTitle(type, v), search)} />
                        </span>
                      </div>
                    </div>
                  ))}
                  <div className="border" />
                </div>
              ) : null
            ))}
            {!Object.values(globalSearchResult).flat().length && !loading && !_.isEmpty(globalSearchResult)
              ? <div className="noData">No Options</div>
              : null}
          </div>
        ) : null}
      </div>
      <div className="actions">
        <Notification />

        <UserSettingsDropdown />
      </div>
    </div>
  );
}

export default Header;
Header.propTypes = {
  title: PropTypes.string.isRequired,
};
