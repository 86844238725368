import { useCallback, useLayoutEffect, useRef } from 'react';

function useEventCallback(fn) {
  const ref = useRef();

  useLayoutEffect(() => {
    ref.current = fn;
  });
  return useCallback((...args) => (0, ref.current)(...args), []);
}

export default useEventCallback;
