import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getPermissionsRequest = createAsyncThunk('permissions/get', async (payload = {}) => {
  const { path, ...reqData } = payload;
  const { data } = await Api.getUserPermissions(reqData);
  return { data, path };
});

export const getPermissionsGroupRequest = createAsyncThunk('permissions/group-get', async (payload = {}) => {
  const { path, ...reqData } = payload;
  const { data } = await Api.getUserPermissionsGroup(reqData);

  return { data, path };
});
