import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from './Tabs';
import Button from '../form/Button';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings_new.svg';
import { ReactComponent as AutomationIcon } from '../../assets/icons/automation.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import HasPermission from '../HasPermission';

function PipelineTabs(props) {
  const { tabs, pipeline } = props;
  const navigate = useNavigate();

  return (
    <Tabs tabs={tabs}>
      <div className="actions">
        <HasPermission
          edit={['get_pipelines', 'create_pipelines', 'delete_pipelines', 'order_pipeline']}
          read={['get_pipelines_readonly',
            'create_pipelines_readonly',
            'delete_pipelines_readonly',
            'order_pipeline_readonly']}
        >
          <div className="settings" onClick={() => navigate(`/${pipeline}s/pipeline/edit`)}>
            <SettingsIcon />
            <p>Edit</p>
          </div>
        </HasPermission>
        <HasPermission
          edit={['get_automation', 'delete_automation', 'create_automation']}
          read={['get_automation_readonly', 'delete_automation_readonly', 'create_automation_readonly']}
        >
          <div className="settings" onClick={() => navigate(`/${pipeline}s/automations`)}>
            <AutomationIcon />
            <p>Automation</p>
          </div>
        </HasPermission>
        <HasPermission edit={[`create_${pipeline}`, 'get_contacts', 'users_list']} exact>
          <Button
            title={(
              <span>
                <AddIcon className="icon" />
                Create
              </span>
            )}
            onClick={() => navigate(`/${pipeline}s/create`)}
          />
        </HasPermission>
      </div>
    </Tabs>
  );
}

export default PipelineTabs;

PipelineTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  pipeline: PropTypes.string,
};

PipelineTabs.defaultProps = {
  pipeline: 'lead',
};
