import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Button from '../form/Button';
import AddTaskForm from './AddTaskForm';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { deleteTaskRequest } from '../../store/actions/tasks';
import { idKey } from '../../store/reducers/leads';

function Task(props) {
  const { setData, tasks } = props;

  const dispatch = useDispatch();

  const [index, setIndex] = useState(null);

  const handleDeleteTask = useCallback((i, task) => {
    setData({
      key: 'tasks',
      index: i,
    });
    if (task[idKey]) {
      dispatch(deleteTaskRequest(task[idKey]));
    }
  }, []);

  const handleSave = useCallback((ev, form) => {
    ev.preventDefault();
    setData({
      key: `tasks[${index}]`,
      value: form,
    });
    setIndex(null);
  }, [index]);

  return (
    <div className="leadsTask">
      {!index ? <Button title="Add a task" onClick={() => setIndex(tasks.length.toString())} /> : null}
      {index ? <AddTaskForm onSave={handleSave} /> : null}
      {!_.isEmpty(tasks) ? (
        <div className="tasksList">
          {tasks.map((task, i) => (
            <div className="task" key={`${task.type}_${task.status}`}>
              <div className="leftBlock">
                <div className="col">
                  <div>
                    <p className="label">Type:</p>
                    <p className="value">{_.upperFirst(task.type)}</p>
                  </div>
                  <div>
                    <p className="label">Status:</p>
                    <p className="value">{_.startCase(task.status)}</p>
                  </div>
                </div>
                <div className="col">
                  <div>
                    <p className="label">Due date:</p>
                    <p className="value">
                      {moment(task.dueDate)
                        .format('MM-DD-YYYY')}
                    </p>
                  </div>
                  <div>
                    <p className="label">Note:</p>
                    <p className="value">{task.note}</p>
                  </div>

                </div>
              </div>
              <div className="rightBlock">
                <DeleteIcon className="delete" onClick={() => handleDeleteTask(i, task)} />
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default Task;

Task.propTypes = {
  tasks: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
};
