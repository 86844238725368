import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import DeleteModal from '../modal/DeleteModal';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import Select from '../form/Select';
import DatePicker from '../form/DatePicker';
import Input from '../form/Input';
import Utils from '../../helpers/Utils';
import { createInvoicePaymentRequest, getInvoiceAfterCreatePaymentRequest } from '../../store/actions/invoices';
import Loader from '../loader/Loader';

const header = [
  {
    title: 'Invoice number',
    path: 'invoiceNumber',
  },
  {
    title: 'Amount',
    placeholder: 'Amount',
    path: 'amount',
    editable: true,
  },
  {
    title: 'Date',
    path: 'date',
    type: 'date',
    editable: true,
  },
  {
    title: 'Payment method',
    path: 'method',
    type: 'select',
    editable: true,
  },
];

const paymentTypes = [
  {
    label: 'Cash',
    value: 'cash',
  },
  {
    label: 'Credit card',
    value: 'creditCard',
  },
  {
    label: 'Bank transfer',
    value: 'bankTransfer',
  },
  {
    label: 'Customer payment',
    value: 'customerPayment',
  },
  {
    label: 'Check',
    value: 'check',
  },
];

const PaymentModal = ({ selectedInvoices, deleteInvoice }) => {
  const dispatch = useDispatch();
  const { invoiceList } = useSelector((state) => state.invoices);

  const scrollWrapper = useRef();

  const [paymentList, setPaymentList] = useState([]);
  const [errors, setErrors] = useState([]);
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [saveLoadingIds, setSaveLoadingIds] = useState([]);

  useEffect(() => {
    setPaymentList(invoiceList.filter((invoice) => selectedInvoices.includes(invoice.id))
      .map(({
        id, invoiceNumber, amount, method, jobId, status, payment, paymentDate,
      }) => {
        const date = status === 'paid'
          ? moment(payment?.[0]?.createdAt).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD');

        return {
          invoiceId: id,
          invoiceNumber,
          amount,
          method,
          jobId,
          date: paymentDate || date,
          cartNumber: payment?.[0]?.cartNumber,
        };
      }));
  }, [invoiceList, selectedInvoices]);

  const changePaymentData = useCallback((paymentId, path, value) => {
    setPaymentList((prev) => prev.map((p) => (p.invoiceId === paymentId ? { ...p, [path]: value } : p)));

    setErrors((prev) => prev.map((p) => (p.invoiceId === paymentId ? { ...p, [path]: false } : p)));
  }, []);

  const deletePayment = (id) => {
    setDeleteModalData(id);
  };

  const deletePaymentItem = useCallback(() => {
    deleteInvoice(deleteModalData);
    setDeleteModalData(null);
  }, [deleteModalData, selectedInvoices]);

  const savePayment = useCallback(async (payment) => {
    if (!payment.amount?.toString()?.trim() || !payment.date) {
      toast.error('Please correct these fields');

      header.forEach((h) => {
        if (!payment[h.path].trim()) {
          setErrors((prev) => {
            if (prev.find((p) => p.invoiceId === payment.invoiceId)) {
              return prev.map((p) => (p.invoiceId === payment.invoiceId ? { ...p, [h.path]: true } : p));
            }

            return [...prev, { invoiceId: payment.invoiceId, [h.path]: true }];
          });
        }
      });
    } else {
      const requestData = { ...payment };
      delete requestData.invoiceNumber;
      setErrors([]);
      setSaveLoadingIds((prev) => [...prev, requestData.invoiceId]);

      await dispatch(createInvoicePaymentRequest(requestData));
      await dispatch(getInvoiceAfterCreatePaymentRequest({ id: requestData.invoiceId }));

      setSaveLoadingIds((prev) => prev.filter((p) => p !== requestData.invoiceId));

      toast.success(<span>
        <b>Success!</b>

        {' Payment successfully registered'}
      </span>);
    }
  }, []);

  const onKeyPress = useCallback((e, isPaste, paymentId) => {
    const maxValue = invoiceList.find((p) => p.id === paymentId)?.amount;

    Utils.keyPressCurrency(e, isPaste, maxValue, 2);
  }, [invoiceList]);

  return (
    <div
      id="table"
      ref={scrollWrapper}
      className="table__wrapper invoice__payment__modal__wrapper"
    >
      <h2>Register payment</h2>

      <div className="invoice__payment__modal__table__wrapper" style={{ width: '100%' }}>
        <table
          className="table"
        >
          <thead className="thead">
            <tr className="tr">
              {header.map((h) => (
                <th className="th" key={h.title}>{h.title}</th>
              ))}
              <th className="th"> Cart Number</th>

              <th className="th">Actions</th>
            </tr>
          </thead>

          <tbody className="tbody">
            {paymentList.map((d) => {
              const method = paymentList.find((p) => p.invoiceId === d.invoiceId)?.method;
              return (
                <tr
                  key={d.invoiceId}
                  className="tr"
                >
                  {header.map((h) => (
                    <td
                      className="td"
                      key={h.title}
                      style={{ opacity: invoiceList.find((p) => p.id === d.invoiceId)?.status === 'paid' ? '.3' : 1 }}
                    >
                      {h.editable
                        ? (
                          h.type === 'select'
                            ? (
                              <Select
                                placeholder={h.placeholder}
                                options={paymentTypes}
                                value={paymentTypes?.find((p) => (p.value === d[h.path])) || null}
                                onChange={(o) => changePaymentData(d.invoiceId, h.path, o.value)}
                                menuPortalTarget={document.body}
                                closeMenuOnScroll={() => scrollWrapper.current}
                                isSearchable={false}
                                error={errors.find((e) => e.invoiceId === d.invoiceId)?.[h.path]}
                                isDisabled={invoiceList.find((p) => p.id === d.invoiceId)?.status === 'paid'}
                              />
                            )
                            : h.type === 'date'
                              ? (
                                <DatePicker
                                  setDate={(date) => changePaymentData(d.invoiceId, h.path, date)}
                                  portalId="root"
                                  date={d[h.path]}
                                  error={errors.find((e) => e.invoiceId === d.invoiceId)?.[h.path]}
                                  disabled={invoiceList.find((p) => p.id === d.invoiceId)?.status === 'paid'}
                                />
                              )
                              : (
                                <Input
                                  currency="$"
                                  placeholder={`$${invoiceList.find((p) => p.id === d.invoiceId)?.amount}`}
                                  value={d[h.path]}
                                  onChange={(e) => changePaymentData(d.invoiceId, h.path, e.target.value)}
                                  onKeyPress={(e) => onKeyPress(e, false, d.invoiceId)}
                                  onPaste={(e) => onKeyPress(e, true)}
                                  error={errors.find((e) => e.invoiceId === d.invoiceId)?.[h.path]}
                                  disabled={invoiceList.find((p) => p.id === d.invoiceId)?.status === 'paid'}
                                />
                              )
                        )

                        : _.get(d, h.path)}
                    </td>
                  ))}

                  <td
                    className="td cartNumberInput"
                    style={{ opacity: invoiceList.find((p) => p.id === d.invoiceId)?.status === 'paid' ? '.3' : 1 }}
                  >

                    {method === 'check' || method === 'creditCard'
                      ? (
                        <Input
                          label="Cart number"
                          disabled={invoiceList.find((p) => p.id === d.invoiceId)?.status === 'paid'}
                          onChange={(e) => changePaymentData(d.invoiceId, 'cartNumber', e.target.value)}
                          value={paymentList.find((p) => p.invoiceId === d.invoiceId)?.cartNumber}
                          type="number"
                        />
                      ) : null}

                  </td>
                  <td
                    className="td actions__wrapper"
                  >
                    <div>
                      <button
                        className="save"
                        onClick={() => !saveLoadingIds.includes(d.invoiceId) && savePayment(d)}
                        disabled={invoiceList.find((p) => p.id === d.invoiceId)?.status === 'paid'}
                      >
                        {saveLoadingIds.includes(d.invoiceId)
                          ? <Loader size={16} borderWidth={3} color="#FFFFFF" />
                          : 'Save'}

                      </button>

                      <DeleteIcon onClick={() => deletePayment(d.invoiceId)} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <DeleteModal
        open={!!deleteModalData}
        text="Are you sure want to delete the invoice?"
        onClose={() => setDeleteModalData(null)}
        onDelete={deletePaymentItem}
      />
    </div>
  );
};

PaymentModal.propTypes = {
  selectedInvoices: PropTypes.array.isRequired,
  deleteInvoice: PropTypes.func.isRequired,
};

export default PaymentModal;
