import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Link, useParams } from 'react-router-dom';
import Input from '../components/form/Input';
import Button from '../components/form/Button';
import Utils from '../helpers/Utils';
import { ReactComponent as EyeIcon } from '../assets/icons/eye.svg';
import WrapperLogout from '../components/logout/WrapperLogout';
import Api from '../Api';

const ForgotPassword = () => {
  const { code } = useParams();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);

  const changeData = useCallback((value) => {
    setEmail(value);

    setError('');
  }, []);

  const confirm = useCallback(async (e) => {
    e.preventDefault();

    let hasError = false;

    if (!email.trim()) {
      setError('Email is required');

      hasError = true;
    } else if (!Utils.isEmail(email.trim())) {
      setError('Email is incorrect');

      hasError = true;
    }

    if (!hasError) {
      setLoading(true);

      try {
        await Api.resetPassword(email.trim());

        toast.success(<span>
          <b>Success!</b>

          {' We have sent activation link to your email'}
        </span>);
      } catch (err) {
        toast.error('Please correct these fields');
        if (err.response.data.errors) {
          setError(_.upperFirst(_.lowerCase(_.startCase(err.response.data.errors?.[0]?.message.replaceAll('"', '')))));
        } else {
          setError(err.response.data.message);
        }
      }

      setLoading(false);
    } else {
      toast.error('Please correct these fields');
    }
  }, [email]);

  return (
    <WrapperLogout title="Reset password">
      <div className="login">
        <div>
          <form
            style={{ height: 'auto' }}
            onSubmit={confirm}
          >
            <p className="title">
              Need a new password?
            </p>

            <p className="desc">
              Please provide your email address and we'll send you instructions on how to change your password.
            </p>

            <Input
              value={email}
              label="Email"
              className="h_40"
              placeholder="info@fusionwindows.com"
              onChange={({ target: { value } }) => changeData(value)}
              error={error}
            />

            <Button
              title="Confirm"
              type="submit"
              loading={loading}
            />
          </form>
          <Link to="/login" className="goBack">
            Go Back To Log in
          </Link>
        </div>
      </div>
    </WrapperLogout>
  );
};

export default ForgotPassword;
