import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import Modal from '../modal/Modal';
import { getSingleEmailsRequest } from '../../store/actions/pipelines';
import Button from '../form/Button';
import Loader from '../loader/Loader';
import EmailForm from './EmailForm';

function SingleEmailModal(props) {
  const {
    id, pipeline, onClose, setData, emails,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reply, setReply] = useState(false);
  const [searchParams] = useSearchParams();

  const singleEmail = useSelector((state) => state.pipelines.singleEmail);

  useEffect(() => {
    (async () => {
      if (id) {
        setLoading(true);
        await dispatch(getSingleEmailsRequest({ id, pipeline }));
        setLoading(false);
      }
    })();
  }, [id, pipeline]);

  if (!singleEmail.html) {
    return null;
  }

  return (
    <Modal open onClose={onClose} className="singleEmailModal">
      {!loading ? (
        <div className="emailHtmlContainer">
          <div className="dest">
            {searchParams.get('dest')}
          </div>
          <Button title={!reply ? 'Reply' : 'Hide'} onClick={() => setReply(!reply)} />
          <div>
            <div dangerouslySetInnerHTML={{ __html: singleEmail.html }} />
          </div>
        </div>
      ) : <Loader />}
      {reply
        ? <div className="leadsEmail"><EmailForm setData={setData} emails={emails} onClose={onClose} /></div> : null}
    </Modal>
  );
}

export default SingleEmailModal;

SingleEmailModal.propTypes = {
  id: PropTypes.string.isRequired,
  pipeline: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  emails: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
};
