import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import Input from '../form/Input';
import Utils from '../../helpers/Utils';
import { additionalDataKey } from '../../store/reducers/leads';
import { ReactComponent as RemoveIcon } from '../../assets/icons/remove.svg';

function Details(props) {
  const { data, setData, errors } = props;

  return (
    <div className="details">

      <div className="additionalExpenses">
        <div className="title">
          Additional expenses
        </div>
        <div className="additionalExpenses">
          <div className="table">
            <table>
              <thead>
              <tr>
                <th>Service / Materials</th>
                <th>Quantity</th>
                <th>Unit Cost</th>
                <th>Line Total</th>
                <th style={{ width: 30 }} />
              </tr>
              </thead>
              <tbody>
              {data?.details?.additionalData?.map((a, i) => (
                <tr key={a[additionalDataKey]}>
                  <td>
                    <Input
                      placeholder="Your text here"
                      value={a.text}
                      onChange={(ev) => {
                        setData({ key: `details.additionalData[${i}].text`, value: ev.target.value });
                      }}
                      error={_.get(errors, `details.additionalData[${i}].text`, '') ? 'Field is required' : ''}
                    />
                  </td>
                  <td>
                    <Input
                      type="number"
                      value={a.qty || ''}
                      onChange={(ev) => {
                        setData({ key: `details.additionalData[${i}].qty`, value: ev.target.value });
                      }}
                      error={_.get(errors, `details.additionalData[${i}].qty`, '') ? 'Field is required' : ''}
                    />
                  </td>
                  <td>
                    <Input
                      type="number"
                      value={a.price || ''}
                      onChange={(ev) => {
                        setData({ key: `details.additionalData[${i}].price`, value: ev.target.value });
                      }}
                      error={_.get(errors, `details.additionalData[${i}].price`, '') ? 'Field is required' : ''}
                    />
                  </td>
                  <td className="total">
                    {a.price ? `$${Utils.formatPrice(a.qty * a.price)}` : null}
                  </td>
                  <td className="remove">
                    <div onClick={() => {
                      const newData = data.details.additionalData.filter((__, index) => i !== index);
                      setData({ key: 'details.additionalData', value: newData });
                    }}
                    >
                      <RemoveIcon />
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>

            </table>
            <div
              className="add"
              onClick={() => setData(
                {
                  key: `details.additionalData[${data.details?.additionalData?.length || 0}]`,
                  value: { [additionalDataKey]: _.uniqueId('additionalDataKey'), text: '' },
                },
              )}
            >
              <AddIcon />
              <p>Add new line</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;

Details.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};
