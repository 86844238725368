import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';
import Wrapper from '../../components/Wrapper';
import { connectGmailRequest, getProfileRequest } from '../../store/actions/account';
import { ReactComponent as GmailIcon } from '../../assets/icons/gmail.svg';
import Input from '../../components/form/Input';

const config = {
  scope: [
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/userinfo.email',
    'https://mail.google.com',
    'https://www.googleapis.com/auth/contacts.other.readonly',
    'https://www.googleapis.com/auth/contacts.readonly',
  ].join(' '),
  access_type: 'offline',
  include_granted_scopes: 'true',
  response_type: 'code',
  redirect_uri: `${window.location.origin}/settings/google/auth`,
  client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
};

const configSearch = Object.entries(config).map(([key, value]) => `${key}=${value}`).join('&');

function GoogleAuth() {
  const dispatch = useDispatch();
  const { googleData } = useSelector((state) => state.account.profile);
  const query = queryString.parse(window.location.search);
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      if (query.code) {
        const { payload } = await dispatch(connectGmailRequest({
          code: query.code,
          redirectUri: `${window.location.origin}/settings/google/auth`,
        }));

        if (payload.status === 'ok') {
          setSearchParams({});
          await dispatch(getProfileRequest());
        }
      }
    })();
  }, [query.code]);

  return (
    <Wrapper title="Connect Gmail">
      <div className="googleAuth">
        <a
          className="mainButton noBorder"
          href={`https://accounts.google.com/o/oauth2/v2/auth?${configSearch}`}
        >
          <div className="connectionButton">
            <GmailIcon />
            <span>{googleData ? 'Update Gmail' : 'Connect Gmail'}</span>
          </div>
        </a>
        {googleData ? (
          <div className="info">
            <Input readOnly value={googleData.name} label="Name" />
            <Input readOnly value={googleData.email} label="Email" />
          </div>
        ) : null}
      </div>
    </Wrapper>
  );
}

export default GoogleAuth;
