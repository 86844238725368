import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Wrapper from '../components/Wrapper';
import TableWithPagination from '../components/tables/TableWithPagination';
import Button from '../components/form/Button';
import { ReactComponent as AddIcon } from '../assets/icons/add.svg';
import { getContactsRequest } from '../store/actions/contacts';
import Api from '../Api';
import { useQuery } from '../helpers/hooks/useQuery';
import { contactsPermissions } from '../data/permissions';
import HasPermission from '../components/HasPermission';
import AddContactModal from '../components/contacts/AddContactModal';

const tableHeader = [
  {
    title: 'Full Name',
    placeholder: 'Full Name',
    path: 'name',
    required: true,
  },
  {
    title: 'Company name',
    placeholder: 'Company name',
    path: 'company',
  },
  {
    title: 'Phone number',
    placeholder: '+1(123)4567890',
    path: 'phone',
    validation: 'phone',
  },
  {
    title: 'Email',
    placeholder: 'Email',
    path: 'email',
    validation: 'email',
  },
  {
    title: 'Address',
    placeholder: 'Address',
    path: 'address',
  },
];

const Contacts = () => {
  const { query, setQuery } = useQuery();

  const dispatch = useDispatch();
  const { contactList, totalPages } = useSelector((state) => state.contacts);

  const [filters, setFilters] = useState({
    page: +query.page || 1,
    limit: +query.limit || 10,
  });
  const { page, limit } = filters;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loading, loadingToggle] = useState(true);

  useEffect(() => {
    (async () => {
      await dispatch(getContactsRequest(filters));
      loadingToggle(false);
    })();
  }, []);

  useEffect(() => {
    setQuery({ ...filters, ...Object.fromEntries(searchParams) });
  }, [filters, searchParams]);

  const onChangePage = useCallback(async (type, value) => {
    const requestData = { ...filters };

    requestData[type] = value;

    if (type === 'limit') {
      requestData.page = 1;
    }

    setFilters(requestData);

    await dispatch(getContactsRequest(requestData));
  }, [filters]);

  const deleteContact = useCallback(async (contact) => {
    await Api.deleteContact(contact.id);

    const requestData = { ...filters };
    requestData.page = contactList.length === 1 && page > 1 ? page - 1 : page;

    await dispatch(getContactsRequest(requestData));
    setFilters(requestData);

    toast.success('Contact deleted');
  }, [contactList, filters]);

  return (
    <Wrapper title="Contacts">
      <HasPermission edit={contactsPermissions}>
        <div className="contacts__content">
          <HasPermission edit="create_contact" read="create_contact_readonly">
            <div className="contacts__buttons__wrapper">
              <Button
                title={(
                  <>
                    <AddIcon />
                    Create
                  </>
                )}
                onClick={() => navigate('?create=contact')}
              />
            </div>
          </HasPermission>
          <HasPermission edit="get_contacts" read="get_contacts_readonly">
            <TableWithPagination
              data={contactList}
              header={tableHeader}
              page={page}
              limit={limit}
              firstLoad={loading}
              totalPages={totalPages}
              onChangePage={onChangePage}
              onEditClick={(d) => navigate(`?create=contact&con-id=${d.id}`)}
              onDeleteClick={deleteContact}
              editPermission="create_contact"
              readEditPermission="create_contact_readonly"
              deletePermission="delete_contact"
              readDeletePermission="delete_contact_readonly"
            />
          </HasPermission>
        </div>

      </HasPermission>
      <AddContactModal />
    </Wrapper>
  );
};

export default Contacts;
