import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const addTaskRequest = createAsyncThunk('tasks/addTaskRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.addTask(payload);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const getTasksRequest = createAsyncThunk('tasks/getTasksRequest', async (payload = {}) => {
  const { path, ...reqData } = payload;
  const { data } = await Api.getTasks(reqData);
  return { data, path };
});

export const deleteTaskRequest = createAsyncThunk('tasks/deleteTaskRequest', async (payload) => {
  const { data } = await Api.deleteTask(payload);
  return data;
});

export const getSingleTaskRequest = createAsyncThunk('tasks/getSingleTaskRequest', async (payload) => {
  const { data } = await Api.getSingleTask(payload);
  return data;
});
