import React, {
  useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import qs from 'query-string';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { ReactComponent as DotsIcon } from '../../assets/icons/dots.svg';

import { deletePipelineRequest, getPipelinesRequest } from '../../store/actions/pipelines';
import DeleteModal from '../modal/DeleteModal';
import ColumnHeaderDropdown from './ColumnHeaderDropdown';
import Automations from '../automation/Automations';

function ColumnHeader(props) {
  const {
    column: {
      color = '#849291', id, status, name,
    }, pipeline, count, total,
  } = props;
  const [open, setOpen] = useState(false);
  const [colId, setColId] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();

  const handleItemClick = useCallback((item) => {
    const query = {
      action: item.key, id, status,
    };
    if (item.key) {
      navigate(`?${qs.stringify(query)}`);
    } else {
      setColId(id);
    }
    setOpen(false);
  }, [id, status]);

  const handleDelete = useCallback(async () => {
    setLoading(true);
    const { payload: { data } } = await dispatch(deletePipelineRequest(colId));
    if (data.status === 'error') {
      toast.error(data.message);
    } else {
      await dispatch(getPipelinesRequest({ type: pipeline }));
      toast.success('Successfully deleted.');
    }

    setColId(null);
    setLoading(false);
  }, [colId]);

  return (
    <div className="columnHeader">
      <div className="main">
        <span className="border" style={{ background: color }} />
        <div className="titleBlock">
          <div>
            <p className="title">
              {name}
            </p>
            {!_.isNull(count) ? <span className="count">{count}</span> : null}
          </div>
          {total ? <p className="price">{total}</p> : null}
        </div>
        {pipeline !== 'task' ? (
          <div onClick={() => {
            setOpen(!open);
            setSearchParams({});
          }}
          >
            <DotsIcon className="icon" />
          </div>
        ) : null}
      </div>
      {open ? (
        <ColumnHeaderDropdown
          onClose={() => setOpen(false)}
          onItemClick={handleItemClick}
          pipeline={pipeline}
        />
      ) : null}
      <DeleteModal
        loading={loading}
        open={!!colId}
        text="Are you sure want to delete the list?"
        onClose={() => setColId(null)}
        onDelete={handleDelete}
      />
      <Automations pipeline={pipeline} id={id} />

    </div>
  );
}

export default ColumnHeader;

ColumnHeader.propTypes = {
  column: PropTypes.object.isRequired,
  pipeline: PropTypes.string.isRequired,
  count: PropTypes.number,
  total: PropTypes.string,
};
ColumnHeader.defaultProps = {
  count: null,
  total: '',
};
