import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Wrapper from '../../components/Wrapper';
import Tabs from '../../components/pipeline/Tabs';
import ColumnsDrag from '../../components/pipeline/ColumnsDrag';
import Button from '../../components/form/Button';
import {
  addPipelineLocal,
  createPipelineRequest,
  getPipelinesRequest, orderPipelineRequest,
} from '../../store/actions/pipelines';
import Utils from '../../helpers/Utils';
import { getUsersRequest } from '../../store/actions/users';
import { getContactsRequest } from '../../store/actions/contacts';

const tabs = [
  {
    path: '/leads',
    tab: 'pipeline',
  },
  {
    path: '/leads/list',
    tab: 'list',
  },
];

function EditLeadPipeline() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pipelines = useSelector((state) => state.pipelines.pipelines.lead);

  useEffect(() => {
    (async () => {
      const { payload: { data = {} } = {} } = await dispatch(getPipelinesRequest({ type: 'lead' }));
      if (!data.pipelines?.length) {
        const pipeline = [
          {
            name: 'Lead title',
            status: '',
            id: _.uniqueId('pipeline_col'),
          },
          {
            name: 'Lead title',
            status: '',
            id: _.uniqueId('pipeline_col'),
          },
          {
            name: 'Lead title',
            status: '',
            id: _.uniqueId('pipeline_col'),
          },
          {
            name: 'Lead title',
            status: '',
            id: _.uniqueId('pipeline_col'),
          },
        ];
        dispatch(addPipelineLocal({
          type: 'lead',
          data: pipeline,
        }));
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(getUsersRequest({ limit: 100 }));
    dispatch(getContactsRequest({ limit: 100 }));
  }, []);

  const handleSave = useCallback(async () => {
    setLoading(true);

    const promises = pipelines.map((p, i) => (
      dispatch(createPipelineRequest({
        name: p.name,
        status: _.camelCase(p.name),
        order: i + 1,
        type: 'lead',
        id: p.id.toString()
          .startsWith('pipeline_col') ? undefined : p.id,
      }))
    ));
    const data = await Promise.all(promises);
    const errors = [];
    data.forEach((d, i) => {
      const error = _.get(d.payload, 'data.errors', []);
      const status = _.get(d.payload, 'data.status');
      const message = _.get(d.payload, 'data.message');
      errors.push(...error);
      if (status === 'error') {
        errors.push({
          path: `message.${i}`,
          message,
        });
      }
    });
    const normErr = Utils.normalizeErrors(errors);
    await dispatch(orderPipelineRequest({ pipelines: pipelines.map((p, i) => ({ id: p.id, order: i + 1 })) }));

    if (!_.isEmpty(normErr)) {
      toast.error(Object.values(normErr).join(', '));
    } else {
      await dispatch(getPipelinesRequest({ type: 'lead' }));
      navigate('/leads/pipeline');
    }
    setLoading(false);
  }, [pipelines]);

  return (
    <Wrapper title="Leads">
      <div className="leads">
        <Tabs tabs={tabs}>
          <div className="actions">
            <Button title="Cancel" noBorder onClick={() => navigate('/leads/pipeline')} />
            <Button
              title="Save"
              onClick={handleSave}
              loading={loading}
            />
          </div>
        </Tabs>
        <ColumnsDrag />
      </div>
    </Wrapper>
  );
}

export default EditLeadPipeline;
