import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import RightModal from '../modal/RightModal';
import Select from '../form/Select';
import {
  createNotificationSettingRequest,
  getNotificationSettingRequest,
  getNotificationTemplatesRequest,
} from '../../store/actions/notification';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/remove.svg';
import Checkbox from '../form/Checkbox';
import Button from '../form/Button';
import Utils from '../../helpers/Utils';

const options = [
  {
    value: 'lead',
    label: 'Lead',
    when: [
      { value: 'created', label: 'Created' },
      { value: 'updated', label: 'Updated' },
      { value: 'assigned', label: 'Assigned' },
      { value: 'close', label: 'Close' }],
  },
  {
    value: 'job',
    label: 'Job',
    when: [
      { value: 'created', label: 'Created' },
      { value: 'updated', label: 'Updated' },
      { value: 'assigned', label: 'Assigned' },
    ],

  },
  {
    value: 'task',
    label: 'Task',
    when: [
      { value: 'created', label: 'Created' },
      { value: 'updated', label: 'Updated' },
      { value: 'assigned', label: 'Assigned' },
      { value: 'completed', label: 'Completed' },
      { value: 'reminder', label: 'Reminder' },
      { value: 'overdue', label: 'Overdue' }],

  },

];

const recipients = [
  { label: 'Admin', value: 'admin' },
  { label: 'Creator', value: 'creator' },
  { label: 'Responsible Person', value: 'responsiblePerson' },
  { label: 'Customer', value: 'contact' },
];

function AddNotificationSettings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [form, setForm] = useState({ items: [{ id: _.uniqueId('setting'), sendTo: ['email'] }] });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const notificationTemplates = useSelector((state) => state.notification.notificationTemplates);
  const notificationSettings = useSelector((state) => state.notification.notificationSettings);

  useEffect(() => {
    if (!_.isEmpty(notificationSettings)) {
      const items = [];
      if (!_.isEmpty(notificationSettings.lead)) {
        items.push({
          value: 'lead',
          id: _.uniqueId(),
          when: notificationSettings.lead.when,
          templateId: notificationSettings.lead.templateId,
          sendTo: notificationSettings.lead.sendTo,
          recipients: notificationSettings.lead.recipients,
        });
      }
      if (!_.isEmpty(notificationSettings.task)) {
        items.push({
          value: 'task',
          id: _.uniqueId(),
          when: notificationSettings.task.when,
          templateId: notificationSettings.task.templateId,
          sendTo: notificationSettings.task.sendTo,
          recipients: notificationSettings.task.recipients,
        });
      }
      if (!_.isEmpty(notificationSettings.job)) {
        items.push({
          value: 'job',
          id: _.uniqueId(),
          when: notificationSettings.job.when,
          templateId: notificationSettings.job.templateId,
          sendTo: notificationSettings.job.sendTo,
          recipients: notificationSettings.job.recipients,
        });
      }
      if (!_.isEmpty(notificationSettings.email)) {
        items.push({
          value: 'email',
          id: _.uniqueId(),
          when: notificationSettings.email.when,
          templateId: notificationSettings.email.templateId,
          sendTo: notificationSettings.email.sendTo,
          recipients: notificationSettings.email.recipients,
        });
      }
      const data = {
        items,
        id: notificationSettings.id,
      };
      setForm({ ...data });
    } else {
      setForm({ items: [{ id: _.uniqueId('setting'), sendTo: ['email'] }] });
    }
  }, [notificationSettings]);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const unsetErrors = useCallback((key) => {
    _.unset(errors, key);
    setErrors({ ...errors });
  }, [errors]);

  const getTemplates = useCallback(async (s) => {
    const { payload: { data } } = await dispatch(getNotificationTemplatesRequest(s ? { s } : undefined));
    return data.templates;
  }, []);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const { items, ...restData } = form;

    let d = {};
    items.forEach((i) => {
      if (i.value) {
        d = {
          ...d,
          [i.value]: {
            when: i.when, templateId: i.templateId, sendTo: i.sendTo, recipients: i.recipients,
          },
        };
      }
    });
    const requestData = { ...d, ...restData };
    const { payload } = await dispatch(createNotificationSettingRequest(requestData));
    if (payload.status === 'ok') {
      toast.success('Successfully saved.');
      setSearchParams({});
      await dispatch(getNotificationSettingRequest());
    } else if (payload.errors) {
      setErrors(Utils.normalizeErrors(payload.errors));
    } else {
      toast.error(payload.message);
    }
    setLoading(false);
  }, [form]);

  const handleCheck = useCallback((val, i) => {
    if (_.get(form, `items[${i}].sendTo`, [])?.includes(val)) {
      form.items[i].sendTo = form.items[i].sendTo.filter((s) => s !== val);
    } else if (form.items[i].sendTo) {
      form.items[i].sendTo = [...form.items[i].sendTo, val];
    } else {
      form.items[i].sendTo = [val];

    }
    setForm({ ...form });
  }, [form]);

  const deleteItem = useCallback((id) => {
    form.items = form.items.filter((i) => i.id !== id);
    setForm({ ...form });
  }, [form]);

  return (
    <RightModal
      open={searchParams.get('create') === 'setting'}
      headerText="Add settings"
      onClose={() => setSearchParams({})}
    >
      <form className="addNotificationSettings" onSubmit={handleSubmit}>
        {form.items.map((item, i) => {
          const whenOptions = item.value ? options.find((o) => o.value === item.value).when : [];
          return (
            <div key={item.id} className="item">
              <Select
                options={options}
                onChange={(val) => handleChange(`items[${i}].value`, val?.value)}
                isOptionDisabled={(o) => form.items.some((f) => f.value === o.value)}
                label="Category"
                isClearable
                value={options.find((o) => o.value === form.items[i].value)}
              />
              {i > 0 ? (
                <div className="deleteIcon" onClick={() => deleteItem(item.id)}>
                  <DeleteIcon />
                </div>
              ) : null}
              {item.value ? (
                <>
                  <div className="options">
                    <Select
                      options={whenOptions}
                      label="Execute"
                      isMulti
                      onChange={(val) => {
                        handleChange(`items[${i}].when`, val.map((v) => v.value));
                        unsetErrors(`${item.value}.when`);
                      }}
                      value={whenOptions.filter((o) => form.items[i].when?.includes(o.value))}
                      error={_.get(errors, `${item.value}.when`)}
                    />
                    <Select
                      label="Template"
                      isAsync
                      cacheOptions
                      loadOptions={getTemplates}
                      defaultOptions
                      getOptionValue={(o) => o.id}
                      getOptionLabel={(o) => o.title}
                      isClearable
                      onChange={(val) => {
                        handleChange(`items[${i}].templateId`, val?.id);
                        unsetErrors(`${item.value}.templateId`);
                      }}
                      value={notificationTemplates.find((o) => o.id === form.items[i].templateId)}
                      error={_.get(errors, `${item.value}.templateId`)}
                    />
                  </div>
                  <Select
                    options={recipients}
                    label="Recipients"
                    isMulti
                    onChange={(val) => {
                      handleChange(`items[${i}].recipients`, val.map((v) => v.value));
                      unsetErrors(`${item.value}.recipients`);
                    }}
                    value={recipients.filter((o) => form.items[i].recipients?.includes(o.value))}
                    error={_.get(errors, `${item.value}.recipients`)}
                  />
                  <Checkbox
                    label="Email"
                    onChange={() => handleCheck('email', i)}
                    checked={item.sendTo?.includes('email')}
                    error={!!errors.sendTo}
                  />
                  <Checkbox
                    label="Browser"
                    onChange={() => handleCheck('browser', i)}
                    checked={item.sendTo?.includes('browser')}
                    error={!!errors.sendTo}
                  />
                </>
              ) : null}
              <div className="border" />
            </div>
          );
        })}

        {form.items.length < 3 ? (
          <div
            onClick={() => {
              if (!form.items.some((f) => !f.value)) {
                handleChange(`items[${form.items.length}].id`, _.uniqueId());
              }
            }}
            className={classNames('addNewLineButton', { disabled: form.items.some((f) => !f.value) })}
          >
            <AddIcon />
            Add category
          </div>
        ) : null}

        <div className="actions">
          <Button title="Save settings" type="submit" loading={loading} />
          <Button title="Cancel" noBorder onClick={() => setSearchParams({})} />
        </div>
      </form>
    </RightModal>
  );
}

export default AddNotificationSettings;
