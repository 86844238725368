import { createReducer } from '@reduxjs/toolkit';
import { getPermissionsGroupRequest, getPermissionsRequest } from '../actions/permissions';

const initialState = {
  permissions: {
    list: [],
    page: 1,
    totalPages: 1,
  },
  permissionsGroup: {
    list: [],
    page: 1,
    totalPages: 1,
  },
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getPermissionsRequest.fulfilled, (store, action) => {
      const { path, data } = action.payload;
      if (!path) {
        store.permissions.list = data.permissions;
        store.permissions.page = +(data.page || 1);
        store.permissions.totalPages = data.totalPages;
      }
    })
    .addCase(getPermissionsGroupRequest.fulfilled, (store, action) => {
      const { path, data } = action.payload;
      if (!path) {
        store.permissionsGroup.list = data.permissionGroups;
        store.permissionsGroup.page = +(data.page || 1);
        store.permissionsGroup.totalPages = data.totalPages;
      }
    });
});

export default reducer;
