import React, { useState, useEffect, useId } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import classnames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow_down.svg';
import { ReactComponent as SelectArrowIcon } from '../../assets/icons/limit_arrow.svg';
import useEventCallback from '../../helpers/hooks/useEventCallback';

const Pagination = ({
  totalPages, page, limit, onChange, limitCounts, hideLimitCounts,
}) => {
  const uniqId = useId();
  const [showLimitCounts, limitCountsToggle] = useState(false);

  const selectLimit = (type, limitCount) => {
    if (type === 'open') {
      limitCountsToggle(!showLimitCounts);
    } else {
      onChange('limit', limitCount);
      limitCountsToggle(false);
    }
  };

  const closeMenu = useEventCallback((e) => {
    if (!e.target.closest(`[data-id="pagination_select_container_${uniqId}"]`) && showLimitCounts) {
      limitCountsToggle(false);
    }
  }, [showLimitCounts]);

  useEffect(() => {
    window.addEventListener('click', closeMenu, true);

    return () => {
      window.removeEventListener('click', closeMenu, true);
    };
  }, []);

  return (
    <div className="pagination__wrapper">
      {totalPages > 1
        ? (
          <ReactPaginate
            previousLabel={<ArrowIcon className="prev__arrow" />}
            nextLabel={<ArrowIcon />}
            pageCount={totalPages}
            forcePage={+page - 1}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={(e) => onChange('page', e.selected + 1)}
            containerClassName="pagination__container"
            activeClassName="pagination__page__active"
            disabledClassName="pagination__page__disabled"
          />
        )
        : <div />}

      {!hideLimitCounts && (
        <div className="pagination__limit__wrapper">
          <button
            type="button"
            data-id={`pagination_select_container_${uniqId}`}
            onClick={() => selectLimit('open')}
          >
            <div>{limit}</div>

            <SelectArrowIcon
              className={classnames('pagination__limit__arrow', { limit__show: showLimitCounts })}
            />

            {showLimitCounts && (
              <div className="pagination__limit__items">
                {limitCounts.map((count) => (
                  limit !== count
                  && (
                    <div
                      role="button"
                      tabIndex="0"
                      onClick={() => selectLimit('close', count)}
                      key={count}
                    >
                      {count}
                    </div>
                  )
                ))}
              </div>
            )}
          </button>

          <p>Entries per page</p>
        </div>
      )}
    </div>
  );
};

Pagination.propTypes = {
  onChange: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  limitCounts: PropTypes.array,
  hideLimitCounts: PropTypes.bool,
};

Pagination.defaultProps = {
  totalPages: 1,
  page: 1,
  limit: 10,
  limitCounts: [10, 30, 50, 100],
  hideLimitCounts: false,
};

export default Pagination;
