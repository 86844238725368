import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  getProfileRequest, getUserContactsFromGoogleRequest, globalSearchRequest, loginRequest, removeGlobalSearchData,
} from '../actions/account';
import Account from '../../helpers/Account';
import { getLeadsRequest } from '../actions/leads';
import { getJobsRequest } from '../actions/jobs';
import { getInvoicesRequest } from '../actions/invoices';
import { getContactsRequest } from '../actions/contacts';
import { getTasksRequest } from '../actions/tasks';
import { getEmailTemplatesRequest } from '../actions/templates';
import { getLeadSourcesRequest } from '../actions/leadSources';
import { getUsersRequest } from '../actions/users';
import { getPermissionsGroupRequest } from '../actions/permissions';

const initialState = {
  token: Account.getToken(),
  profile: Account.get(),
  globalSearchResult: {},
  contactsFromGoogle: [],
};
export default createReducer(initialState, (builder) => {
  builder
    .addCase(loginRequest.fulfilled, (state, action) => {
      const { data, remember } = action.payload;
      state.token = data.token;
      state.profile = data.user;
      Account.setToken(data.token, remember);
      Account.set(data.user);
    })
    .addCase(getProfileRequest.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.profile = data.user;
      Account.set(data.user);
    })
    .addCase(globalSearchRequest.fulfilled, (state, action) => {
      const search = {};
      _.forEach(action.payload.data, (val, key) => {
        if (_.isArray(val)) {
          search[key] = val;
        }
      });
      state.globalSearchResult = search;
    })
    .addCase(getLeadsRequest.fulfilled, (state, action) => {
      const {
        data: { leads }, path,
      } = action.payload;
      if (path) {
        state.globalSearchResult = { leads };
      }
    })
    .addCase(getJobsRequest.fulfilled, (state, action) => {
      const { data: { jobs }, path } = action.payload;
      if (path) {
        state.globalSearchResult = { jobs };
      }
    })
    .addCase(getInvoicesRequest.fulfilled, (state, action) => {
      const { data: { invoices }, path } = action.payload;
      if (path) {
        state.globalSearchResult = { invoices };
      }
    })
    .addCase(getContactsRequest.fulfilled, (state, action) => {
      const { data: { contacts }, path } = action.payload;
      if (path) {
        state.globalSearchResult = { contacts };
      }
    })
    .addCase(getTasksRequest.fulfilled, (state, action) => {
      const { data: { tasks }, path } = action.payload;
      if (path) {
        state.globalSearchResult = { tasks };
      }
    })
    .addCase(getEmailTemplatesRequest.fulfilled, (state, action) => {
      const { data: { templates }, path } = action.payload;
      if (path) {
        state.globalSearchResult = { templates };
      }
    })
    .addCase(getLeadSourcesRequest.fulfilled, (state, action) => {
      const { data: { leadSources }, path } = action.payload;
      if (path) {
        state.globalSearchResult = { leadSources };
      }
    })
    .addCase(getUsersRequest.fulfilled, (state, action) => {
      const { data: { users }, path } = action.payload;
      if (path) {
        state.globalSearchResult = { users };
      }
    })
    .addCase(getPermissionsGroupRequest.fulfilled, (state, action) => {
      const { data: { permissionGroups }, path } = action.payload;
      if (path) {
        state.globalSearchResult = { permissions: permissionGroups };
      }
    })
    .addCase(getUserContactsFromGoogleRequest.fulfilled, (state, action) => {

    })
    .addCase(removeGlobalSearchData, (state) => {
      state.globalSearchResult = {};
    });
});
