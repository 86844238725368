import React from 'react';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';

function LazyWrapper(props) {
  const { children, scrollContainer, total } = props;
  if (total < 500) {
    return children;
  }
  return (
    <LazyLoad
      unmountIfInvisible
      overflow
      height={122}
      scrollContainer={scrollContainer}
    >
      {children}
    </LazyLoad>
  );
}

export default LazyWrapper;

LazyWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  scrollContainer: PropTypes.object,
  total: PropTypes.number.isRequired,
};
LazyWrapper.defaultProps = {
  scrollContainer: undefined,
};
