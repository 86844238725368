import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Wrapper from '../../components/Wrapper';
import HasPermission from '../../components/HasPermission';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import AddNotificationSettings from '../../components/notification/AddNotificationSettings';
import { getNotificationSettingRequest, getNotificationTemplatesRequest } from '../../store/actions/notification';
import Loader from '../../components/loader/Loader';
import Input from '../../components/form/Input';
import Checkbox from '../../components/form/Checkbox';

function NotificationSettings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const notificationSettings = useSelector((state) => state.notification.notificationSettings);
  const notificationTemplates = useSelector((state) => state.notification.notificationTemplates);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getNotificationSettingRequest());
      await dispatch(getNotificationTemplatesRequest());
      setLoading(false);
    })();
  }, []);

  return (
    <Wrapper title="Notification settings">
      {!loading ? (
        <div className="contacts__content notificationSettings">
          <div className="contacts__buttons__wrapper">
            <HasPermission read="save_notification_settings_readonly" edit="save_notification_settings">
              <div
                onClick={() => setSearchParams({ create: 'setting' })}
                className={classNames('mainButton', { edit: !_.isEmpty(notificationSettings) })}
              >
                {_.isEmpty(notificationSettings)
                  ? (
                    <>
                      <AddIcon />
                      Create
                    </>
                  )
                  : (
                    <>
                      <EditIcon />
                      Edit
                    </>
                  )}
              </div>
            </HasPermission>
          </div>
          {!_.isEmpty(notificationSettings) ? (
            <div className="notificationSettingsContent">
              {!_.isEmpty(notificationSettings.lead)
                ? (
                  <div className="row">
                    <p className="category">Lead:</p>
                    <p className="when">When</p>
                    <Input
                      readOnly
                      label="Execute"
                      value={notificationSettings.lead.when.map((w) => _.startCase(w)).join(', ')}
                    />
                    <Input
                      readOnly
                      label="Template"
                      value={notificationTemplates.find((t) => t.id === notificationSettings.lead.templateId)?.title}
                    />
                      <Checkbox
                        label="Email"
                        readOnly
                        checked={notificationSettings.lead.sendTo.includes('email')}
                      />
                      <Checkbox
                        label="Browser"
                        readOnly
                        checked={notificationSettings.lead.sendTo.includes('browser')}
                      />
                    <div className="border" />
                  </div>
                ) : null}
              {!_.isEmpty(notificationSettings.job)
                ? (
                  <div className="row">
                    <p className="category">Job:</p>
                    <p className="when">When</p>
                    <Input
                      readOnly
                      label="Execute"
                      value={notificationSettings.job.when.map((w) => _.startCase(w)).join(', ')}
                    />
                    <Input
                      readOnly
                      label="Template"
                      value={notificationTemplates.find((t) => t.id === notificationSettings.job.templateId)?.title}
                    />
                      <Checkbox
                        label="Email"
                        readOnly
                        checked={notificationSettings.job.sendTo.includes('email')}
                      />
                      <Checkbox
                        label="Browser"
                        readOnly
                        checked={notificationSettings.job.sendTo.includes('browser')}
                      />
                    <div className="border" />
                  </div>
                ) : null}
              {!_.isEmpty(notificationSettings.task)
                ? (
                  <div className="row">
                    <p className="category">Task:</p>
                    <p className="when">When</p>
                    <Input
                      readOnly
                      label="Execute"
                      value={notificationSettings.task.when.map((w) => _.startCase(w)).join(', ')}
                    />
                    <Input
                      readOnly
                      label="Template"
                      value={notificationTemplates.find((t) => t.id === notificationSettings.task.templateId)?.title}
                    />
                      <Checkbox
                        label="Email"
                        readOnly
                        checked={notificationSettings.task.sendTo.includes('email')}
                      />
                      <Checkbox
                        label="Browser"
                        readOnly
                        checked={notificationSettings.task.sendTo.includes('browser')}
                      />
                    <div className="border" />
                  </div>
                ) : null}

            </div>
          ) : <div className="noData">No settings yet</div>}
        </div>
      ) : null}
      {loading ? <Loader /> : null}
      {searchParams.get('create') === 'setting' ? <AddNotificationSettings /> : null}
    </Wrapper>
  );
}

export default NotificationSettings;
