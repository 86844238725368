import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Wrapper from '../../components/Wrapper';
import Api from '../../Api';

function GoToAuth() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const navigate = useNavigate();

  useEffect(() => {
    if (code) {
      (async () => {
        try {
          const { data } = await Api.authorizeGoto(code);
          if (data.status === 'ok') {
            navigate('/settings/goto');
          }
        } catch (e) {
          alert(JSON.stringify(e.response));
        }
      })();
    }
  }, [code]);
  return (
    <Wrapper title="GoTo">
      <div>
        Getting GoTo code.
      </div>
    </Wrapper>
  );
}

export default GoToAuth;
