import React, { useCallback, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Select from '../form/Select';
import DatePicker from '../form/DatePicker';
import Input from '../form/Input';
import Button from '../form/Button';
import Utils from '../../helpers/Utils';
import Api from '../../Api';

const fields = [
  {
    title: 'Amount',
    path: 'amount',
  },
  {
    title: 'Date',
    path: 'date',
    type: 'date',
  },
  {
    title: 'Payment method',
    path: 'method',
    type: 'select',
  },
];

const paymentTypes = [
  {
    label: 'Cash',
    value: 'cash',
  },
  {
    label: 'Credit card',
    value: 'creditCard',
  },
  {
    label: 'Bank transfer',
    value: 'bankTransfer',
  },
  {
    label: 'Customer payment',
    value: 'customerPayment',
  },
  {
    label: 'Check',
    value: 'check',
  },
];
const InvoicePayment = ({
  paymentInfo, closeModal, setPaymentInfo, innerRef,
}) => {
  const [paymentData, setPaymentData] = useState({});

  const [errors, setErrors] = useState({});
  const [saveLoading, saveLoadingToggle] = useState(false);

  useLayoutEffect(() => {
    setPaymentData(paymentInfo);
  }, [paymentInfo]);

  const changePayment = useCallback((path, value) => {
    setPaymentData((prev) => ({ ...prev, [path]: value }));

    setErrors((prev) => ({ ...prev, [path]: '' }));
  }, []);

  const onKeyPress = useCallback((e, isPaste) => {
    const maxValue = paymentInfo.amount;

    Utils.keyPressCurrency(e, isPaste, maxValue, 2);
  }, [paymentInfo]);

  const savePayment = useCallback(async (e) => {
    e.preventDefault();

    let hasError = false;

    fields.forEach(({ title, path }) => {
      if (!paymentData[path]) {
        setErrors((prev) => ({
          ...prev,
          [path]: `${title} is required`,
        }));

        hasError = true;
      }
    });

    if (!hasError) {
      saveLoadingToggle(true);

      try {
        await Api.createInvoicePayment(paymentData);

        const { data } = await Api.getInvoices({ id: paymentData.invoiceId });

        const invoice = data.invoices[0];
        innerRef.status = invoice.status;
        innerRef.payment = invoice.payment;
        innerRef.total = invoice.total;

        const amount = invoice.status === 'paid'
          ? 0
          : +(+invoice.total - _.sumBy(invoice.payment, (p) => +p.amount)).toFixed(2);

        if (+amount) {
          setPaymentInfo(() => ({ ...paymentData, amount }));
        } else {
          closeModal();
        }

        toast.success(<span>
          <b>Success!</b>

          {' Payment successfully registered'}
        </span>);
      } catch (err) {
        toast.error('Please correct these fields');

        if (err.response.data.errors) {
          setErrors(Utils.normalizeErrors(err.response.data.errors));
        } else {
          setErrors((prev) => ({
            ...prev,
            invoiceNumber: err.response.data.message,
          }));
        }
      }

      saveLoadingToggle(false);
    }
  }, [paymentData]);

  return (
    <form onSubmit={savePayment}>
      {fields.map(({
        title, path, type,
      }) => (
        <div key={title} className="new__contact__input__wrapper invoice__payment">
          {type === 'select'
            ? (
              <Select
                label={title}
                options={paymentTypes}
                value={paymentTypes?.find((p) => (p.value === paymentData[path])) || null}
                onChange={(o) => changePayment(path, o.value)}
                isSearchable={false}
                error={errors[path]}
              />
            )
            : type === 'date'
              ? (
                <DatePicker
                  label={title}
                  date={paymentData[path]}
                  setDate={(date) => changePayment(path, date)}
                  error={errors[path]}
                />
              )
              : (
                <Input
                  label={title}
                  currency="$"
                  placeholder={`$${paymentInfo?.amount}`}
                  value={paymentData[path]}
                  onChange={(e) => changePayment(path, e.target.value)}
                  onKeyPress={(e) => onKeyPress(e, false)}
                  onPaste={(e) => onKeyPress(e, true)}
                  error={errors[path]}
                />
              )}

        </div>
      ))}
      <div className="new__contact__input__wrapper invoice__payment">
        {['check', 'creditCard'].includes(paymentData.method) ? (
          <div className="cartNumber">
            <Input
              label="Cart number"
              onChange={(e) => changePayment('cartNumber', e.target.value)}
              value={paymentData.cartNumber}
              type="number"
            />
          </div>
        ) : null }
      </div>

      <div className="new__users__buttons__wrapper">
        <div className="new__contact__buttons__wrapper">
          <Button
            title="Save"
            loading={saveLoading}
            type="submit"
          />

          <Button
            title="Cancel"
            noBorder
            type="button"
            onClick={closeModal}
          />
        </div>
      </div>
    </form>
  );
};

InvoicePayment.propTypes = {
  paymentInfo: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  setPaymentInfo: PropTypes.func.isRequired,
  innerRef: PropTypes.object,
};

InvoicePayment.defaultProps = {
  innerRef: {},
};

export default InvoicePayment;
