import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getLeadsRequest = createAsyncThunk('leads/getLeadsRequest', async (payload = {}) => {
  const { path, ...reqData } = payload;
  const { data } = await Api.getLeads(reqData);
  return { data, path };
});

export const addLeadRequest = createAsyncThunk('leads/addLeadRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.addLead(payload);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const sendLeadEmailRequest = createAsyncThunk('leads/sendLeadEmailRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.sendLeadEmail(payload);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const deleteLeadNoteRequest = createAsyncThunk('leads/deleteLeadNoteRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.deleteLeadNote(payload);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const getLeadSourcesRequest = createAsyncThunk('leads/getLeadSourcesRequest', async (payload) => {
  const { data } = await Api.getLeadSources(payload);
  return data;
});

export const deleteLeadRequest = createAsyncThunk('leads/deleteLeadRequest', async (payload) => {
  const { data } = await Api.deleteLead(payload);
  return data;
});

export const getSingleLeadRequest = createAsyncThunk('leads/getSingleLeadRequest', async (payload) => {
  const { data } = await Api.getSingleLead(payload);
  return data;
});

export const setLeadData = createAction('leads/setLeadData');
export const unsetLeadFormErrors = createAction('leads/unsetLeadFormErrors');
export const removeLeadData = createAction('leads/removeLeadData');
