import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Loader from '../loader/Loader';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import Pagination from '../pagination/Pagination';
import DeleteModal from '../modal/DeleteModal';
import { deleteTaskRequest, getTasksRequest } from '../../store/actions/tasks';
import HasPermission from '../HasPermission';

const header = [
  { title: 'Status', path: 'status' },
  { title: 'Type', path: 'type' },
];

function TasksList(props) {
  const {
    onPageChange, page, limit,
  } = props;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [taskId, setTaskId] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const tasks = useSelector((state) => state.tasks.tasks);
  const tasksPages = useSelector((state) => state.tasks.tasksPages);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getTasksRequest({ page, limit }));
      setLoading(false);
    })();
  }, [page, limit]);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    const { payload } = await dispatch(deleteTaskRequest(taskId));
    if (payload?.status === 'ok') {
      await dispatch(getTasksRequest({ page, limit }));
      toast.success('Successfully deleted!');
    } else {
      toast.error('Something went wrong.');
    }
    setTaskId(null);
    setLoadingDelete(false);
  }, [taskId]);

  return (
    <div className="leadsList">
      <div className="table__wrapper leadsTable">
        {loading ? (
          <div className="loader__wrapper">
            <Loader size={60} borderWidth={4} />
          </div>
        ) : null}

        {!loading && !_.isEmpty(tasks) ? (
          <table
            style={{ minWidth: 165 }}
            className="table"
          >
            <thead className="thead">
              <tr className="tr">
                {header.map((h) => (
                  <th className="th" key={h.title}>{h.title}</th>
                ))}

                <th style={{ width: 165 }} className="th">Actions</th>
              </tr>
            </thead>

            <tbody className="tbody">
              {tasks.map((d) => (
                <tr
                  className="tr"
                  key={d.id}
                >
                  <td
                    className="td"
                  >
                    {_.startCase(d.status)}
                  </td>
                  <td
                    className="td"
                  >
                    {_.startCase(d.type)}
                  </td>
                  <td style={{ width: 165 }} className="th actions__wrapper">
                    <HasPermission edit="create_task" read="create_task_readonly">
                      <EditIcon
                        className="action__edit__icon"
                        onClick={() => navigate(`?create=task&id=${d.id}`)}
                      />
                    </HasPermission>
                    <HasPermission edit="delete_task" read="delete_task_readonly">
                      <DeleteIcon onClick={() => setTaskId(d.id)} />
                    </HasPermission>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {_.isEmpty(tasks) && !loading ? <div className="noData">No tasks yet.</div> : null}
      </div>
      {!_.isEmpty(tasks) ? (
        <Pagination
          page={page}
          totalPages={tasksPages}
          limit={limit}
          onChange={onPageChange}
        />
      ) : null}
      <DeleteModal
        loading={loadingDelete}
        open={!!taskId}
        text="Are you sure want to delete the task?"
        onClose={() => setTaskId(null)}
        onDelete={handleDelete}
      />
    </div>
  );
}

export default TasksList;

TasksList.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
};
