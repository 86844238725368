import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getLeadSourcesRequest = createAsyncThunk('leadSources/get', async (payload = {}) => {
  const { path, ...reqData } = payload;
  const { data } = await Api.getLeadSources(reqData);
  return { data, path };
});

export const getSingleLeadSourceRequest = createAsyncThunk('leadSources/getSingleLeadSourceRequest', async (payload = {}) => {
  const { data } = await Api.getLeadSources(payload);
  return data;
});
