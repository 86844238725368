import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import Api from '../../Api';

export const makeCallRequest = createAsyncThunk('call/makeCallRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.makeCall(payload);
    return { data, callId: data.callId };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const hangupCallRequest = createAsyncThunk('call/hangupCallRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.hangupCall(payload);
    return { data, callId: payload.callId };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const manageCallRequest = createAsyncThunk('call/manageCallRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.manageCall(payload);
    return { data, callId: payload.callId, type: payload.type };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const answerCallRequest = createAsyncThunk('call/answerCallRequest', async (payload, thunkAPI) => {
  try {
    const calls = Object.values(thunkAPI.getState().call.calls); // todo
    if (!_.isEmpty(calls)) {
      await Promise.all(calls.map((c) => thunkAPI.dispatch(manageCallRequest({
        type: 'hold', sessionId: c.content.sessionId, callId: c.content.callId,
      }))));
    }
    const { data } = await Api.answerCall(payload);
    return { data, callId: payload.callId };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const rejectCallRequest = createAsyncThunk('call/rejectCallRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.rejectCall(payload);
    return { data, callId: payload.callId };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const getActiveCallsRequest = createAsyncThunk('call/getActiveCallsRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.getActiveCalls(payload);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const getCallsRequest = createAsyncThunk('call/getCallsRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.getCalls(payload);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const setCallStatus = createAction('call/setCallStatus');
