import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getEmailTemplatesRequest } from '../../store/actions/templates';
import Button from '../form/Button';
import Emails from './Emails';
import EmailForm from './EmailForm';
import SingleEmailModal from './SingleEmailModal';

function Email(props) {
  const {
    setData,
    emails,
    pipeline,
    email,
  } = props;

  const dispatch = useDispatch();
  const [emailId, setEmailId] = useState(null);
  const [sendEmail, setSendEmail] = useState(false);
  useEffect(() => {
    dispatch(getEmailTemplatesRequest());
  }, []);

  return (
    <div className="leadsEmail">
      <Emails pipeline={pipeline} setEmailId={(id) => setEmailId(id)} />
      <Button
        title={!sendEmail ? 'Send Email' : 'Hide'}
        onClick={() => setSendEmail(!sendEmail)}
        style={{ marginBottom: 20 }}
        outlined
      />
      {sendEmail ? <EmailForm setData={setData} emails={emails} email={email} /> : null}
      {emailId ? (
        <SingleEmailModal
          id={emailId}
          pipeline={pipeline}
          setData={setData}
          emails={emails}
          onClose={() => setEmailId(null)}
        />
      ) : null}
    </div>
  );
}

export default Email;

Email.propTypes = {
  emails: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  pipeline: PropTypes.string.isRequired,
  email: PropTypes.string,
};

Email.defaultProps = {
  email: ''
};
