import React, { useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function CreateInfoWrapper(props) {
  const {
    tabs, tab, onTabChange, errors, children,
  } = props;
  const errKeys = useMemo(() => Object.keys(errors), [errors]);
  return (
    <div className="createLeadInfoWrapper">
      <div className="tabs">
        {tabs.map((t) => (
          <p
            key={t}
            onClick={() => onTabChange(t)}
            className={classNames({
              active: tab === t,
              error: errKeys.includes(t) || errKeys.includes(`${t}s`),
            })}
          >
            {_.startCase(t)}
          </p>
        ))}
      </div>
      {children}
    </div>
  );
}

export default CreateInfoWrapper;

CreateInfoWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  tab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  tabs: PropTypes.array,
  errors: PropTypes.object,
};

CreateInfoWrapper.defaultProps = {
  tabs: ['activity', 'notes', 'emails', 'job_quotation', 'task', 'calls'],
  errors: {},
};
