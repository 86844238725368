import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import _ from 'lodash';

export const useQuery = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [queryString] = useSearchParams();
  const query = useMemo(() => Object.fromEntries(queryString), [queryString]);

  const queryToStringify = (object) => {
    const arrayWithQuery = Object.entries(object)
      .filter((param) => (!!param[1] && !_.isArray(param[1])) || !_.isEmpty(_.compact(param[1])))
      .map((param) => param.join('='));

    return `?${arrayWithQuery.join('&')}`;
  };

  const setQuery = (search) => navigate({
    pathname,
    search: queryToStringify(search),
  }, { replace: true });

  return {
    query,
    setQuery,
    queryToStringify,
  };
};
