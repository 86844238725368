import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Tooltip from 'rc-tooltip';
import ColumnHeader from './ColumnHeader';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import AddQuickLead from '../leads/AddQuickLead';
import Loader from '../loader/Loader';
import {
  archivePipelineElementRequest,
  changePipelineElementStatusLocal,
  changePipelineElementStatusRequest,
} from '../../store/actions/pipelines';
import AddQuickJob from '../jobs/AddQuickJob';
import Utils from '../../helpers/Utils';
import usePermission from '../../helpers/hooks/usePermission';
import FakeColumnHeader from './FakeColumnHeader';
import LazyWrapper from './LazyWrapper';
import { ReactComponent as DotsIcon } from '../../assets/icons/dots.svg';
import { ReactComponent as ArchiveIcon } from '../../assets/icons/archive.svg';
import DeleteModal from '../modal/DeleteModal';

function DraggableContainer(props) {
  const {
    loading,
    pipeline,
    pipelineData,
  } = props;
  const draggableContainerRef = useRef();

  const [addingIds, setAddingIds] = useState([]);
  const [fakeColsCount, setFakeCalsCount] = useState(0);
  const [archivingId, setArchivingId] = useState(null);
  const [archivingConfirmation, setArchivingConfirmation] = useState(null);
  const [loadingArchive, setLoadingArchive] = useState(false);

  const changeStatusAllow = usePermission(`change_${pipeline}_status`);
  const editAllow = usePermission(`create_${pipeline}`);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pipelines = useSelector((state) => state.pipelines.pipelines[pipeline]);

  useEffect(() => {
    if (draggableContainerRef.current) {
      const { width } = draggableContainerRef.current.getBoundingClientRect();
      if (pipelines.length * 240 < width) {
        const count = Math.round((width - pipelines.length * 240) / 240);
        setFakeCalsCount(count);
      } else {
        setFakeCalsCount(0);
      }
    }
  }, [draggableContainerRef, pipelines]);

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('mouseup', handleCloseEvent);
    }, 0);
    return () => {
      window.removeEventListener('mouseup', handleCloseEvent);
    };
  }, []);

  const handleCloseEvent = useCallback((ev) => {
    if (!(ev.target.closest('.dotsIcon') || ev.target.closest('.dropdown'))) {
      setArchivingId(null);
    }
  }, []);

  const onDragEnd = useCallback(async (result) => {
    const {
      source,
      destination,
      draggableId,
    } = result;

    if (!destination) {
      return null;
    }

    const [status] = destination.droppableId.split('_');
    const [sourceStatus] = source.droppableId.split('_');
    const [, id] = draggableId.split('_');
    if (sourceStatus === status) {
      let data = Utils.arrayMove(pipelineData[status], source.index, destination.index);
      data = data.map((d, i) => ({
        ...d,
        order: i + 1,
      }));
      await dispatch(changePipelineElementStatusLocal({
        id,
        status,
        pipeline,
        data,
      }));

      dispatch(changePipelineElementStatusRequest({
        pipeline, [`${pipeline}s`]: data.map((p) => ({ id: p.id, status, order: p.order })),
      }));
      return null;
    }
    let data = pipelineData[status] ? [...pipelineData[status]] : [];
    const lead = Object.values(pipelineData).flat().find((j) => j.id === +id);
    data.splice(destination.index, 0, lead);
    data = data.map((d, i) => ({
      ...d,
      order: i + 1,
    }));
    await dispatch(changePipelineElementStatusLocal({
      id,
      status,
      pipeline,
      sourceStatus,
      data,
    }));
    dispatch(changePipelineElementStatusRequest({
      pipeline,
      [`${pipeline}s`]: data.map((p) => ({
        id: p.id, status, order: p.order, changed: +id === p.id,
      })),
    }));
    return null;
  }, [pipelineData]);

  const handleSetAddingIds = useCallback((id) => {
    setAddingIds([...addingIds, id]);
  }, [addingIds]);

  const handleRemoveAddingIds = useCallback((id) => {
    setAddingIds(addingIds.filter((a) => a !== id));
  }, [addingIds]);

  const passedDateColor = useCallback((date) => {
    if (pipeline === 'task' && !moment().isBefore(moment(date))) {
      return '#E40000';
    }
    return 'transparent';
  }, [pipeline]);

  const handleArchive = useCallback(async (id) => {
    setLoadingArchive(true);
    await dispatch(archivePipelineElementRequest({ pipeline, id }));
    setLoadingArchive(false);
    setArchivingConfirmation(null);
    setArchivingId(null);
  }, [pipeline]);

  return (
    <div className="draggableContainer" ref={draggableContainerRef}>
      {loading ? (
        <div className="loader__wrapper">
          <Loader size={60} borderWidth={4} />
        </div>
      ) : null}
      {!loading ? (
        <>
          <div className="columnHeaders">
            {pipelines.map((item) => {
              const calData = _.find(pipelineData, (data, status) => status === item.status);
              const total = Utils.formatPrice(_.sumBy(calData, 'sale'));
              return (
                <ColumnHeader
                  column={item}
                  key={item.id}
                  pipeline={pipeline}
                  count={calData?.length || 0}
                  total={total ? `$${total}` : '$0'}
                />
              );
            })}
            {fakeColsCount ? _.range(0, fakeColsCount).map((i) => (
              <FakeColumnHeader key={i} />
            )) : null}
          </div>
          <div className="draggableCol">
            <DragDropContext onDragEnd={onDragEnd}>
              {pipelines.map((el) => (
                <Droppable
                  key={el.id.toString()}
                  droppableId={`${el.status}_${el.id}`}
                >
                  {(provided) => (
                    <div className="droppableColWrapper">
                      {['job', 'lead'].includes(pipeline) ? (
                        <button
                          type="button"
                          className={classNames('addButton', { open: addingIds.includes(el.id) })}
                          onClick={() => handleSetAddingIds(el.id)}
                        >
                          <AddIcon />
                        </button>
                      ) : null}
                      {addingIds.includes(el.id) && pipeline === 'lead'
                        ? <AddQuickLead onClose={() => handleRemoveAddingIds(el.id)} status={el.status} />
                        : null}
                      {addingIds.includes(el.id) && pipeline === 'job'
                        ? <AddQuickJob onClose={() => handleRemoveAddingIds(el.id)} status={el.status} />
                        : null}
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="droppableCol"
                      >
                        {pipelineData[el.status]?.map((item, index) => (
                          <LazyWrapper
                            key={item.id}
                            total={pipelineData[el.status].length}
                            scrollContainer={draggableContainerRef.current}
                          >
                            <Draggable
                              draggableId={`${el.status}_${item.id}`}
                              index={index}
                              isDragDisabled={!changeStatusAllow}
                            >
                              {(p) => (

                                <div
                                  ref={p.innerRef}
                                  {...p.draggableProps}
                                  {...p.dragHandleProps}
                                  className={classNames(
                                    'draggable',
                                    { deleted: item.responsiblePersons?.status === 'deleted' },
                                  )}
                                  style={{
                                    ...p.draggableProps.style,
                                    border: `1px solid ${passedDateColor(item.dueDate)}`,
                                    background: item.stateColor ? `${item.stateColor}1A` : null,
                                  }}
                                >
                                  <div
                                    className="dotsIcon"
                                    onClick={() => setArchivingId(!archivingId ? item.id : null)}
                                  >
                                    <DotsIcon />
                                  </div>
                                  {archivingId === item.id ? (
                                    <ul className="dropdown">
                                      <li
                                        onClick={() => setArchivingConfirmation(archivingId)}
                                        className="dropDownItem"
                                      >
                                        <ArchiveIcon />
                                        <span>{`Archive ${pipeline}`}</span>
                                      </li>
                                    </ul>
                                  ) : null}

                                  <div
                                    onClick={() => {
                                      if (editAllow) {
                                        return (pipeline !== 'task'
                                          ? navigate(`/${pipeline}s/edit/${item.id}`)
                                          : navigate(`?create=task&id=${item.id}`));
                                      }
                                      return null;
                                    }}
                                  >
                                    {item.responsiblePersons?.status === 'deleted' ? (
                                      <div className="infoIconContainer">
                                        <Tooltip
                                          overlay={`The responsible person of this ${pipeline} is deactivated.`}
                                          placement="top"
                                        >
                                          <InfoIcon />
                                        </Tooltip>
                                      </div>
                                    ) : null}
                                    <p className="name">
                                      {item.contacts?.name || item.contact?.name}
                                    </p>
                                    <p className="task">
                                      {
                                        `${item.responsiblePersons?.firstName || ''} ${item.responsiblePersons?.lastName || ''}`
                                      }
                                    </p>
                                    {pipeline !== 'task'
                                      ? <p className="sale">{`$${Utils.formatPrice(item.sale) || 0}`}</p>
                                      : null}
                                    <p className="task">{item.company}</p>
                                    <div className="info">
                                      <div className="date">
                                        <CalendarIcon className="icon" />
                                        <span>
                                          {moment(item.updatedAt)
                                            .format('M/D/YYYY')}
                                        </span>
                                      </div>
                                      {pipeline === 'lead' ? (
                                        <div className="taskId">
                                          {item.leadId}
                                        </div>
                                      ) : null}
                                    </div>
                                    {['job', 'lead'].includes(pipeline) ? (
                                      <button
                                        className={classNames('status', {
                                          task: item.hasTask,
                                          noTasks: !item.hasTask,
                                        })}
                                        type="button"
                                      >
                                        {!item.hasTask ? 'No Task' : 'Task'}
                                      </button>
                                    ) : null}
                                    {pipeline === 'task' ? (
                                      <div className="dueDateContainer">
                                        <p>Due date</p>
                                        <p className="dueDate">{moment(item.dueDate).format('M/D/YYYY')}</p>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>

                              )}
                            </Draggable>
                          </LazyWrapper>

                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}

                </Droppable>
              ))}
            </DragDropContext>
            {fakeColsCount ? _.range(1, fakeColsCount + 1).map((a) => (
              <div className="droppableColWrapper" key={a}>
                {['job', 'lead'].includes(pipeline) ? (
                  <button
                    type="button"
                    className="addButton"
                  >
                    <div className="fakeButton" />
                  </button>
                ) : null}
              </div>
            )) : null}
          </div>

        </>
      ) : null}
      <DeleteModal
        loading={loadingArchive}
        open={!!archivingConfirmation}
        text={`Are you sure want to archive the ${pipeline}?`}
        onClose={() => setArchivingConfirmation(null)}
        onDelete={() => handleArchive(archivingConfirmation)}
      />
    </div>
  );
}

export default DraggableContainer;

DraggableContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  pipeline: PropTypes.string.isRequired,
  pipelineData: PropTypes.object.isRequired,
};
