import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Wrapper from '../../../components/Wrapper';
import Button from '../../../components/form/Button';
import Input from '../../../components/form/Input';
import Textarea from '../../../components/form/Textarea';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/select_arrow.svg';
import Checkbox from '../../../components/form/Checkbox';
import { getPermissionsRequest } from '../../../store/actions/permissions';
import Api from '../../../Api';
import Loader from '../../../components/loader/Loader';
import Utils from '../../../helpers/Utils';

const PermissionAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { list } = useSelector((state) => state.permissions.permissions);

  const [permissionData, setPermissionData] = useState({
    title: '',
    description: '',
    permissions: [],
    userCount: 0,
  });

  const {
    title, description, permissions, userCount,
  } = permissionData;

  const [errors, setErrors] = useState({});

  const [openedPermissionsId, setOpeningPermissionsId] = useState([]);

  const [loading, loadingToggle] = useState(true);
  const [saveLoading, saveLoadingToggle] = useState(false);

  useEffect(() => {
    (async () => {
      if (id !== 'add') {
        const [currentPermissionData] = await Promise.all([
          Api.getUserPermissionsGroup({ id }),
          dispatch(getPermissionsRequest({ limit: 200 })),
        ]);

        const permission = currentPermissionData.data?.permissionGroups?.[0];

        if (!permission) {
          navigate('/404');
        } else {
          setPermissionData({
            title: permission.title || '',
            description: permission.description || '',
            permissions: permission.permissions || [],
            userCount: permission.userCount || 0,
          });
        }
      } else {
        await dispatch(getPermissionsRequest());
      }

      loadingToggle(false);
    })();
  }, []);

  const changePermissionData = useCallback((path, value, secValue = []) => {
    setPermissionData((prev) => {
      if (path === 'allEditPermissions') {
        let newPermissions = _.uniq([...prev.permissions, ...value, ...secValue]);

        if (value.every((p) => prev.permissions.includes(p))) {
          newPermissions = prev.permissions.filter((p) => !value.includes(p));
        }

        return {
          ...prev,
          permissions: newPermissions,
        };
      }

      if (path === 'allReadPermissions') {
        let newPermissions = _.uniq([...prev.permissions, ...value]);

        if (value.every((p) => prev.permissions.includes(p))) {
          const filteredReadPermission = prev.permissions.filter((p) => !value.includes(p));
          const checkedEditPermission = prev.permissions.filter((p) => secValue.includes(p));

          newPermissions = _.uniq([...filteredReadPermission, ...checkedEditPermission.map((v) => `${v}_readonly`)]);
        }

        return {
          ...prev,
          permissions: newPermissions,
        };
      }

      if (path === 'permissions') {
        let newPermissions = [...prev.permissions, value];

        if (!_.isEmpty(secValue)) {
          newPermissions.push(secValue);
        }

        if (prev.permissions.includes(value)) {
          newPermissions = prev.permissions.filter((p) => p !== value);
        }

        return {
          ...prev,
          permissions: newPermissions,
        };
      }

      return { ...prev, [path]: value };
    });
    const errorPath = ['title', 'description'].includes(path) ? path : 'permissions';

    setErrors((prev) => {
      delete prev[errorPath];

      return prev;
    });
  }, []);

  const openingPermission = useCallback((permissionId) => {
    setOpeningPermissionsId((prev) => {
      if (prev.includes(permissionId)) {
        return prev.filter((p) => p !== permissionId);
      }

      return [...prev, permissionId];
    });
  }, []);

  const save = useCallback(async () => {
    let hasError = false;

    if (!title.trim()) {
      setErrors((prev) => ({
        ...prev,
        title: 'Name is required',
      }));

      hasError = true;
    }

    if (!permissions.length) {
      setErrors((prev) => ({
        ...prev,
        permissions: 'Permissions is required',
      }));

      hasError = true;
    }

    if (!hasError) {
      saveLoadingToggle(true);

      try {
        const data = Utils.deleteEmptyKeys(Utils.trimObjValues(permissionData));
        delete data.userCount;

        if (id !== 'add') data.id = id;

        await Api.createPermissionGroup(data);

        toast.success(<span>
          <b>Success!</b>

          {` ${id !== 'add' ? 'Role is updated' : 'New role is created'}`}
        </span>);

        cancel();
      } catch (err) {
        toast.error('Please correct these fields');

        if (err.response.data.errors) {
          setErrors(Utils.normalizeErrors(err.response.data.errors));
        } else {
          setErrors((prev) => ({
            ...prev,
            title: err.response.data.message,
          }));
        }
      }

      saveLoadingToggle(false);
    } else {
      toast.error('Please correct these fields');
    }
  }, [permissionData]);

  const cancel = useCallback(() => {
    navigate('/settings/permissions');
  }, []);

  return (
    <Wrapper title="Permissions">
      <div className="contacts__content">
        {loading
          ? (
            <div className="loader__wrapper">
              <Loader size={60} borderWidth={5} />
            </div>
          )

          : (
            <>
              <div className="contacts__buttons__wrapper permission">
                <Button
                  noBorder
                  title="Cancel"
                  onClick={cancel}
                />

                <Button
                  title="Save"
                  loading={saveLoading}
                  onClick={save}
                />
              </div>

              <div className="permission__name__wrapper">
                <div className="permission__title__wrapper">
                  <div className="permission__title">
                    <div className="permission__role">
                      Admin
                    </div>

                    <div className="permission__info">
                      Admins can manage and publish the content they created
                    </div>
                  </div>

                  <div className="permission__user__count">
                    {`${userCount} users with this role`}
                  </div>
                </div>

                <div className="permission__fields__wrapper">
                  <Input
                    label="Name"
                    placeholder="Name"
                    className="h_40"
                    value={title}
                    onChange={({ target: { value } }) => changePermissionData('title', value)}
                    error={errors.title}
                  />

                  <Textarea
                    label="Description"
                    placeholder="Description"
                    value={description}
                    onChange={({ target: { value } }) => changePermissionData('description', value)}
                    error={errors.description}
                  />
                </div>

              </div>

              <div className="permissions__table__wrapper">
                <div className="error">{errors.permissions}</div>

                <div className="permissions__table__header">
                  <div className="role__name" />
                  <div className="edit">EDIT</div>
                  <div className="edit">READ</div>
                </div>

                <div className="permissions__table">
                  {list.map((l) => {
                    const allEditPermissions = l.child.map((c) => c.permissions[0]).flat(1);
                    const allReadPermissions = l.child.map((c) => c.permissions[1]).flat(1);

                    return (
                      <div key={l.id} className="permissions__table__tr">
                        <div className="permissions__table__tr__parent">
                          <div className="role__name">{l.title}</div>

                          <div className="edit">
                            <Checkbox
                              greenBG
                              checked={allEditPermissions.every((p) => permissions.includes(p))}
                              indeterminate={allEditPermissions.some((p) => permissions.includes(p))}
                              onChange={() => {
                                changePermissionData('allEditPermissions', allEditPermissions, allReadPermissions);
                              }}
                            />
                          </div>

                          <div className="edit">
                            <Checkbox
                              greenBG
                              checked={allReadPermissions.every((p) => permissions.includes(p))}
                              indeterminate={allReadPermissions.some((p) => permissions.includes(p))}
                              disabled={allEditPermissions.every((p) => permissions.includes(p))}
                              onChange={() => {
                                changePermissionData('allReadPermissions', allReadPermissions, allEditPermissions);
                              }}
                            />
                          </div>

                          <button
                            className="permissions__table__tr__arrow"
                            onClick={() => openingPermission(l.id)}
                          >
                            <ArrowIcon className={classNames({ up: openedPermissionsId.includes(l.id) })} />
                          </button>
                        </div>

                        {openedPermissionsId.includes(l.id) && l.child.map((c) => {
                          const edit = c.permissions[0];
                          const read = c.permissions[1];

                          return (
                            <div key={c.id} className="permissions__table__tr__children">
                              <div className="role__name">{c.title}</div>

                              <div className="edit">
                                <Checkbox
                                  greenBG
                                  checked={permissions.includes(edit)}
                                  onChange={() => changePermissionData('permissions', edit, read)}
                                />
                              </div>

                              <div className="edit">
                                <Checkbox
                                  greenBG
                                  checked={permissions.includes(read)}
                                  onChange={() => changePermissionData('permissions', read)}
                                  disabled={permissions.includes(edit)}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
      </div>
    </Wrapper>
  );
};

export default PermissionAdd;
