import React from 'react';
import Wrapper from '../../components/Wrapper';
import PipelineTabs from '../../components/pipeline/PipelineTabs';
import AutomationsList from '../../components/automation/AutomationsList';
import Automations from '../../components/automation/Automations';
import HasPermission from '../../components/HasPermission';

const tabs = [
  { path: '/jobs', tab: 'pipeline' },
  { path: '/jobs/list', tab: 'list' },
];

function JobAutomations() {
  return (
    <Wrapper title="Leads automations">
      <div className="automationsList">
        <PipelineTabs tabs={tabs} />
        <HasPermission read="get_automation_readonly" edit="get_automation">
          <AutomationsList pipeline="job" />
        </HasPermission>
      </div>
      <Automations pipeline="job" />
    </Wrapper>
  );
}

export default JobAutomations;
