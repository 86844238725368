import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DraggableContainer from '../pipeline/DraggableContainer';
import { getJobsRequest } from '../../store/actions/jobs';

function JobsPipeline() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const jobs = useSelector((state) => state.jobs.jobsGrouped);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getJobsRequest({ pipeline: true }));
      setLoading(false);
    })();
  }, []);
  return (
    <DraggableContainer loading={loading} pipeline="job" pipelineData={jobs} />
  );
}

export default JobsPipeline;
