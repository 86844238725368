import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { ReactComponent as CloseSvg } from '../../assets/icons/remove.svg';

const Modal = ({
  open, onClose, children, className, zIndex,
}) => {
  const [showModal, modalShowToggle] = useState(false);

  const handleEsc = useCallback((event) => {
    if (event.keyCode === 27) onClose();
  }, []);

  const closeModal = () => {
    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('width');
    document.body.ontouchmove = () => true;
    window.removeEventListener('keydown', handleEsc);
  };

  useEffect(() => {
    setTimeout(() => modalShowToggle(open));

    if (open) {
      document.body.style.width = `${document.body.getBoundingClientRect().width}px`;
      document.body.style.overflowY = 'hidden';
      document.body.ontouchmove = () => false;
      window.addEventListener('keydown', handleEsc);
    } else {
      closeModal();
    }
  }, [open]);

  useEffect(() => () => {
    closeModal();
  }, []);

  return (
    open && createPortal(
      <div
        id="modal"
        className="modal__container"
        style={{ zIndex }}
      >
        <div
          className="backdrop"
          onClickCapture={onClose}
        />

        <div
          className={classnames(`modal__wrapper ${className}`, { show_modal: showModal })}
        >
          {onClose && (
            <CloseSvg
              className="close"
              onClick={onClose}
            />
          )}

          {children}
        </div>
      </div>,
      document.getElementById('root') || document.body,
    )
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  zIndex: PropTypes.number,
};

Modal.defaultProps = {
  className: '',
  zIndex: 1000,
  onClose: null,
};

export default Modal;
