import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Wrapper from '../../components/Wrapper';
import CreateInfoWrapper from '../../components/pipelineFormElements/CreateInfoWrapper';
import Button from '../../components/form/Button';
import Activity from '../../components/pipelineFormElements/Activity';
import Notes from '../../components/pipelineFormElements/Notes';
import Email from '../../components/pipelineFormElements/Email';
import LeadCreateLeft from '../../components/leads/LeadCreateLeft';
import Task from '../../components/pipelineFormElements/Task';
import {
  addLeadRequest, getSingleLeadRequest, removeLeadData, setLeadData,
} from '../../store/actions/leads';
import { getPipelinesRequest } from '../../store/actions/pipelines';
import Loader from '../../components/loader/Loader';
import Calls from '../../components/pipelineFormElements/Calls';
import { leadIdKey } from '../../store/reducers/leads';
import Details from '../../components/pipelineFormElements/Details';
import { deleteFilesRequest } from '../../store/actions/jobs';
import { ReactComponent as ArrowIcon } from '../../assets/icons/select_arrow.svg';

function CreateLead() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { leadId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams({});

  const [tab, setTab] = useState('activity');
  const [loading, setLoading] = useState('');
  const [loadingData, setLoadingData] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const leadData = useSelector((state) => state.leads.leadData);
  const leadActivity = useSelector((state) => state.leads.leadActivity);
  const calls = useSelector((state) => state.leads.calls);
  const errors = useSelector((state) => state.leads.errors);
  const singleLead = useSelector((state) => state.leads.singleLead);

  useEffect(() => {
    dispatch(getPipelinesRequest({ type: 'lead' }));

    return () => {
      dispatch(removeLeadData());
    };
  }, []);

  useEffect(() => {
    if (leadId) {
      (async () => {
        setLoadingData(true);
        await dispatch(getSingleLeadRequest(leadId));
        setLoadingData(false);
      })();
    }
  }, [leadId]);

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('mouseup', handleCloseEvent);
    }, 0);
    return () => {
      window.removeEventListener('mouseup', handleCloseEvent);
    };
  }, []);

  const handleCloseEvent = useCallback((ev) => {
    if (!ev.target.closest('.dropdown')) {
      setDropdown(false);
    }
  }, []);

  const handleTabChange = useCallback((t) => {
    setTab(t);
  }, [tab]);

  const getTab = useCallback(() => ({
    activity: <Activity activity={leadActivity} pipeline="lead" />,
    notes: <Notes setData={(data) => dispatch(setLeadData(data))} notes={leadData.notes} pipeline="lead" />,
    emails: <Email
      setData={(data) => dispatch(setLeadData(data))}
      emails={leadData.emails}
      pipeline="lead"
      email={leadData.email}
    />,
    task: <Task setData={(data) => dispatch(setLeadData(data))} tasks={leadData.tasks} pipeline="lead" />,
    job_quotation: <Details setData={(data) => dispatch(setLeadData(data))} data={leadData} errors={errors} />,
    calls: <Calls calls={calls} />,
  })[tab], [tab, leadData, errors]);

  const handleCreateLead = useCallback(async (exit) => {
    setLoading(exit ? 'exit' : 'stay');
    setDropdown(false);
    const details = {};
    const filesDif = _.difference(singleLead?.details?.files, leadData?.details?.files);
    if (!_.isEmpty(filesDif)) {
      await Promise.all(filesDif.map((f) => dispatch(deleteFilesRequest(f))));
    }
    if (leadData.details.files) {
      details.files = [...leadData.details.files];
    }
    details.additionalData = leadData.details.additionalData.map((d) => ({
      text: d.text,
      qty: d.qty,
      price: d.price,
    }));
    const { payload: { data } } = await dispatch(addLeadRequest({
      ...leadData,
      created: searchParams.get('from') === 'call' ? 'call' : undefined,
      id: leadId ? +leadId : undefined,
      notes: !_.isEmpty(leadData.notes) ? leadData.notes.map((n) => ({
        text: n.text,
        id: n.id,
      })) : undefined,
      tasks: !_.isEmpty(leadData.tasks) ? leadData.tasks : undefined,
      emails: !_.isEmpty(leadData.emails) && !leadId ? leadData.emails : undefined,
      details,
    }));

    if (data.status === 'ok') {
      if (leadId) {
        toast.success('Successfully updated.');
      } else {
        toast.success('Successfully created.');
      }
      setSearchParams({});
      if (exit) {
        navigate('/leads');
      }
    }
    setLoading('');
  }, [leadData, leadId, searchParams, singleLead]);

  return (
    <Wrapper title={`${!leadId ? 'New' : 'Edit'} lead`}>
      {!loadingData ? (
        <div className="createLead">
          <LeadCreateLeft />
          <div className="right">
            <div className="top">
              <div>
                {!leadId ? <p className="new">New</p> : `#${leadData[leadIdKey]}`}
              </div>
              <div className="actions">
                <Button
                  title="Cancel"
                  noBorder
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <div className="createButtonContainer">
                  <Button
                    title={leadId ? 'Save' : 'Create'}
                    onClick={() => handleCreateLead(false)}
                    loading={!!loading}
                    className="create"
                  />
                  <div className="dropdownIndicator" onClick={() => setDropdown(true)}>
                    <ArrowIcon />
                  </div>
                  {dropdown ? (
                    <div className="dropdown" onClick={() => handleCreateLead(true)}>
                      {leadId ? 'Save and exit' : 'Create and exit'}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="main">
              <CreateInfoWrapper onTabChange={handleTabChange} tab={tab} errors={errors}>
                {getTab()}
              </CreateInfoWrapper>
            </div>
          </div>
        </div>
      ) : <Loader />}
    </Wrapper>
  );
}

export default CreateLead;
