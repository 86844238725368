import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Wrapper from '../../components/Wrapper';
import Loader from '../../components/loader/Loader';
import Input from '../../components/form/Input';
import Button from '../../components/form/Button';
import Editor from '../../components/form/JoditReact';

import {
  createNotificationTemplateRequest,
  getSingleNotificationTemplateRequest,
} from '../../store/actions/notification';
import Utils from '../../helpers/Utils';
import Select from '../../components/form/Select';

const options = [
  {
    label: 'Lead',
    value: 'lead',
  },
  {
    label: 'Job',
    value: 'job',
  },
  {
    label: 'Customer',
    value: 'customer',
  },
];

const subOptions = {
  lead: [
    {
      label: 'Address',
      value: ' {{lead.address}}',
    },
    {
      label: 'Company',
      value: ' {{lead.company}}',
    },
    {
      label: 'Email',
      value: ' {{lead.email}}',
    },
    {
      label: 'Phone',
      value: ' {{lead.phone}}',
    },
    {
      label: 'Sale',
      value: ' {{lead.sale}}',
    },
    {
      label: 'Status',
      value: ' {{lead.status}}',
    },
    {
      label: 'Responsible person name',
      value: ' {{lead.responsiblePersonName}}',
    },
    {
      label: 'Responsible person lastname',
      value: ' {{lead.responsiblePersonLastName}}',
    },
  ],
  job: [
    {
      label: 'Additional data',
      value: ' {{job.additionalData}}',
    },
    {
      label: 'Address',
      value: ' {{job.address}}',
    },
    {
      label: 'Company',
      value: ' {{job.company}}',
    },
    {
      label: 'Email',
      value: ' {{job.email}}',
    },
    {
      label: 'Phone',
      value: ' {{job.phone}}',
    },
    {
      label: 'Sale',
      value: ' {{job.sale}}',
    },
    {
      label: 'Status',
      value: ' {{job.status}}',
    },
  ],
  customer: [
    {
      label: 'Address',
      value: ' {{contact.address}}',
    },
    {
      label: 'Company',
      value: ' {{contact.company}}',
    },
    {
      label: 'Email',
      value: ' {{contact.email}}',
    },
    {
      label: 'Name',
      value: ' {{contact.name}}',
    },
    {
      label: 'Phone',
      value: ' {{contact.phone}}',
    },
  ],
};

function AddNotificationTemplate() {
  const { templateId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [field, setField] = useState('');
  useEffect(() => {
    if (templateId) {
      (async () => {
        setLoading(true);
        const { payload: { data } } = await dispatch(getSingleNotificationTemplateRequest({ id: templateId }));
        setForm({
          title: data.templates[0].title,
          subject: data.templates[0].subject,
          html: data.templates[0].html,
        });
        setLoading(false);
      })();
    }
  }, [templateId]);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setSaveLoading(true);

    if (templateId) {
      form.id = templateId;
    }

    const { payload } = await dispatch(createNotificationTemplateRequest(form));
    if (payload.errors) {
      setErrors(Utils.normalizeErrors(payload.errors));
    } else {
      navigate('/settings/notification-templates');
      toast.success(`Successfully ${templateId ? 'updated' : 'created'}.`);
    }
    setSaveLoading(false);
  }, [form, templateId]);

  return (
    <Wrapper title={`${templateId === 'add' ? 'Create' : 'Edit'} template`}>
      <div className="email__template__content addNotificationTemplate">
        {loading
          ? (
            <div className="loader__wrapper">
              <Loader size={60} borderWidth={5} />
            </div>
          )
          : (
            <div className="email__template__wrapper">
              <form className="email__template__left__block" onSubmit={handleSubmit}>
                <div className="email__template__left__top">
                  <div className="email__template__new">
                    {!templateId ? 'New' : 'Edit'}
                  </div>

                  <Input
                    label=""
                    placeholder="Template"
                    value={form.title}
                    error={errors.title}
                    onChange={(ev) => handleChange('title', ev.target.value)}
                  />

                  <Button
                    title="Cancel"
                    noBorder
                    type="button"
                    onClick={() => navigate('/settings/notification-templates')}
                  />

                  <Button
                    title={!templateId ? 'Create' : 'Save'}
                    loading={saveLoading}
                    type="submit"
                  />

                </div>

                <div className="email__template__left__bottom">
                  <Input
                    label="Subject"
                    value={form.subject}
                    className="h_40"
                    placeholder="Subject"
                    error={errors.subject}
                    onChange={(ev) => handleChange('subject', ev.target.value)}
                  />

                  <Editor
                    preview
                    value={form.html}
                    onChange={(value) => handleChange('html', value)}
                    error={errors.html}
                  />
                </div>
              </form>

              <div className="email__template__right__block">
                <Select
                  label="Fields"
                  placeholder="Fields"
                  options={options}
                  value={options.find((o) => o.value === field)}
                  onChange={(o) => setField(o.value)}
                  isSearchable={false}
                />

                {field && (
                  <Select
                    label={`${_.upperFirst(field)} fields`}
                    placeholder={`${_.upperFirst(field)} fields`}
                    options={subOptions[field]}
                    onChange={(o) => handleChange('html', `${form.html} ${o.value}`)}
                    isSearchable={false}
                  />
                )}
              </div>

            </div>
          )}
      </div>
    </Wrapper>
  );
}

export default AddNotificationTemplate;
