import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getUsersRequest = createAsyncThunk('users/get', async (payload = {}) => {
  const {
    path, page, limit, list,
  } = payload;
  const { data } = await Api.getUsers({ page, limit, list });
  return { data, path };
});

export const getSingleUserRequest = createAsyncThunk('users/getSingleUserRequest', async (payload = {}) => {
  const { data } = await Api.getUsers(payload);
  return data;
});
