import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Table from './Table';
import Pagination from '../pagination/Pagination';
import Loader from '../loader/Loader';

const TableWithPagination = ({
  page, totalPages, limit, onChangePage, limitCounts, hideLimitCounts, firstLoad, showCheckbox, selectedItems,
  data, header, tableClassName, hideActions, minWidth, onEditClick, onDeleteClick, deleteModalText, onCheckboxChange,
  editPermission, readEditPermission, deletePermission, readDeletePermission,
}) => {
  const [loading, setLoading] = useState(false);

  const changePage = useCallback(async (path, count) => {
    setLoading(true);
    await onChangePage(path, count);
    setLoading(false);
  }, [onChangePage]);

  return (
    <div className={`table__with__pagination__wrapper ${tableClassName}`}>
      {firstLoad
        ? (
          <div className="loader__wrapper">
            <Loader size={60} borderWidth={5} />
          </div>
        )

        : (
          <>
            <Table
              data={data}
              header={header}
              hideActions={hideActions}
              minWidth={minWidth}
              showCheckbox={showCheckbox}
              selectedItems={selectedItems}
              loading={loading}
              onCheckboxChange={onCheckboxChange}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
              deleteModalText={deleteModalText}
              editPermission={editPermission}
              readEditPermission={readEditPermission}
              deletePermission={deletePermission}
              readDeletePermission={readDeletePermission}
            />

            <Pagination
              page={page}
              totalPages={totalPages}
              limit={limit}
              limitCounts={limitCounts}
              hideLimitCounts={hideLimitCounts || !data.length}
              onChange={changePage}
            />
          </>
        )}
    </div>
  );
};

TableWithPagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  limitCounts: PropTypes.array,
  hideLimitCounts: PropTypes.bool,
  firstLoad: PropTypes.bool,
  header: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  tableClassName: PropTypes.string,
  deleteModalText: PropTypes.string,
  minWidth: PropTypes.number,
  hideActions: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  selectedItems: PropTypes.array,
  onCheckboxChange: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  editPermission: PropTypes.string,
  readEditPermission: PropTypes.string,
  deletePermission: PropTypes.string,
  readDeletePermission: PropTypes.string,
};

TableWithPagination.defaultProps = {
  totalPages: 1,
  page: 1,
  limit: 10,
  limitCounts: [10, 30, 50, 100],
  showCheckbox: false,
  hideLimitCounts: false,
  firstLoad: false,
  tableClassName: '',
  deleteModalText: 'Are you sure you want to delete the customer?',
  minWidth: 0,
  hideActions: false,
  selectedItems: [],
  onCheckboxChange: () => {},
  onEditClick: () => {},
  onDeleteClick: () => {},
  editPermission: '',
  readEditPermission: '',
  deletePermission: '',
  readDeletePermission: '',

};
export default TableWithPagination;
