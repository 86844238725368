import React from 'react';
import { useSelector } from 'react-redux';
import Wrapper from '../../components/Wrapper';
import { ReactComponent as GoToIcon } from '../../assets/images/gotoLogo.svg';

const {
  REACT_APP_FRONTEND_URL = 'https://3c22-178-160-242-244.ngrok-free.app',
  REACT_APP_GO_TO_CLIENT_ID = 'e282bbfa-ccd5-4aeb-8f4c-3e7d223929bc',
} = process.env;
const gotoAuthUrl = 'https://authentication.logmeininc.com/oauth/authorize';

function GoTo() {
  const profile = useSelector((state) => state.account.profile);

  return (
    <Wrapper title="GoTo">
      <div className="goTo">
        <div className="icon">
          <GoToIcon />
        </div>
        {!profile.gotoConnect ? (
          <a
            className="mainButton"
            href={`${gotoAuthUrl}?response_type=code&redirect_uri=${REACT_APP_FRONTEND_URL}/goto/auth&client_id=${REACT_APP_GO_TO_CLIENT_ID}`}
          >
            Get GoTo Token
          </a>
        ) : <div className="have">You already have GoTo token. </div>}
      </div>
    </Wrapper>
  );
}

export default GoTo;
