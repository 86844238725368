import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ColumnHeader from '../pipeline/ColumnHeader';
import { dueDates, pipelineTriggers } from '../../data/options';
import { getPipelinesRequest } from '../../store/actions/pipelines';
import { deleteAutomationRequest, getAutomationsListRequest } from '../../store/actions/automation';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { getContactsRequest } from '../../store/actions/contacts';
import { getUsersRequest } from '../../store/actions/users';
import Loader from '../loader/Loader';
import DeleteModal from '../modal/DeleteModal';
import HasPermission from '../HasPermission';

function AutomationsList(props) {
  const { pipeline } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [automationId, setAutomationId] = useState(null);

  const automationList = _.groupBy(useSelector((state) => state.automation.automationList[pipeline]), 'actionStatus');
  const pipelines = useSelector((state) => state.pipelines.pipelines[pipeline]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getPipelinesRequest({ type: pipeline }));
      await dispatch(getAutomationsListRequest(pipeline));
      dispatch(getContactsRequest());
      dispatch(getUsersRequest());
      setLoading(false);
    })();
  }, [pipeline]);

  const getAction = useCallback((action) => ({
    createJob: 'job',
    sendEmail: 'email',
    createState: 'state',
    createTask: 'task',
  })[action], []);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    await dispatch(deleteAutomationRequest(automationId));
    await dispatch(getAutomationsListRequest(pipeline));
    toast.success('Automation successfully deleted.');
    setAutomationId(null);
    setLoadingDelete(false);
  }, [automationId]);
  // a.action !== 'createState'

  return (
    <>
      {!loading ? (
        <div className="columnsDrag">
          {pipelines.map((el) => (
            <div key={el.id}>
              <ColumnHeader
                column={el}
                key={el.id}
                pipeline={pipeline}
              />
              <div className="colContainer">
                <div className="colData">
                  {automationList[el.status]?.map((a) => {
                    const dataArr = _.isArray(a.data) ? a.data : [a.data];
                    return (
                      <div className="automationItem" key={a.id}>
                        <div className="info">
                          <p className="bold">{_.startCase(a.action)}</p>
                          {a.action === 'createState' && a.data.color
                            ? (
                              <p className="colorContainer">
                                Change color to
                                <span className="color" style={{ background: a.data.color }} />
                              </p>
                            ) : null}
                          <p>{pipelineTriggers.find((t) => t.value === a.when)?.label}</p>
                          {dataArr.map((d) => (
                            <Fragment key={d.date}>
                              <p className="bold">{dueDates.find((t) => t.dueDate === d.dateType)?.label}</p>
                              <p>{d.date}</p>
                            </Fragment>
                          ))}
                        </div>
                        <div className="actions">
                          <HasPermission edit="create_automation" read="create_automation_readonly">
                            <EditIcon
                              onClick={(ev) => {
                                const { clientY, clientX } = ev;
                                const left = window.innerWidth - clientX < 250 ? clientX - 450 : clientX - 210;
                                const query = {
                                  action: getAction(a.action), id: el.id, status: el.status, _id: a.id,
                                };
                                navigate(`?${qs.stringify(query)}`, { state: { top: clientY, left } });
                              }}
                            />
                          </HasPermission>
                          <HasPermission edit="delete_automation" read="delete_automation_readonly">
                            <DeleteIcon onClick={() => setAutomationId(a.id)} />
                          </HasPermission>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : <Loader />}
      <DeleteModal
        loading={loadingDelete}
        open={!!automationId}
        text="Are you sure want to delete the automation?"
        onClose={() => setAutomationId(null)}
        onDelete={handleDelete}
      />
    </>

  );
}

export default AutomationsList;

AutomationsList.propTypes = {
  pipeline: PropTypes.string.isRequired,
};
