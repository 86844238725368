import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Wrapper from '../../components/Wrapper';
import LeadsList from '../../components/leads/LeadsList';
import { getPipelinesRequest } from '../../store/actions/pipelines';
import PipelineTabs from '../../components/pipeline/PipelineTabs';
import { getContactsRequest } from '../../store/actions/contacts';
import { getUsersRequest } from '../../store/actions/users';
import HasPermission from '../../components/HasPermission';
import { leadsPermissions } from '../../data/permissions';
import LeadsPipeline from '../../components/leads/LeadsPipeline';

const tabs = [
  {
    path: '/leads',
    tab: 'pipeline',
  },
  {
    path: '/leads/list',
    tab: 'list',
  },
];

function Leads() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { tab } = useParams();

  useEffect(() => {
    dispatch(getPipelinesRequest({ type: 'lead' }));
    dispatch(getContactsRequest({ limit: 100 }));
    dispatch(getUsersRequest({ limit: 100 }));
  }, []);

  return (
    <Wrapper title="Leads">
      <div className="leads">
        <PipelineTabs tabs={tabs} />
        <HasPermission edit={leadsPermissions}>
          {tab === 'pipeline' || !tab
            ? <LeadsPipeline />
            : null}
        </HasPermission>
        <HasPermission edit={leadsPermissions}>
          {tab === 'list' ? (
            <LeadsList
              page={page}
              onPageChange={(k, v) => {
                if (k === 'page') {
                  setPage(v);
                } else {
                  setLimit(v);
                  setPage(1);
                }
              }}
              limit={limit}
            />
          ) : null}
        </HasPermission>
      </div>
    </Wrapper>
  );
}

export default Leads;
