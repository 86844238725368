import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment/moment';
import RangeDatePicker from '../../components/form/RangeDatePicker';
import Select from '../../components/form/Select';
import { defaultDates } from '../../data/options';
import utils from '../../helpers/Utils';
import Api from '../../Api';
import Loader from '../../components/loader/Loader';
import { useQuery } from '../../helpers/hooks/useQuery';

const options = {
  series: [],
  options: {
    chart: {
      type: 'bar',
      height: 430,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      marker: {
        show: true,
      },
      x: {
        show: true,
      },
      y: {
        title: {
          formatter: () => '',
        },
      },
    },
    legend: {
      show: false,
    },
    colors: ['#356966', '#FFD831', '#849291', '#FF9900'],
    xaxis: {
      categories: [],
    },
  },

};

const thisMonthStartDay = moment().startOf('month').format('YYYY-MM-DD');
const thisMonthEndDate = moment().format('YYYY-MM-DD');

function JobStatistics() {
  const { query } = useQuery();
  const [chart, setChart] = useState(options);
  const [responseData, setResponseData] = useState({
    jobsDelivered: 0,
    jobsInstalled: 0,
    jobsOrdered: 0,
    jobsCompleted: 0,
    totalJobs: 0,
  });
  const [requestData, setRequestData] = useState([thisMonthStartDay, thisMonthEndDate]);
  const [loading, setLoading] = useState(true);

  const {
    jobsDelivered,
    jobsInstalled,
    jobsOrdered,
    jobsCompleted,
    totalJobs,
  } = responseData;

  useEffect(() => {
    (async () => {
      if (requestData[1]) {
        setLoading(true);

        const { data: { result } } = await Api.jobsStatistics(
          {
            startDate: requestData[0],
            endDate: requestData[1],
            id: query.id,
          },
        );

        setResponseData({
          jobsDelivered: result.jobsDelivered,
          jobsInstalled: result.jobsInstalled,
          jobsOrdered: result.jobsOrdered,
          jobsCompleted: result.jobsCompleted,
          totalJobs: result.totalJobs,
        });

        setChart((prev) => ({
          ...prev,
          series: result.series,
          options: {
            ...prev.options,
            xaxis: {
              ...prev.options.xaxis,
              categories: result.categories,
            },
          },
        }));
        setLoading(false);
      }
    })();
  }, [requestData, query]);

  const onChangeDate = (date) => {
    setRequestData(date);
  };
  return (

    <div className="job-statistic_wrapper">
      <div className="job-statistic_left">
        <div className="job-statistic_left_header">
          <h2>Total number of jobs</h2>

          <div className="calendar">
            <RangeDatePicker
              date={requestData}
              setDate={(date) => onChangeDate(date)}
            />

            <Select
              options={defaultDates}
              onChange={(date) => onChangeDate(date.value)}
              value={defaultDates.filter((d) => JSON.stringify(d.value) === JSON.stringify(requestData))}
            />

          </div>
        </div>
        {loading
          ? <div className="dashboard_loader_wrapper"><Loader className="loader_dashboard" /></div>
          : (
            <Chart
              height="435px"
              width="100%"
              type="bar"
              options={chart.options}
              series={chart.series}
            />
          )}

      </div>

      <div className="job-statistic_right">
        <div className="job-statistic_right_total">
          {totalJobs}
        </div>
        <div className="job-statistic_right_category_wrapper">

          <div className="job-statistic_right_category">
            <span
              style={{
                '--button-color': '#356966',
              }}
            />

            <p>Jobs Completed</p>

            <p>
              -
              {jobsCompleted}
            </p>
          </div>

          <div className="job-statistic_right_category">
            <span
              style={{
                '--button-color': '#FFD831',
              }}
            />

            <p>Jobs Ordered</p>

            <p>
              -
              {jobsOrdered}
            </p>
          </div>

          <div className="job-statistic_right_category">
            <span
              style={{
                '--button-color': '#849291',
              }}
            />

            <p>Jobs Delivered</p>

            <p>
              -
              {jobsDelivered}
            </p>
          </div>

          <div className="job-statistic_right_category">
            <span
              style={{
                '--button-color': '#FF9900',
              }}
            />

            <p>Jobs Installed</p>

            <p>
              -
              { jobsInstalled}
            </p>
          </div>

        </div>
      </div>

    </div>

  );
}

export default JobStatistics;
