import React from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddTask from './AddTask';
import AddEmail from './AddEmail';
import AddState from './AddState';
import AddJob from './AddJob';

function Automations(props) {
  const { pipeline, id } = props;
  const [searchParams] = useSearchParams();
  const query = { ...Object.fromEntries(searchParams) };

  return (
    <>
      {query.action === 'task' && +query.id === id ? <AddTask pipeline={pipeline} /> : null}
      {query.action === 'email' && +query.id === id ? <AddEmail pipeline={pipeline} /> : null}
      {query.action === 'state' && +query.id === id ? <AddState pipeline={pipeline} /> : null}
      {query.action === 'job' && +query.id === id ? <AddJob pipeline={pipeline} /> : null}
    </>
  );
}

export default Automations;

Automations.propTypes = {
  pipeline: PropTypes.string.isRequired,
};
