import React from 'react';
import Wrapper from '../components/Wrapper';

function NotFound() {
  return (
    <Wrapper title="Not found">Not Found</Wrapper>
  );
}

export default NotFound;
