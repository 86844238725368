import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import Api from '../../Api';
import Wrapper from '../../components/Wrapper';
import Checkbox from '../../components/form/Checkbox';
import Input from '../../components/form/Input';
import DatePicker from '../../components/form/DatePicker';
import Utils from '../../helpers/Utils';
import Textarea from '../../components/form/Textarea';
import Select from '../../components/form/Select';
import Button from '../../components/form/Button';
import RightModal from '../../components/modal/RightModal';
import Loader from '../../components/loader/Loader';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as LetterIcon } from '../../assets/icons/mail_letter.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/remove.svg';
import InvoicePayment from '../../components/invoices/InvoicePayment';
import PaymentHistory from '../../components/invoices/PaymentHistory';
import AutocompleteInput from '../../components/form/AutocompleteInput';

const addressFields = [
  {
    title: 'Full name',
    placeholder: 'Name',
    path: 'fullName',
    required: true,
  },
  {
    title: 'Street address',
    placeholder: 'Address',
    path: 'address',
    required: true,
  },
  {
    title: 'City',
    placeholder: 'City',
    path: 'city',
    required: true,
  },
  {
    title: 'State',
    placeholder: 'State',
    path: 'state',
    required: true,
  },
  {
    title: 'Zip',
    placeholder: 'Zip',
    path: 'zip',
    validation: 'number',
    required: true,
  },
  {
    title: 'Phone number',
    placeholder: '+1(123)4567890',
    path: 'phone',
    required: true,
    mask: '+1 999-999-9999',
  },
  {
    title: 'Email',
    placeholder: 'Email',
    path: 'email',
    validation: 'email',
  },
];

const serviceHeader = [
  {
    title: 'Service/Material',
    placeholder: 'Name',
    path: 'text',
    required: true,
  },
  {
    title: 'Qty',
    placeholder: 'Qty',
    path: 'qty',
    required: true,
    validation: 'currency',
  },
  {
    title: 'Unit Cost',
    placeholder: 'Unit Cost',
    path: 'cost',
    required: true,
    validation: 'currency',
  },
  {
    title: 'Line Total',
  },
];

const invoiceDefaultData = {
  invoiceNumber: '',
  date: '',
  dueDate: '',
  company: '',
  tax: '',
  taxType: 'usd',
  discount: '',
  discountType: 'percent',
  note: '',
  shippingAddress: {
    zip: '',
    city: '',
    email: '',
    phone: '',
    state: '',
    address: '',
    fullName: '',
  },
  billingAddress: {
    zip: '',
    city: '',
    email: '',
    phone: '',
    state: '',
    address: '',
    fullName: '',
  },
  services: [
    {
      qty: '',
      text: '',
      cost: '',
      amount: '',
    },
  ],
  total: '',
  subTotal: '',
  storageFee: '',
};

const taxOptions = [
  {
    label: '$',
    value: 'usd',
  },
  {
    label: '%',
    value: 'percent',
  },
];

const calculateTotal = (data) => {
  data.subTotal = +_.sumBy(data.services, (s) => +s.amount).toFixed(2);

  data.total = data.subTotal;

  if (+data.discount) {
    data.total = +(data.discountType === 'usd'
      ? data.total - data.discount
      : data.total - (data.subTotal / 100) * data.discount).toFixed(2);
  }

  if (+data.tax) {
    data.total = +(data.taxType === 'usd'
      ? +data.tax + data.total
      : data.total + +(data.total / 100) * data.tax).toFixed(2);
  }

  data.total += +(data.storageFee || 0);

  return data;
};
const calculatePaymentTotal = (total, payments) => +(total - _.sumBy(payments, (p) => +p.amount)).toFixed(2);

const statusColors = [
  {
    status: 'partially_paid',
    color: '255,153,0',
  },
  {
    status: 'paid',
    color: '0,175,49',
  },
  {
    status: 'unpaid',
    color: '245,0,0',
  },
];
const getStatusColor = (status) => statusColors.find((c) => c.status === status)?.color || '#292929';

const InvoiceAdd = () => {
  const navigate = useNavigate();
  const { invoiceId, jobId } = useParams();
  const timeout = useRef();
  const statusInfo = useRef();

  const [invoiceInfo, setInvoiceInfo] = useState({ ...invoiceDefaultData, jobId });
  const [storageFee, setStorageFee] = useState(false);
  const [canSendEmail, sendEmailToggle] = useState(false);
  const [errors, setErrors] = useState({});

  const [loading, loadingToggle] = useState(true);
  const [saveLoading, saveLoadingToggle] = useState(false);
  const [downloadLoading, saveDownloadToggle] = useState(false);

  const [isModalOpen, modalOpeningToggle] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({});

  useEffect(() => {
    (async () => {
      const [JobData, invoiceData] = await Promise.all([
        Api.getJobs({ id: jobId }),
        invoiceId !== 'add' ? Api.getInvoices({ id: invoiceId }) : null,
      ]);

      const job = JobData.data.jobs?.[0];
      const invoice = invoiceData?.data?.invoices?.[0];

      if ((invoiceId !== 'add' && !invoice) || !job) {
        navigate('/404');
      } else if (invoiceId === 'add') {
        const data = _.cloneDeep(invoiceInfo);

        const { contact } = job;

        data.company = contact.company || '';

        data.shippingAddress = {
          fullName: contact.name || '',
          email: contact.email || '',
          phone: contact.phone || '',
        };

        try {
          const { data: { result } } = await Api.googleAddressAutocomplete(contact.address);

          const placeId = result?.[0]?.place_id;

          if (placeId) {
            const { data: { place } } = await Api.googleAddressByPlaceId(placeId);

            const addressData = place.address_components;

            const address = `${addressData.find((d) => d.types.includes('street_number'))?.short_name || ''} ${addressData.find((d) => d.types.includes('route'))?.long_name || ''} `;

            const addressInfo = {
              zip: addressData.find((d) => d.types.includes('postal_code'))?.short_name || '',
              city: addressData.find((d) => d.types.includes('political'))?.short_name || '',
              state: addressData.find((d) => d.types.includes('administrative_area_level_1'))?.short_name || '',
              address,
            };

            data.shippingAddress = { ...data.shippingAddress, ...addressInfo };
          }
        } catch (err) {
          console.warn(err);
        }

        data.billingAddress = data.shippingAddress;

        data.date = moment().format('YYYY-MM-DD');
        data.dueDate = moment().add(14, 'day').format('YYYY-MM-DD');

        if (!_.isEmpty(job.quotation.detail)) {
          data.services = job.quotation.detail.map((d) => ({
            qty: d.qty,
            text: d.text,
            cost: d.price,
            amount: d.qty && d.price ? d.qty * d.price : '',
          }));

          const total = _.sumBy(data.services, (p) => +p.amount);
          data.total = total;
          data.subTotal = total;
        }

        setInvoiceInfo(data);
        setStorageFee(job.storageFee);
      } else {
        setStorageFee(job.storageFee);

        if (invoice) {
          sendEmailToggle(Utils.isEmail(invoice.customer.email));

          const data = { jobId, id: invoiceId };

          _.forEach(invoiceDefaultData, (value, key) => {
            data[key] = key.toLowerCase().includes('date')
              ? moment(invoice[key]).format('YYYY-MM-DD')
              : invoice[key];
          });

          setInvoiceInfo(data);

          const amount = calculatePaymentTotal(invoice.total, invoice.payment);

          statusInfo.current = {
            status: invoice.status,
            total: invoice.total,
            payment: invoice.payment,
          };

          if (+amount) {
            const payment = {
              invoiceId, jobId, amount, method: 'cash', date: moment().format('YYYY-MM-DD'),
            };

            setPaymentInfo(payment);
          }
        }
      }

      loadingToggle(false);
    })();
  }, []);

  const downloadPgf = async () => {
    saveDownloadToggle(true);
    await Api.downloadInvoice({ jobId, invoiceId });
    saveDownloadToggle(false);
  };

  const sendEmail = () => {
    if (canSendEmail) {
      Api.sendInvoiceEmail({ invoiceIds: [invoiceId] });

      toast.success('Email sent');
    } else {
      toast.error('Missing customer email');
    }
  };

  const setAddress = async (value, parentPath) => {
    const { data: { place } } = await Api.googleAddressByPlaceId(value.place_id);

    const addressData = place.address_components;

    let address = `${addressData.find((d) => d.types.includes('street_number'))?.short_name || ''} ${addressData.find((d) => d.types.includes('route'))?.long_name || ''}`;

    if (invoiceInfo[parentPath].address === address) {
      address += ' ';
    }

    const addressInfo = {
      zip: addressData.find((d) => d.types.includes('postal_code'))?.short_name || '',
      city: addressData.find((d) => d.types.includes('political'))?.short_name || '',
      state: addressData.find((d) => d.types.includes('administrative_area_level_1'))?.short_name || '',
      address,
    };

    setInvoiceInfo((prev) => ({
      ...prev,
      [parentPath]: { ...prev[parentPath], ...addressInfo },
    }));
  };

  const changeInvoiceInfo = useCallback((path, value = '', parentPath = '', index = null) => {
    setInvoiceInfo((prev) => {
      if (parentPath) {
        if (typeof index === 'number') {
          const newData = prev[parentPath].map((p, i) => {
            if (i === index) {
              const newValue = { ...p, [path]: value };

              if (path === 'qty') {
                newValue.amount = +(+p.cost * +value).toFixed(2);
              } else if (path === 'cost') {
                newValue.amount = +(+p.qty * +value).toFixed(2);
              }

              return newValue;
            }

            return p;
          });

          return calculateTotal({
            ...prev,
            [parentPath]: newData,
          });
        }

        return {
          ...prev,
          [parentPath]: { ...prev[parentPath], [path]: value },
        };
      }

      if (path === 'copy') {
        return {
          ...prev,
          shippingAddress: prev.billingAddress,
        };
      }

      if (path === 'addServiceLine') {
        return {
          ...prev,
          services: [
            ...prev.services,
            {
              qty: '',
              cost: '',
              text: '',
              amount: '',
            }],
        };
      }

      if (path === 'deleteServiceLine') {
        const newServices = prev.services.filter((s, i) => i !== index);

        return calculateTotal({ ...prev, services: newServices });
      }

      const totalCalculatePath = ['tax', 'taxType', 'discount', 'discountType'];

      if (totalCalculatePath.includes(path)) {
        return calculateTotal({ ...prev, [path]: value });
      }

      return { ...prev, [path]: value };
    });

    setErrors((prev) => {
      if (parentPath) {
        if (typeof index === 'number') {
          return {
            ...prev,
            pay: '',
            [parentPath]: (prev[parentPath] || [])?.map((p, i) => (i === index ? { ...p, [path]: '' } : p)),
          };
        }

        return {
          ...prev,
          pay: '',
          [parentPath]: { ...prev[parentPath], [path]: '' },
        };
      }

      if (path === 'copy') {
        return {
          ...prev,
          shippingAddress: prev.billingAddress,
        };
      }

      if (path === 'addServiceLine') {
        return prev;
      }

      if (path === 'deleteServiceLine') {
        return {
          ...prev,
          services: prev.services?.filter((s, i) => i !== index),
        };
      }

      const payError = ['tax', 'taxType', 'discount', 'discountType'];

      if (prev.pay && payError.includes(path)) {
        return { ...prev, pay: '', [path]: '' };
      }

      return { ...prev, [path]: '' };
    });
  }, []);

  const saveInvoice = useCallback(async (e) => {
    e.preventDefault();

    const requestData = Utils.trimObjValues(invoiceInfo);
    requestData.billingAddress = Utils.trimObjValues(invoiceInfo.billingAddress);
    requestData.shippingAddress = Utils.trimObjValues(invoiceInfo.shippingAddress);
    requestData.services = invoiceInfo.services.map((s) => Utils.trimObjValues(s));

    let hasError = false;

    const requiredPath = ['invoiceNumber', 'date', 'dueDate'];
    const addresses = ['billingAddress', 'shippingAddress'];

    requiredPath.forEach((path) => {
      if (!requestData[path]) {
        setErrors((prev) => ({
          ...prev,
          [path]: `${_.upperFirst(_.lowerCase(_.startCase(path)))} is required`,
        }));

        hasError = true;
      }
    });

    addresses.forEach((address) => {
      addressFields.forEach(({
        title, path, required, validation,
      }) => {
        if (required && !requestData[address][path]) {
          setErrors((prev) => ({
            ...prev,
            [address]: { ...prev[address], [path]: `${title} is required` },
          }));

          hasError = true;
        } else if (validation === 'email' && requestData[address][path] && !Utils.isEmail(requestData[address][path])) {
          setErrors((prev) => ({
            ...prev,
            [address]: { ...prev[address], [path]: `${title} is incorrect` },
          }));

          hasError = true;
        }
      });
    });

    let servicesErrors = Array(requestData.services.length).fill({});

    requestData.services.forEach((s, index) => {
      serviceHeader.forEach((h) => {
        if (!s.empty && h.required && !s[h.path]) {
          servicesErrors = servicesErrors.map((p, i) => (i === index
            ? { ...p, [h.path]: `${h.title} is required` }
            : p));

          setErrors((prev) => ({
            ...prev,
            services: servicesErrors,
          }));

          hasError = true;
        }
      });
    });

    if (!hasError && +invoiceInfo.total < +_.sumBy(statusInfo.current?.payment, (p) => +p.amount).toFixed(2)) {
      setErrors((prev) => ({
        ...prev,
        pay: 'Registered payments are greater than the Total',
      }));

      hasError = true;
    }

    if (!hasError && +invoiceInfo.total <= 0) {
      setErrors((prev) => ({
        ...prev,
        pay: 'Total must be greater than 0',
      }));

      hasError = true;
    }

    if (!hasError) {
      saveLoadingToggle(true);

      try {
        requestData.billingAddress = Utils.deleteEmptyKeys(requestData.billingAddress);
        requestData.shippingAddress = Utils.deleteEmptyKeys(requestData.shippingAddress);

        delete requestData.total;
        delete requestData.subTotal;
        delete requestData.storageFee;

        await Api.createInvoice(Utils.deleteEmptyKeys(requestData));

        if (invoiceId === 'add') {
          navigate('/jobs');
        } else {
          const { data } = await Api.getInvoices({ id: invoiceId });

          const invoice = data.invoices[0];

          const amount = calculatePaymentTotal(invoice.total, invoice.payment);

          statusInfo.current = {
            status: invoice.status,
            total: invoice.total,
            payment: invoice.payment,
          };

          setPaymentInfo((prev) => ({ ...prev, amount }));
        }

        toast.success(<span>
          <b>Success!</b>

          {` ${invoiceId !== 'add' ? 'Invoice is updated' : 'New invoice is created'}`}
        </span>);
      } catch (err) {
        toast.error('Please correct these fields');

        if (err.response?.data?.errors) {
          setErrors(Utils.normalizeErrors(err.response.data.errors));
        } else {
          setErrors((prev) => ({
            ...prev,
            invoiceNumber: err.response.data.message,
          }));
        }
      }

      saveLoadingToggle(false);
    } else {
      toast.error('Please correct these fields');
    }
  }, [invoiceInfo]);

  const cancelInvoice = useCallback(() => {
    navigate(invoiceId === ' add' ? '/jobs' : '/invoices');
  }, []);

  const paymentModalOpening = useCallback((type) => {
    modalOpeningToggle(true);

    if (type === 'paymentHistory') {
      setPaymentInfo((prev) => ({ ...prev, type }));
    }
  }, []);

  const closePaymentModal = useCallback(() => {
    modalOpeningToggle(false);

    timeout.current = setTimeout(() => {
      setPaymentInfo((prev) => {
        delete prev.type;

        return prev;
      });
    }, 500);
  }, []);

  const deleteHistory = useCallback(async (paymentId) => {
    await Api.deletePaymentItem({ invoiceId, paymentId });

    statusInfo.current.payment = statusInfo.current.payment.filter((p) => p.id !== paymentId);

    const amount = calculatePaymentTotal(statusInfo.current.total, statusInfo.current.payment);

    setPaymentInfo((prev) => ({ ...prev, amount }));

    if (!statusInfo.current.payment.length) {
      closePaymentModal();
      statusInfo.current.status = 'unpaid';
    }
  }, [invoiceInfo]);

  const onKeyPress = useCallback((e, validationType, isPaste = false) => {
    if (validationType === 'phone') {
      Utils.keyPressPhoneNumber(e, isPaste);
    } else if (validationType === 'currency') {
      Utils.keyPressCurrency(e, isPaste, 1000000, 2);
    } else if (validationType === 'number') {
      Utils.keyPressNumber(e, isPaste);
    }
  }, []);

  const paidStatus = statusInfo.current?.status === 'paid';
  const hidePaymentHistoryBtn = invoiceId !== 'add' && statusInfo.current?.status !== 'unpaid';
  const hidePaymentBtn = invoiceId !== 'add' && !paidStatus;

  return (
    <Wrapper title={`${invoiceId === 'add' ? 'Create' : 'Edit'} invoice`}>
      <div className="invoice__wrapper">
        {loading
          ? (
            <div className="loader__wrapper">
              <Loader size={60} borderWidth={5} />
            </div>
          )
          : (
            <form onSubmit={saveInvoice}>
              <div className="invoice__content">
                <div className="invoice__header">
                  <div className="invoice__header__top">
                    <h2>
                      Invoice

                      {invoiceId !== 'paid' && (
                        <div
                          style={{
                            color: `rgba(${getStatusColor(statusInfo.current?.status)})`,
                            background: `rgba(${getStatusColor(statusInfo.current?.status)}, 0.1)`,
                          }}
                          className="invoice__status"
                        >
                          {_.upperFirst(statusInfo.current?.status.replace('_', ' '))}
                        </div>
                      )}
                    </h2>

                    <div className="invoice__header_top__btns__wrapper">
                      <Checkbox
                        greenBG
                        checked={storageFee}
                        label="Calculate storage fee"
                        readOnly
                      />

                      {invoiceId !== 'add' && (
                        <>
                          <button
                            type="button"
                            onClick={sendEmail}
                          >
                            <LetterIcon />

                            Send email
                          </button>

                          <button
                            type="button"
                            onClick={downloadPgf}
                          >
                            {downloadLoading
                              ? <Loader size={16} borderWidth={3} color="#356966" />

                              : <DownloadIcon />}

                            Download invoice
                          </button>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="invoice__header__fields__wrapper">
                    <div>
                      <Input
                        label="Invoice No"
                        placeholder="#"
                        className="h_40"
                        value={invoiceInfo.invoiceNumber}
                        error={errors.invoiceNumber}
                        onChange={({ target: { value } }) => changeInvoiceInfo('invoiceNumber', value)}
                        onKeyPress={(e) => onKeyPress(e, 'number')}
                        onPaste={(e) => onKeyPress(e, 'number', true)}
                        disabled={paidStatus}
                      />
                    </div>

                    <div>
                      <DatePicker
                        label="Invoice Date"
                        setDate={(date) => changeInvoiceInfo('date', date)}
                        date={invoiceInfo.date}
                        error={errors.date}
                        disabled={paidStatus}
                      />

                      <DatePicker
                        label="Due Date"
                        setDate={(date) => changeInvoiceInfo('dueDate', date)}
                        date={invoiceInfo.dueDate}
                        error={errors.dueDate}
                        disabled={paidStatus}
                      />
                    </div>
                  </div>
                </div>

                <div className="invoice__addresses__wrapper">
                  <div className="invoice__addresses__billing__address">
                    <div className="invoice__addresses__title">
                      Billing address
                    </div>

                    {addressFields.map(({
                      title, placeholder, path, required, validation, mask,
                    }) => (
                      <div key={title} className="invoice__addresses__input__wrapper">
                        {path === 'address'
                          ? (
                            <AutocompleteInput
                              className="h_40"
                              label={`${title}${required ? ' *' : ''}`}
                              placeholder={placeholder}
                              getFullData
                              onSelectOption={(value) => setAddress(value, 'billingAddress')}
                              defaultValue={invoiceInfo.billingAddress[path]}
                              error={errors.billingAddress?.[path]}
                              disabled={paidStatus}
                            />
                          )

                          : (
                            <Input
                              label={`${title}${required ? ' *' : ''}`}
                              placeholder={placeholder}
                              className="h_40"
                              value={invoiceInfo.billingAddress[path]}
                              error={errors.billingAddress?.[path]}
                              onChange={({ target: { value } }) => changeInvoiceInfo(path, value, 'billingAddress')}
                              onKeyPress={(e) => onKeyPress(e, validation)}
                              onPaste={(e) => onKeyPress(e, validation, true)}
                              disabled={paidStatus}
                              mask={mask}
                            />
                          )}
                      </div>
                    ))}
                  </div>

                  <div className="invoice__addresses__billing__address">
                    <div className="invoice__addresses__title">
                      Shipping address

                      {!paidStatus && (
                        <button
                          type="button"
                          onClick={() => changeInvoiceInfo('copy')}
                        >
                          Copy billing address
                        </button>
                      )}
                    </div>

                    {addressFields.map(({
                      title, placeholder, path, required, validation, mask,
                    }) => (
                      <div key={title} className="invoice__addresses__input__wrapper">
                        {path === 'address'
                          ? (
                            <AutocompleteInput
                              className="h_40"
                              label={`${title}${required ? ' *' : ''}`}
                              placeholder={placeholder}
                              getFullData
                              onSelectOption={(value) => setAddress(value, 'shippingAddress')}
                              defaultValue={invoiceInfo.shippingAddress[path]}
                              error={errors.shippingAddress?.[path]}
                              disabled={paidStatus}
                            />
                          )

                          : (
                            <Input
                              label={`${title}${required ? ' *' : ''}`}
                              placeholder={placeholder}
                              className="h_40"
                              value={invoiceInfo.shippingAddress[path]}
                              error={errors.shippingAddress?.[path]}
                              onChange={({ target: { value } }) => changeInvoiceInfo(path, value, 'shippingAddress')}
                              onKeyPress={(e) => onKeyPress(e, validation)}
                              onPaste={(e) => onKeyPress(e, validation, true)}
                              disabled={paidStatus}
                              mask={mask}
                            />
                          )}
                      </div>
                    ))}
                  </div>

                  <div className="invoice__addresses__billing__address">
                    <div className="invoice__addresses__title">
                      Company name
                    </div>

                    <div className="invoice__addresses__input__wrapper">
                      <Input
                        label="Company name"
                        placeholder="Company name"
                        className="h_40"
                        value={invoiceInfo.company}
                        error={errors.company}
                        onChange={({ target: { value } }) => changeInvoiceInfo('company', value)}
                        disabled={paidStatus}
                      />
                    </div>
                  </div>
                </div>

                <div className={classnames('invoice__table__wrapper', { error: errors.pay })}>
                  <div className="invoice__table__header">
                    {serviceHeader.map((h) => (
                      <div className="td" key={h.title}>{h.title}</div>
                    ))}

                    <div className="td delete__table__item" />
                  </div>

                  <div className="invoice__table__body">
                    {invoiceInfo.services.map((d, i) => (
                      <div
                        className="tr"
                        key={i}
                      >
                        {serviceHeader.map((h) => (
                          <div
                            className="td"
                            key={h.title}
                          >
                            {h.path
                              ? (
                                <Input
                                  placeholder={h.placeholder}
                                  className="h_40"
                                  value={invoiceInfo.services[i][h.path]}
                                  error={errors?.services?.[i]?.[h.path]}
                                  onChange={({ target: { value } }) => changeInvoiceInfo(h.path, value, 'services', i)}
                                  onKeyPress={(e) => onKeyPress(e, h.validation)}
                                  onPaste={(e) => onKeyPress(e, h.validation, true)}
                                  disabled={paidStatus}
                                />
                              )
                              : (
                                <div className="td__line__total">
                                  $
                                  {invoiceInfo.services[i].amount}
                                </div>
                              )}
                          </div>
                        ))}

                        <div className="td delete__table__item">
                          {invoiceInfo.services.length > 1 && !paidStatus && (
                            <button
                              type="button"
                              className="delete__btn__wrapper"
                              onClick={() => changeInvoiceInfo('deleteServiceLine', '', '', i)}
                            >
                              <DeleteIcon />
                            </button>
                          )}
                        </div>
                      </div>
                    ))}

                    {!paidStatus && (
                      <button
                        className="invoice__table__add__line"
                        type="button"
                        onClick={() => changeInvoiceInfo('addServiceLine')}
                      >
                        <AddIcon />

                        Add new line
                      </button>
                    )}

                    {hidePaymentHistoryBtn && (
                      <button
                        className="invoice__table__view__payments"
                        type="button"
                        onClick={() => paymentModalOpening('paymentHistory')}
                      >
                        View payments
                      </button>
                    )}
                  </div>
                </div>

                <div className="invoice__note__wrapper">
                  <Textarea
                    label="Additional notes"
                    placeholder="Add note"
                    value={invoiceInfo.note}
                    error={errors.note}
                    onChange={({ target: { value } }) => changeInvoiceInfo('note', value)}
                    disabled={paidStatus}
                  />

                  <div className="invoice__total__wrapper">
                    <div>
                      <div>Subtotal:</div>

                      <div>
                        $
                        {invoiceInfo.subTotal}
                      </div>
                    </div>

                    {storageFee && invoiceId !== 'add' && (
                      <div>
                        <div>Storage fee:</div>

                        <div>
                          $
                          {invoiceInfo.storageFee || 0}
                        </div>
                      </div>
                    )}

                    <div>
                      <div>Discount:</div>

                      <div className="tax__field__wrapper">
                        <input
                          value={invoiceInfo.discount}
                          onChange={({ target: { value } }) => changeInvoiceInfo('discount', value)}
                          onKeyPress={(e) => onKeyPress(e, 'currency')}
                          onPaste={(e) => onKeyPress(e, 'currency', true)}
                          disabled={paidStatus}
                        />

                        <Select
                          placeholder=""
                          options={taxOptions}
                          value={taxOptions.find((t) => t.value === invoiceInfo.discountType)}
                          onChange={(o) => changeInvoiceInfo('discountType', o.value)}
                          isSearchable={false}
                          isDisabled={paidStatus}
                        />
                      </div>
                      {/* {errors.discount && <span className="errorText">{errors.discount}</span> } */}
                    </div>

                    <div>
                      <div>Sales Tax:</div>

                      <div className="tax__field__wrapper">
                        <input
                          value={invoiceInfo.tax}
                          onChange={({ target: { value } }) => changeInvoiceInfo('tax', value)}
                          onKeyPress={(e) => onKeyPress(e, 'currency')}
                          onPaste={(e) => onKeyPress(e, 'currency', true)}
                          disabled={paidStatus}
                        />

                        <Select
                          placeholder=""
                          options={taxOptions}
                          value={taxOptions.find((t) => t.value === invoiceInfo.taxType)}
                          onChange={(o) => changeInvoiceInfo('taxType', o.value)}
                          isSearchable={false}
                          isDisabled={paidStatus}
                        />
                      </div>
                      {/* {errors.tax && <span className="errorText">{errors.tax}</span> } */}
                    </div>

                    <div className="total">
                      <div>Total:</div>

                      <div className={classnames('', { error: errors.pay })}>
                        $
                        {invoiceInfo.total}
                      </div>

                    </div>
                    {errors.pay && <span className="errorText">{errors.pay}</span>}
                  </div>
                </div>
              </div>

              <div className="invoice__buttons__wrapper">
                {!paidStatus && (
                  <Button
                    title="Save"
                    loading={saveLoading}
                    type="submit"
                  />
                )}

                <Button
                  noBorder
                  title={paidStatus ? 'Back' : 'Cancel'}
                  onClick={cancelInvoice}
                />

                {hidePaymentBtn && (
                  <Button
                    title="Register payment"
                    onClick={paymentModalOpening}
                  />
                )}
              </div>
            </form>
          )}

        <RightModal
          open={isModalOpen}
          onClose={closePaymentModal}
          modalWidth={paymentInfo.type === 'paymentHistory' ? '610px' : '480px'}
          headerText={paymentInfo.type === 'paymentHistory'
            ? `Invoice #${invoiceInfo.invoiceNumber}`
            : 'Register payment'}
        >
          {
            paymentInfo.type === 'paymentHistory'
              ? (
                <PaymentHistory
                  data={statusInfo.current}
                  deleteHistory={deleteHistory}
                  closeModal={closePaymentModal}
                />
              )

              : (
                <InvoicePayment
                  paymentInfo={paymentInfo}
                  setPaymentInfo={setPaymentInfo}
                  closeModal={closePaymentModal}
                  innerRef={statusInfo.current}
                />
              )
          }
        </RightModal>
      </div>
    </Wrapper>
  );
};

InvoiceAdd.propTypes = {};

export default InvoiceAdd;
