import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { BlockPicker } from 'react-color';
import Select from '../form/Select';
import Button from '../form/Button';
import { createAutomationRequest, getAutomationsListRequest } from '../../store/actions/automation';
import Utils from '../../helpers/Utils';
import Conditions from './Conditions';
import Input from '../form/Input';
import ExecuteDropdown from './ExecuteDropdown';
import AutomationWrapper from './AutomationWrapper';
import { dueDates, pipelineTriggers } from '../../data/options';

function AddState(props) {
  const { pipeline } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [execute, setExecute] = useState(false);
  const [form, setForm] = useState({
    pipeline,
    action: 'createState',
    actionStatus: searchParams.get('status'),
    state: {},
  });

  const [condition, setCondition] = useState([{
    id: _.uniqueId(),
  }]);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [picker, setPicker] = useState(false);

  const automationList = useSelector((state) => state.automation.automationList[pipeline]);
  const automationId = searchParams.get('_id');


  useEffect(() => {
    const automation = automationList?.find((a) => a.id === +automationId);
    if (automation) {
      const {
        color, dateType,
      } = automation.data;
      const data = {
        when: automation.when,
        id: automationId,
        state: {
          date: dueDates.find((due) => due.dueDate === dateType)?.value,
          color,
          dateType,
        },
      };
      if (!_.isEmpty(automation.condition)) {
        const con = [];
        _.forEach(automation.condition, (val, op) => {
          val.forEach((cd) => {
            con.push({
              operator: op,
              condition: Object.keys(cd)[0],
              id: _.uniqueId(),
              ...Object.values(cd)[0],
            });
          });
        });
        setCondition(con);
      }

      setForm({ ...form, ...data });
    }
  }, [automationId, automationList]);

  const handleDeleteCondition = useCallback((id) => {
    let con = condition.filter((c) => c.id !== id);
    if (!con.length) {
      con = [{ id: _.uniqueId() }];
    }
    setCondition(con);
  }, [condition]);

  const handleClose = useCallback(() => {
    const query = { ...Object.fromEntries(searchParams) };
    delete query.action;
    delete query.id;
    delete query._id;
    delete query.status;
    setSearchParams(query, { replace: true });
  }, [searchParams]);

  const handleConditionChange = useCallback((path, val) => {
    _.set(condition, path, val);
    setCondition([...condition]);
  }, [condition]);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm(form);
    setErrors({ ...errors });
  }, [form, errors]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const con = {};

    condition.forEach((c) => {
      if (c.operator === '$or') {
        con.$or = [...(con.$or || []), { [c.condition]: { $in: c.$in } }];
      }
      if (c.operator === '$and') {
        con.$and = [
          ...(con.$and || []),
          { [c.condition]: c.condition === 'sale' ? { $gte: c.$gte, $lte: c.$lte } : { $in: c.$in } },
        ];
      }
    });

    form.condition = con;
    const { payload: { data } } = await dispatch(createAutomationRequest(form));

    if (data.status === 'error') {
      setErrors(Utils.normalizeErrors(data.errors));
    }
    if (data.status === 'ok') {
      setSearchParams({});
      toast.success(`Automation successfully ${form.id ? 'updated' : 'added'}.`);
      await dispatch(getAutomationsListRequest(pipeline));
    }
    setLoading(false);
  }, [form, searchParams, condition]);


  return (
    <div className="addStateContainer addAutomationContainer">

      <AutomationWrapper onSubmit={handleSubmit}>
        <div>
          <div>
            <div className="colorPicker">
              <div onClick={() => setPicker(true)}>
                <Input placeholder="Color" value={form.state.color} error={errors.state?.color} />
              </div>
              {picker ? (
                <>
                  <div className="picker">
                    <BlockPicker onChange={(v) => handleChange('state.color', v.hex)} color={form.state.color} />
                  </div>
                  <div className="overlay" onClick={() => setPicker(false)} />
                </>
              ) : null}

            </div>
            <div onClick={() => setExecute(!execute)}>
              <Select
                placeholder="Execute"
                isDisabled
                error={errors.dateType || errors.when ? 'Execute is required.' : undefined}
                value={pipelineTriggers.find((p) => p.value === form.when)}
              />
            </div>
            {execute ? (
              <ExecuteDropdown
                form={form}
                onChange={handleChange}
                onClose={() => setExecute(false)}
                automation="state"
              />
            ) : null}
            <Conditions
              condition={condition}
              onChange={handleConditionChange}
              onDeleteCondition={handleDeleteCondition}
            />

          </div>

          <div className="actions">
            <Button title="Save" type="submit" loading={loading} />
            <Button title="Cancel" noBorder onClick={handleClose} />
          </div>
        </div>
      </AutomationWrapper>
    </div>
  );
}

export default AddState;

AddState.propTypes = {
  pipeline: PropTypes.string,
};

AddState.defaultProps = {
  pipeline: 'lead',
};
