import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  createInvoicePaymentRequest,
  deleteInvoicePaymentRequest,
  getInvoiceAfterCreatePaymentRequest,
  getInvoicesRequest,
} from '../actions/invoices';

const initialState = {
  invoiceList: [],
  totalPages: 1,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getInvoicesRequest.fulfilled, (store, action) => {
      const { data, path } = action.payload;

      if (!path) {
        store.invoiceList = data.invoices.map((invoice) => {
          const amount = invoice.status === 'paid'
            ? '0'
            : +(+invoice.total - _.sumBy(invoice.payment, 'amount').toFixed(2)).toFixed(2);
          const method = invoice.status === 'paid' ? invoice.payment?.[0]?.paymentMethod : 'cash';

          return {
            ...invoice, amount, method,
          };
        });
        store.totalPages = action.payload.totalPages;
      }
    })
    .addCase(createInvoicePaymentRequest.pending, (store, action) => {
      const data = action.meta.arg;

      store.invoiceList = store.invoiceList.map((l) => {
        if (data.invoiceId === l.id) {
          const amount = `${l.amount - +data.amount}`;

          return {
            ...l,
            amount,
            status: !(l.amount - +data.amount) ? 'paid' : 'partially_paid',
            method: data.method,
            paymentDate: data.date,
          };
        }

        return l;
      });
    })
    .addCase(getInvoiceAfterCreatePaymentRequest.fulfilled, (store, action) => {
      const invoice = action.payload.invoices[0];
      const { payment } = invoice;

      store.invoiceList = store.invoiceList.map((l) => (invoice.id === l.id ? { ...l, payment } : l));
    })
    .addCase(deleteInvoicePaymentRequest.pending, (store, action) => {
      const data = action.meta.arg;

      store.invoiceList = store.invoiceList.map((l) => {
        if (data.invoiceId === l.id) {
          const payment = l.payment.filter((p) => p.id !== data.paymentId);
          const invoice = { ...l, payment };

          if (!payment.length) invoice.status = 'unpaid';

          invoice.amount = invoice.status === 'paid'
            ? '0'
            : +invoice.total - _.sumBy(invoice.payment, 'amount').toFixed(2);

          return invoice;
        }

        return l;
      });
    });
});

export default reducer;
