import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../components/form/Input';
import Button from '../components/form/Button';
import Utils from '../helpers/Utils';
import { ReactComponent as EyeIcon } from '../assets/icons/eye.svg';
import WrapperLogout from '../components/logout/WrapperLogout';
import Api from '../Api';


const pass = Utils.generatePassword();



const SetPassword = () => {
  const { code } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({ password: pass, confirmPassword: pass });
  const { password, confirmPassword } = data;

  const [fieldsType, setFieldsType] = useState({ password: 'password', confirmPassword: 'password' });

  const [errors, setErrors] = useState({
    password: '', confirmPassword: '',
  });

  const [loading, setLoading] = useState(false);


  const changeData = useCallback((path, value) => {
    setData((prev) => ({ ...prev, [path]: value }));

    setErrors((prev) => ({
      ...prev,
      [path]: '',
    }));
  }, []);

  const setType = useCallback((path) => {
    setFieldsType((prev) => ({ ...prev, [path]: prev[path] === 'password' ? 'text' : 'password' }));
  }, []);

  const confirm = useCallback(async (e) => {
    e.preventDefault();

    let hasError = false;

    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        setErrors({
          confirmPassword: 'Password mismatch',
        });

        hasError = true;
      }
    } else {
      _.forEach(data, (value, key) => {
        if (!value) {
          setErrors((prev) => ({
            ...prev,
            [key]: _.upperFirst(_.lowerCase(`${_.startCase(key)} is required`)),
          }));

          hasError = true;
        }
      });
    }

    if (!hasError) {
      setLoading(true);

      try {
        await Api.setPassword({ code, ...data });

        toast.success(<span>
          <b>Success!</b>

          {' New password saved successfully'}
        </span>);
        navigate('/login');
      } catch (err) {
        if (err.response.data.errors) {
          setErrors(Utils.normalizeErrors(err.response.data.errors));
        } else {
          toast.error(err.response.data.message);
        }
      }

      setLoading(false);
    } else {
      toast.error('Please correct these fields');
    }
  }, [data]);

  return (
    <WrapperLogout title="Set a password">
      <div className="login">
        <form
          style={{ height: 'auto' }}
          onSubmit={confirm}
        >
          <p className="title">
            Set a password
          </p>

          <p className="desc">
            Please enter a your new password
          </p>

          <Input
            value={password}
            label="Password"
            type={fieldsType.password}
            className="h_40"
            placeholder="******"
            onChange={({ target: { value } }) => changeData('password', value)}
            rightIcon={(
              <EyeIcon
                onClick={() => setType('password')}
                className={fieldsType.password}
              />
            )}
            error={errors.password}
          />

          <Input
            value={confirmPassword}
            label="Confirm password"
            type={fieldsType.confirmPassword}
            className="h_40"
            placeholder="******"
            onChange={({ target: { value } }) => changeData('confirmPassword', value)}
            rightIcon={(
              <EyeIcon
                onClick={() => setType('confirmPassword')}
                className={fieldsType.confirmPassword}
              />
            )}
            error={errors.confirmPassword}
          />

          <Button
            title="Confirm"
            type="submit"
            loading={loading}
          />
        </form>
      </div>
    </WrapperLogout>
  );
};

export default SetPassword;
