import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

function FileInput(props) {
  const [id] = useState(_.uniqueId('file_input'));

  const {
    children, ...restProps
  } = props;
  return (
    <div className="fileInput">
      <label className="file" htmlFor={id}>
        {children}
        <input type="file" id={id} {...restProps} />
      </label>
    </div>
  );
}

FileInput.propTypes = {
  children: PropTypes.any,
};
FileInput.defaultProps = {
  children: undefined,
};
export default FileInput;
