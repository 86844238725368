import _ from 'lodash';
import { arrayMoveImmutable } from 'array-move';

class Utils {
  static normalizeErrors(errors) {
    const errObj = {};
    if (_.isArray(errors)) {
      errors.forEach((e) => {
        _.set(errObj, e.path, _.upperFirst(_.lowerCase(_.startCase(e.message.replaceAll('"', '')))));
      });
    }
    return errObj;
  }

  static deleteEmptyKeys = (object) => {
    const obj = _.cloneDeep(object);

    for (const propName in obj) {
      if (typeof obj[propName] !== 'boolean'
      && typeof obj[propName] === 'object' ? _.isEmpty(obj[propName]) : !obj[propName]) {
        delete obj[propName];
      }
    }

    return obj;
  };

  static trimObjValues = (object) => {
    const obj = _.cloneDeep(object);

    for (const propName in obj) {
      if (typeof obj[propName] === 'string' && obj[propName]) {
        obj[propName] = obj[propName].trim();
      }
    }

    return obj;
  };

  static isEmail = (email) => {
    const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailReg.test(String(email).toLowerCase());
  };

  static isValidPassword = (password) => {
    const passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;

    return passwordReg.test(password);
  };

  static keyPressNumber = (e, past, positiveNumber, maxLength, maxValue) => {
    if (e.which === 13) {
      return;
    }

    let { value } = e.target;
    const { selectionStart, selectionEnd } = e.target;
    value = value.replace('%', '');

    const pastValueWithCurrentValue = value.slice(0, selectionStart) + (e?.clipboardData?.getData('text') || '');

    const val = past
      ? pastValueWithCurrentValue
      : (value.length && value.length === value.substring(selectionStart, selectionEnd).length
        ? e.key
        : value.substring(0, selectionStart) + e.key + value.substring(selectionStart, value.length));

    const valueToNumber = +val;
    const firstNumber = val.slice(0, 1);

    if (e.which === 32 || (positiveNumber && +firstNumber === 0)
      || isNaN(valueToNumber) || (maxLength && val.length > maxLength)
      || val.includes('.') || val.includes('e') || val.includes('+') || val.includes('-')
      || (maxValue && valueToNumber > maxValue)) {
      e.preventDefault();
    }
  };

  static keyPressCurrency = (e, past, maxValue, countOfDigitsAfterDot) => {
    if (e.which === 13) {
      return;
    }

    const { selectionStart, selectionEnd, value } = e.target;
    const pastValueWithCurrentValue = value.slice(0, selectionStart) + e?.clipboardData?.getData('text');

    const val = past
      ? pastValueWithCurrentValue
      : (value.length === value.substring(selectionStart, selectionEnd).length
        ? e.key
        : value.substring(0, selectionStart) + e.key + value.substring(selectionStart, value.length));

    const valueToNumber = +val;

    if (e.which === 32 || (val.length > 1 && +val.slice(0, 1) === 0 && val.slice(1, 2) !== '.')
      // eslint-disable-next-line no-restricted-globals
      || (val.slice(1, 2) === '.' && !isNaN(+val.slice(2, val.length)
        .replace('.', 'nun'))
        // eslint-disable-next-line no-restricted-globals
        ? false : isNaN(valueToNumber)) || val.includes('e') || val.includes('+') || val.includes('-')
      || (!isNaN(countOfDigitsAfterDot) && val.split('.')?.[1]?.length > countOfDigitsAfterDot)
      || (!isNaN(maxValue) && valueToNumber > +maxValue)) {
      e.preventDefault();
    }
  };

  static keyPressPhoneNumber = (e, past) => {
    if (e.which === 13) {
      return;
    }

    const { selectionStart, selectionEnd, value } = e.target;
    const pastValueWithCurrentValue = value.slice(0, selectionStart) + (e?.clipboardData?.getData('text') || '');

    const val = past
      ? pastValueWithCurrentValue
      : (value.length && value.length === value.substring(selectionStart, selectionEnd).length
        ? value
        : value.substring(0, selectionStart) + e.key + value.substring(selectionStart, value.length));

    if (!/^[0-9()\-+]*$/.test(val)) {
      e.preventDefault();
    }
  };

  static arrayMove(array, oldIndex, newIndex) {
    return arrayMoveImmutable(array, oldIndex, newIndex);
  }

  static addElementInArray(array, index, element) {
    return array.splice(index, 0, element);
  }

  static formatPrice(number) {
    if (!number) {
      return '';
    }
    return (`${(+number || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00', '')}`).replace('$-', '-$');
  }

  static formatDuration(s) {
    return new Date(s * 1000).toISOString().substring(14, 19);
  }

  static formatPhoneNumber(phone) {
    if (!phone) {
      return '';
    }
    const cleaned = phone.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      let code = '+1';
      if (match[1]) {
        if (match[1].includes('+1')) {
          // eslint-disable-next-line prefer-destructuring
          code = match[1];
        } else {
          code = `+${match[1]}`;
        }
      }
      return [code, ' ', match[2], '-', match[3], '-', match[4]].join('');
    }
    return phone;
  }

  static escRegExp(string) {
    return (string || '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  static formatHtml = (text, search = '') => {
    try {
      const searchArr = _.isArray(search) ? search : search.split(' ');
      let s = searchArr.map((t) => this.escRegExp(t)).join('|');
      s = s.replace(/\s+/g, '').split('').join('\\s*');
      return {
        __html: (text || '').replace(new RegExp(`(${s})`, 'ig'), '<b>$1</b>'),
      };
    } catch (e) {
      return {
        __html: text,
      };
    }
  };

  static generatePassword() {
    const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const symbols = '!@#$*_+=:.?';
    const str = _.range(0, 8).map(() => letters[_.random(0, letters.length - 1)]).join('');
    const num = numbers[_.random(0, numbers.length - 1)];
    const symb = symbols[_.random(0, symbols.length - 1)];
    return `${num}${str}${symb}`;
  }
}

export default Utils;
