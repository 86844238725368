import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { ReactComponent as NoActivityIcon } from '../../assets/icons/no_activity.svg';
import Editor from '../form/Editor';
import Button from '../form/Button';
import { addActivityNoteRequest, deleteActivityNoteRequest, getActivityNotesRequest } from '../../store/actions/notes';
import noImg from '../../assets/images/no_img_user.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';

function Activity(props) {
  const { activity, pipeline } = props;
  const [activityGroupCount, setActivityGroupCount] = useState(2);
  const [note, setNote] = useState('');
  const [noteId, setNoteId] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { leadId, jobId } = useParams();
  const dispatch = useDispatch();

  const notes = useSelector((state) => state.notes.notes);
  useEffect(() => {
    if (leadId || jobId) {
      dispatch(getActivityNotesRequest({ sourceId: leadId || jobId, type: pipeline }));
    }
  }, [leadId, jobId, pipeline]);

  const handleSeeMore = useCallback(() => {
    setActivityGroupCount(activityGroupCount + 2);
  }, [activityGroupCount]);

  const getActivityInfo = useCallback((data) => {
    if (data.action === `Create ${pipeline}`) {
      return (
        <>
          <span>{`${data.userName || 'User'} created ${pipeline} with`}</span>
          <span className="bold">{_.startCase(data.status)}</span>
          <span> status.</span>
        </>
      );
    }
    if (data.action === 'Change status') {
      return (
        <>
          <span>{`${data.userName || 'User'} changed status to`}</span>
          <span className="bold">{`${_.startCase(data.status)}.`}</span>
        </>
      );
    }
    if (data.action === 'sendJobAndLeadEmail') {
      return (
        <>
          <span>{`${data.userName || 'User'} sent email from`}</span>
          <span className="bold">{data.data.from}</span>
          <span> to</span>
          <span className="bold">{`${data.data.to}.`}</span>
        </>
      );
    }
    if (data.action === 'Update lead') {
      return (
        <span>{`${data.userName || 'User'} updated ${pipeline}.`}</span>
      );
    }

    return `${_.upperFirst(pipeline)} updated.`;
  }, [pipeline]);

  const handleAddNote = useCallback(async () => {
    setLoading(true);
    await dispatch(addActivityNoteRequest({
      sourceId: leadId || jobId, text: note, type: pipeline, id: noteId,
    }));
    await dispatch(getActivityNotesRequest({ sourceId: leadId || jobId, type: pipeline }));
    setNote('');
    setLoading(false);
  }, [note, leadId, jobId, pipeline, noteId]);

  if (_.isEmpty(activity)) {
    return (
      <div className="activity">
        <div className="noActivity">
          <NoActivityIcon />
          <p>You don’t have an activity yet</p>
        </div>
      </div>
    );
  }

  const handleDeleteNote = useCallback(async (id) => {
    await dispatch(deleteActivityNoteRequest(id));
    await dispatch(getActivityNotesRequest({ sourceId: leadId || jobId, type: pipeline }));
  }, [leadId, jobId, pipeline]);

  const handleEditNote = useCallback((n, id) => {
    setNote(n);
    setNoteId(id);
  }, []);

  return (
    <div className="activityWithInfo">
      <h3 className="notesBlockLabel">
        Activity
      </h3>
      <div className="activityContainer">
        {Object.entries(activity).map(([date, actions], index) => {
          if (index < activityGroupCount) {
            return (
              <Fragment key={date}>
                <div className="groupDate">
                  {date === moment().format('MM.DD.YYYY') ? 'Today' : date}
                </div>
                <div className="items">

                  {actions.map((a) => (
                    <div className="activityItem" key={a._id}>
                      <p className="dot" />
                      <div>
                        <p className="date">
                          {moment(a.createdAt)
                            .format('MM.DD.YYYY hh:mm')}
                        </p>
                        <p className="action">
                          <span>{getActivityInfo(a)}</span>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </Fragment>
            );
          }
          return null;
        })}
        {Object.values(activity).length > activityGroupCount
          ? <div className="seeMore" onClick={handleSeeMore}>See more</div> : null}
      </div>
      <h3 className="notesBlockLabel">
        Notes
      </h3>
      <div className="notesBlock">
        {!_.isEmpty(notes) ? notes.map((n) => (
          <div key={n.id} className={classNames('note', { editing: note === n.text })}>
            <div className="img">
              <img src={noImg} alt="no_img" />
            </div>
            <div className="info">
              <div className="top">
                <div>
                  <p className="name">{n.name}</p>
                  <span className="dot" />
                  <p className="date">{moment(n.updatedAt).format('lll')}</p>
                </div>
                <div className="actions">
                  <div className="icon" onClick={() => handleEditNote(n.text, n.id)}>
                    <EditIcon />
                  </div>
                  <div className="icon" onClick={() => handleDeleteNote(n.id)}>
                    <DeleteIcon />
                  </div>
                </div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: n.text }} className="text" />
            </div>
          </div>
        )) : null}
      </div>
      <div className="notes">
        <Editor placeholder="Your note here" value={note} onChange={(val) => setNote(val)} />
        <Button title="Add note" onClick={handleAddNote} loading={loading} disabled={!note} />
      </div>
    </div>
  );
}

export default Activity;

Activity.propTypes = {
  activity: PropTypes.object.isRequired,
  pipeline: PropTypes.string.isRequired,
};
