import React, {
  createContext, useCallback, useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Navigate, useLocation, useNavigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Menu from './Menu';
import Header from './Header';
import HeadersMeta from './HeadersMeta';
import Loader from './loader/Loader';
import { initSocket } from '../store/actions/socket';
import { removeGlobalSearchData } from '../store/actions/account';
import menuItemsList from '../data/menuItemsList';

export const LeftMenuContext = createContext(null);

function Wrapper(props) {
  const {
    children,
    title,
  } = props;

  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const [open, setOpen] = useState(JSON.parse(localStorage.getItem('isMenuOpen')) ?? true);

  const token = useSelector((state) => state.account.token);
  const profile = useSelector((state) => state.account.profile);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(initSocket());
  }, []);

  useEffect(() => {
    dispatch(removeGlobalSearchData());
  }, [pathname]);

  useEffect(() => {
    if (!token && pathname.includes('unsubscribe')) {
      navigate(`/login?path=${pathname}${search}`);
    }
  }, [token, pathname, search]);

  useMemo(() => {
    const { permissions } = profile.role || {};
    if (permissions) {
      const menu = menuItemsList.find((m) => permissions.some((p) => m.permissions.includes(p)));
      if (menu) {
        navigate(menu.path);
      }
    }
  }, []);

  const openingMenu = useCallback(() => {
    setOpen((prev) => {
      localStorage.setItem('isMenuOpen', !prev);
      return !prev;
    });
  }, []);

  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="wrapper">
      {!_.isEmpty(profile) ? (
        <>
          <HeadersMeta title={title} />

          <Menu isOpen={open} toggleMenu={openingMenu} />

          <div className={classNames('content', { open })}>
            <Header title={title} />

            <LeftMenuContext.Provider value={open}>
              {children}
            </LeftMenuContext.Provider>
          </div>
        </>
      ) : <Loader />}

    </div>
  );
}

export default Wrapper;

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
