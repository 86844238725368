import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment/moment';
import Loader from '../loader/Loader';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import DeleteModal from '../modal/DeleteModal';
import Checkbox from '../form/Checkbox';
import HasPermission from '../HasPermission';
import Utils from '../../helpers/Utils';

const statusColors = [
  {
    statuses: ['pending', 'partially_paid'],
    color: '#FF9900',
  },
  {
    statuses: ['active', 'paid'],
    color: '#00AF31',
  },
  {
    statuses: ['unpaid'],
    color: '#F50000',
  },
];
const getStatusColor = (status) => statusColors.find(({ statuses }) => statuses.includes(status))?.color || '#292929';

const formattingValue = (key, value) => {
  const upperFirstKeys = ['status'];
  const currencyKeys = ['total'];
  const phoneKey = ['phone'];
  const dateKeys = ['date', 'dueDate'];

  if (upperFirstKeys.includes(key)) {
    return _.upperFirst(value.replace('_', ' '));
  }

  if (currencyKeys.includes(key)) {
    return `$${value}`;
  }

  if (dateKeys.includes(key)) {
    return moment(value).format('MM/DD/yyyy');
  }

  if (phoneKey.includes(key)) {
    return Utils.formatPhoneNumber(value);
  }

  return typeof value === 'number' ? value : (value || '-');
};
const Table = ({
  data, header, className, hideActions, minWidth, loading, selectedItems, showCheckbox, deleteModalText,
  onCheckboxChange, onEditClick, onDeleteClick, editPermission, readEditPermission, deletePermission,
  readDeletePermission,
}) => {
  const [deleteModalData, setDeleteModalData] = useState({});
  const [deleteLoading, deleteLoadingToggle] = useState(false);
  const width = useMemo(() => minWidth || header.length * 165 + (hideActions ? 0 : 165), [minWidth, data]);

  const onDelete = useCallback(async () => {
    deleteLoadingToggle(true);
    await onDeleteClick(deleteModalData);

    setDeleteModalData({});
    deleteLoadingToggle(false);
  }, [deleteModalData]);

  return (
    data.length
      ? (
        <div className={`table__wrapper ${className}`}>
          {loading && (
            <div className="loader__wrapper">
              <Loader size={60} borderWidth={4} />
            </div>
          )}

          <table
            style={{ minWidth: width }}
            className="table"
          >
            <thead className="thead">
              <tr className="tr">
                {showCheckbox && (
                <th className="th checkbox__wrapper">
                  <Checkbox
                    checked={data.every((d) => selectedItems.includes(d.id))}
                    onChange={() => onCheckboxChange('all')}
                    greenBG
                  />
                </th>
                )}

                {header.map((h) => (
                  <th className="th" key={h.title}>{h.title}</th>
                ))}

                {!hideActions && <th style={{ width: 165 }} className="th">Actions</th>}
              </tr>
            </thead>

            <tbody className="tbody">
              {data.map((d) => (
                <tr
                  className="tr"
                  key={d.id}
                >
                  {showCheckbox && (
                  <th className="td checkbox__wrapper">
                    <Checkbox
                      checked={selectedItems.includes(d.id)}
                      onChange={() => onCheckboxChange(d.id)}
                      greenBG
                    />
                  </th>
                  )}

                  {header.map((h) => (
                    <td
                      className="td"
                      key={h.title}
                      style={{
                        color: h.onClick ? '#356966' : getStatusColor(_.get(d, h.path)),
                        fontWeight: h.onClick ? 600 : 400,
                        cursor: h.onClick ? 'pointer' : 'default',
                      }}
                      onClick={() => h.onClick && h.onClick(d)}
                    >
                      {h.innerElement ? h.innerElement(d, formattingValue) : formattingValue(h.path, _.get(d, h.path))}
                    </td>
                  ))}

                  {!hideActions && (
                  <td style={{ width: 165 }} className="th actions__wrapper">
                    <HasPermission edit={editPermission} read={readEditPermission}>
                      <EditIcon
                        className="action__edit__icon"
                        onClick={() => onEditClick(d)}
                      />
                    </HasPermission>
                    <HasPermission edit={deletePermission} read={readDeletePermission}>
                      <DeleteIcon onClick={() => setDeleteModalData(d)} />
                    </HasPermission>
                  </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <DeleteModal
            open={!_.isEmpty(deleteModalData)}
            text={deleteModalText}
            loading={deleteLoading}
            onClose={() => setDeleteModalData({})}
            onDelete={onDelete}
          />
        </div>
      )

      : <div className="table__no__result">No Data Found</div>
  );
};

Table.propTypes = {
  header: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  deleteModalText: PropTypes.string,
  minWidth: PropTypes.number,
  showCheckbox: PropTypes.bool,
  hideActions: PropTypes.bool,
  loading: PropTypes.bool,
  selectedItems: PropTypes.array,
  onCheckboxChange: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  editPermission: PropTypes.string.isRequired,
  readEditPermission: PropTypes.string.isRequired,
  deletePermission: PropTypes.string.isRequired,
  readDeletePermission: PropTypes.string.isRequired,
};

Table.defaultProps = {
  className: '',
  deleteModalText: '',
  minWidth: 0,
  showCheckbox: false,
  hideActions: false,
  loading: false,
  selectedItems: [],
  onCheckboxChange: () => {
  },
  onEditClick: () => {
  },
  onDeleteClick: () => {
  },
};

export default Table;
