import React, { useCallback, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { ReactComponent as ArrowIcon } from '../../assets/icons/percent_arrow.svg';
import Modal from '../../components/modal/Modal';
import RangeDatePicker from '../../components/form/RangeDatePicker';
import Select from '../../components/form/Select';
import { defaultDates } from '../../data/options';
import Api from '../../Api';
import Loader from '../../components/loader/Loader';
import { useQuery } from '../../helpers/hooks/useQuery';

const options = {
  options: {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1.5,
      curve: 'straight',
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      dropShadow: {
        enabled: false,
      },
      categories: [],
    },

    yaxis: {
      show: false,
      labels: {
        show: false,
      },
    },
  },
  series: [],
};

const optionsModal = {
  series: [],
  options: {
    chart: {
      type: 'bar',
      height: 430,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth: '30%',
        dataLabels: {
          position: 'top',
        },
        colors: {
          backgroundBarColors: ['#E5E5EF'],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 5,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      marker: {
        show: true,
      },
      x: {
        show: true,
      },
      y: {
        title: {
          formatter: () => '',
        },
      },
    },
    legend: {
      show: false,
    },
    colors: ['#356966'],
    markers: {
      strokeWidth: 1,
    },
    xaxis: {
      // labels: {
      //   formatter(value) {
      //     return moment.utc(value).format('MMM D');
      //   },
      // },
      categories: [],
    },
  },
};

const thisMonthStartDay = moment().startOf('month').format('YYYY-MM-DD');
const thisMonthEndDate = moment().format('YYYY-MM-DD');
const LeadStatistic = ({ data, type, loading }) => {
  const { query } = useQuery();
  const [openModal, setOpenModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [chart, setChart] = useState(options);
  const [chartModal, setChartModal] = useState(optionsModal);
  const [requestData, setRequestData] = useState([thisMonthStartDay, thisMonthEndDate]);

  useEffect(() => {
    setChart((prev) => ({
      ...prev,
      series: [{
        data: data.values,
      }],
      options: {
        ...prev.options,
        xaxis: {
          ...prev.options.xaxis,
          categories: data.dates,
        },
        stroke: {
          ...prev.options.stroke,
          colors: type === 'leadsStatistic' ? '#356966' : '#FFD831',
        },
      },
    }));

    setChartModal((prev) => ({
      ...prev,
      series: [{
        data: data.values,
      }],
      options: {
        ...prev.options,
        xaxis: {
          ...prev.options.xaxis,
          categories: data.dates,
        },
      },
    }));
  }, [type, data]);

  const onChangeDate = useCallback(async (date) => {
    setModalLoading(true);
    setRequestData(date);

    if (date[1]) {
      const result = await Api.leadsStatistic({ startDate: date[0], endDate: date[1], id: query.id });

      setChartModal((prev) => ({
        ...prev,
        series: [{
          data: result.data[type].values,
        }],
        options: {
          ...prev.options,
          xaxis: {
            ...prev.options.xaxis,
            categories: result.data[type].dates,
          },
        },
      }));
    }

    setModalLoading(false);
  }, []);

  const closeModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <>
      <div className="leadsStatistic_wrapper" onClick={() => setOpenModal(true)}>

        {loading && <Loader className="loader_dashboard" />}

        <div className="leadsStatistic_left">
          <p className="leadsStatistic_title">Statistics</p>

          <h2>{type === 'leadsStatistic' ? 'Total leads' : 'Leads converted into jobs'}</h2>

          <p className="leadsStatistic_total">{data.leadsCount}</p>

          <div className={`leadsStatistic_percent ${+data.growthRate < 0 ? 'down' : ''}`}>
            <p>{`${data.growthRate}%`}</p>

            <ArrowIcon />
          </div>
        </div>

        <div className="leadsStatistic_chart">
          {!!chart.series.length && (
          <Chart
            height="100px"
            width="150px"
            type="line"
            options={chart.options}
            series={chart.series}
          />
          )}
        </div>

      </div>

      <Modal open={openModal} onClose={closeModal}>
        <div className="leadsStatistic_modal_wrapper">
          <div className="leadsStatistic_modal_header">
            <h2>
              {type === 'leadsStatistic' ? 'Total leads' : 'Leads converted into jobs'}
            </h2>

            <div className="calendar">
              <RangeDatePicker
                date={requestData}
                setDate={(date) => onChangeDate(date)}
              />

              <Select
                options={defaultDates}
                onChange={(date) => onChangeDate(date.value)}
                value={defaultDates.filter((d) => JSON.stringify(d.value) === JSON.stringify(requestData))}
              />

            </div>

          </div>

          {modalLoading
            ? <div className="dashboard_loader_wrapper"><Loader className="loader_dashboard" /></div>
            : (
              <Chart
                height="435px"
                width="100%"
                type="bar"
                options={chartModal.options}
                series={chartModal.series}
              />
            )}
        </div>

      </Modal>

    </>
  );
};

LeadStatistic.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default LeadStatistic;
