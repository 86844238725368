import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as CallingIcon } from '../../assets/icons/calling_white.svg';
import { ReactComponent as MicrophoneIcon } from '../../assets/icons/microphone.svg';
import { ReactComponent as MicrophoneMutedIcon } from '../../assets/icons/microphone_muted.svg';
import { ReactComponent as PauseIcon } from '../../assets/icons/pause.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone_new.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg';
import Utils from '../../helpers/Utils';

function CallDetails(props) {
  const {
    ringing, onManage, onHangup, calling, callDetails: {
      callId, appCallStatus, appCallHoldState, appCallMuteState, name, phone,
    },
  } = props;

  const [duration, setDuration] = useState(0);

  useEffect(() => {
    let interval;
    if (appCallStatus === 'answered') {
      interval = setInterval(() => {
        setDuration(duration + 1);
      }, 1000);
    } else {
      setDuration(0);
    }

    return () => {
      clearInterval(interval);
    };
  }, [duration, appCallStatus]);

  return (
    <div className="callDetails">
      <div className="infoContainer">
        <CallingIcon />
        <div className="info">
          <p className="name">{name || Utils.formatPhoneNumber(phone)}</p>
          {ringing ? <p className="duration">Ringing</p> : null}
          {calling && !duration ? <p className="duration">Calling</p> : null}
          {duration ? <p className="duration">{Utils.formatDuration(duration)}</p> : null}
        </div>
      </div>
      <div className="actions">
        <div
          className={classNames('icon', { ringing })}
          onClick={() => {
            if (!ringing) {
              onManage(appCallHoldState === 'hold' ? 'unhold' : 'hold', callId);
            }
          }}
        >
          {appCallHoldState !== 'hold' ? <PauseIcon /> : <PlayIcon />}
        </div>
        <div
          className={classNames('icon', { ringing })}
          onClick={() => {
            if (!ringing) {
              onManage(appCallMuteState !== 'mute' ? 'mute' : 'unmute', callId);
            }
          }}
        >
          {appCallMuteState !== 'mute' ? <MicrophoneIcon /> : <MicrophoneMutedIcon />}
        </div>
        <div
          className={classNames('icon endCall', { ringing })}
          onClick={() => {
            if (!ringing) {
              onHangup(callId);
            }
          }}
        >
          <PhoneIcon />
        </div>
      </div>
    </div>
  );
}

export default CallDetails;

CallDetails.propTypes = {
  ringing: PropTypes.bool,
  callDetails: PropTypes.object,
  onManage: PropTypes.func.isRequired,
  onHangup: PropTypes.func.isRequired,
  calling: PropTypes.bool,
};

CallDetails.defaultProps = {
  ringing: false,
  callDetails: {},
  calling: false,
};
