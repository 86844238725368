import { createReducer } from '@reduxjs/toolkit';
import { getActivityNotesRequest } from '../actions/notes';

const initialState = {
  notes: [],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getActivityNotesRequest.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.notes = data.notes;
    });
});

export default reducer;
