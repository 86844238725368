import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  addPipelineLocal,
  changePipelineLocal,
  getEmailsRequest,
  getPipelinesRequest,
  getPipelineStatusesRequest, getSingleEmailsRequest,
  orderPipelineLocal,
} from '../actions/pipelines';
import Utils from '../../helpers/Utils';

const initialState = {
  pipelines: { lead: [], job: [], task: [] },
  pipelineStatuses: { lead: [], job: [], task: [] },
  emails: { emails: [] },
  singleEmail: {},
};
export default createReducer(initialState, (builder) => {
  builder
    .addCase(getPipelinesRequest.fulfilled, (state, action) => {
      const { data, type } = action.payload;
      state.pipelines = { ...state.pipelines, [type]: _.orderBy(data.pipelines, 'order') };
    })
    .addCase(orderPipelineLocal, (state, action) => {
      const { type, oldInd, newInd } = action.payload;
      state.pipelines[type] = Utils.arrayMove(state.pipelines[type], oldInd, newInd);
    })
    .addCase(getPipelineStatusesRequest.fulfilled, (state, action) => {
      const { type, data } = action.payload;
      state.pipelineStatuses = { ...state.pipelineStatuses, [type]: data.statuses };
    })
    .addCase(addPipelineLocal, (state, action) => {
      const { type, data } = action.payload;
      state.pipelines[type] = data;
    })
    .addCase(changePipelineLocal, (state, action) => {
      const { type, key, value } = action.payload;
      _.set(state.pipelines[type], key, value);
    })
    .addCase(getEmailsRequest.fulfilled, (state, action) => {
      state.emails = action.payload.data;
    })
    .addCase(getSingleEmailsRequest.fulfilled, (state, action) => {
      state.singleEmail = action.payload.data.email;
    });
});
