import { createReducer } from '@reduxjs/toolkit';
import { getContactsRequest } from '../actions/contacts';

const initialState = {
  contactList: [],
  totalPages: 1,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getContactsRequest.fulfilled, (store, action) => {
      const { data, path } = action.payload;
      if (!path) {
        store.contactList = data.contacts;
        store.totalPages = data.totalPages;
      }
    });
});

export default reducer;
