import React, {
  Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../components/Wrapper';
import TableWithPagination from '../../components/tables/TableWithPagination';
import Button from '../../components/form/Button';
import { ReactComponent as FiltersIcon } from '../../assets/icons/filters.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';
import { ReactComponent as FailIcon } from '../../assets/icons/attention_2.svg';
import RightModal from '../../components/modal/RightModal';
import { deleteInvoicePaymentRequest, getInvoicesRequest } from '../../store/actions/invoices';
import Api from '../../Api';
import { useQuery } from '../../helpers/hooks/useQuery';
import BulkSelect from '../../components/form/BulkSelect';
import InvoiceFilterModal from '../../components/invoices/InvoiceFilterModal';
import PrintModal from '../../components/invoices/PrintModal';
import InvoicePrint from '../../components/invoices/InvoicePrint';
import Utils from '../../helpers/Utils';
import PaymentModal from '../../components/invoices/PaymentModal';
import PaymentHistory from '../../components/invoices/PaymentHistory';
import Modal from '../../components/modal/Modal';
import HasPermission from '../../components/HasPermission';
import { invoicePermissions } from '../../data/permissions';

const bulkOptions = [
  {
    title: 'Email invoices',
    path: 'email',
  },
  {
    title: 'Register payment',
    path: 'payment',
  },
  {
    title: 'Print invoices',
    path: 'print',
  },
];

const formattingQueryDate = (queryDate = '') => {
  const [start, end] = queryDate.split(',') || [];

  const isValidStart = moment(start, 'yyyy-MM-DD', true).isValid();
  const isValidEnd = moment(end, 'yyyy-MM-DD', true).isValid();

  return !isValidStart || (end && !isValidEnd)
    ? []

    : queryDate.split(',');
};

const formattingRequestData = (data) => {
  const requestData = Utils.deleteEmptyKeys(data);

  if (requestData.total?.length === 2 && !requestData.total[0]) {
    requestData.total = ['0', requestData.total[1]];
  }

  requestData.total = _.compact(requestData.total);

  return requestData;
};

const hasFilters = (filters) => {
  const clonedFilters = { ...filters };
  delete clonedFilters.page;
  delete clonedFilters.limit;

  let hasFilter = 1;

  _.forEach(clonedFilters, (value, key) => {
    if (key !== 'invoiceDate' && !_.isEmpty(value)) {
      hasFilter += 1;
    }
  });

  return hasFilter;
};

const Invoices = () => {
  const navigate = useNavigate();
  const { query, setQuery } = useQuery();

  const dispatch = useDispatch();
  const { invoiceList, totalPages } = useSelector((state) => state.invoices);

  const timeout = useRef();

  const [filters, setFilters] = useState({
    page: +query.page || 1,
    limit: +query.limit || 10,
    s: query.s || '',
    invoiceDate: formattingQueryDate(query.invoiceDate),
    dueDate: formattingQueryDate(query.dueDate),
    total: query.total ? query.total.split(',') : [],
  });
  const { page, limit } = filters;

  const [selectedInvoices, selectInvoice] = useState([]);

  const [loading, loadingToggle] = useState(true);

  const [isModalOpen, modalOpeningToggle] = useState(false);
  const [modalData, setModalData] = useState({});

  const [emailModalInfo, setEmailModalInfo] = useState({});

  const [isPrint, printToggle] = useState(false);

  const statusComponent = useCallback((data, formattingData) => (
    <div className="payment__status__wrapper">
      {formattingData('status', data.status)}

      {data.status !== 'unpaid' && (
        <button
          onClick={() => openInvoiceModal('paymentHistory', data)}
        >
          View
        </button>
      )}
    </div>
  ), []);

  const tableHeader = useMemo(() => [
    {
      title: 'Invoice number',
      placeholder: 'Invoice number',
      path: 'invoiceNumber',
      valuePath: 's',
      onClick: (data) => openInvoiceModal('print', data),
    },
    {
      title: 'Company',
      placeholder: 'Company',
      path: 'company',
    },
    {
      title: 'Name',
      placeholder: 'Name',
      path: 'customer.name',
    },
    {
      title: 'Invoice date',
      path: 'date',
      valuePath: 'invoiceDate',
      type: 'date',
    },
    {
      title: 'Due date',
      path: 'dueDate',
      type: 'date',
    },
    {
      title: 'Amount',
      placeholder: 'Amount',
      path: 'total',
      type: 'range',
    },
    {
      title: 'Status',
      path: 'status',
      hide: true,
      innerElement: statusComponent,
    },
  ], []);

  const fields = useMemo(() => tableHeader.filter((field) => !field.hide), []);

  useLayoutEffect(() => {
    if (query.first) {
      (async () => {
        loadingToggle(true);
        closeInvoiceModal();
        selectInvoice([]);
        const firstLoadFilter = { invoiceDate: formattingQueryDate(query.invoiceDate) };

        setFilters(firstLoadFilter);

        await dispatch(getInvoicesRequest(formattingRequestData(firstLoadFilter)));

        loadingToggle(false);
      })();
    }
  }, [query.first]);

  useEffect(() => {
    (async () => {
      await dispatch(getInvoicesRequest(formattingRequestData(filters)));

      loadingToggle(false);
    })();

    return () => clearTimeout(timeout.current);
  }, []);

  useEffect(() => {
    setQuery(filters);

    if (+filters.page === 1 && selectedInvoices.length) {
      selectInvoice([]);
    }
  }, [filters]);

  const saveFilters = useCallback(async (newFilters) => {
    const requestData = { ...newFilters, page: 1 };
    setFilters(requestData);

    await dispatch(getInvoicesRequest(formattingRequestData(requestData)));
  }, []);

  const onChangePage = useCallback(async (type, value) => {
    const requestData = { ...filters };

    requestData[type] = value;

    if (type === 'limit') {
      requestData.page = 1;
    }

    setFilters(requestData);

    await dispatch(getInvoicesRequest(formattingRequestData(requestData)));
  }, [filters]);

  const openInvoiceModal = useCallback((type, printData = {}) => {
    modalOpeningToggle(true);

    setModalData(() => {
      const data = { ...printData };
      data.type = type;

      return data;
    });
  }, []);

  const closeInvoiceModal = useCallback(() => {
    modalOpeningToggle(false);

    timeout.current = setTimeout(() => {
      setModalData({});
    }, 500);
  }, []);

  const editInvoice = useCallback((invoice) => {
    navigate(`/invoices/${invoice.jobId}/${invoice.id}`);
  }, []);

  const deleteInvoice = useCallback(async (user) => {
    await Api.deleteInvoice(user.id);

    const requestData = { ...filters };
    requestData.page = invoiceList.length === 1 && page > 1 ? page - 1 : page;

    await dispatch(getInvoicesRequest(formattingRequestData(requestData)));
    setFilters(requestData);

    toast.success('Invoice deleted');
  }, [invoiceList, filters]);

  const selectBulkOption = useCallback(async (path) => {
    if (!selectedInvoices.length) {
      toast.error('Please select invoice');
    } else if (path === 'print') {
      await printToggle(true);
      document.body.classList.add('print');
      window.print();

      printToggle(false);
      document.body.classList.remove('print');
    } else if (path === 'email') {
      const allowedInvoiceNumbers = [];
      const notAllowedInvoiceNumbers = [];

      const filteredInvoices = invoiceList.filter((invoice) => selectedInvoices.includes(invoice.id))
        .filter((invoice) => {
          if (!invoice.customer?.email) {
            notAllowedInvoiceNumbers.push(invoice.invoiceNumber);
          }

          return !!invoice.customer?.email;
        }).map((invoice) => {
          allowedInvoiceNumbers.push(invoice.invoiceNumber);
          return invoice.id;
        });

      if (filteredInvoices.length) {
        Api.sendInvoiceEmail({ invoiceIds: filteredInvoices });
      }

      setEmailModalInfo({
        allowedInvoiceNumbers,
        notAllowedInvoiceNumbers,
      });
    } else if (path === 'payment') {
      openInvoiceModal(path);
    }
  }, [selectedInvoices]);

  const selectInvoiceItem = useCallback((id) => {
    selectInvoice((prev) => {
      if (id === 'all') {
        const allItems = invoiceList.map((u) => u.id);

        if (prev.length && allItems.every((p) => prev.includes(p))) {
          return prev.filter((p) => !allItems.includes(p));
        }

        return _.uniq([...prev, ...allItems]);
      }

      if (prev.includes(id)) {
        return prev.filter((p) => p !== id);
      }

      return [...prev, id];
    });
  }, [invoiceList]);

  const deleteSelectedInvoice = useCallback((id) => {
    selectInvoice((prev) => {
      const filteredInvoice = prev.filter((p) => p !== id);

      if (!filteredInvoice.length) closeInvoiceModal();

      return filteredInvoice;
    });
  }, []);

  const deleteHistory = useCallback(async (paymentId) => {
    dispatch(deleteInvoicePaymentRequest({ invoiceId: modalData.id, paymentId }));

    const payment = modalData.payment.filter((p) => p.id !== paymentId);
    setModalData((prev) => ({ ...prev, payment }));

    toast.success('Payment deleted');

    if (!payment.length) {
      closeInvoiceModal();
    }
  }, [modalData]);

  return (
    <>
      <Wrapper title="Invoices">
        <div className="contacts__content">
          <div className="invoices__buttons__wrapper">
            <BulkSelect
              options={bulkOptions}
              onClick={selectBulkOption}
            />

            <Button
              outlined
              title={(
                <>
                  <FiltersIcon />

                  Filters

                  {!!hasFilters(filters) && (
                    <span className="filters__count">
                      {hasFilters(filters)}
                    </span>
                  )}
                </>
              )}
              onClick={() => openInvoiceModal('filters')}
            />
          </div>
          <HasPermission read={invoicePermissions}>
            <TableWithPagination
              data={invoiceList}
              header={tableHeader}
              showCheckbox
              selectedItems={selectedInvoices}
              onCheckboxChange={selectInvoiceItem}
              page={page}
              limit={limit}
              firstLoad={loading}
              totalPages={totalPages}
              onChangePage={onChangePage}
              onEditClick={editInvoice}
              onDeleteClick={deleteInvoice}
              deleteModalText="Are you sure you want to delete the invoice?"
              deletePermission="delete_invoice"
              readDeletePermission="delete_invoice_readonly"
            />
          </HasPermission>
        </div>

        <RightModal
          open={!!isModalOpen}
          onClose={closeInvoiceModal}
          openFromBelow={modalData.type === 'payment'}
          className={modalData.type === 'payment' ? 'invoices__payment__modal' : 'invoices__right__modal'}
          modalWidth={modalData.type === 'filters' ? '480px' : '610px'}
          headerText={modalData.type === 'paymentHistory'
            ? `Invoice #${modalData.invoiceNumber}`
            : modalData.type === 'filters'
              ? 'Invoice Filters' : ''}
        >
          {modalData.type === 'paymentHistory'
            ? (
              <PaymentHistory
                data={modalData}
                deleteHistory={deleteHistory}
              />
            )

            : modalData.type === 'filters'
              ? (
                <InvoiceFilterModal
                  saveFilters={saveFilters}
                  fields={fields}
                  defaultFilters={filters}
                  closeModal={() => closeInvoiceModal()}
                />
              )

              : modalData.type === 'payment'
                ? (
                  <PaymentModal
                    selectedInvoices={selectedInvoices}
                    deleteInvoice={deleteSelectedInvoice}
                  />
                )

                : (
                  <PrintModal
                    data={modalData}
                    closeModal={() => closeInvoiceModal()}
                  />
                )}
        </RightModal>

        <Modal
          open={!_.isEmpty(emailModalInfo)}
          onClose={() => setEmailModalInfo({})}
          className="email__modal__wrapper"
        >
          {!!emailModalInfo.allowedInvoiceNumbers?.length && (
            <div>
              <SuccessIcon />

              <div>
                <div>
                  Emails successfully sent for the following invoice numbers
                </div>

                {emailModalInfo.allowedInvoiceNumbers.join(', ')}
              </div>
            </div>
          )}

          {!!emailModalInfo.notAllowedInvoiceNumbers?.length && (
            <div>
              <FailIcon />

              <div>
                <div>
                  An error occurred while attempting to send the emails for the following invoice numbers
                </div>

                {emailModalInfo.notAllowedInvoiceNumbers.join(', ')}

                <div className="error">
                  Email address doesn’t exist
                </div>
              </div>
            </div>
          )}
        </Modal>
      </Wrapper>

      {isPrint && createPortal(
        <div id="modal">
          <div className="right__modal__wrapper">
            <div className="right__modal__content">
              {invoiceList.filter((invoice) => selectedInvoices.includes(invoice.id))
                .map((invoice) => (
                  <Fragment key={invoice.id}>
                    <InvoicePrint data={invoice} hideButtons />

                    <div className="pagebreak" />
                  </Fragment>
                ))}
            </div>
          </div>
        </div>,
        document.getElementById('root') || document.body,
      )}
    </>
  );
};

export default Invoices;
