import { createReducer } from '@reduxjs/toolkit';
import { getLeadSourcesRequest } from '../actions/leadSources';

const initialState = {
  sources: [],
  totalPages: 1,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getLeadSourcesRequest.fulfilled, (store, action) => {
      const { path, data } = action.payload;
      if (!path) {
        store.sources = data.leadSources;
        store.totalPages = data.totalPages;
      }
    });
});

export default reducer;
