import React, { useEffect, useId, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../assets/icons/select_arrow.svg';
import useEventCallback from '../../helpers/hooks/useEventCallback';

const BulkSelect = ({ options, onClick }) => {
  const id = useId();

  const [isOpen, openingMenu] = useState(false);

  const closeMenu = useEventCallback((e) => {
    if (!e.target.closest(`[id="bulk__select__${id}"]`) && isOpen) {
      openingMenu(false);
    }
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener('click', closeMenu, true);

    return () => {
      window.removeEventListener('click', closeMenu, true);
    };
  }, []);
  const opening = () => {
    openingMenu((prev) => !prev);
  };

  const selectOption = (path) => {
    onClick(path);

    opening();
  };

  return (
    <div
      id={`bulk__select__${id}`}
      className={classnames('bulk__select__wrapper', { open: isOpen })}
    >
      <button onClick={opening}>
        Bulk actions

        <ArrowIcon />
      </button>

      {isOpen && (
      <div className="bulk__select__options__wrapper">
        {options.map(({ path, title }) => (
          <div
            key={path}
            onClick={() => selectOption(path)}
          >
            {title}
          </div>
        ))}
      </div>
      )}
    </div>
  );
};

BulkSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default BulkSelect;
