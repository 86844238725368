import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../../components/Wrapper';
import Button from '../../../components/form/Button';
import TableWithPagination from '../../../components/tables/TableWithPagination';
import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg';
import Api from '../../../Api';
import { getPermissionsGroupRequest } from '../../../store/actions/permissions';
import { useQuery } from '../../../helpers/hooks/useQuery';
import HasPermission from '../../../components/HasPermission';
import { usersPermissions } from '../../../data/permissions';

const tableHeader = [
  {
    title: 'Name',
    path: 'title',
  },
  {
    title: 'Description',
    path: 'description',
  },
  {
    title: 'Users',
    path: 'userCount',
  },
];

const Permissions = () => {
  const navigate = useNavigate();
  const { query, setQuery } = useQuery();

  const dispatch = useDispatch();
  const { list, totalPages } = useSelector((state) => state.permissions.permissionsGroup);

  const [filters, setFilters] = useState({
    page: +query.page || 1,
    limit: +query.limit || 10,
  });
  const { page, limit } = filters;

  const [loading, loadingToggle] = useState(true);

  useEffect(() => {
    (async () => {
      await dispatch(getPermissionsGroupRequest(filters));

      loadingToggle(false);
    })();
  }, []);

  useEffect(() => {
    setQuery(filters);
  }, [filters]);

  const onChangePage = useCallback(async (type, value) => {
    const requestData = { ...filters };

    requestData[type] = value;

    if (type === 'limit') {
      requestData.page = 1;
    }

    setFilters(requestData);

    await dispatch(getPermissionsGroupRequest(requestData));
  }, [filters]);

  const editRole = useCallback((permission) => {
    navigate(`/settings/permissions/${permission.id}`);
  }, []);

  const deleteRole = useCallback(async (permission) => {
    await Api.deletePermissionGroup(permission.id);

    const requestData = { ...filters };
    requestData.page = list.length === 1 && page > 1 ? page - 1 : page;

    await dispatch(getPermissionsGroupRequest(requestData));
    setFilters(requestData);

    toast.success('Role deleted');
  }, [list, filters]);

  const permissionList = useMemo(() => list.map((l) => ({
    ...l, userCount: `${l.userCount} user${+l.userCount > 1 ? 's' : ''}`,
  })), [list]);

  return (
    <Wrapper title="Roles">
      <div className="contacts__content">
        <div className="contacts__buttons__wrapper">
          <HasPermission
            read={['create_permissions_group_readonly', 'permission_groups_list_readonly']}
            edit={['create_permissions_group', 'permission_groups_list']}
          >
            <Button
              style={{ width: 150 }}
              title={(
                <>
                  <AddIcon />

                  Add new role
                </>
              )}
              onClick={() => editRole({ id: 'add' })}
            />
          </HasPermission>
        </div>
        <HasPermission edit={usersPermissions}>
          <TableWithPagination
            data={permissionList}
            header={tableHeader}
            page={page}
            limit={limit}
            firstLoad={loading}
            totalPages={totalPages}
            onChangePage={onChangePage}
            onEditClick={editRole}
            onDeleteClick={deleteRole}
            deleteModalText="Are you sure you want to delete the role?"
            deletePermission="delete_permissions_group"
            readDeletePermission="delete_permissions_group_readonly"
            editPermission="create_permissions_group"
            readEditPermission="create_permissions_group_readonly"
          />
        </HasPermission>
      </div>
    </Wrapper>
  );
};

export default Permissions;
