import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import { ReactComponent as CallingIcon } from '../../assets/icons/calling_green.svg';
import CallDetails from './CallDetails';
import {
  answerCallRequest, hangupCallRequest, manageCallRequest, rejectCallRequest,
} from '../../store/actions/call';
import IncomingCallDetail from './IncomingCallDetail';

let mediaStream;
let peerConnection;
const peerConnectionConfig = {
  iceServers: [
    { urls: 'stun:stun.stunprotocol.org:3478' },
    { urls: 'stun:stun.l.googleAuth.com:19302' },
  ],
};

function IncomingCall() {
  const dispatch = useDispatch();
  const audio = useRef();

  const [openMulti, setOpenMulti] = useState(false);

  const calls = useSelector((state) => state.call.calls);

  const incomingCalls = useMemo(() => {
    let list = _.filter(calls, (call) => call.appCallType === 'incoming');
    if (!_.isEmpty(list)) {
      list = list.map((l) => ({
        ...l,
        callId: l.content.callId,
      }));
    }
    return list;
  }, [calls]);

  const handleEnd = useCallback((callId) => {
    dispatch(hangupCallRequest({ callId }));
  }, []);

  const handleAnswer = useCallback(async (callId) => {
    if (!audio.current) {
      return;
    }
    if (!navigator.mediaDevices.getUserMedia) {
      alert('Your browser does not support getUserMedia API');
      return;
    }
    mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    peerConnection = new RTCPeerConnection(peerConnectionConfig);

    peerConnection.onicecandidate = () => {
    };
    peerConnection.ontrack = (ev) => {
      // eslint-disable-next-line prefer-destructuring
      audio.current.srcObject = ev.streams[0];
    };

    mediaStream.getTracks().forEach((track) => {
      peerConnection.addTrack(track, mediaStream);
    });

    const offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(offer);

    const call = incomingCalls.find((c) => c.callId === callId);

    const { channelId } = call;

    const datum = {
      sdp: offer.sdp,
      channelId,
      callId,
    };

    const { payload: { data } = {} } = await dispatch(answerCallRequest(datum));

    const signal = {
      sdp: data.sdp,
      type: offer.type,
    };

    await peerConnection.setRemoteDescription(new RTCSessionDescription(signal));

    const a = await peerConnection.createAnswer();
    await peerConnection.setLocalDescription(a);
  }, [incomingCalls, audio]);

  const handleManageCall = useCallback(async (type, callId) => {
    await dispatch(manageCallRequest({ type, callId }));
  }, []);

  const handleReject = useCallback(async (callId) => {
    await dispatch(rejectCallRequest({ callId, action: 'DECLINE' }));
  }, []);

  const incoming = _.filter(incomingCalls, (c) => c.appCallStatus === 'incoming');

  return (
    <div className="call">
      <div className={classNames('incoming', { multi: incoming.length > 1 })}>
        {incoming.length === 1
          ? incomingCalls.map((call) => {
            if (call.appCallStatus === 'incoming') {
              return (
                <IncomingCallDetail call={call} onAnswer={handleAnswer} onReject={handleReject} />
              );
            }
            return null;
          })
          : null}
        {incoming.length > 1
          ? (
            <div className="multiCalls" onClick={() => setOpenMulti(!openMulti)}>
              <CallingIcon />
              <span>{incoming.length}</span>
            </div>
          ) : null}
        {openMulti ? (
          ReactDOM.createPortal(
            <div className="multiCallsModal">
              {incomingCalls.map((call) => {
                if (call.appCallStatus === 'incoming') {
                  return (
                    <IncomingCallDetail call={call} onAnswer={handleAnswer} onReject={handleReject} />
                  );
                }
                return null;
              })}
            </div>,
            document.body,
          )
        ) : null}
      </div>
      <audio ref={audio} autoPlay />
      {!_.isEmpty(incomingCalls) && incomingCalls.some((c) => c.appCallStatus === 'answered')
        ? incomingCalls.filter((c) => c.appCallStatus === 'answered').map((call, i) => (
          <div className="answered" key={call.callId} style={{ bottom: i * 90 }}>
            <CallDetails
              onManage={handleManageCall}
              onHangup={handleEnd}
              callDetails={{ ...call, name: call.content.name }}
            />
          </div>
        )) : null}
    </div>
  );
}

export default IncomingCall;
