import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import _ from 'lodash';

const RangeDatePicker = ({
  date, label, setDate, className, error,
}) => {
  const [id] = useState(_.uniqueId('input'));

  const onChange = (newDate) => {
    const [start, end] = newDate;

    const startDate = start && moment(start).isValid()
      ? moment(start).format('yyyy-MM-DD')
      : null;

    const endDate = end && moment(end).isValid()
      ? moment(end).format('yyyy-MM-DD')
      : null;

    const formattingDate = moment(startDate).isAfter(endDate) ? [endDate, startDate] : [startDate, endDate];

    setDate(_.compact(formattingDate));
  };

  return (
    <div className={classNames(`date__picker__wrapper ${className}`, { error: !!error })}>
      <label htmlFor={`d_p_${id}`}>
        {label}
      </label>

      <ReactDatePicker
        selected={date?.[0] ? new Date(date[0]) : null}
        startDate={date?.[0] ? new Date(date[0]) : null}
        endDate={date?.[1] ? new Date(date[1]) : null}
        onBlur={(e) => {
          const [startData, endData] = e.target.value.split(' - ');

          onChange([startData, endData]);
        }}
        id={`d_p_${id}`}
        placeholderText="mm/dd/yyyy - mm/dd/yyyy"
        onChange={onChange}
        showMonthDropdown
        showYearDropdown
        showIcon
        isClearable
        selectsRange
        dateFormat="MM/dd/yyyy"
      />

      {!!error && <span className="errorText">{error}</span>}
    </div>
  );
};

RangeDatePicker.propTypes = {
  date: PropTypes.array,
  label: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  setDate: PropTypes.func.isRequired,
};

RangeDatePicker.defaultProps = {
  date: [],
  label: '',
  error: '',
  className: '',
};

export default RangeDatePicker;
