import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const loginRequest = createAsyncThunk('account/loginRequest', async (payload, thunkAPI) => {
  try {
    const { form, remember } = payload;
    const { data } = await Api.login(form);
    return { data, remember };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});
export const getProfileRequest = createAsyncThunk('account/getProfileRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.getProfile();
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const globalSearchRequest = createAsyncThunk('account/globalSearchRequest', async (payload, thunkAPI) => {
  try {
    const { path, ...reqData } = payload;
    const { data } = await Api.globalSearch(reqData);
    return data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const connectGmailRequest = createAsyncThunk('account/connectGmailRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.connectGmail(payload);
    return data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const googleAddressAutocompleteRequest = createAsyncThunk(
  'account/googleAddressAutocompleteRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.googleAddressAutocomplete(payload);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getUserContactsFromGoogleRequest = createAsyncThunk(
  'account/getUserContactsFromGoogleRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getUserContactsFromGoogle(payload);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const unsubscribeFromEmailsRequest = createAsyncThunk(
  'account/unsubscribeFromEmailsRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.unsubscribeFromEmails(payload);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const removeGlobalSearchData = createAction('account/removeGlobalSearchData');
