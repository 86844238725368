import React from 'react';

function FakeColumnHeader() {
  return (
    <div className="columnHeader fake">
      <div className="main">
        <span className="border" />
        <div className="titleBlock">
          <div>
            <p className="title" />
          </div>
          <p className="price" />
        </div>
      </div>
    </div>
  );
}

export default FakeColumnHeader;
