import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import RightModal from '../modal/RightModal';
import Select from '../form/Select';
import { taskTypes } from '../../data/options';
import Textarea from '../form/Textarea';
import Button from '../form/Button';
import Utils from '../../helpers/Utils';
import DatePicker from '../form/DatePicker';
import { addTaskRequest, getSingleTaskRequest, getTasksRequest } from '../../store/actions/tasks';
import { ReactComponent as ArrowIcon } from '../../assets/icons/select_arrow.svg';

function AddTaskModal() {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState('');
  const [errors, setErrors] = useState({});
  const [dropdown, setDropdown] = useState(false);

  const pipelinesList = useSelector((state) => state.pipelines.pipelines.task);
  const users = useSelector((state) => state.users.usersList);
  const jobs = useSelector((state) => state.jobs.jobs);
  const leads = useSelector((state) => state.leads.leads);
  const taskId = searchParams.get('id');
  const from = searchParams.get('from');

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('mouseup', handleCloseEvent);
    }, 0);
    return () => {
      window.removeEventListener('mouseup', handleCloseEvent);
    };
  }, []);

  const handleCloseEvent = useCallback((ev) => {
    if (!ev.target.closest('.dropdown')) {
      setDropdown(false);
    }
  }, []);

  const pipelines = useMemo(() => {
    if (from === 'archive') {
      return [{ name: 'Archived', status: 'archived' }, ...pipelinesList];
    }
    return pipelinesList;
  }, [pipelinesList, from]);

  useEffect(() => {
    if (taskId) {
      (async () => {
        const { payload: { tasks } } = await dispatch(getSingleTaskRequest(taskId));
        const data = {
          type: tasks[0].type,
          status: tasks[0].status,
          responsiblePerson: tasks[0].responsiblePerson,
          dueDate: tasks[0].dueDate,
          leadId: tasks[0].leadId,
          jobId: tasks[0].jobId,
          note: tasks[0].note,
          id: +taskId,
        };
        setForm({ ...data });
      })();
    }
    return () => setErrors({});
  }, [taskId]);

  const handleClose = useCallback(() => {
    const query = { ...Object.fromEntries(searchParams) };
    delete query.create;
    delete query.id;
    setSearchParams(query);
    setForm({});
  }, [searchParams]);

  const handleSubmit = useCallback(async (exit) => {
    setLoading(exit ? 'exit' : 'stay');
    setDropdown(false);
    const { payload: { data } } = await dispatch(addTaskRequest(form));

    if (data.status === 'error') {
      if (data.errors) {
        setErrors(Utils.normalizeErrors(data.errors));
      } else {
        toast.error(data.message);
      }
    }
    if (data.status === 'ok') {
      await dispatch(getTasksRequest());
      toast.success(`Task is successfully ${taskId ? 'updated' : 'created'}.`);
      if (exit) {
        handleClose();
      }
    }
    setLoading(false);
  }, [form, taskId]);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  return (
    <RightModal
      headerText={taskId ? 'Edit task' : 'New task'}
      open={searchParams.get('create') === 'task'}
      onClose={handleClose}
      className="addTaskModal"
    >
      <form>
        <Select
          placeholder="Type"
          options={taskTypes}
          isSearchable={false}
          onChange={(v) => handleChange('type', v.value)}
          value={taskTypes.find((o) => o.value === form.type)}
          error={errors.type}
        />
        <Select
          placeholder="Status"
          options={pipelines}
          isSearchable={false}
          onChange={(v) => handleChange('status', v.status)}
          value={pipelines.find((o) => o.status === form.status)}
          error={errors.status}
          getOptionLabel={(o) => o.name}
          getOptionValue={(o) => o.status}
          isOptionDisabled={(o) => o.status === 'archived'}
        />
        <Select
          placeholder="Responsible person"
          options={users}
          getOptionValue={(o) => o.id}
          getOptionLabel={(o) => `${o.firstName} ${o.lastName}`}
          onChange={(val) => handleChange('responsiblePerson', val.id)}
          value={users.find((u) => u.id === form.responsiblePerson)}
          error={errors.responsiblePerson}
        />
        <DatePicker
          placeholder="Due date"
          date={form.dueDate}
          setDate={(d) => handleChange('dueDate', d)}
          error={errors.dueDate}
        />
        <div className="elements">
          <Select
            placeholder="Lead"
            options={leads}
            getOptionValue={(o) => o.id}
            getOptionLabel={(o) => `${o.contacts.name} - ${_.startCase(o.status)}`}
            onChange={(val) => handleChange('leadId', val.id)}
            value={leads.find((u) => u.id === form.leadId)}
            error={errors.leadId}
          />
          <Select
            placeholder="Job"
            options={jobs}
            getOptionValue={(o) => o.id}
            getOptionLabel={(o) => `${o.contact.name} - ${_.startCase(o.status)}`}
            onChange={(val) => handleChange('jobId', val.id)}
            value={jobs.find((u) => u.id === form.jobId)}
            error={errors.jobId}
          />
        </div>

        <Textarea
          placeholder="Notes"
          error={errors.note}
          value={form.note}
          onChange={(ev) => handleChange('note', ev.target.value)}
        />
        <div className="actions">

          <div className="createButtonContainer">
            <Button
              onClick={() => handleSubmit(false)}
              title={taskId ? 'Save' : 'Add task'}
              loading={!!loading}
              className="create"
            />
            <div className="dropdownIndicator" onClick={() => setDropdown(true)}>
              <ArrowIcon />
            </div>
            {dropdown ? (
              <div className="dropdown" onClick={() => handleSubmit(true)}>
                {taskId ? 'Save and exit' : 'Create and exit'}
              </div>
            ) : null}
          </div>
          <Button title="Cancel" noBorder onClick={handleClose} />
        </div>
      </form>
    </RightModal>
  );
}

export default AddTaskModal;
