import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import PropTypes from 'prop-types';

function TimePicker(props) {
  const { selected, label, ...restProps } = props;

  return (
    <div className="timePickerWrapper">
      {label ? <span className="label">{label}</span> : null}
      <DatePicker
        selected={selected ? moment(selected, 'hh:mm a').toDate() : null}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={10}
        dateFormat="h:mm aa"
        className="timePicker"
        {...restProps}
      />
    </div>

  );
}

export default TimePicker;

TimePicker.propTypes = {
  selected: PropTypes.string,
  label: PropTypes.string,
};

TimePicker.defaultProps = {
  selected: undefined,
  label: '',
};
