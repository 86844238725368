import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from '../form/Select';
import { dueDates, taskTypes } from '../../data/options';
import DatePicker from '../form/DatePicker';
import Textarea from '../form/Textarea';
import Button from '../form/Button';
import { getPipelinesRequest } from '../../store/actions/pipelines';

function AddTaskForm(props) {
  const { onSave } = props;
  const dispatch = useDispatch();
  const [dueDate, setDueDate] = useState('');
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);

  const pipelines = useSelector((state) => state.pipelines.pipelines.task);
  const users = useSelector((state) => state.users.usersList);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getPipelinesRequest({ type: 'task' }));
      setLoading(false);
    })();
  }, []);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    setForm({ ...form });
  }, [form]);

  return (
    <form onSubmit={useCallback((ev) => onSave(ev, form), [form])}>
      <Select
        placeholder="Type"
        options={taskTypes}
        isSearchable={false}
        onChange={(v) => handleChange('type', v?.value)}
        value={taskTypes.find((o) => o.value === form.type)}
        isClearable
      />
      <Select
        placeholder="Status"
        options={pipelines}
        isSearchable={false}
        onChange={(v) => handleChange('status', v?.status)}
        value={pipelines.find((o) => o.status === form.status)}
        getOptionValue={(o) => o.status}
        getOptionLabel={(o) => o.name}
        isLoading={loading}
        isClearable
      />
      <Select
        placeholder="Responsible person"
        options={users}
        onChange={(v) => handleChange('responsiblePerson', v?.id)}
        value={users.find((t) => t.id === _.get(form, 'responsiblePerson', ''))}
        getOptionValue={(o) => o.id}
        getOptionLabel={(o) => `${o.firstName} ${o.lastName}`}
        isClearable
      />
      <Select
        placeholder="Due date"
        options={dueDates}
        isSearchable={false}
        onChange={(v) => {
          handleChange('dueDate', v?.value);
          setDueDate(v?.dueDate || '');
        }}
        value={dueDates.find((o) => o.value === form.dueDate)}
        isClearable
      />

      {dueDate === 'custom' ? (
        <div className="customDate">
          <DatePicker
            setDate={(date) => handleChange('dueDate', date)}
            date={form.dueDate}
          />
        </div>
      ) : null}
      <Textarea
        placeholder="Notes"
        onChange={(ev) => handleChange('note', ev.target.value)}
        value={form.note}
      />
      <div>
        <Button title="Save" type="submit" disabled={!(form.status || form.type || form.responsiblePerson)} />
      </div>
    </form>
  );
}

export default AddTaskForm;

AddTaskForm.propTypes = {
  onSave: PropTypes.func.isRequired,
};
