import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { dueDates, pipelineTriggers as triggers } from '../../data/options';
import DatePicker from '../form/DatePicker';
import TimePicker from '../form/TimePicker';

function ExecuteDropdown(props) {
  const {
    onClose, form, onChange, automation, scheduledTriggers, pipelineTriggers,
  } = props;

  const listRef = useRef();
  const customRef = useRef();
  const dropdownRef = useRef();
  const saveRef = useRef();

  useEffect(() => {
    if (saveRef.current && form.when && form[automation]?.dateType) {
      saveRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
    }
  }, [saveRef, form, automation]);

  const canSave = automation.includes('email') || (form[automation]?.date && form[automation].dateType && form.when);

  return (
    <div className="executeDropdown">
      <Helmet>
        <body className="disableScroll" />
      </Helmet>
      <div className={classNames('dropdown', { top: automation === 'job' })} ref={dropdownRef}>
        {pipelineTriggers ? (
          <div className="item">
            <div className="first">
              Pipeline triggers
            </div>
            <ul>
              {triggers.map((t) => (
                <li
                  className={t.value === form.when ? 'active' : ''}
                  onClick={() => {
                    onChange('when', t.value);
                    if (listRef.current) {
                      listRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                    }
                  }}
                  key={t.value}
                >
                  {t.label}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        {scheduledTriggers ? (
          <div className="item">
            <div>
              Scheduled triggers
            </div>
            <ul ref={listRef}>
              {dueDates.map((t) => (
                <li
                  className={t.dueDate === _.get(form, `${automation}.dateType`, '') ? 'active' : ''}
                  onClick={() => {
                    onChange(`${automation}.dateType`, t.dueDate, t.dueDate !== 'custom');
                    onChange(`${automation}.date`, t.value, false);
                    if (t.dueDate === 'custom' && customRef.current) {
                      setTimeout(() => {
                        customRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
                      }, 0);
                    }
                  }}
                  key={t.dueDate}
                >
                  {t.label}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        <div className="item" ref={customRef}>
          {scheduledTriggers && _.get(form, `${automation}.dateType`, '') === 'custom' ? (
            <>
              <div>
                Scheduled triggers
              </div>
              <section className="datepicker">
                <DatePicker
                  label="On"
                  setDate={(val) => {
                    onChange(`${automation}.date`, moment(val, 'YYYY-MM-DD')
                      .format('YYYY-MM-DD hh:mm a'), false);
                    if (saveRef.current) {
                      saveRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
                    }
                  }}
                  portalId={document.body}
                  date={_.get(form, `${automation}.date`, '')}
                />
                <TimePicker
                  label="At"
                  onChange={(val) => {
                    const currentDate = _.get(form, `${automation}.date`, '');
                    const day = currentDate.substring(0, currentDate.indexOf(' '));
                    const date = `${day} ${moment(val).format('hh:mm a')}`;
                    onChange(`${automation}.date`, date);
                  }}
                  portalId={document.body}
                  selected={_.get(form, `${automation}.date`, '')
                    .substring(_.get(form, `${automation}.date`, '').indexOf(' ') + 1)}
                />
              </section>
            </>
          )
            : null}
        </div>
        {automation !== 'email' ? (
          <div className="saveButton">
            <p
              onClick={() => {
                if (canSave) {
                  onClose();
                }
              }}
              className={!canSave ? 'disabled' : ''}
            >
              Save
            </p>
          </div>
        ) : null}
        <div ref={saveRef} />
      </div>
    </div>
  );
}

export default ExecuteDropdown;

ExecuteDropdown.propTypes = {
  onClose: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  automation: PropTypes.string.isRequired,
  scheduledTriggers: PropTypes.bool,
  pipelineTriggers: PropTypes.bool,
};

ExecuteDropdown.defaultProps = {
  scheduledTriggers: true,
  pipelineTriggers: true,
};
