import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';
import Wrapper from '../../components/Wrapper';
import CreateInfoWrapper from '../../components/pipelineFormElements/CreateInfoWrapper';
import Button from '../../components/form/Button';
import Activity from '../../components/pipelineFormElements/Activity';
import Notes from '../../components/pipelineFormElements/Notes';
import Email from '../../components/pipelineFormElements/Email';
import Task from '../../components/pipelineFormElements/Task';
import { getPipelinesRequest } from '../../store/actions/pipelines';
import JobCreateRight from '../../components/jobs/JobCreateLeft';
import {
  addJobRequest, deleteFilesRequest, getSingleJobRequest, removeJobData, setJobData,
} from '../../store/actions/jobs';
import Details from '../../components/pipelineFormElements/Details';
import Loader from '../../components/loader/Loader';
import Checkbox from '../../components/form/Checkbox';
import DatePicker from '../../components/form/DatePicker';
import { ReactComponent as ArrowIcon } from '../../assets/icons/select_arrow.svg';
import Quotations from '../../components/pipelineFormElements/Quotations';

function CreateJob() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobId } = useParams();

  const [tab, setTab] = useState('activity');
  const [loading, setLoading] = useState('');
  const [loadingData, setLoadingData] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const jobData = useSelector((state) => state.jobs.jobData);
  const singleJobData = useSelector((state) => state.jobs.singleJobData);
  const jobActivity = useSelector((state) => state.jobs.jobActivity);
  const errors = useSelector((state) => state.jobs.errors);

  useEffect(() => {
    if (jobId) {
      (async () => {
        setLoadingData(true);
        await dispatch(getSingleJobRequest(jobId));
        setLoadingData(false);
      })();
    }
  }, [jobId]);

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('mouseup', handleCloseEvent);
    }, 0);
    return () => {
      window.removeEventListener('mouseup', handleCloseEvent);
    };
  }, []);

  const handleCloseEvent = useCallback((ev) => {
    if (!ev.target.closest('.dropdown')) {
      setDropdown(false);
    }
  }, []);

  useEffect(() => {
    dispatch(getPipelinesRequest({ type: 'job' }));

    return () => {
      dispatch(removeJobData());
    };
  }, []);

  const handleTabChange = useCallback((t) => {
    setTab(t);
  }, [tab]);

  const getTab = useCallback(() => ({
    activity: <Activity activity={jobActivity} pipeline="job" />,
    notes: <Notes setData={(data) => dispatch(setJobData(data))} notes={jobData.notes} pipeline="job" />,
    emails: <Email
      setData={(data) => dispatch(setJobData(data))}
      emails={jobData.emails}
      pipeline="job"
      email={jobData.email}
    />,
    job_expense: <Details setData={(data) => dispatch(setJobData(data))} data={jobData} errors={errors} />,
    job_quotation: <Quotations setData={(data) => dispatch(setJobData(data))} data={jobData} errors={errors} />,
    task: <Task setData={(data) => dispatch(setJobData(data))} tasks={jobData.tasks} pipeline="job" />,
  })[tab], [tab, jobData, errors]);

  const handleCreateJob = useCallback(async (exit) => {
    setLoading(exit ? 'exit' : 'stay');
    setDropdown(false);
    const details = {};
    const quotation = {};
    const filesDif = _.difference(singleJobData?.details?.files, jobData?.details?.files);
    if (!_.isEmpty(filesDif)) {
      await Promise.all(filesDif.map((f) => dispatch(deleteFilesRequest(f))));
    }
    if (jobData.details.files) {
      details.files = [...jobData.details.files];
    }
    details.additionalData = jobData.details.additionalData.map((d) => ({
      text: d.text,
      qty: d.qty,
      price: d.price,
    }));
    if (jobData.quotation && jobData.quotation.detail) {
      quotation.detail = jobData.quotation.detail.map((d) => ({
        text: d.text,
        qty: d.qty,
        price: d.price,
      }));
    }

    console.log(jobData);

    const { payload: { data } } = await dispatch(addJobRequest({
      address: jobData.address,
      company: jobData.company,
      contact: jobData.contact,
      email: jobData.email,
      phone: jobData.phone,
      responsiblePerson: jobData.responsiblePerson,
      sale: jobData.sale,
      status: jobData.status,
      storageFee: jobData.storageFee,
      storageFeeStartDate: jobData.storageFeeStartDate,
      id: jobId ? +jobId : undefined,
      notes: !_.isEmpty(jobData.notes) ? jobData.notes.map((n) => ({
        text: n.text,
        id: n.id,
      })) : undefined,
      tasks: !_.isEmpty(jobData.tasks) ? jobData.tasks : undefined,
      emails: !_.isEmpty(jobData.emails) && !jobId ? jobData.emails : undefined,
      details,
      quotation,
    }));

    if (data.status === 'ok') {
      if (jobId) {
        toast.success('Successfully updated.');
      } else {
        toast.success('Successfully created.');
      }
      if (exit) {
        navigate('/jobs');
      }
    }
    setLoading('');
  }, [jobData, jobId, singleJobData]);

  return (
    <Wrapper title={jobId ? 'Edit job' : 'New job'}>
      {!loadingData ? (
        <div className="createLead">
          <JobCreateRight />
          <div className="right">
            <div className="top">
              <div>
                {!jobId ? <p className="new">New</p> : null}
                <div className="feeActions">
                  <Checkbox
                    label="Calculate storage fee"
                    greenBG
                    onChange={() => {
                      dispatch(setJobData({ key: 'storageFee', value: !jobData.storageFee }));
                    }}
                    checked={!!jobData.storageFee}
                  />
                  <DatePicker
                    error={!!errors.storageFeeStartDate}
                    date={jobData.storageFeeStartDate}
                    setDate={(val) => {
                      dispatch(setJobData({ key: 'storageFeeStartDate', value: moment(val).format('YYYY-MM-DD') }));
                    }}
                  />
                </div>
              </div>
              <div className="actions">
                <Button title="Cancel" noBorder onClick={() => navigate(-1)} />
                <div className="createButtonContainer">
                  <Button
                    title={jobId ? 'Save' : 'Create'}
                    onClick={() => handleCreateJob(false)}
                    loading={!!loading}
                    className="create"
                  />
                  <div className="dropdownIndicator" onClick={() => setDropdown(true)}>
                    <ArrowIcon />
                  </div>
                  {dropdown ? (
                    <div className="dropdown" onClick={() => handleCreateJob(true)}>
                      {jobId ? 'Save and exit' : 'Create and exit'}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="main">
              <CreateInfoWrapper
                onTabChange={handleTabChange}
                tab={tab}
                errors={errors}
                tabs={['activity', 'notes', 'emails', 'job_expense', 'job_quotation', 'task', 'calls']}
              >
                {getTab()}
              </CreateInfoWrapper>
            </div>
          </div>

        </div>
      ) : <Loader />}
    </Wrapper>
  );
}

export default CreateJob;
