import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Tooltip from 'rc-tooltip';
import Loader from '../loader/Loader';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone_new.svg';
import Pagination from '../pagination/Pagination';
import DeleteModal from '../modal/DeleteModal';
import { deleteLeadRequest, getLeadsRequest } from '../../store/actions/leads';
import CallModal from '../calls/CallModal';
import OutgoingCall from '../calls/OutgoingCall';
import HasPermission from '../HasPermission';
import Utils from '../../helpers/Utils';

const header = [
  { title: 'Status', path: 'status' },
  { title: 'Email', path: 'email' },
  { title: 'Phone', path: 'phone' },
];

function LeadsList(props) {
  const {
    onPageChange, page, limit,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [leadId, setLeadId] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [callModal, setCallModal] = useState(false);
  const [callInfo, setCallInfo] = useState({});

  const leads = useSelector((state) => state.leads.leads);
  const leadsPages = useSelector((state) => state.leads.leadsPages);
  const calls = useSelector((state) => state.call.calls);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getLeadsRequest({
        page,
        limit,
      }));
      setLoading(false);
    })();
  }, [page, limit]);

  const outgoingCalls = useMemo(() => _.filter(calls, (call) => call.appCallType === 'outgoing'), [calls]);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    const { payload } = await dispatch(deleteLeadRequest(leadId));
    if (payload?.status === 'ok') {
      await dispatch(getLeadsRequest({ page, limit }));
      toast.success('Successfully deleted!');
    } else {
      toast.error('Something went wrong.');
    }
    setLeadId(null);
    setLoadingDelete(false);
  }, [leadId]);

  const getPhoneNumberIcon = useCallback((phone, name, id) => (
    <PhoneIcon
      className={`phoneIcon ${!_.isEmpty(calls) ? 'disabled' : ''}`}
      onClick={() => {
        if (!phone) {
          setCallModal(true);
        } else {
          setCallInfo({ phone, name, leadId: id });
        }
      }}
    />
  ), [calls]);

  return (
    <div className="leadsList">
      <div className="table__wrapper leadsTable">
        {loading ? (
          <div className="loader__wrapper">
            <Loader size={60} borderWidth={4} />
          </div>
        ) : null}

        {!loading && !_.isEmpty(leads) ? (
          <table
            style={{ minWidth: 165 }}
            className="table"
          >
            <thead className="thead">
              <tr className="tr">
                {header.map((h) => (
                  <th className="th" key={h.title}>{h.title}</th>
                ))}

                <th style={{ width: 165 }} className="th">Actions</th>
              </tr>
            </thead>

            <tbody className="tbody">
              {leads.map((d) => (
                <tr
                  className="tr"
                  key={d.id}
                >
                  <td
                    className="td"
                  >
                    {_.startCase(d.status)}
                  </td>
                  <td
                    className="td"
                  >
                    {d.email || '-'}
                  </td>
                  <td
                    className="td"
                  >
                    {Utils.formatPhoneNumber(d.phone)}
                  </td>
                  <td style={{ width: 165 }} className="th actions__wrapper">
                    {!_.isEmpty(outgoingCalls) ? (
                      <Tooltip overlay="You are on other call" showArrow={false} destroyTooltipOnHide>
                        {getPhoneNumberIcon(d.phone, d.contactName, d.id)}
                      </Tooltip>
                    ) : getPhoneNumberIcon(d.phone, d.contactName, d.id)}
                    <HasPermission edit="create_lead" read="create_lead_readonly">
                      <EditIcon
                        className="action__edit__icon"
                        onClick={() => navigate(`/leads/edit/${d.id}`)}
                      />
                    </HasPermission>
                    <HasPermission edit="delete_lead" read="delete_lead_readonly">
                      <DeleteIcon onClick={() => setLeadId(d.id)} />
                    </HasPermission>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {_.isEmpty(leads) && !loading ? <div className="noData">No leads yet.</div> : null}
      </div>
      {!_.isEmpty(leads) ? (
        <Pagination
          page={page}
          totalPages={leadsPages}
          limit={limit}
          onChange={onPageChange}
        />
      ) : null}
      <DeleteModal
        loading={loadingDelete}
        open={!!leadId}
        text="Are you sure want to delete the lead?"
        onClose={() => setLeadId(null)}
        onDelete={handleDelete}
      />
      <CallModal
        open={callModal}
        onCancel={() => setCallModal(false)}
        onStart={(number) => setCallInfo({ number })}
      />
      {!_.isEmpty(callInfo) ? <OutgoingCall callInfo={callInfo} onEnd={() => setCallInfo({})} /> : null}
    </div>
  );
}

export default LeadsList;

LeadsList.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
};
