import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Select from '../form/Select';
import Button from '../form/Button';
import { createAutomationRequest, getAutomationsListRequest } from '../../store/actions/automation';
import Utils from '../../helpers/Utils';
import ExecuteDropdown from './ExecuteDropdown';
import AutomationWrapper from './AutomationWrapper';
import Input from '../form/Input';
import { dueDates, pipelineTriggers } from '../../data/options';
import Conditions from './Conditions';

function AddJob(props) {
  const { pipeline } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [execute, setExecute] = useState(false);
  const [form, setForm] = useState({
    pipeline,
    action: 'createJob',
    actionStatus: searchParams.get('status'),
    job: {
      status: searchParams.get('status'),
    },
  });

  const [condition, setCondition] = useState([{
    id: _.uniqueId(),
  }]);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const users = useSelector((state) => state.users.usersList);
  const contacts = useSelector((state) => state.contacts.contactList);
  const automationList = useSelector((state) => state.automation.automationList[pipeline]);
  const automationId = searchParams.get('_id');

  useEffect(() => {
    const automation = automationList?.find((a) => a.id === +automationId);
    if (automation) {
      const {
        phone, email, company, status, contact, dateType, dueDate, responsiblePerson, sale,
      } = automation.data;

      const data = {
        when: automation.when,
        id: automationId,
        job: {
          phone,
          email,
          company,
          status,
          contact,
          dateType,
          dueDate,
          responsiblePerson,
          sale,
          date: dueDates.find((due) => due.dueDate === dateType)?.value,
        },
      };
      if (!_.isEmpty(automation.condition)) {
        const con = [];
        _.forEach(automation.condition, (val, op) => {
          val.forEach((cd) => {
            con.push({
              operator: op,
              condition: Object.keys(cd)[0],
              id: _.uniqueId(),
              ...Object.values(cd)[0],
            });
          });
        });
        setCondition(con);
      }
      setForm({ ...form, ...data });
    }
  }, [automationId, automationList]);

  const handleClose = useCallback(() => {
    const query = { ...Object.fromEntries(searchParams) };
    delete query.action;
    delete query.id;
    delete query._id;
    delete query.status;
    setSearchParams(query, { replace: true });
  }, [searchParams]);

  const handleConditionChange = useCallback((path, val) => {
    _.set(condition, path, val);
    setCondition([...condition]);
  }, [condition]);

  const handleDeleteCondition = useCallback((id) => {
    let con = condition.filter((c) => c.id !== id);
    if (!con.length) {
      con = [{ id: _.uniqueId() }];
    }
    setCondition(con);
  }, [condition]);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const con = {};

    condition.forEach((c) => {
      if (c.operator === '$or') {
        con.$or = [...(con.$or || []), { [c.condition]: { $in: c.$in } }];
      }
      if (c.operator === '$and') {
        con.$and = [
          ...(con.$and || []),
          { [c.condition]: c.condition === 'sale' ? { $gte: c.$gte, $lte: c.$lte } : { $in: c.$in } },
        ];
      }
    });

    form.condition = con;
    const { payload: { data } } = await dispatch(createAutomationRequest(form));

    if (data.status === 'error') {
      setErrors(Utils.normalizeErrors(data.errors));
    }
    if (data.status === 'ok') {
      setSearchParams({});
      toast.success(`Automation successfully ${form.id ? 'updated' : 'added'}.`);
      await dispatch(getAutomationsListRequest(pipeline));
    }
    setLoading(false);
  }, [form, searchParams, condition]);

  return (
    <div className="addTaskContainer addAutomationContainer">

      <AutomationWrapper onSubmit={handleSubmit}>
        <div>
          <Select
            placeholder="Responsible person"
            options={users}
            onChange={(v) => handleChange('job.responsiblePerson', v?.id)}
            value={users.find((t) => t.id === _.get(form, 'job.responsiblePerson', ''))}
            error={_.get(errors, 'job.responsiblePerson')}
            getOptionValue={(o) => o.id}
            getOptionLabel={(o) => `${o.firstName} ${o.lastName}`}
            isClearable
          />
          <Select
            placeholder="Name"
            options={contacts}
            onChange={(v) => handleChange('job.contact', v?.id)}
            value={contacts.find((t) => t.id === _.get(form, 'job.contact', ''))}
            error={_.get(errors, 'job.contact')}
            getOptionValue={(o) => o.id}
            getOptionLabel={(o) => o.name}
            isClearable
          />
          <Input
            placeholder="Sale"
            type="number"
            onChange={(ev) => handleChange('job.sale', +ev.target.value)}
            value={_.get(form, 'job.sale', '')}
            error={_.get(errors, 'job.sale')}
          />
          <Input
            placeholder="Company"
            onChange={(ev) => handleChange('job.company', ev.target.value)}
            value={_.get(form, 'job.company')}
            error={_.get(errors, 'job.company')}
          />
          <Input
            placeholder="Email"
            onChange={(ev) => handleChange('job.email', ev.target.value)}
            value={_.get(form, 'job.email')}
            error={_.get(errors, 'job.email')}
          />
          <Input
            placeholder="Phone"
            onChange={(ev) => handleChange('job.phone', ev.target.value)}
            value={_.get(form, 'job.phone', '')}
            error={_.get(errors, 'job.phone')}
            mask="+1 999-999-9999"
          />
          <div>
            <div onClick={() => setExecute(!execute)}>
              <Select
                placeholder="Execute"
                isDisabled
                error={errors.job?.dateType || errors.when ? 'Execute is required.' : undefined}
                value={pipelineTriggers.find((p) => p.value === form.when)}
              />
            </div>
            {execute ? (
              <ExecuteDropdown
                form={form}
                onChange={handleChange}
                onClose={() => setExecute(false)}
                automation="job"
              />
            ) : null}
          </div>
          <Conditions
            condition={condition}
            onChange={handleConditionChange}
            onDeleteCondition={handleDeleteCondition}
          />

          <div className="actions">
            <Button title="Save" type="submit" loading={loading} />
            <Button title="Cancel" noBorder onClick={handleClose} />
          </div>
        </div>
      </AutomationWrapper>
    </div>
  );
}

export default AddJob;

AddJob.propTypes = {
  pipeline: PropTypes.string,
};

AddJob.defaultProps = {
  pipeline: 'lead',
};
