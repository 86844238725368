import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Loader from '../loader/Loader';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import Pagination from '../pagination/Pagination';
import DeleteModal from '../modal/DeleteModal';
import { deleteJobRequest, getJobsRequest } from '../../store/actions/jobs';
import Utils from '../../helpers/Utils';

const header = [
  { title: 'Status', path: 'status' },
  { title: 'Email', path: 'email' },
  { title: 'Phone', path: 'phone' },
  { title: 'Company', path: 'company' },
];

function JobsList(props) {
  const {
    onPageChange, page, limit,
  } = props;

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [jobId, setJobId] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const jobs = useSelector((state) => state.jobs.jobs);
  const jobsPages = useSelector((state) => state.jobs.jobsPages);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getJobsRequest({
        page,
        limit,
      }));
      setLoading(false);
    })();
  }, [page, limit]);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    const { payload } = await dispatch(deleteJobRequest(jobId));
    if (payload?.status === 'ok') {
      await dispatch(getJobsRequest({ page, limit }));
      toast.success('Successfully deleted!');
    } else {
      toast.error('Something went wrong.');
    }
    setJobId(null);
    setLoadingDelete(false);
  }, [jobId]);

  return (
    <div className="leadsList">
      <div className="table__wrapper leadsTable">
        {loading ? (
          <div className="loader__wrapper">
            <Loader size={60} borderWidth={4} />
          </div>
        ) : null}

        {!loading && !_.isEmpty(jobs) ? (
          <table
            style={{ minWidth: 165 }}
            className="table"
          >
            <thead className="thead">
              <tr className="tr">
                {header.map((h) => (
                  <th className="th" key={h.title}>{h.title}</th>
                ))}

                <th style={{ width: 165 }} className="th">Actions</th>
              </tr>
            </thead>

            <tbody className="tbody">
              {jobs.map((d) => (
                <tr
                  className="tr"
                  key={d.id}
                >
                  <td
                    className="td"
                  >
                    {_.startCase(d.status)}
                  </td>
                  <td
                    className="td"
                  >
                    {d.email || '-'}
                  </td>
                  <td
                    className="td"
                  >
                    {Utils.formatPhoneNumber(d.phone)}
                  </td>
                  <td
                    className="td"
                  >
                    {d.company || '-'}
                  </td>
                  <td style={{ width: 165 }} className="th actions__wrapper">
                    <EditIcon
                      className="action__edit__icon"
                      onClick={() => navigate(`/jobs/edit/${d.id}`)}
                    />

                    <DeleteIcon onClick={() => setJobId(d.id)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {_.isEmpty(jobs) && !loading ? <div className="noData">No jobs yet.</div> : null}
      </div>
      {!_.isEmpty(jobs) ? (
        <Pagination
          page={page}
          totalPages={jobsPages}
          limit={limit}
          onChange={onPageChange}
        />
      ) : null}
      <DeleteModal
        loading={loadingDelete}
        open={!!jobId}
        text="Are you sure want to delete the job?"
        onClose={() => setJobId(null)}
        onDelete={handleDelete}
      />
    </div>
  );
}

export default JobsList;

JobsList.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
};
