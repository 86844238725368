import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getCallInfoSocket, getIncomingCallSocket } from '../actions/socket';
import {
  answerCallRequest, getActiveCallsRequest, getCallsRequest,
  hangupCallRequest,
  makeCallRequest,
  manageCallRequest,
  rejectCallRequest,
  setCallStatus,
} from '../actions/call';

const initialState = {
  calls: {},
  appCallStatus: '',
  callsList: [],
  callsPages: 1,
};
export default createReducer(initialState, (builder) => {
  builder
    .addCase(getIncomingCallSocket, (state, action) => {
      state.calls = {
        ...state.calls,
        [action.payload.content.callId]: { ...action.payload, appCallStatus: 'incoming', appCallType: 'incoming' },
      };
    })
    .addCase(getActiveCallsRequest.fulfilled, (state, action) => {
      const { call } = action.payload.data;

      state.calls = {
        ...state.calls,
        [call.id]: {
          ...call,
          callId: call.id,
          channelId: call.inCallChannelId,
          content: { callId: call.id, name: call.name, number: call.number },
          appCallStatus: call.status === 'RINGING' ? 'incoming' : 'answered',
          appCallType: 'incoming',
        },
      };
    })
    .addCase(answerCallRequest.fulfilled, (state, action) => {
      state.calls = _.forEach({ ...state.calls }, (call, id) => {
        if (id === action.payload.callId) {
          call.appCallStatus = 'answered';
        }
      });
    })
    .addCase(hangupCallRequest.fulfilled, (state, action) => {
      const calls = { ...state.calls };
      delete calls[action.payload.callId];
      state.calls = calls;
    })
    .addCase(setCallStatus, (state, action) => {
      state.calls = _.forEach({ ...state.calls }, (call, id) => {
        if (id === action.payload.callId) {
          call.appCallStatus = action.payload.status;
        }
      });
    })
    .addCase(makeCallRequest.pending, (state, action) => {
      const { args } = action.meta;
      state.appCallStatus = 'calling';
      state.callStatus = 'ringing';
      state.calls = {
        ...state.calls,
        ringing: { ...args, appCallType: 'outgoing', callId: _.uniqueId('call') },
      };
    })
    .addCase(makeCallRequest.fulfilled, (state, action) => {
      const calls = {
        ...state.calls,
        [action.payload.callId]: { ...action.payload, appCallStatus: 'answered', appCallType: 'outgoing' },
      };

      state.callStatus = 'calling';
      state.calls = calls;
    })
    .addCase(rejectCallRequest.fulfilled, (state, action) => {
      state.calls = _.forEach({ ...state.calls }, (call, id) => {
        if (id === action.payload.callId) {
          call.appCallStatus = 'ended';
        }
      });
    })
    .addCase(manageCallRequest.fulfilled, (state, action) => {
      const { callId, type } = action.payload;
      state.calls = _.forEach({ ...state.calls }, (call, id) => {
        if (id === callId) {
          if (['mute', 'unmute'].includes(type)) {
            call.appCallMuteState = type;
          }
          if (['hold', 'unhold'].includes(type)) {
            call.appCallHoldState = type;
          }
        }
      });
    })
    .addCase(getCallsRequest.fulfilled, (state, action) => {
      const { calls, totalPages } = action.payload.data;
      state.callsList = calls;
      state.callsPages = totalPages;
    })
    .addCase(getCallInfoSocket, (state, action) => {
      const { type, reason, callId } = action.payload;
      let calls = { ...state.calls };
      if (type === 'ended' || reason === 'hangup' || reason === 'answer') {
        delete calls[callId];
      } else {
        calls = {
          ...calls,
          [callId]: {
            ...state.calls[callId], ...action.payload, appCallStatus: action.payload.type,
          },
        };
      }
      state.calls = calls;
    });
});
