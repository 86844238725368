import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getNotificationListRequest = createAsyncThunk('get/notification', async (payload) => {
  const { data } = await Api.getNotificationList(payload);
  return data;
});

export const seenNotificationRequest = createAsyncThunk('seen/notification', (payload) => {
  Api.seenNotification(payload);
});

export const deleteNotificationRequest = createAsyncThunk('delete/notification', async (payload) => {
  await Api.deleteNotification(payload);
});

export const checkUnreadNotificationRequest = createAsyncThunk('request/unread/notification', async () => {
  const { data } = await Api.checkUnreadNotification();
  return data;
});

export const clearNotificationList = createAction('clear/notification');
export const unreadNotification = createAction('unread/notification');

export const getNotificationTemplatesRequest = createAsyncThunk(
  'notification/getNotificationTemplatesRequest',
  async (payload) => {
    const { data } = await Api.getNotificationTemplates(payload);
    return { data };
  },
);

export const deleteNotificationTemplateRequest = createAsyncThunk(
  'notification/deleteNotificationTemplateRequest',
  async (payload) => {
    const { data } = await Api.deleteNotificationTemplate(payload);
    return { data };
  },
);

export const createNotificationTemplateRequest = createAsyncThunk(
  'notification/createNotificationTemplateRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.createNotificationTemplate(payload);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const createNotificationSettingRequest = createAsyncThunk(
  'notification/createNotificationSettingRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.createNotificationSetting(payload);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const getSingleNotificationTemplateRequest = createAsyncThunk(
  'notification/getSingleNotificationTemplateRequest',
  async (payload) => {
    const { data } = await Api.getNotificationTemplates(payload);
    return { data };
  },
);

export const getNotificationSettingRequest = createAsyncThunk(
  'notification/getNotificationSettingRequest',
  async (payload) => {
    const { data } = await Api.getNotificationSetting(payload);
    return { data };
  },
);
