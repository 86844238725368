import { useEffect } from 'react';
import Account from '../helpers/Account';

export default function Logout() {
  useEffect(() => {
    Account.delete().then(() => {
      window.location.href = '/login';
    });
  }, []);
  return null;
}
