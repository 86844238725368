import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as NotificationIcon } from '../../assets/icons/notification.svg';
import NotificationModal from './NotificationModal';
import NotificationInfo from './NotificationInfo';
import { getNotificationListRequest, unreadNotification } from '../../store/actions/notification';
import NotificationsManager from '../../helpers/NotificationsManager';

function Notification() {
  const dispatch = useDispatch();
  const unreadExist = useSelector((store) => store.notification.unreadExist);
  const notificationList = useSelector((store) => store.notification.notificationList);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const seeAllClick = useCallback(() => {
    setOpen(false);
    setOpenModal(true);
  }, []);

  useEffect(() => {
    NotificationsManager.event.on('message', handleNewMessage);
    return () => {
      NotificationsManager.event.removeListener('message', handleNewMessage);
    };
  }, []);

  const handleNewMessage = useCallback(async () => {
    setLoading(true);

    await dispatch(unreadNotification());

    await dispatch(getNotificationListRequest({ page: 1 }));

    setLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getNotificationListRequest({ page: 1 }));
      setLoading(false);
    })();
  }, []);

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setOpen(false);
  }, []);

  return (
    <div className="notifications">
      <div className="notifications_icon" onClick={() => setOpen((prev) => !prev)}>
        <NotificationIcon />

        {unreadExist && <span className="dot" />}
      </div>

      {open ? (
        <>
          <div className="overlay" onClick={closeModal} />

          <div className="notification_wrapper">

            <NotificationInfo onClose={() => setOpen(false)} firstLoad={loading} />

            {!!notificationList.length && (
            <div
              className="notification_footer"
              onClick={seeAllClick}
            >
              <div>See all</div>
            </div>
            )}
          </div>
        </>
      ) : null}

      <NotificationModal open={openModal} close={closeModal} />
    </div>
  );
}

export default Notification;
