import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  addLeadRequest,
  getLeadSourcesRequest,
  getLeadsRequest,
  getSingleLeadRequest,
  removeLeadData,
  setLeadData,
  unsetLeadFormErrors,
} from '../actions/leads';
import Utils from '../../helpers/Utils';
import { changePipelineElementStatusLocal } from '../actions/pipelines';

export const additionalDataKey = Symbol('additionalDataKey');

const initialState = {
  leads: [],
  leadsGrouped: {},
  leadsPages: 0,
  leadSources: [],
  leadData: {
    tasks: [],
    notes: [],
    emails: [],
    details: { files: [{}] },
  },
  errors: {},
  singleLead: { files: [{}] },
  leadActivity: {},
  calls: [],
};

export const idKey = Symbol('id');
export const leadIdKey = Symbol('leadId');
export default createReducer(initialState, (builder) => {
  builder
    .addCase(getLeadsRequest.fulfilled, (state, action) => {
      const {
        data: {
          totalPages,
          leads,
        }, path,
      } = action.payload;
      if (!path) {
        state.leads = leads;
        state.leadsGrouped = _.groupBy(_.orderBy(leads, 'order'), 'status');
        state.leadsPages = totalPages;
      }
    })
    .addCase(getLeadSourcesRequest.fulfilled, (state, action) => {
      state.leadSources = action.payload.leadSources;
    })
    .addCase(addLeadRequest.pending, (state) => {
      state.errors = {};
    })
    .addCase(addLeadRequest.rejected, (state, action) => {
      const {
        errors,
        message,
      } = action.payload.data;
      if (errors) {
        state.errors = Utils.normalizeErrors(action.payload.data.errors);
      } else {
        toast.error(message);
      }
    })
    .addCase(unsetLeadFormErrors, (state, action) => {
      _.unset(state.errors, action.payload.key);
    })
    .addCase(setLeadData, (state, action) => {
      const {
        key,
        value,
        index,
      } = action.payload;
      const leadData = _.cloneDeep(state.leadData);
      if (index?.toString()) {
        leadData[key].splice(index, 1);
      } else {
        _.set(leadData, key, value);
      }

      if (key.includes('additionalData')) {
        leadData.sale = _.sum(leadData.details.additionalData.filter((d) => d.qty || d.price)
          .map((d) => (+d.qty || 1) * (+d.price || 0)))?.toFixed(2);
      }
      state.leadData = leadData;
    })
    .addCase(getSingleLeadRequest.fulfilled, (state, action) => {
      const lead = action.payload.leads[0] || { tasks: [], emails: [] };
      state.singleLead = lead;
      let { details = {} } = lead;
      if (_.isEmpty(details.additionalData)) {
        details = { ...details };
      } else {
        details = {
          ...details,
          additionalData: details.additionalData.map((d) => ({
            ...d, [additionalDataKey]: _.uniqueId('additionalDataKey'),
          })),
        };
      }
      state.leadData = {
        address: lead.address,
        company: lead.company,
        contact: lead.contactId,
        tasks: lead.tasks.map((t) => ({
          type: t.type,
          dueDate: t.dueDate,
          note: t.note,
          status: t.status,
          [idKey]: t.id,
          responsiblePerson: t.responsiblePerson,
        })),
        responsiblePerson: lead.responsiblePerson,
        leadSource: lead.leadSourceId,
        status: lead.status,
        sale: lead.sale || _.sum(details.additionalData.map((d) => (+d.qty || 0) * (+d.price || 0)))?.toFixed(2),
        phone: lead.phone,
        notes: lead.notes,
        [leadIdKey]: lead.leadId,
        emails: lead.emails.map((e) => ({
          to: e.to,
          subject: e.subject,
          templateId: e.templateId,
          text: e.text,
        })),
        email: lead.email,
        details,
      };
      state.leadActivity = _.groupBy(lead.activity, (l) => moment(l.createdAt).format('MM.DD.YYYY'));
      state.calls = lead.calls;
    })
    .addCase(changePipelineElementStatusLocal, (state, action) => {
      const {
        id,
        status,
        pipeline,
        data,
        sourceStatus,
      } = action.payload;
      if (pipeline === 'lead') {
        state.leadsGrouped[status] = data;
        if (sourceStatus) {
          state.leadsGrouped[sourceStatus] = state.leadsGrouped[sourceStatus]?.filter((j) => j.id !== +id);
        }
      }
    })
    .addCase(removeLeadData, (state) => {
      state.leadData = {
        tasks: [],
        notes: [],
        emails: [],
        details: { additionalData: [{}] },
      };
      state.leadActivity = {};
      state.calls = [];
      state.errors = {};
    });
});
