import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as AddIcon } from '../../assets/icons/add_back.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/envelop.svg';
import { ReactComponent as JobIcon } from '../../assets/icons/job.svg';
import { ReactComponent as StateIcon } from '../../assets/icons/state.svg';
import { ReactComponent as RemoveIcon } from '../../assets/icons/delete.svg';

const items = [
  {
    title: 'Add a task', icon: <AddIcon />, id: 1, key: 'task', pipeline: ['lead', 'job'],
  },
  {
    title: 'Send an email', icon: <EmailIcon />, id: 2, key: 'email', pipeline: ['lead', 'job'],
  },
  {
    title: 'Create a job', icon: <JobIcon />, id: 3, key: 'job', pipeline: ['lead'],
  },
  {
    title: 'Create a state', icon: <StateIcon />, id: 4, key: 'state', pipeline: ['lead', 'job'],
  }
];

function ColumnHeaderDropdown(props) {
  const {
    onClose, pipeline, onItemClick,
  } = props;

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('click', handleCloseEvent);
    }, 0);
    return () => {
      window.removeEventListener('click', handleCloseEvent);
    };
  }, []);

  const handleCloseEvent = useCallback((ev) => {
    if (!ev.target.closest('.columnHeaderDropdown')) {
      onClose();
    }
  }, []);

  return (
    <ul className="dropdown columnHeaderDropdown">
      {items.filter((it) => it.pipeline.includes(pipeline)).map((i) => (
        <li key={i.id} onClick={() => onItemClick(i)}>
          {i.icon}
          <p>{i.title}</p>
        </li>
      ))}
    </ul>
  );
}

export default ColumnHeaderDropdown;

ColumnHeaderDropdown.propTypes = {
  onClose: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  pipeline: PropTypes.string.isRequired,
};
