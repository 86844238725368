import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import Wrapper from '../../components/Wrapper';
import { deleteNotificationTemplateRequest, getNotificationTemplatesRequest } from '../../store/actions/notification';
import Loader from '../../components/loader/Loader';
import HasPermission from '../../components/HasPermission';
import Pagination from '../../components/pagination/Pagination';
import DeleteModal from '../../components/modal/DeleteModal';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';

function NotificationTemplates() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const notificationTemplates = useSelector((state) => state.notification.notificationTemplates);
  const notificationTemplatesTotal = useSelector((state) => state.notification.notificationTemplatesTotal);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getNotificationTemplatesRequest({ page, limit }));
      setLoading(false);
    })();
  }, [page, limit]);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    await dispatch(deleteNotificationTemplateRequest(templateId));
    await dispatch(getNotificationTemplatesRequest({ page, limit }));
    setTemplateId(null);
    setLoadingDelete(false);
  }, [templateId]);

  return (
    <Wrapper title="Notification templates">
      <div className="contacts__content">
        <div className="contacts__buttons__wrapper">
          <HasPermission read="create_notification_template_readonly" edit="create_notification_template">
            <NavLink
              to="/settings/notification-templates/add"
              className={classNames('mainButton')}
            >
              <AddIcon />

              Create
            </NavLink>
          </HasPermission>
        </div>
        <div className="table__wrapper leadsTable">
          {loading ? (
            <div className="loader__wrapper">
              <Loader size={60} borderWidth={4} />
            </div>
          ) : null}

          {!loading && !_.isEmpty(notificationTemplates) ? (
            <table
              style={{ minWidth: 165 }}
              className="table"
            >
              <thead className="thead">
                <tr className="tr">
                  <th className="th">Name</th>
                  <th className="th">Notification body</th>
                  <th style={{ width: 165 }} className="th">Actions</th>
                </tr>
              </thead>

              <tbody className="tbody">
                {notificationTemplates.map((d) => (
                  <tr
                    className="tr"
                    key={d.id}
                  >
                    <td
                      className="td"
                    >
                      {d.title}
                    </td>
                    <td
                      className="td"
                      dangerouslySetInnerHTML={{ __html: d.html }}
                    />
                    <td style={{ width: 165 }} className="th actions__wrapper">
                      <HasPermission edit="create_notification_template" read="create_notification_template_readonly">
                        <EditIcon
                          className="action__edit__icon"
                          onClick={() => navigate(`/settings/notification-templates/edit/${d.id}`)}
                        />
                      </HasPermission>
                      <HasPermission edit="delete_notification_template" read="delete_notification_template_readonly">
                        <DeleteIcon onClick={() => setTemplateId(d.id)} />
                      </HasPermission>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
          {_.isEmpty(notificationTemplates) && !loading
            ? <div className="noData">No notification templates yet.</div> : null}
        </div>
        {!_.isEmpty(notificationTemplates) ? (
          <Pagination
            page={page}
            totalPages={notificationTemplatesTotal}
            limit={limit}
            onChange={(k, v) => {
              if (k === 'page') {
                setPage(v);
              } else {
                setLimit(v);
                setPage(1);
              }
            }}
          />
        ) : null}
        <DeleteModal
          loading={loadingDelete}
          open={!!templateId}
          text="Are you sure want to delete this template?"
          onClose={() => setTemplateId(null)}
          onDelete={handleDelete}
        />
      </div>
    </Wrapper>
  );
}

export default NotificationTemplates;
