import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Checkbox(props) {
  const {
    label, checked, greenBG, indeterminate, error, ...restProps
  } = props;
  const [id] = useState(_.uniqueId('checkbox'));

  return (
    <label
      htmlFor={id}
      className={classNames('checkboxWrapper', { greenBG, indeterminate, error })}
    >
      {label}
      <input type="checkbox" checked={checked} id={id} {...restProps} />
      <span className="checkmark" />
    </label>
  );
}

export default Checkbox;

Checkbox.propTypes = {
  label: PropTypes.string,
  greenBG: PropTypes.bool,
  indeterminate: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  error: PropTypes.bool,
};

Checkbox.defaultProps = {
  label: '',
  greenBG: false,
  indeterminate: false,
  error: false,
};
