import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import RightModal from '../modal/RightModal';
import Select from '../form/Select';
import Input from '../form/Input';
import Button from '../form/Button';
import Api from '../../Api';
import { getSingleUserRequest, getUsersRequest } from '../../store/actions/users';
import Utils from '../../helpers/Utils';
import { useQuery } from '../../helpers/hooks/useQuery';

const tableHeader = [
  {
    title: 'First name',
    placeholder: 'First name',
    path: 'firstName',
    required: true,
  },
  {
    title: 'Last name',
    placeholder: 'Last name',
    path: 'lastName',
    required: true,
  },
  {
    title: 'Email',
    placeholder: 'Email',
    path: 'email',
    validation: 'email',
    required: true,
  },
  {
    title: 'Extension',
    placeholder: 'Extension',
    path: 'extension',
    validation: 'extension',
    required: true,
  },
  {
    title: 'Role',
    placeholder: 'Role',
    path: 'role.title',
    valuePath: 'role',
    required: true,
    type: 'select',
  },
  {
    title: 'Status',
    path: 'status',
    hide: true,
  },
];

const fields = tableHeader.filter((field) => !field.hide);
const userDefaultData = {
  firstName: '',
  lastName: '',
  email: '',
  role: null,
};

function AddUserModal() {
  const { query } = useQuery();

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [errors, setErrors] = useState({});
  const [roles, setRoles] = useState([]);
  const [saveLoading, saveLoadingToggle] = useState(false);
  const [userInfo, setUserInfo] = useState(userDefaultData);

  const userId = searchParams.get('id');

  useEffect(() => {
    if (userId) {
      (async () => {
        const { payload: { users } } = await dispatch(getSingleUserRequest({ id: userId }));
        const d = {
          firstName: users[0].firstName,
          lastName: users[0].lastName,
          email: users[0].email,
          role: users[0].role?.id || users[0].role,
          status: users[0].status,
          extension: users[0].extension,
          id: +userId,
        };
        setUserInfo({ ...d });
      })();
    }
  }, [userId]);

  useEffect(() => {
    (async () => {
      const { data } = await Api.getUserPermissionsGroup({ limit: 100 });
      setRoles(data.permissionGroups.map((p) => ({ id: p.id, name: p.title })));
    })();
  }, []);

  const changeUser = useCallback((path, value) => {
    setUserInfo((prev) => ({ ...prev, [path]: value }));

    setErrors((prev) => {
      delete prev[path];

      return prev;
    });
  }, []);

  const closeContactModal = useCallback((id = null) => {
    const q = { ...Object.fromEntries(searchParams) };
    delete q.create;
    delete q.id;
    delete q.from;
    if (_.isNumber(id)) {
      q['rp-id'] = id;
    }
    setSearchParams(q);
    setUserInfo(userDefaultData);
    setErrors({});
  }, [searchParams]);

  const saveContact = useCallback(async (e) => {
    e.preventDefault();
    saveLoadingToggle(true);

    try {
      const data = Utils.deleteEmptyKeys(Utils.trimObjValues(userInfo));

      delete data.status;

      const { data: d } = await Api.createUser(data);
      await dispatch(getUsersRequest({
        page: +query.page || 1,
        limit: +query.limit || 10,
        list: 'all',
      }));

      closeContactModal(d.userId);
      toast.success(`${userInfo.id ? 'Successfully updated' : 'Successfully created'}`);
    } catch (err) {
      if (err.response.data.errors) {
        setErrors(Utils.normalizeErrors(err.response.data.errors));
      } else {
        setErrors((prev) => ({
          ...prev,
          email: err.response.data.message,
        }));
      }
    }

    saveLoadingToggle(false);
  }, [userInfo, query]);

  const resendEmail = useCallback(() => {
    Api.resendNewPasswordEmail(userInfo.id);

    toast.success('New link sent by email');
  }, [userInfo]);

  const loadRoles = useCallback(async (inputValue, callback) => {
    const { data } = await Api.getUserPermissionsGroup({ s: inputValue });

    callback(data.permissionGroups.map((p) => ({ id: p.id, name: p.title })));
  }, []);

  return (
    <RightModal
      open={searchParams.get('create') === 'user'}
      onClose={closeContactModal}
      headerText={`${userInfo?.id ? 'Edit' : 'New'} user`}
    >
      <form onSubmit={saveContact}>
        {fields.map(({
          title, placeholder, path, required, type, valuePath, mask,
        }) => (
          <div key={title} className="new__contact__input__wrapper">
            {type === 'select'
              ? (
                <Select
                  isAsync
                  label={`${title}${required ? ' *' : ''}`}
                  placeholder={placeholder}
                  onChange={(o) => changeUser(valuePath || path, o.id || '')}
                  value={roles?.find((o) => (o.id === userInfo[valuePath || path])) || null}
                  getOptionLabel={(o) => o.name}
                  getOptionValue={(o) => o.id}
                  loadOptions={loadRoles}
                  defaultOptions={roles}
                  error={errors[valuePath || path]}
                />
              )

              : (
                <Input
                  label={`${title}${required ? ' *' : ''}`}
                  placeholder={placeholder}
                  value={userInfo[path]}
                  onChange={({ target: { value } }) => changeUser(path, value)}
                  error={errors[path]}
                  mask={mask}
                />
              )}
          </div>
        ))}

        <div className="new__users__buttons__wrapper">
          <div className="new__contact__buttons__wrapper">
            <Button
              title={`${userInfo?.id ? 'Edit' : 'Add new'} user`}
              loading={saveLoading}
              type="submit"
            />

            <Button
              title="Cancel"
              noBorder
              type="button"
              onClick={closeContactModal}
            />
          </div>

          {userInfo.status === 'pending' && (
            <Button
              title="Resend email"
              type="button"
              onClick={resendEmail}
            />
          )}
        </div>
      </form>
    </RightModal>
  );
}

export default AddUserModal;
