import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Navigate } from 'react-router-dom';
import Tooltip from 'rc-tooltip';
import usePermission from '../helpers/hooks/usePermission';

document.body.addEventListener('click', (ev) => {
  if (ev.target.closest('span.readOnly')) {
    ev.preventDefault();
    ev.stopPropagation();
  }
}, { capture: true });
document.body.addEventListener('keydown', (ev) => {
  if (ev.target.closest('span.readOnly')) {
    ev.preventDefault();
    ev.stopPropagation();
  }
}, { capture: true });
document.body.addEventListener('focus', (ev) => {
  if (ev.target.closest('span.readOnly')) {
    ev.preventDefault();
    ev.stopPropagation();
    ev.target.blur();
  }
}, { capture: true });

function HasPermission(props) {
  const {
    edit, read, children, exact, redirect, else: elseCondition,
  } = props;
  const editArray = _.isArray(edit) ? edit : [edit];
  const hasAccess = usePermission(editArray, exact);
  const hasReadAccess = usePermission(read || editArray.map((d) => `${d}_readonly`), exact);
  if (hasAccess || edit === 'none') {
    return children;
  }
  if (hasReadAccess) {
    return (
      <Tooltip overlay="Permission Denied" placement="top" destroyTooltipOnHide>
        <span className="readOnly">{children}</span>
      </Tooltip>
    );
  }
  if (redirect) {
    return <Navigate to={_.isString(redirect) ? redirect : '/'} />;
  }
  return elseCondition;
}

HasPermission.propTypes = {
  edit: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  read: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.any,
  exact: PropTypes.bool,
  else: PropTypes.any,
};

HasPermission.defaultProps = {
  exact: false,
  redirect: false,
  children: null,
  edit: null,
  read: null,
  else: null,
};
export default HasPermission;
