import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getInvoicesRequest = createAsyncThunk('invoices/get', async (payload) => {
  const { path, ...reqData } = payload;
  const { data } = await Api.getInvoices(reqData);
  return { data, path };
});

export const createInvoicePaymentRequest = createAsyncThunk('invoices/create/payment', async (payload = {}) => {
  const { data } = await Api.createInvoicePayment(payload);

  return data;
});

export const getInvoiceAfterCreatePaymentRequest = createAsyncThunk('invoices/get-invoice-after-create-payment', async (payload = {}) => {
  const { data } = await Api.getInvoices(payload);

  return data;
});

export const deleteInvoicePaymentRequest = createAsyncThunk('invoices/delete/payment', async (payload = {}) => {
  const { data } = await Api.deletePaymentItem(payload);

  return data;
});
