import React, { useLayoutEffect } from 'react';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as MenuIcon } from '../assets/icons/menu.svg';
import { ReactComponent as LogoSvg } from '../assets/images/logo.svg';
import { ReactComponent as ArrowSvg } from '../assets/icons/arrow_down.svg';
import menuItemsList from '../data/menuItemsList';
import { menuOpening } from '../store/actions/menu';
import IncomingCall from './calls/IncomingCall';
import HasPermission from './HasPermission';

function Menu(props) {
  const {
    isOpen,
    toggleMenu,
  } = props;
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const { openedMenuId } = useSelector((state) => state.menu);

  useLayoutEffect(() => {
    const findOpenedMenu = menuItemsList.find((item) => pathname.includes(item.path));

    if (findOpenedMenu && !openedMenuId) dispatch(menuOpening(findOpenedMenu.id));
  }, []);

  return (
    <div className={classNames('menu', { isOpen })}>
      <div className="top">
        <MenuIcon className="burger" onClick={toggleMenu} />

        {isOpen
          ? (
            <>
              <LogoSvg className="logo" />
              {/* <p className="title">Fusion Windows</p> */}
            </>
          )
          : null}
      </div>

      <ul className="menuItems">
        {menuItemsList.map((item) => (
          <li key={item.id}>
            {item.subMenu
              ? (
                <HasPermission edit={item.permissions}>
                  <div
                    role="button"
                    tabIndex="0"
                    className={pathname.includes(item.path) ? 'active' : ''}
                    style={{ cursor: 'pointer' }}
                    onClick={() => dispatch(menuOpening(item.id))}
                  >
                    {item.icon}
                    {isOpen ? <span className="label">{item.label}</span> : null}

                    <ArrowSvg className={classNames('sub__menu__arrow', { open: openedMenuId === item.id })} />
                  </div>

                  {openedMenuId === item.id && (
                    <ul className="subMenuItems">
                      {item.subMenu.map((subItem) => (
                        <li key={subItem.id}>
                          <HasPermission edit={subItem.permissions}>
                            <NavLink to={subItem.path}>
                              {subItem.icon ? subItem.icon : null}
                              <span className="label">{subItem.label}</span>
                            </NavLink>
                          </HasPermission>
                        </li>
                      ))}
                    </ul>
                  )}
                </HasPermission>
              )

              : (
                <HasPermission edit={item.permissions}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    {item.icon}
                    {isOpen
                      ? <span className="label">{item.label}</span>
                      : null}
                  </NavLink>
                </HasPermission>
              )}
          </li>
        ))}
      </ul>
      <IncomingCall />
    </div>
  );
}

export default Menu;

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};
