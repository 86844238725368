import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';
import { getLeadsRequest } from './leads';
import { getJobsRequest } from './jobs';
import { getTasksRequest } from './tasks';

export const getPipelinesRequest = createAsyncThunk('pipelines/getPipelinesRequest', async (payload) => {
  const { data } = await Api.getPipelines(payload);
  return { data, type: payload.type };
});

export const createPipelineRequest = createAsyncThunk('pipelines/createPipelineRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.createPipeline(payload);
    return { data, type: payload.type };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const orderPipelineRequest = createAsyncThunk('pipelines/orderPipelineRequest', async (payload) => {
  const { data } = await Api.orderPipeline(payload);
  return data;
});

export const deletePipelineRequest = createAsyncThunk('pipelines/deletePipelineRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.deletePipeline(payload);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const getPipelineStatusesRequest = createAsyncThunk('pipelines/getPipelineStatusesRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.getPipelineStatuses(payload);
    return { data, type: payload.type };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const orderPipelineLocal = createAction('pipelines/orderPipelineLocal');

export const addPipelineLocal = createAction('pipelines/addPipelineLocal');
export const changePipelineLocal = createAction('pipelines/changePipelineLocal');
export const changePipelineElementStatusLocal = createAction('pipelines/changePipelineElementStatusLocal');

export const changePipelineElementStatusRequest = createAsyncThunk(
  'pipelines/changePipelineElementStatusRequest',
  async (payload, thunkAPI) => {
    const { data } = await Api.changePipelineElementStatus(payload);
    const { pipeline } = payload;
    if (pipeline === 'lead') {
      await thunkAPI.dispatch(getLeadsRequest({ pipeline: true }));
    } else if (pipeline === 'job') {
      await thunkAPI.dispatch(getJobsRequest({ pipeline: true }));
    } else if (pipeline === 'task') {
      await thunkAPI.dispatch(getTasksRequest({ pipeline: true }));
    }
    return { data, ...payload };
  },
);

export const archivePipelineElementRequest = createAsyncThunk(
  'pipelines/archivePipelineElementRequest',
  async (payload, thunkAPI) => {
    const { data } = await Api.archivePipelineElement(payload);
    const { pipeline } = payload;
    if (pipeline === 'lead') {
      await thunkAPI.dispatch(getLeadsRequest({ pipeline: true }));
    } else if (pipeline === 'job') {
      await thunkAPI.dispatch(getJobsRequest({ pipeline: true }));
    } else if (pipeline === 'task') {
      await thunkAPI.dispatch(getTasksRequest({ pipeline: true }));
    }
    return { data, ...payload };
  },
);

export const getEmailsRequest = createAsyncThunk('pipelines/getEmailsRequest', async (payload, thunkAPI) => {
  try {
    const { id, pipeline, ...params } = payload;
    const { data } = await Api.getEmails(id, pipeline, params);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const getSingleEmailsRequest = createAsyncThunk('pipelines/getSingleEmailsRequest', async (payload, thunkAPI) => {
  try {
    const { id, pipeline } = payload;
    const { data } = await Api.getSingleEmails(id, pipeline);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});
