import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { googleAddressAutocompleteRequest } from '../../store/actions/account';
import Input from './Input';
import Loader from '../loader/Loader';

function AutocompleteInput(props) {
  const {
    onSelectOption, defaultValue, getFullData, ...restProps
  } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [open, setOpen] = useState('');
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = useCallback(async (ev) => {
    const val = ev.target.value;
    setValue(val);
    if (val) {
      setLoading(true);
      const { payload } = await dispatch(googleAddressAutocompleteRequest(ev.target.value));
      setOpen(true);
      setLoading(false);
      setOptions(payload.result || []);
    }
  }, []);

  return (
    <div className="autocompleteInputWrapper">
      {open ? <div className="overlay" onClick={() => setOpen(false)} /> : null}
      <Input
        onChange={handleChange}
        onFocus={handleChange}
        value={value}
        rightIcon={loading ? <Loader size={20} /> : null}
        style={{ paddingRight: loading ? 48 : 10 }}
        {...restProps}
      />
      {open && options.length ? (
        <ul>
          {options.map((o) => (
            <li
              key={o.place_id}
              onClick={() => {
                onSelectOption(getFullData ? o : o.description);
                setValue(o.description);
                setOpen(false);
              }}
            >
              {o.description}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}

export default AutocompleteInput;

AutocompleteInput.propTypes = {
  onSelectOption: PropTypes.func.isRequired,
  getFullData: PropTypes.bool,
  defaultValue: PropTypes.string,
};

AutocompleteInput.defaultProps = {
  defaultValue: '',
  getFullData: false,
};
