import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import ReactSelect, { components } from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import CreatableAsyncSelect from 'react-select/async-creatable';
import _ from 'lodash';
import arrowIcon from '../../assets/icons/select_arrow.svg';
import Loader from '../loader/Loader';

function Select(props) {
  const {
    label, error, isAsync, isCreatable, Menu, isCreatableAsync, ...restProps
  } = props;

  const ref = useRef();
  const [id] = useState(_.uniqueId('select'));

  useEffect(() => {
    if (ref.current.controlRef.closest('[data-rbd-droppable-context-id]')) {
      window.addEventListener('click', handleClickEvent);
    }
    return () => {
      window.removeEventListener('click', handleClickEvent);
    };
  }, [ref]);

  const handleClickEvent = useCallback((ev) => {
    if (ev.defaultPrevented || !ev.target.closest('.fus_container')) {
      return;
    }
    const selectId = ev.target.closest('.fus_container').getAttribute('id');
    if (selectId === id) {
      ref.current.openAfterFocus = true;
      ref.current.focus();
    }
  }, [ref.current]);

  const DropdownIndicator = useCallback(() => (
    <div className="fusIndicatorsContainer">
      <img src={arrowIcon} alt="icon" className="fus_icon" />
    </div>
  ), []);

  const loader = useCallback(() => <Loader />, []);

  return (
    <div className={classNames('selectWrapper', { error: !!error })}>
      {label ? <p className="label">{label}</p> : null}

      {isAsync
        ? (
          <AsyncSelect
            id={id}
            classNamePrefix="fus"
            className="fus_container"
            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
            loadingMessage={loader}
            {...restProps}
            ref={ref}
          />
        ) : null}

      {isCreatable
        ? (
          <CreatableSelect
            id={id}
            classNamePrefix="fus"
            className="fus_container"
            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
            loadingMessage={loader}
            {...restProps}
            ref={ref}
          />
        ) : null}
      {isCreatableAsync
        ? (
          <CreatableAsyncSelect
            id={id}
            classNamePrefix="fus"
            className="fus_container"
            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
            loadingMessage={loader}
            {...restProps}
            ref={ref}
          />
        ) : null}
      {!(isCreatable || isAsync || isCreatableAsync) ? (
        <ReactSelect
          id={id}
          classNamePrefix="fus"
          className="fus_container"
          components={{ DropdownIndicator, Menu, IndicatorSeparator: () => null }}
          loadingMessage={loader}
          {...restProps}
          ref={ref}
        />
      ) : null}

      {error ? <span className="errorText">{error}</span> : null}
    </div>
  );
}

export default Select;

Select.propTypes = {
  label: PropTypes.string,
  isAsync: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isCreatableAsync: PropTypes.bool,
  Menu: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Select.defaultProps = {
  label: '',
  isAsync: false,
  isCreatableAsync: false,
  isCreatable: false,
  error: '',
  Menu: components.Menu,
};
