import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTasksRequest } from '../../store/actions/tasks';
import DraggableContainer from '../pipeline/DraggableContainer';

function TasksPipeline() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const tasks = useSelector((state) => state.tasks.tasksGrouped);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getTasksRequest({ pipeline: true }));
      setLoading(false);
    })();
  }, []);

  return (
    <DraggableContainer loading={loading} pipeline="task" pipelineData={tasks} />
  );
}

export default TasksPipeline;
