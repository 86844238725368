import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import striptags from 'striptags';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Editor from '../form/Editor';
import Button from '../form/Button';
import noImg from '../../assets/images/no_img_user.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { deleteLeadNoteRequest } from '../../store/actions/leads';
import { deleteJobNoteRequest } from '../../store/actions/jobs';

function Notes(props) {
  const { setData, notes, pipeline } = props;
  const dispatch = useDispatch();

  const [note, setNote] = useState({ text: '', i: null });

  const profile = useSelector((state) => state.account.profile);

  const handleChange = useCallback((text) => {
    setNote({ ...note, text });
  }, [note]);

  const handleAddNote = useCallback(() => {
    if (!note.text) {
      return;
    }
    let index = 0;

    if (notes) {
      index = note.i?.toString() ? note.i : index = notes.length;
    }
    const noteData = {
      text: note.text,
      temp_date: moment().format('lll'),
      temp_name: `${profile.firstName} ${profile.lastName}`,
      temp_key: _.uniqueId('note'),

    };
    setData({ key: `notes[${index}]`, value: noteData });
    setNote({ text: '<p><br></p>', i: null });
  }, [note, notes, profile, setData]);

  const handleEditNote = useCallback((n, i) => {
    setNote({ text: n.text, i: i.toString() });
  }, []);

  const handleDeleteNote = useCallback((index, id) => {
    setData({ key: 'notes', index });
    if (id) {
      if (pipeline === 'lead') {
        dispatch(deleteLeadNoteRequest(id));
      }
      if (pipeline === 'job') {
        dispatch(deleteJobNoteRequest(id));
      }
    }
  }, [setData]);

  return (
    <div className="notes">
      <div className="editorBlock">
        <Editor placeholder="Your note here" onChange={handleChange} value={note.text} />
        <Button
          title={note.i ? 'Edit comment' : 'Add comment'}
          onClick={handleAddNote}
          disabled={!striptags(note.text)}
        />
      </div>
      <div className="notesBlock">
        {!_.isEmpty(notes) ? notes.map((n, i) => (
          <div key={n.temp_key || n.id} className={classNames('note', { editing: note.i === i.toString() })}>
            <div className="img">
              <img src={noImg} alt="no_img" />
            </div>
            <div className="info">
              <div className="top">
                <div>
                  <p className="name">{n.temp_name || n.name}</p>
                  <span className="dot" />
                  <p className="date">{n.temp_date || moment(n.updatedAt).format('lll')}</p>
                </div>
                <div className="actions">
                  <div className="icon" onClick={() => handleEditNote(n, i)}>
                    <EditIcon />
                  </div>
                  <div className="icon" onClick={() => handleDeleteNote(i, n.id)}>
                    <DeleteIcon />
                  </div>
                </div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: n.text }} className="text" />
            </div>
          </div>
        )) : null}
      </div>
    </div>
  );
}

export default Notes;

Notes.propTypes = {
  notes: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  pipeline: PropTypes.string.isRequired,
};
