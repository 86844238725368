import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const ToggleButton = ({
  className, label, onChange, checked, disabled, labelMarginLeft,
}) => {
  const [uniqId] = useState(_.uniqueId('toggle_id_'));

  return (
    <div className={`toggle_wrapper ${className}`}>
      <input
        id={uniqId}
        type="checkbox"
        className="toggle_input"
        checked={checked}
        onChange={({ target }) => onChange(target.checked)}
        disabled={disabled}
      />

      <label className="toggle_label" htmlFor={uniqId}>

        <div className="toggle_back">
          <span className="toggle_ellipse" />
        </div>

        {label && <span style={{ marginLeft: labelMarginLeft }} className="toggle_text">{label}</span>}
      </label>
    </div>
  );
};

ToggleButton.propTypes = {
  checked: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  labelMarginLeft: PropTypes.number,
  disabled: PropTypes.bool,
};

ToggleButton.defaultProps = {
  className: '',
  label: '',
  labelMarginLeft: 10,
  checked: false,
  disabled: false,
};

export default ToggleButton;
