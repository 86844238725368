import { createReducer } from '@reduxjs/toolkit';
import { getUsersRequest } from '../actions/users';

const initialState = {
  usersList: [],
  totalPages: 1,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getUsersRequest.fulfilled, (store, action) => {
      const { path, data } = action.payload;
      if (!path) {
        store.usersList = data.users;
        store.totalPages = data.totalPages;
      }
    });
});

export default reducer;
