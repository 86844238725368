import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as UserIcon } from '../assets/icons/user.svg';
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow_down.svg';

function UserSettingsDropdown() {
  const [open, setOpen] = useState(false);

  const profile = useSelector((state) => state.account.profile);

  return (
    <div className="userSettingsDropdown">
      <UserIcon />
      <div className="select" onClick={() => setOpen(!open)}>
        <p>{`${profile.firstName} ${profile.lastName}`}</p>
        <ArrowIcon className={open ? 'open' : ''} />
      </div>
      {open ? (
        <>
          <div className="overlay" onClick={() => setOpen(false)} />
          <ul className="dropdown">
            <li><Link to="/logout">Log out</Link></li>
          </ul>
        </>
      ) : null}
    </div>

  );
}

export default UserSettingsDropdown;
