import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import Wrapper from '../../components/Wrapper';
import Button from '../../components/form/Button';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { getUsersRequest } from '../../store/actions/users';
import Api from '../../Api';
import HasPermission from '../../components/HasPermission';
import { usersPermissions } from '../../data/permissions';
import AddUserModal from '../../components/users/AddUserModal';
import Select from '../../components/form/Select';
import Loader from '../../components/loader/Loader';
import Pagination from '../../components/pagination/Pagination';
import DeleteModal from '../../components/modal/DeleteModal';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as RecoverIcon } from '../../assets/icons/recover.svg';

const filterOptions = [{ value: 'all', label: 'Active users' }, { value: 'deleted', label: 'Deleted users' }];
const Users = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [userId, setUserId] = useState(null);
  const [recoverUserId, setRecoverUserId] = useState(null);

  const usersList = useSelector((state) => state.users.usersList);
  const totalPages = useSelector((state) => state.users.totalPages);

  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    limit: 10,
    list: 'all',
  });

  useEffect(() => {
    (async () => {
      if (_.isEmpty(usersList)) {
        setLoading(true);
      }
      await dispatch(getUsersRequest({ ...Object.fromEntries(searchParams) }));
      setLoading(false);
    })();
  }, [searchParams]);

  const onChangePage = useCallback(async (type, value) => {
    const requestData = { ...Object.fromEntries(searchParams) };

    requestData[type] = value;

    if (type === 'limit') {
      requestData.page = 1;
    }
    setSearchParams(requestData);
  }, [searchParams]);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    await Api.deleteUser(userId);

    const requestData = { ...Object.fromEntries(searchParams) };
    requestData.page = usersList.length === 1 && requestData.page > 1 ? requestData.page - 1 : requestData.page;

    await dispatch(getUsersRequest(requestData));
    setSearchParams(requestData);
    setLoadingDelete(false);
    setUserId(null);

    toast.success('User deleted');
  }, [usersList, searchParams, userId]);

  const handleRecover = useCallback(async () => {
    setLoadingDelete(true);
    const user = usersList.find((u) => u.id === recoverUserId);
    const datum = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role?.id || user.role,
      id: user.id,
      recover: true,
      extension: user.extension,
    };
    try {
      await Api.createUser(datum);
      await dispatch(getUsersRequest({ ...Object.fromEntries(searchParams) }));
      toast.success('User successfully recovered');
    } catch (e) {
      toast.error(e.response.data.message);
    }

    setLoadingDelete(false);
    setRecoverUserId(null);
  }, [recoverUserId, usersList, searchParams]);

  const getStatusColor = useCallback((status) => ({
    active: '#00AF31',
    pending: '#FF9900',
    deleted: '#D02B20',
  })[status], []);

  return (
    <Wrapper title="Users">
      <div className="contacts__content usersList">
        <div className="contacts__buttons__wrapper">
          <div className="filterUsers">
            <Select
              options={filterOptions}
              value={filterOptions.find((f) => f.value === searchParams.get('list'))}
              onChange={(v) => setSearchParams({ ...Object.fromEntries(searchParams), list: v.value, page: 1 })}
            />
          </div>
          <HasPermission read="create_user_readonly" edit="create_user">
            <Button
              title={(
                <>
                  <AddIcon />
                  Create
                </>
              )}
              onClick={() => setSearchParams({ ...Object.fromEntries(searchParams), create: 'user' })}
            />
          </HasPermission>
        </div>
        <div className="table__wrapper">

          <HasPermission edit={usersPermissions}>
            {loading ? (
              <div className="loader__wrapper">
                <Loader size={60} borderWidth={4} />
              </div>
            ) : null}

            {!loading && !_.isEmpty(usersList) ? (
              <table
                style={{ minWidth: 165 }}
                className="table"
              >
                <thead className="thead">
                <tr className="tr">
                  <th className="th">First name</th>
                  <th className="th">Last name</th>
                  <th className="th">Email</th>
                  <th className="th">Extension</th>
                  <th className="th">Role</th>
                  <th className="th">Status</th>
                  <th style={{ width: 165 }} className="th">Actions</th>
                </tr>
                </thead>

                <tbody className="tbody">
                {usersList.map((d) => (
                  <tr
                    className="tr"
                    key={d.id}
                  >
                    <td className="td">{d.firstName || '-'}</td>
                    <td className="td">{d.lastName || '-'}</td>
                    <td className="td">{d.email || '-'}</td>
                    <td className="td">{d.extension}</td>
                    <td className="td">{d.role.title || '-'}</td>
                    <td className="td">
                      <span style={{ color: getStatusColor(d.status) }}>{_.upperFirst(d.status)}</span>
                    </td>
                    <td style={{ width: 165 }} className="th ">
                      <div className="actions__wrapper">
                        <HasPermission edit="create_user" read="create_user_readonly">
                          {d.status !== 'deleted' ? (
                              <EditIcon
                                className="action__edit__icon"
                                onClick={() => setSearchParams({
                                  ...Object.fromEntries(searchParams), create: 'user', id: d.id,
                                })}
                              />
                            )
                            : (
                              <RecoverIcon
                                className="action__edit__icon recover"
                                onClick={() => setRecoverUserId(d.id)}
                              />
                            )}
                        </HasPermission>
                        <HasPermission edit="delete_user" read="delete_user_readonly">
                          <DeleteIcon onClick={() => setUserId(d.id)} />
                        </HasPermission>
                      </div>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            ) : null}
            {_.isEmpty(usersList) && !loading ? <div className="noData">No users yet.</div> : null}
          </HasPermission>
        </div>
        {!_.isEmpty(usersList) ? (
          <Pagination
            page={searchParams.get('page')}
            totalPages={totalPages}
            limit={searchParams.get('limit')}
            onChange={onChangePage}
          />
        ) : null}
        <DeleteModal
          loading={loadingDelete}
          open={!!userId}
          text="Are you sure want to delete this user?"
          onClose={() => setUserId(null)}
          onDelete={handleDelete}
        />
        <DeleteModal
          loading={loadingDelete}
          open={!!recoverUserId}
          text="Are you sure want to recover this user?"
          onClose={() => setRecoverUserId(null)}
          onDelete={handleRecover}
        />
      </div>

      <AddUserModal />
    </Wrapper>
  );
};

export default Users;
