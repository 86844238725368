import React, { useEffect } from 'react';
import {
  BrowserRouter, Routes, Route, Navigate, Outlet,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Dashboard from './pages/dashboard/Dashboard';
import Leads from './pages/leads/Leads';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Contacts from './pages/Contacts';
import CreateLead from './pages/leads/CreateLead';
import EditLeadPipeline from './pages/leads/EditLeadPipeline';
import Users from './pages/settings/Users';
import SetPassword from './pages/SetPassword';
import ForgotPassword from './pages/ForgotPassword';
import NotFound from './pages/NotFound';
import Permissions from './pages/settings/permissions/Permissions';
import PermissionAdd from './pages/settings/permissions/PermissionAdd';
import LeadSources from './pages/settings/LeadSources';
import Invoices from './pages/invoices/Invoices';
import InvoiceAdd from './pages/invoices/InvoiceAdd';
import Jobs from './pages/jobs/Jobs';
import EditJobPipeline from './pages/jobs/EditJobPipeline';
import CreateJob from './pages/jobs/CreateJob';
import Tasks from './pages/tasks/Tasks';
import EditTaskPipeline from './pages/tasks/EditTaskPipeline';
import Templates from './pages/templates/Templates';
import TemplateAdd from './pages/templates/TemplateAdd';
import NotificationsManager from './helpers/NotificationsManager';
import LeadAutomations from './pages/leads/LeadAutomations';
import GoTo from './pages/goto/GoTo';
import GoToAuth from './pages/goto/GoToAuth';
import JobAutomations from './pages/jobs/JobAutomations';
import { getProfileRequest } from './store/actions/account';
import GoogleAuth from './pages/googleAuth/GoogleAuth';
import NotificationTemplates from './pages/notificationTamplates/NotificationTemplates';
import AddNotificationTemplate from './pages/notificationTamplates/AddNotificationTemplate';
import NotificationSettings from './pages/notificationTamplates/NotificationSettings';
import CallReports from './pages/CallReports';
import Archive from './pages/Archive';
import Unsubscribe from './pages/Unsubscribe';

NotificationsManager.init().catch(console.error);

function App() {
  const token = useSelector((state) => state.account.token || '');
  const calls = useSelector((state) => state.call.calls);
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      NotificationsManager.reload(token);
      dispatch(getProfileRequest());
    }
  }, [token]);

  useEffect(() => {
    const handleUnload = (event) => {
      if (!_.isEmpty(calls)) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [calls]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/leads" element={<Outlet />}>
            <Route index element={<Leads />} />
            <Route path=":tab" element={<Leads />} />
            <Route path="create" element={<CreateLead />} />
            <Route path="edit/:leadId" element={<CreateLead />} />
            <Route path="pipeline/edit" element={<EditLeadPipeline />} />
            <Route path="automations" element={<LeadAutomations />} />
          </Route>
          <Route path="/jobs" element={<Outlet />}>
            <Route index element={<Jobs />} />
            <Route path=":tab" element={<Jobs />} />
            <Route path="pipeline/edit" element={<EditJobPipeline />} />
            <Route path="create" element={<CreateJob />} />
            <Route path="edit/:jobId" element={<CreateJob />} />
            <Route path="automations" element={<JobAutomations />} />
          </Route>
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/tasks/:tab" element={<Tasks />} />
          <Route path="/tasks/pipeline/edit" element={<EditTaskPipeline />} />

          <Route path="/invoices" element={<Outlet />}>
            <Route index element={<Invoices />} />
            <Route path="/invoices/:jobId/:invoiceId" element={<InvoiceAdd />} />
          </Route>

          <Route path="/settings" element={<Outlet />}>
            <Route path="email-templates" element={<Templates />} />
            <Route path="email-templates/:id" element={<TemplateAdd />} />
            <Route path="notification-templates" element={<NotificationTemplates />} />
            <Route path="notification-templates/add" element={<AddNotificationTemplate />} />
            <Route path="notification-templates/edit/:templateId" element={<AddNotificationTemplate />} />
            <Route path="notification-settings" element={<NotificationSettings />} />
            <Route path="lead-sources" element={<LeadSources />} />
            <Route path="users" element={<Users />} />
            <Route path="permissions" element={<Permissions />} />
            <Route path="permissions/:id" element={<PermissionAdd />} />
            <Route path="goto" element={<GoTo />} />
            <Route path="google/auth" element={<GoogleAuth />} />
          </Route>
          <Route path="/goto/auth" element={<GoToAuth />} />
          <Route path="/call-reports" element={<CallReports />} />
          <Route path="/archive" element={<Archive />} />
          <Route path="/email-unsubscribe" element={<Unsubscribe />} />

          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/set-password/:code" element={<SetPassword />} />

          {/* <Route path="/service-not-available" element={<SetPassword />} /> */}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>

  );
}

export default App;
