import { useSelector } from 'react-redux';
import _ from 'lodash';

const emptyObj = {};
export default function usePermission(permission, exact = false) {
  const { permissions, slug } = useSelector((state) => state.account.profile.role || emptyObj);
  if (slug === 'superadmin') {
    return true;
  }
  if (!permission) {
    return false;
  }
  const permissionList = _.isArray(permission) ? permission : [permission];
  const permissionScopes = _.intersection(permissionList, permissions);
  if (exact) {
    return permissionScopes.length === permissionList.length;
  }
  return permissionScopes.length > 0;
}
