import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from '../form/Select';
import Input from '../form/Input';
import { getLeadSourcesRequest, setLeadData, unsetLeadFormErrors } from '../../store/actions/leads';
import { getUsersRequest } from '../../store/actions/users';
import { getContactsRequest } from '../../store/actions/contacts';
import AddUserModal from '../users/AddUserModal';
import AddContactModal from '../contacts/AddContactModal';
import AutocompleteInput from '../form/AutocompleteInput';

function LeadCreateLeft() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams({});
  const navigate = useNavigate();

  const [resPersonValue, setResPersonValue] = useState('');
  const [contactValue, setContactValue] = useState('');

  const pipelinesList = useSelector((state) => state.pipelines.pipelines.lead);
  const users = useSelector((state) => state.users.usersList);
  const contactsList = useSelector((state) => state.contacts.contactList);
  const leadSources = useSelector((state) => state.leads.leadSources);
  const leadData = useSelector((state) => state.leads.leadData);
  const errors = useSelector((state) => state.leads.errors);
  const profile = useSelector((state) => state.account.profile);

  const from = searchParams.get('from');

  const pipelines = useMemo(() => {
    if (from === 'archive') {
      return [{ name: 'Archived', status: 'archived' }, ...pipelinesList];
    }
    return pipelinesList;
  }, [pipelinesList, from]);
  const resPersons = useMemo(() => {
    let persons = users.map((u) => ({
      ...u,
      value: u.id,
      label: `${u.firstName} ${u.lastName}`,
    }));
    if (resPersonValue) {
      persons = [...persons, { value: '_create', label: `Create "${resPersonValue}"` }];
    }

    return persons;
  }, [users, resPersonValue]);

  const contacts = useMemo(() => {
    let persons = contactsList.map((u) => ({
      ...u,
      value: u.id,
      label: u.name,
    }));
    if (contactValue) {
      persons = [...persons, { value: '_create', label: `Create "${contactValue}"` }];
    }

    return persons;
  }, [contactsList, contactValue]);

  useEffect(() => {
    dispatch(getUsersRequest({ limit: 100 }));
    dispatch(getContactsRequest({ limit: 100 }));
    dispatch(getLeadSourcesRequest({ limit: 100 }));
  }, []);

  useEffect(() => {
    const phone = searchParams.get('phone');
    const rpId = searchParams.get('rp-id');
    const cId = searchParams.get('c-id');
    if (phone) {
      handleChange('phone', phone);
    }
    if (rpId) {
      (async () => {
        await dispatch(getUsersRequest({ limit: 100 }));
        handleChange('responsiblePerson', +rpId);
      })();
    }
    if (cId) {
      (async () => {
        const { payload: { data } } = await dispatch(getContactsRequest({ limit: 100 }));
        const contact = data.contacts.find((c) => c.id === +cId);
        handleContactChange(contact);
      })();
    }
  }, [searchParams]);

  const handleContactChange = useCallback((val) => {
    const resP = resPersons.find((r) => val?.responsibleUsers?.includes(r.value))?.value;
    handleChange('contact', val?.id);
    handleChange('phone', val?.phone);
    handleChange('email', val?.email);
    handleChange('address', val?.address || undefined);
    handleChange('company', val?.company || undefined);
    handleChange('responsiblePerson', resP || profile.id);
  }, [profile, resPersons]);

  const handleChange = useCallback((key, value) => {
    dispatch(setLeadData({ key, value }));
    dispatch(unsetLeadFormErrors({ key }));
  }, []);

  return (
    <div className="left">
      <p className="title">Lead Information</p>
      <Select
        placeholder="Status"
        options={pipelines}
        isSearchable={false}
        onChange={(val) => handleChange('status', val?.status || '')}
        value={pipelines.find((s) => s.status === leadData.status)}
        error={errors.status}
        getOptionLabel={(o) => o.name}
        getOptionValue={(o) => o.status}
        isClearable
        isOptionDisabled={(o) => o.status === 'archived'}
      />
      <Select
        placeholder="Customer name"
        options={contacts}
        onInputChange={(v) => setContactValue(v)}
        onChange={(val) => {
          if (val?.value === '_create') {
            navigate(`?create=contact&from=lead&val=${contactValue}`);
          } else {
            handleContactChange(val);
          }
        }}
        value={contacts.filter((c) => c.id?.toString() === leadData.contact?.toString())}
        error={errors.contact}
        isClearable
      />
      <Input
        placeholder="Company name"
        onChange={(ev) => handleChange('company', ev.target.value || undefined)}
        value={leadData.company}
        error={errors.company}
      />
      <Input
        placeholder="Phone number"
        onChange={(ev) => handleChange('phone', ev.target.value)}
        value={leadData.phone}
        error={errors.phone}
        mask="+1 999-999-9999"
      />
      <Input
        placeholder="Email"
        onChange={(ev) => handleChange('email', ev.target.value)}
        value={leadData.email}
        error={errors.email}
      />
      <AutocompleteInput
        placeholder="Address"
        error={errors.address}
        onSelectOption={(val) => handleChange('address', val || undefined)}
        defaultValue={leadData.address}
      />
      <Select
        placeholder="Lead source"
        options={leadSources}
        getOptionValue={(o) => o.id}
        getOptionLabel={(o) => o.name}
        onChange={(val) => handleChange('leadSource', val?.id)}
        value={leadSources.find((s) => s.id === leadData.leadSource)}
        error={errors.leadSource}
        isClearable
      />
      <Input
        placeholder="Sale"
        value={leadData.sale || ''}
        readOnly
      />
      <Select
        placeholder="Responsible person"
        options={resPersons}
        filterOption={(o, val) => o.label.toLowerCase().includes(val.toLowerCase()) || o.value === '_create'}
        onInputChange={(v) => setResPersonValue(v)}
        onChange={(val) => {
          if (val?.value === '_create') {
            navigate('?create=user&from=lead');
          } else {
            handleChange('responsiblePerson', val?.id);
          }
        }}
        value={resPersons.filter((u) => u.id === leadData.responsiblePerson)}
        error={errors.responsiblePerson}
        isClearable
      />
      <AddUserModal />
      <AddContactModal />
    </div>
  );
}

export default LeadCreateLeft;
