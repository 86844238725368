import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Wrapper from '../../components/Wrapper';
import { getPipelinesRequest } from '../../store/actions/pipelines';
import JobsList from '../../components/jobs/JobsList';
import PipelineTabs from '../../components/pipeline/PipelineTabs';
import { getContactsRequest } from '../../store/actions/contacts';
import { getUsersRequest } from '../../store/actions/users';
import HasPermission from '../../components/HasPermission';
import { jobsPermissions } from '../../data/permissions';
import JobsPipeline from '../../components/jobs/JobsPipeline';

const tabs = [
  {
    path: '/jobs',
    tab: 'pipeline',
  },
  {
    path: '/jobs/list',
    tab: 'list',
  },
];

function Jobs() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { tab } = useParams();

  useEffect(() => {
    dispatch(getPipelinesRequest({ type: 'job' }));
    dispatch(getContactsRequest({ limit: 100 }));
    dispatch(getUsersRequest({ limit: 100 }));
  }, []);

  return (
    <Wrapper title="Jobs">
      <div className="leads">
        <PipelineTabs tabs={tabs} pipeline="job" />
        <HasPermission edit={jobsPermissions}>
          {tab === 'pipeline' || !tab
            ? <JobsPipeline />
            : null}
        </HasPermission>
        <HasPermission edit={jobsPermissions}>
          {tab === 'list' ? (
            <JobsList
              page={page}
              onPageChange={(k, v) => {
                if (k === 'page') {
                  setPage(v);
                } else {
                  setLimit(v);
                  setPage(1);
                }
              }}
              limit={limit}
            />
          ) : null}
        </HasPermission>
      </div>
    </Wrapper>
  );
}

export default Jobs;
