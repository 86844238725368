import React from 'react';
import PropTypes from 'prop-types';
// import html2canvas from 'html2canvas';
// import { jsPDF } from 'jspdf';
import InvoicePrint from './InvoicePrint';
import Api from '../../Api';

const PrintModal = ({ data }) => {
  const downloadPgf = async () => {
    // closeModal();
    //
    // const printNode = document.getElementById('divToPrint');
    // const buttons = document.getElementsByClassName('print__modal__header__btns__wrapper')?.[0];
    //
    // setLoading(true);
    //
    // buttons.style.display = 'none';
    //
    // await html2canvas(printNode, {
    //   scale: '2',
    // })
    //   .then((canvas) => {
    //     buttons.style.display = 'flex';
    //
    //     const imgWidth = 208;
    //     const pageHeight = 295;
    //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //     let heightLeft = imgHeight;
    //     let position = 0;
    //     heightLeft -= pageHeight;
    //
    //     const doc = new jsPDF('p', 'mm');
    //     doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
    //
    //     while (heightLeft >= 0) {
    //       position = heightLeft - imgHeight;
    //       doc.addPage();
    //       doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
    //       heightLeft -= pageHeight;
    //     }
    //
    //     doc.save('Invoice.pdf');
    //   });

    await Api.downloadInvoice({ jobId: data.jobId, invoiceId: data.id });
  };

  const print = () => {
    document.body.classList.add('print');
    window.print();

    document.body.classList.remove('print');
  };

  return (
    <div id="divToPrint">
      <InvoicePrint
        print={print}
        download={downloadPgf}
        data={data}
      />
    </div>
  );
};

PrintModal.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PrintModal;
