import { io } from 'socket.io-client';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Account from '../../helpers/Account';

const { REACT_APP_API_URL } = process.env;

let socket;

export const initSocket = createAsyncThunk('socket/initSocket', (payload, thunkAPI) => {
  if (socket) {
    return;
  }

  const token = Account.getToken();

  socket = io.connect(REACT_APP_API_URL, {
    transports: ['websocket'],
    query: {
      token: `Bearer ${token}`,
    },
  });

  socket.on('incoming-call', (data) => thunkAPI.dispatch(getIncomingCallSocket(data.data)));
  socket.on('call-event', (data) => thunkAPI.dispatch(getCallInfoSocket(data.data)));
});

export const getIncomingCallSocket = createAction('socket/getIncomingCallSocket');
export const getCallInfoSocket = createAction('socket/getCallInfoSocket');
