import { createReducer } from '@reduxjs/toolkit';
import { menuOpening } from '../actions/menu';

const initialState = {
  openedMenuId: null,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(menuOpening, (store, { payload }) => {
      store.openedMenuId = store.openedMenuId === payload ? null : payload;
    });
});

export default reducer;
