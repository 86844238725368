import React, {
  useRef, useEffect, useMemo, useCallback,
} from 'react';
import { Jodit } from 'jodit';
import 'jodit/build/jodit.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import account from '../../helpers/Account';
import useEventCallback from '../../helpers/hooks/useEventCallback';

const { REACT_APP_API_URL } = process.env;

export const addTextInHtmlString = (htmlString, text) => {
  const regex = /<\/([a-z0-9]*)>\s*$/;
  const match = htmlString.match(regex);

  if (match) {
    const lastClosingTag = match[0];

    return `${htmlString.substring(0, match.index)} ${text}&nbsp; ${lastClosingTag}`;
  }

  return `${htmlString + text}&nbsp;`;
};

const Editor = ({
  value, onChange, placeholder, error, preview,
}) => {
  const textAreaRef = useRef(null);
  const jodit = useRef(null);

  const config = useMemo(() => {
    const defaultConfig = {
      imageDefaultWidth: 150,
      readonly: false,
      enter: 'p',
      placeholder: 'Insert text here',
      disablePlugins: 'tab,symbols,stat,sticky,xpath,wrap-nodes,video,table-keyboard-navigation,table,spellcheck,speesourcech-recognize,select-cells,size,search,resize-handler,resize-cells,about,add-new-line',
      buttons: 'undo,redo, |, bold,italic,underline,strikethrough, |, paragraph,fontsize,lineHeight,brush,ul,ol,align,indent,outdent,hr, |, cut,copy,paste, image,link,source',
      buttonsMD: 'undo,redo, |, bold,italic,underline,strikethrough, |, paragraph,fontsize,lineHeight,brush,ul,ol,align,indent,outdent,hr, |, cut,copy,paste, image,link,source',
      buttonsSM: 'undo,redo, |, bold,italic,underline,strikethrough, |, paragraph,fontsize,lineHeight,brush,ul,ol,align,indent,outdent,hr, |, cut,copy,paste, image,link,source',
      buttonsXS: 'undo,redo, |, bold,italic,underline,strikethrough, |, paragraph,fontsize,lineHeight,brush,ul,ol,align,indent,outdent,hr, |, cut,copy,paste, image,link,source',
      tooltip: true,
      uploader: {
        url: `${REACT_APP_API_URL}/users/file/upload`,
        headers: {
          authorization: account.getToken(),
        },

        isSuccess(resp) {
          return !!resp.files?.[0];
        },
        getMessage(resp) {
          return resp.files?.[0] ? 'success' : 'error';
        },
        buildData(data) {
          const formData = new FormData();

          for (const d of data.entries()) {
            if (d?.[0]?.includes('files')) {
              formData.append('file[]', d[1]);
            }
          }

          return formData;
        },
        process(resp) {
          return {
            files: resp.files.map((f) => `${REACT_APP_API_URL}${f}`),
            baseurl: '',
            error: resp.error,
            msg: resp.message,
          };
        },
        defaultHandlerSuccess(data) {
          // eslint-disable-next-line react/no-this-in-sfc
          data.files.forEach((f) => this.s.insertImage(f, {}, 150));
        },
      },
    };

    if (preview) {
      defaultConfig.buttons += ',preview';
      defaultConfig.buttonsMD = defaultConfig.buttons;
      defaultConfig.buttonsSM = defaultConfig.buttons;
      defaultConfig.buttonsXS = defaultConfig.buttons;
    }

    if (placeholder) {
      defaultConfig.placeholder = placeholder;
    }

    return defaultConfig;
  }, []);

  const handleBlur = useEventCallback((ev) => {
    onChange(jodit.current.value, ev);
  }, [onChange, jodit.current]);

  useEffect(() => {
    if (textAreaRef?.current) {
      jodit.current = Jodit.make(textAreaRef.current, config);
      // makeJodit.events.on('change', (value) => onChange(value));
      jodit.current.events.on('blur', handleBlur);
    }

    return () => {
      if (jodit.current) {
        jodit.current.destruct();
        jodit.current?.events
          ?.off('blur', handleBlur);
      }
    };
  }, []);

  useEffect(() => {
    if (jodit.current.value !== value) {
      const updateValue = () => {
        jodit.current.value = value;
      };

      jodit.current?.isReady
        ? updateValue()
        : jodit.current?.waitForReady()?.then(updateValue);
    }
  }, [value]);

  return (
    <div className={classNames('jodit__wrapper', { error: !!error })}>
      <textarea style={{ display: 'none' }} ref={textAreaRef} />

      {error && <span className="errorText">{error}</span>}
    </div>
  );
};

Editor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  preview: PropTypes.bool,
  error: PropTypes.string,
};

Editor.defaultProps = {
  value: '',
  onChange: () => {
  },
  placeholder: '',
  preview: false,
  error: '',
};

export default Editor;
