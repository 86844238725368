import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import Input from '../form/Input';
import Utils from '../../helpers/Utils';
import { additionalDataKey } from '../../store/reducers/leads';
import { ReactComponent as RemoveIcon } from '../../assets/icons/remove.svg';
import FileInput from '../form/FileInput';
import Button from '../form/Button';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete_new.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { calcJobProfitRequest } from '../../store/actions/jobs';

function Quotations(props) {
  const { data, setData, errors } = props;
  const { jobId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const profit = useSelector((state) => state.jobs.profit);

  const handleSetCalculation = useCallback(async () => {
    setLoading(true);
    await dispatch(calcJobProfitRequest({ jobId }));
    setLoading(false);
  }, [jobId]);

  const getColor = useCallback((status) => ({
    pending: '#FF9900',
    partially_paid: '#FF9900',
    paid: '#00AF31',
    unpaid: '#F50000',
  })[status], []);

  return (
    <div className="details">
      {pathname.includes('job') ? (
        <div className="info">
          <div>
            <div className="title">
              Order confirmation
            </div>

            <div>
              <FileInput
                multiple
                onChange={(ev) => {
                  [...ev.target.files].forEach((f) => {
                    f._uri = URL.createObjectURL(f);
                  });
                  setData({
                    key: 'details.files', value: [...(data.details.files || []), ...ev.target.files],
                  });
                }}
                accept="application/pdf"
              >
                <UploadIcon />
                <span className="uploadFile">Upload</span>
              </FileInput>
              <div>
                {_.get(data, 'details.files', false) ? data.details.files.map((f, i) => (
                  <div className="fileRow" key={f.name || f}>
                    <div>
                      <FileIcon />
                      {!_.isEmpty(f)
                        ? (
                          <a href={f._uri || f} target="_blank" rel="noreferrer">
                            {f.name || f?.split('-')
                              .pop()}
                          </a>
                        ) : null}
                    </div>
                    <DeleteIcon
                      className="delete"
                      onClick={() => {
                        const value = data.details.files.filter((__, index) => index !== i);
                        setData({ key: 'details.files', value });
                      }}
                    />
                  </div>
                )) : null}
              </div>

            </div>

          </div>
          <div>
            {jobId ? (
              <div className="invoice">
                <div className="title">Invoice</div>
                <Button outlined title="Generate invoice" onClick={() => navigate(`/invoices/${jobId}/add`)} />
              </div>
            ) : null}
          </div>

        </div>
      ) : null}
      {jobId && !_.isEmpty(data.invoices)
        ? (
          <div className="table__wrapper leadsTable">

            <table
              style={{ minWidth: 165 }}
              className="table"
            >
              <thead className="thead">
              <tr className="tr">
                <th className="th">Invoice number</th>
                <th className="th">Company</th>
                <th className="th">Invoice date</th>
                <th className="th">Due date</th>
                <th className="th">Amount</th>
                <th className="th">Status</th>
              </tr>
              </thead>

              <tbody className="tbody">
              {data.invoices.map((d) => (
                <tr
                  className="tr"
                  key={d.id}
                >
                  <td className="td">
                    {d.invoiceNumber}
                  </td>
                  <td className="td">
                    {d.company}
                  </td>
                  <td className="td">
                    {moment(d.invoiceDate).format('MM/DD/YYYY')}
                  </td>
                  <td className="td">
                    {moment(d.dueDate).format('MM/DD/YYYY')}
                  </td>
                  <td className="td">
                    {`$${Utils.formatPrice(d.total)}`}
                  </td>
                  <td className="td" style={{ color: getColor(d.status) }}>
                    {_.startCase(d.status)}
                  </td>

                </tr>
              ))}
              </tbody>
            </table>
          </div>
        )
        : null}
      <div className="additionalExpenses">
        <div className="title">
          Additional expenses
        </div>
        <div className="additionalExpenses">
          <div className="table">
            <table>
              <thead>
              <tr>
                <th>Service / Materials</th>
                <th>Quantity</th>
                <th>Unit Cost</th>
                <th>Line Total</th>
                <th style={{ width: 30 }} />
              </tr>
              </thead>
              <tbody>
              {data.quotation?.detail?.map((a, i) => (
                <tr key={a[additionalDataKey]}>
                  <td>
                    <Input
                      placeholder="Your text here"
                      value={a.text}
                      onChange={(ev) => {
                        setData({ key: `quotation.detail[${i}].text`, value: ev.target.value });
                      }}
                      error={_.get(errors, `quotation.detail[${i}].text`, '') ? 'Field is required' : ''}
                    />
                  </td>
                  <td>
                    <Input
                      type="number"
                      value={a.qty || ''}
                      onChange={(ev) => {
                        setData({ key: `quotation.detail[${i}].qty`, value: ev.target.value });
                      }}
                      error={_.get(errors, `quotation.detail[${i}].qty`, '') ? 'Field is required' : ''}
                    />
                  </td>
                  <td>
                    <Input
                      type="number"
                      value={a.price || ''}
                      onChange={(ev) => {
                        setData({ key: `quotation.detail[${i}].price`, value: ev.target.value });
                      }}
                      error={_.get(errors, `quotation.detail[${i}].price`, '') ? 'Field is required' : ''}
                    />
                  </td>
                  <td className="total">
                    {a.price ? `$${Utils.formatPrice(a.qty * a.price)}` : null}
                  </td>
                  <td className="remove">
                    <div onClick={() => {
                      const newData = data.quotation.detail.filter((__, index) => i !== index);
                      setData({ key: 'quotation.detail', value: newData });
                    }}
                    >
                      <RemoveIcon />
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>

            </table>
            <div
              className="add"
              onClick={() => setData(
                {
                  key: `quotation.detail[${data.quotation?.detail.length || 0}]`,
                  value: { [additionalDataKey]: _.uniqueId('additionalDataKey'), text: '' },
                },
              )}
            >
              <AddIcon />
              <p>Add new line</p>
            </div>
          </div>
        </div>
        {jobId ? <Button title="Calculate" onClick={handleSetCalculation} loading={loading} /> : null}
        {!_.isNull(profit) ? (
          <div className="profit">
            <p className="label">Profit of this job:</p>
            <p className="value">{`$${Utils.formatPrice(profit) || 0}`}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Quotations;

Quotations.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};
