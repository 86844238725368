import React, { useCallback, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import Input from '../form/Input';
import Button from '../form/Button';
import RangeDatePicker from '../form/RangeDatePicker';
import Utils from '../../helpers/Utils';

const InvoiceFilterModal = ({
  fields, saveFilters, closeModal, defaultFilters,
}) => {
  const [filters, setFilters] = useState({});
  const [saveLoading, saveLoadingToggle] = useState(false);
  const [cancelLoading, cancelLoadingToggle] = useState(false);

  const { limit } = filters;

  useLayoutEffect(() => {
    setFilters(defaultFilters);
  }, []);

  const changeFilters = useCallback((path, value) => {
    setFilters((prev) => ({ ...prev, [path]: value }));
  }, []);

  const save = useCallback(async (e) => {
    e.preventDefault();

    saveLoadingToggle(true);
    await saveFilters(filters);

    saveLoadingToggle(false);
    closeModal();
  }, [filters]);

  const cancel = useCallback(async () => {
    cancelLoadingToggle(true);

    await saveFilters({
      limit,
      page: 1,
      s: '',
      invoiceDate: [moment().subtract(14, 'day').format('yyyy-MM-DD'), moment().format('yyyy-MM-DD')],
      dueDate: [],
      total: [],
    });

    cancelLoadingToggle(false);
    closeModal();
  }, [limit]);

  const onKeyPress = useCallback((e, isPaste = false) => {
    Utils.keyPressCurrency(e, isPaste);
  }, []);

  return (
    <form onSubmit={save}>
      {fields.map(({
        title, placeholder, path, type, valuePath,
      }) => (
        <div key={title} className="new__contact__input__wrapper">
          {type === 'date'
            ? (
              <RangeDatePicker
                label={title}
                date={filters[valuePath || path]}
                setDate={(date) => changeFilters(valuePath || path, date)}
              />
            )

            : type === 'range'
              ? (
                <div className="range__fields">
                  <Input
                    label={title}
                    placeholder="From"
                    value={filters[valuePath || path]?.[0] || ''}
                    currency={path === 'total' ? '$' : ''}
                    onChange={({ target: { value } }) => {
                      changeFilters(valuePath || path, filters[valuePath || path]?.[1]?.trim()
                        ? [value, filters[valuePath || path][1]]
                        : [value]);
                    }}
                    onKeyPress={onKeyPress}
                    onPaste={(e) => onKeyPress(e, true)}
                  />

                  <span> - </span>

                  <Input
                    label={' '}
                    placeholder="To"
                    value={filters[valuePath || path]?.[1] || ''}
                    currency={path === 'total' ? '$' : ''}
                    onChange={({ target: { value } }) => {
                      changeFilters(valuePath || path, [filters[valuePath || path]?.[0] || '', value]);
                    }}
                    onKeyPress={onKeyPress}
                    onPaste={(e) => onKeyPress(e, true)}
                  />
                </div>
              )

              : (
                <Input
                  label={title}
                  placeholder={placeholder}
                  value={filters[valuePath || path]}
                  currency={path === 'total' ? '$' : ''}
                  onChange={({ target: { value } }) => changeFilters(valuePath || path, value)}
                />
              )}
        </div>
      ))}

      <div className="new__users__buttons__wrapper">
        <div className="new__contact__buttons__wrapper">
          <Button
            title="Apply filters"
            loading={saveLoading}
            type="submit"
          />

          <Button
            title="Cancel"
            noBorder
            type="button"
            loading={cancelLoading}
            onClick={cancel}
          />
        </div>
      </div>
    </form>
  );
};

InvoiceFilterModal.propTypes = {
  fields: PropTypes.array.isRequired,
  defaultFilters: PropTypes.object.isRequired,
  saveFilters: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default InvoiceFilterModal;
