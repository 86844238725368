import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { ReactComponent as DragIcon } from '../../assets/icons/drag.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete_new.svg';
import {
  addPipelineLocal,
  changePipelineLocal,
  deletePipelineRequest,
  getPipelinesRequest, getPipelineStatusesRequest,
  orderPipelineLocal,
} from '../../store/actions/pipelines';
import Input from '../form/Input';
import Select from '../form/Select';
import DeleteModal from '../modal/DeleteModal';
import HasPermission from '../HasPermission';
import usePermission from '../../helpers/hooks/usePermission';

function ColumnsDrag(props) {
  const { pipeline } = props;
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hovered, setHovered] = useState(null);

  const dispatch = useDispatch();
  const orderPipelineAllow = usePermission('order_pipeline');

  const pipelines = useSelector((state) => state.pipelines.pipelines[pipeline]);
  const pipelineStatuses = useSelector((state) => state.pipelines.pipelineStatuses[pipeline]);

  useEffect(() => {
    dispatch(getPipelineStatusesRequest({ type: pipeline }));
  }, [pipeline]);
  const onDragEnd = useCallback(
    (ev) => {
      dispatch(orderPipelineLocal({ type: pipeline, oldInd: ev.source.index, newInd: ev.destination.index }));
    },
    [pipeline],
  );

  const handleClick = useCallback((index) => {
    const data = [...pipelines];
    data.splice(index, 0, { name: `${_.upperFirst(pipeline)} title`, status: '', id: _.uniqueId('pipeline_col') });
    dispatch(addPipelineLocal({ type: pipeline, data }));
  }, [pipelines, pipeline]);

  const handleDelete = useCallback(async () => {
    setLoading(true);
    if (!id.toString().startsWith('pipeline_col')) {
      const { payload: { data } } = await dispatch(deletePipelineRequest(id));
      if (data.status === 'error') {
        toast.error(`This stage cannot be deleted as there are ${_.upperFirst(pipeline)}s associated with this status.`);
      } else {
        await dispatch(getPipelinesRequest({ type: pipeline }));
      }
    } else {
      const data = [...pipelines].filter((p) => p.id !== id);
      dispatch(addPipelineLocal({ type: pipeline, data }));
    }

    setLoading(false);
    setId(null);
  }, [id, pipeline]);

  return (
    <div className="columnsDrag">
      <HasPermission edit="get_pipelines" read="get_pipelines_readonly">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="cols"
              >
                {pipelines.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id.toString()}
                    index={index}
                    isDragDisabled={!orderPipelineAllow}
                  >
                    {(p) => (
                      <div
                        ref={p.innerRef}
                        {...p.draggableProps}
                        {...p.dragHandleProps}
                        onMouseEnter={() => setHovered(item.id)}
                        onMouseLeave={() => setHovered(null)}
                      >
                        <div className="col">
                          <div className="main">
                            <div className="border" />
                            <span>{item.name}</span>
                            <DragIcon />
                          </div>
                          <HasPermission edit="create_pipelines" read="create_pipelines_readonly">
                            {index + 1 < pipelines.length || pipelines.length === 1
                              ? (
                                <button
                                  className="addButton"
                                  onClick={() => handleClick(index)}
                                  style={{ display: index === 0 || hovered === item.id ? 'flex' : 'none' }}
                                >
                                  <AddIcon />
                                </button>
                              )
                              : null}
                          </HasPermission>
                        </div>
                        <div className="colContainer">
                          <div className="colData">
                            <div>
                              <Input
                                label="Title"
                                onChange={(ev) => dispatch(changePipelineLocal({
                                  type: pipeline,
                                  key: `[${index}].name`,
                                  value: ev.target.value,
                                }))}
                                value={item.name}
                                maxLength={24}
                              />
                            </div>
                            <HasPermission edit="delete_pipelines" read="delete_pipelines_readonly">
                              <div className="delete" onClick={() => setId(item.id)}>
                                <DeleteIcon />
                                <span>Delete stage</span>
                              </div>
                            </HasPermission>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </HasPermission>
      <DeleteModal
        loading={loading}
        open={!!id}
        text="Are you sure want to delete the list?"
        onClose={() => setId(null)}
        onDelete={handleDelete}
      />
    </div>
  );
}

export default ColumnsDrag;
ColumnsDrag.propTypes = {
  pipeline: PropTypes.string,
};
ColumnsDrag.defaultProps = {
  pipeline: 'lead',
};
