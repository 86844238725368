import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

function Input(props) {
  const {
    label, type, onChange, value, leftIcon, rightIcon, error, placeholder, currency, className, mask, ...restProps
  } = props;
  const [id] = useState(_.uniqueId('input'));

  return (
    <div className={classNames(`inputWrapper ${className}`, { error: !!error })}>
      {leftIcon ? (
        <span className="leftIcon">
          {leftIcon}
        </span>
      ) : null}
      {label ? <label htmlFor={id}>{label}</label> : null}
      {currency && value ? <span className="currency">{currency}</span> : null}
      {mask
        ? (
          <InputMask
            type={type}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            mask={mask}
            {...restProps}
          />
        )
        : (
          <input
            type={type}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            {...restProps}
          />
        )}
      {rightIcon ? <span className="rightIcon">{rightIcon}</span> : null}
      {error ? <span className="errorText">{error}</span> : null}
    </div>
  );
}

export default Input;
Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  leftIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  rightIcon: PropTypes.element,
  placeholder: PropTypes.string,
  currency: PropTypes.string,
  className: PropTypes.string,
  mask: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input.defaultProps = {
  label: '',
  type: 'text',
  value: '',
  placeholder: '',
  currency: '',
  mask: '',
  className: '',
  leftIcon: undefined,
  rightIcon: undefined,
  onChange: () => {
  },
  error: false,
};
