import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { getEmailsRequest } from '../../store/actions/pipelines';
import Loader from '../loader/Loader';
import Pagination from '../pagination/Pagination';
import { ReactComponent as RealoadIcon } from '../../assets/icons/reload.svg';

function Emails(props) {
  const { leadId, jobId } = useParams();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const emails = useSelector((state) => state.pipelines.emails);

  const id = leadId || jobId;

  useEffect(() => {
    if (id) {
      (async () => {
        setLoading(true);
        await dispatch(getEmailsRequest({
          id, pipeline: props.pipeline, page, limit,
        }));
        setLoading(false);
      })();
    }
  }, [id, page, limit]);

  const reloadData = useCallback(async () => {
    if (id) {
      setReload(true);
      setPage(1);
      await dispatch(getEmailsRequest({
        id, pipeline: props.pipeline, page: 1, limit,
      }));
      setReload(false);
    }
  }, [id, limit]);

  const groupedEmails = useMemo(() => _.groupBy(emails.emails, (l) => moment(l.createdAt)
    .format('MM.DD.YYYY')), [emails]);

  return (
    <>
      <div className="reloadIcon"><RealoadIcon className={reload ? 'loading' : null} onClick={reloadData} /></div>

      <div className="emails">
        {_.isEmpty(emails) && !loading ? <div>No Emails Yet</div> : null}
        {_.isEmpty(groupedEmails) && loading ? <Loader /> : null}
        {!_.isEmpty(emails) && !loading ? (
          <div className="list">
            {_.map(groupedEmails, (em, date) => (
              <div key={date}>
                <div className="groupDateContainer">
                  <div className="groupDate">
                    {date === moment().format('MM.DD.YYYY') ? 'Today' : date}
                  </div>
                </div>
                {em.map((e) => {
                  const isOutgoing = e.from.includes('fusionwindows.com');
                  const emailData = {
                    destination: isOutgoing ? e.to : e.from,
                    infoText: isOutgoing ? 'Outgoing email to' : 'Incoming email from',
                  };
                  return (
                    <div key={e.id} className="item">
                      <div>

                        <p className="date">
                          {moment(e.date).format('MM.DD.YYYY hh:mm')}
                        </p>
                        <p className="info">
                          {emailData.infoText}
                          {' '}
                          <span className="from">{emailData.destination}</span>
                        </p>
                        <div className="emailText" dangerouslySetInnerHTML={{ __html: e.text }} />
                      </div>
                      <p
                        className="view"
                        onClick={() => {
                          props.setEmailId(e.messageId);
                          searchParams.set('dest', emailData.destination);
                          setSearchParams(searchParams);
                        }}
                      >
                        View
                      </p>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        ) : null}

      </div>
      {emails.totalPages && !loading ? (
        <Pagination
          page={page}
          totalPages={emails.totalPages}
          limit={limit}
          onChange={(k, v) => {
            if (k === 'page') {
              setPage(v);
            } else {
              setLimit(v);
              setPage(1);
            }
          }}
        />
      ) : null}
    </>
  );
}

export default Emails;

Emails.propTypes = {
  pipeline: PropTypes.string.isRequired,
  setEmailId: PropTypes.func.isRequired,
};
