import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as PrintIcon } from '../../assets/icons/print.svg';
import Loader from '../loader/Loader';

const header = [
  {
    title: 'Service/Material',
    path: 'text',
  },
  {
    title: 'Qty',
    path: 'qty',
  },
  {
    title: 'Unit Cost',
    path: 'cost',
  },
  {
    title: 'Total',
    path: 'amount',
  },
];
const formattingValue = (key, value) => {
  const currencyKeys = ['cost', 'amount'];

  if (currencyKeys.includes(key)) {
    return `$${value}`;
  }

  return typeof value === 'number' ? value : (value || '-');
};

const formattingTax = (type, value) => (type === 'percent' ? `${value}%` : `$${value}`);

const InvoicePrint = ({
  data, print, download, hideButtons,
}) => {
  const [loading, setLoading] = useState(false);
  const downloadPdf = async () => {
    setLoading(true);
    await download();
    setLoading(false);
  };

  return (
    <div className="print__modal__content">
      <div className="print__modal__header">
        <div className="print__modal__header__title">
          <h2>Invoice</h2>

          <span>{`No: #${data.invoiceNumber}`}</span>
        </div>

        {!hideButtons && (
          <div className="print__modal__header__btns__wrapper">
            <button
              disabled={loading}
              onClick={downloadPdf}
            >
              {loading
                ? <Loader size={16} borderWidth={3} color="#356966" />

                : <DownloadIcon />}

              Download invoice
            </button>

            <button onClick={print}>
              <PrintIcon />

              Print invoice
            </button>
          </div>
        )}
      </div>

      <div className="print__modal__dates">
        <div className="print__modal__invoice__date">
          <span>Invoice Date</span>

          {moment(data.date).format('MM/DD/yyyy')}
        </div>

        <div className="print__modal__invoice__date">
          <span>Due Date</span>

          {moment(data.dueDate).format('MM/DD/yyyy')}
        </div>
      </div>

      <div className="print__modal__info">
        <h3>Fusion Windows</h3>

        <div className="print__modal__info__address__wrapper">
          <div>
            712 Saint James Drive, Lancaster, Pennsylvania, 17602
          </div>

          <div>
            username@email.com
          </div>

          <div>
            717-437-7553
          </div>
        </div>

        <div className="print__modal__details__wrapper">
          <div className="print__modal__details">
            <div className="print__modal__details__title">
              Customer Details
            </div>

            <div>{data.customer?.name}</div>
            <div>{data.customer?.email}</div>
            <div>{data.customer?.phone}</div>
          </div>

          <div className="print__modal__details">
            <div className="print__modal__details__title">
              Billing Address
            </div>

            <div>
              {_.compact([
                data.billingAddress?.address, data.billingAddress?.city,
                data.billingAddress?.state, data.billingAddress?.zip,
              ]).join(', ')}
            </div>
            <div>{data.billingAddress?.email}</div>
            <div>{data.billingAddress?.phone}</div>
          </div>
        </div>

        <div className="print__modal__table__wrapper">
          <table
            className="table"
          >
            <thead className="thead">
              <tr className="tr">
                <th style={{ color: '#687076' }} className="th">#</th>

                {header.map((h) => (
                  <th className="th" key={h.title}>{h.title}</th>
                ))}
              </tr>
            </thead>

            <tbody className="tbody">
              {data.services?.map((s, i) => (
                <tr
                  className="tr"
                  key={i}
                >
                  <td className="td">{i + 1}</td>

                  {header.map((h) => (
                    <td
                      className="td"
                      key={h.title}
                    >
                      {formattingValue(h.path, _.get(s, h.path))}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="print__modal__total__wrapper">
          <div>
            <div>Subtotal:</div>

            <div>{`$${data.subTotal}`}</div>
          </div>

          {data.storageFee && (
            <div>
              <div>Storage fee:</div>

              <div>{`$${data.storageFee}`}</div>
            </div>
          )}

          <div>
            <div>Tax:</div>

            <div>{formattingTax(data.taxType, data.tax)}</div>
          </div>

          <div>
            <div>Discount:</div>

            <div>{formattingTax(data.discountType, data.discount)}</div>
          </div>

          <div className="total">
            <div>Total:</div>

            <div>{`$${data.total}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

InvoicePrint.propTypes = {
  data: PropTypes.object.isRequired,
  print: PropTypes.func,
  download: PropTypes.func,
  hideButtons: PropTypes.bool,
};

InvoicePrint.defaultProps = {
  print: () => {},
  download: () => {},
  hideButtons: false,
};

export default InvoicePrint;
