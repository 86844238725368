import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const addActivityNoteRequest = createAsyncThunk('notes/addActivityNoteRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.addActivityNote(payload);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const getActivityNotesRequest = createAsyncThunk('notes/getActivityNotesRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.getActivityNotes(payload);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const deleteActivityNoteRequest = createAsyncThunk('notes/deleteActivityNoteRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.deleteActivityNote(payload);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});
