import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Input from '../form/Input';
import Button from '../form/Button';
import Select from '../form/Select';
import { addJobRequest, getJobsRequest } from '../../store/actions/jobs';
import { getUsersRequest } from '../../store/actions/users';
import { getContactsRequest } from '../../store/actions/contacts';
import Utils from '../../helpers/Utils';
import AddUserModal from '../users/AddUserModal';
import AddContactModal from '../contacts/AddContactModal';

function AddQuickJob(props) {
  const { onClose, status } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({});

  const [form, setForm] = useState({
    email: '',
    phone: '',
    status,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [contactValue, setContactValue] = useState('');
  const [resPersonValue, setResPersonValue] = useState('');

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.account.profile);
  const users = useSelector((state) => state.users.usersList);
  const contactsList = useSelector((state) => state.contacts.contactList);

  const resPersons = useMemo(() => {
    let persons = users.map((u) => ({
      ...u,
      value: u.id,
      label: `${u.firstName} ${u.lastName}`,
    }));
    if (resPersonValue) {
      persons = [...persons, { value: '_create', label: `Create "${resPersonValue}"` }];
    }

    return persons;
  }, [users, resPersonValue]);

  const contacts = useMemo(() => {
    let persons = contactsList.map((u) => ({
      ...u,
      value: u.id,
      label: u.name,
    }));
    if (contactValue) {
      persons = [...persons, { value: '_create', label: `Create "${contactValue}"` }];
    }

    return persons;
  }, [contactsList, contactValue]);


  useEffect(() => {
    const rpId = searchParams.get('rp-id');
    const cId = searchParams.get('c-id');
    if (rpId) {
      (async () => {
        await dispatch(getUsersRequest({ limit: 100 }));
        handleChange('responsiblePerson', +rpId);
      })();
    }
    if (cId) {
      (async () => {
        const { payload: { data } } = await dispatch(getContactsRequest({ limit: 100 }));
        const contact = data.contacts.find((c) => c.id === +cId);
        handleContactChange(contact);
      })();
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(getUsersRequest({ limit: 100 }));
    dispatch(getContactsRequest({ limit: 100 }));
  }, []);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleAddLead = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const { payload: { data } } = await dispatch(addJobRequest(form));
    if (data.status === 'ok') {
      await dispatch(getJobsRequest());
      onClose();
    } else if (data.status === 'error' && data.errors) {
      setErrors(Utils.normalizeErrors(data.errors));
    } else {
      toast.error(data.message);
    }

    setLoading(false);
  }, [form]);

  const handleContactChange = useCallback((v) => {
    handleChange('contact', v?.id);
    handleChange('phone', v?.phone);
    handleChange('email', v?.email);
    handleChange('company', v?.company || undefined);
    handleChange('address', v?.address || undefined);
    handleChange('responsiblePerson', v?.responsibleUsers[0] || profile.id);
  }, [profile]);


  return (
    <>
      <form className="addLead" onSubmit={handleAddLead}>
        <Select
          placeholder="Name"
          options={contacts}
          onInputChange={(v) => setContactValue(v)}
          onChange={(val) => {
            if (val?.value === '_create') {
              navigate(`?create=contact&from=lead&val=${contactValue}`);
            } else {
              handleContactChange(val);
            }
          }}
          value={contacts.filter((c) => c.id?.toString() === form.contact?.toString())}
          error={errors.contact}
          isClearable
        />
        <Input
          placeholder="Company name"
          value={form.company}
          onChange={(ev) => handleChange('company', ev.target.value)}
          error={errors.company}
        />
        <Input
          placeholder="Phone number"
          value={form.phone}
          onChange={(ev) => handleChange('phone', ev.target.value)}
          error={errors.phone}
          mask="+1 999-999-9999"
        />
        <Input
          placeholder="Email"
          value={form.email}
          onChange={(ev) => handleChange('email', ev.target.value)}
          error={errors.email}
        />
        <Select
          placeholder="Responsible person"
          options={resPersons}
          filterOption={(o, val) => o.label.toLowerCase().includes(val.toLowerCase()) || o.value === '_create'}
          onInputChange={(v) => setResPersonValue(v)}
          onChange={(val) => {
            if (val?.value === '_create') {
              navigate('?create=user&from=lead');
            } else {
              handleChange('responsiblePerson', val?.id);
            }
          }}
          value={resPersons.filter((u) => u.id === form.responsiblePerson)}
          error={errors.responsiblePerson}
          isClearable
        />
        <div className="actions">
          <Button title="Add" type="submit" loading={loading} />
          <Button title="Cancel" noBorder onClick={onClose} />
        </div>
      </form>
      <AddUserModal />
      <AddContactModal />
    </>
  );
}

export default AddQuickJob;

AddQuickJob.propTypes = {
  onClose: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};
