import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Select from '../form/Select';
import Button from '../form/Button';
import { createAutomationRequest, getAutomationsListRequest } from '../../store/actions/automation';
import Utils from '../../helpers/Utils';
import Conditions from './Conditions';
import Input from '../form/Input';
import Editor from '../form/Editor';
import ExecuteDropdown from './ExecuteDropdown';
import AutomationWrapper from './AutomationWrapper';
import { pipelineTriggers, dueDates, sentToOptions } from '../../data/options';
import { getEmailTemplatesRequest } from '../../store/actions/templates';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/remove.svg';

function AddEmail(props) {
  const { pipeline } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [execute, setExecute] = useState(false);
  const [scheduleTrigger, setScheduleTrigger] = useState(null);
  const [form, setForm] = useState({
    pipeline,
    action: 'sendEmail',
    actionStatus: searchParams.get('status'),
    email: { templates: [{ id: _.uniqueId('template_') }] },
  });

  const [condition, setCondition] = useState([{
    id: _.uniqueId(),
  }]);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const email = useSelector((state) => state.templates.email.list);
  const automationList = useSelector((state) => state.automation.automationList[pipeline]);
  const automationId = searchParams.get('_id');

  useEffect(() => {
    const automation = automationList?.find((a) => a.id === +automationId);
    if (automation) {
      const data = {
        when: automation.when,
        id: automationId,
        email: {
          sendTo: automation.data[0].sendTo,
          sender: automation.data[0].sender,
          subject: automation.data[0].subject,
          text: automation.data[0].text,
          templates: automation.data.map((d) => ({
            id: d.templateId,
            date: dueDates.find((due) => due.dueDate === d.dateType)?.value,
            dateType: d.dateType,
          })),
        },
      };
      if (!_.isEmpty(automation.condition)) {
        const con = [];
        _.forEach(automation.condition, (val, op) => {
          val.forEach((cd) => {
            con.push({
              operator: op,
              condition: Object.keys(cd)[0],
              id: _.uniqueId(),
              ...Object.values(cd)[0],
            });
          });
        });
        setCondition(con);
      }

      setForm({ ...form, ...data });
    }
  }, [automationId, automationList]);

  useEffect(() => {
    dispatch(getEmailTemplatesRequest());
  }, []);

  const handleDeleteCondition = useCallback((id) => {
    let con = condition.filter((c) => c.id !== id);
    if (!con.length) {
      con = [{ id: _.uniqueId() }];
    }
    setCondition(con);
  }, [condition]);

  const handleClose = useCallback(() => {
    const query = { ...Object.fromEntries(searchParams) };
    delete query.action;
    delete query.id;
    delete query._id;
    delete query.status;
    setSearchParams(query, { replace: true });
  }, [searchParams]);

  const handleConditionChange = useCallback((path, val) => {
    _.set(condition, path, val);
    setCondition([...condition]);
  }, [condition]);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const con = {};
    condition.forEach((c) => {
      if (c.operator === '$or') {
        con.$or = [...(con.$or || []), { [c.condition]: { $in: c.$in } }];
      }
      if (c.operator === '$and') {
        con.$and = [
          ...(con.$and || []),
          { [c.condition]: c.condition === 'sale' ? { $gte: c.$gte, $lte: c.$lte } : { $in: c.$in } },
        ];
      }
    });

    form.condition = con;
    const { payload: { data } } = await dispatch(createAutomationRequest(form));

    if (data.status === 'error') {
      setErrors(Utils.normalizeErrors(data.errors));
    }
    if (data.status === 'ok') {
      setSearchParams({});
      toast.success(`Automation successfully ${form.id ? 'updated' : 'added'}.`);
      await dispatch(getAutomationsListRequest(pipeline));
    }
    setLoading(false);
  }, [form, searchParams, condition]);

  const addTemplate = useCallback((i) => {
    _.set(form.email, `templates[${i}]`, { id: _.uniqueId('template_') });
    setForm({ ...form });
  }, [form.email.templates]);

  const deleteTemplate = useCallback((id) => {
    form.email.templates = form.email.templates.filter((t) => t.id !== id);
    setForm({ ...form });
  }, [form.email.templates]);

  return (
    <div className="addEmailContainer addAutomationContainer">
      <AutomationWrapper onSubmit={handleSubmit}>
        <div>
          <Input
            placeholder="Title"
            value={form.email.subject}
            error={errors.subject}
            onChange={(ev) => handleChange('email.subject', ev.target.value)}
          />
          {form.email.templates.map((t, i) => (
            <div key={t.id}>
              <Select
                options={email}
                placeholder="Template"
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.id}
                onChange={(val) => handleChange(`email.templates[${i}].id`, val?.id)}
                value={email.find((e) => t.id === e.id)}
                isOptionDisabled={(o) => form.email.templates.some((temp) => temp.id === o.id)}
                isClearable
              />
              {!t.id?.toString().startsWith('template') ? (
                <>
                  <div onClick={() => setScheduleTrigger(!scheduleTrigger ? t.id : null)}>
                    <Select
                      placeholder="Due Date"
                      isDisabled
                      error={errors.dateType || errors.when ? 'Date is required.' : undefined}
                      value={dueDates.find((d) => d.dueDate === t.dateType)}
                    />
                  </div>
                  {scheduleTrigger === t.id ? (
                    <ExecuteDropdown
                      form={form}
                      onChange={(k, v) => {
                        handleChange(k, v);
                      }}
                      onClose={() => setScheduleTrigger(null)}
                      automation={`email.templates[${i}]`}
                      scheduledTriggers
                      pipelineTriggers={false}
                    />
                  ) : null}
                </>
              ) : null}
              {i > 0 ? (
                <div className="deleteIcon" onClick={() => deleteTemplate(t.id)}>
                  <DeleteIcon />
                </div>
              ) : null}
              <div className="border" />
            </div>
          ))}
          {form.email.templates.length < email.length
            ? (
              <div onClick={() => addTemplate(form.email.templates.length)} className="addNewLineButton">
                <AddIcon />
                <p>Add template</p>
              </div>
            ) : null}
          <div onClick={() => setExecute(!execute)}>
            <Select
              placeholder="Execute"
              isDisabled
              error={_.get(errors.email, 'template[0].date') || errors.when ? 'Execute is required.' : undefined}
              value={pipelineTriggers.find((p) => p.value === form.when)}
            />
          </div>
          {execute ? (
            <ExecuteDropdown
              form={form}
              onChange={(key, value) => {
                handleChange(key, value);
                setExecute(false);
              }}
              onClose={() => setExecute(false)}
              automation="email"
              scheduledTriggers={false}
            />
          ) : null}
          <Select
            placeholder="Send to"
            options={sentToOptions}
            onChange={(v) => handleChange('email.sendTo', v?.value)}
            value={sentToOptions.find((o) => o.value === form.email.sendTo)}
            isClearable
            error={errors?.email?.sendTo ? 'Send to is required.' : undefined}
          />
          <Select
            placeholder="Sender"
            options={[{ value: 'admin', label: 'Admin' }]}
            onChange={(v) => handleChange('email.sender', v?.value)}
            value={[{ value: 'admin', label: 'Admin' }].find((o) => o.value === form.email.sender)}
            isClearable
          />

          <Conditions
            condition={condition}
            onChange={handleConditionChange}
            onDeleteCondition={handleDeleteCondition}
          />

          <div className="actions">
            <Button title="Save" type="submit" loading={loading} />
            <Button title="Cancel" noBorder onClick={handleClose} />
          </div>
        </div>
      </AutomationWrapper>
    </div>
  );
}

export default AddEmail;

AddEmail.propTypes = {
  pipeline: PropTypes.string,
};

AddEmail.defaultProps = {
  pipeline: 'lead',
};
