import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Wrapper from '../components/Wrapper';
import Input from '../components/form/Input';
import Button from '../components/form/Button';
import { unsubscribeFromEmailsRequest } from '../store/actions/account';

function Unsubscribe() {
  const [searchParams] = useSearchParams({});
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const initEmail = searchParams.get('email');
  const dispatch = useDispatch();

  useEffect(() => {
    if (initEmail) {
      setEmail(initEmail);
    }
  }, [initEmail]);

  const handleUnsubscribe = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const { payload: { data } } = await dispatch(unsubscribeFromEmailsRequest(email));
    if (data?.status === 'error') {
      if (data.errors) {
        toast.error(_.get(data.errors, '[0].message', data.message));
      } else {
        toast.error(data.message);
      }
    } else {
      toast.success('Successfully unsubscribed!');
    }
    setLoading(false);
  }, [email]);

  return (
    <Wrapper title="Unsubscribe">
      <div className="unsubscribeContainer">
        <form onSubmit={handleUnsubscribe} className="unsubscribe">
          <h3>Unsubscribe</h3>
          <Input value={email} onChange={(ev) => setEmail(ev.target.value)} placeholder="Email" />
          <Button title="Unsubscribe" loading={loading} type="submit" />
        </form>
      </div>
    </Wrapper>
  );
}

export default Unsubscribe;
