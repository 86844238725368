import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getJobsRequest = createAsyncThunk('jobs/getJobsRequest', async (payload = {}) => {
  const { path, ...reqData } = payload;
  const { data } = await Api.getJobs(reqData);
  return { data, path };
});

export const deleteJobRequest = createAsyncThunk('jobs/deleteJobRequest', async (payload) => {
  const { data } = await Api.deleteJob(payload);
  return data;
});

export const setJobData = createAction('jobs/setJobData');

export const unsetJobFormErrors = createAction('jobs/unsetJobFormErrors');

export const addJobRequest = createAsyncThunk('jobs/addJobRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.addJob(payload);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const deleteJobNoteRequest = createAsyncThunk('jobs/deleteJobNoteRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.deleteJobNote(payload);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const getSingleJobRequest = createAsyncThunk('jobs/getSingleJobRequest', async (payload) => {
  const { data } = await Api.getSingleJob(payload);
  return data;
});

export const calcJobProfitRequest = createAsyncThunk('jobs/calcJobProfitRequest', async (payload) => {
  const { data } = await Api.calcJobProfit(payload);
  return data;
});

export const deleteFilesRequest = createAsyncThunk('jobs/deleteFilesRequest', async (payload) => {
  const { data } = await Api.deleteFiles(payload);
  return data;
});

export const sendJobEmailRequest = createAsyncThunk('jobs/sendJobEmailRequest', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.sendJobEmail(payload);
    return { data };
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const removeJobData = createAction('jobs/removeJobData');
