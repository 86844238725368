import React from 'react';
import classNames from 'classnames';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Utils from '../../helpers/Utils';
import Button from '../form/Button';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone_new.svg';

function IncomingCallDetail(props) {
  const { call, onAnswer, onReject } = props;
  const [searchParams] = useSearchParams({});
  const navigate = useNavigate();

  return (
    <div className={classNames('incomingCall', { isContact: call.leadExist })} key={call.callId}>
      <div className="callHeader">
        <PhoneIcon />
        <p>Incoming Call</p>
      </div>
      <div className="info">
        <p>{call.contact?.name ? call.contact.name : Utils.formatPhoneNumber(call.content.number)}</p>
      </div>
      <div className="actions">
        <div className="icon answer" onClick={() => onAnswer(call.callId)}>
          <PhoneIcon />
        </div>
        <div className="icon endCall" onClick={() => onReject(call.callId)}>
          <PhoneIcon />
        </div>
      </div>
      {!call.leadExist && searchParams.get('from') !== 'call' ? (
        <div className="addLeadButton">
          <Button
            title="Add lead"
            onClick={() => navigate(`/leads/create?from=call&phone=${call.content.number}`)}
          />
        </div>
      ) : null}
    </div>
  );
}

export default IncomingCallDetail;

IncomingCallDetail.propTypes = {
  call: PropTypes.object.isRequired,
  onAnswer: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};
