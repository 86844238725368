import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Select from '../form/Select';
import { conditions as conditionsList } from '../../data/options';
import Input from '../form/Input';
import { getUsersRequest } from '../../store/actions/users';
import { getContactsRequest } from '../../store/actions/contacts';
import { getLeadSourcesRequest } from '../../store/actions/leads';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/remove.svg';

const operatorOptions = [
  { label: 'Or', value: '$or' },
  { label: 'And', value: '$and' },
];

function Conditions(props) {
  const { onChange, condition, onDeleteCondition } = props;

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.usersList);
  const contacts = useSelector((state) => state.contacts.contactList);
  const leadSources = useSelector((state) => state.leads.leadSources);

  const conditions = useMemo(() => {
    if (pathname.includes('job')) {
      return conditionsList.filter((c) => c.value !== 'id');
    }
    return conditionsList;
  }, [conditionsList, pathname]);

  useEffect(() => {
    dispatch(getUsersRequest({ limit: 100 }));
    dispatch(getContactsRequest({ limit: 100 }));
    dispatch(getLeadSourcesRequest({ limit: 100 }));
  }, []);

  const options = useMemo(() => ({
    responsiblePerson: users,
    contactId: contacts,
    leadSourceId: leadSources,
  }), [users, contacts, leadSources]);

  const getLabelKey = useCallback((cond, o) => ({
    responsiblePerson: `${o.firstName} ${o.lastName}`,
    contactId: o.name,
    leadSourceId: o.name,
  })[cond], []);

  return (
    <div className="conditions">
      {condition.map((c, i) => (
        <div className="row" key={c.id}>
          <div className="query">
            <div className={`left ${c.condition && c.condition !== 'sale' ? 'small' : ''}`}>
              <Select
                placeholder="Condition"
                options={conditions}
                isOptionDisabled={(o) => condition.map((con) => con.condition).includes(o.value)}
                onChange={(v) => {
                  onChange(`[${i}].condition`, v.value);
                  onChange(`[${i}].operator`, '$and');
                  onChange(`[${i}].$in`, []);
                }}
                menuPlacement="top"
                value={conditions.find((con) => con.value === c.condition)}
              />
            </div>
            {c.condition && c.condition !== 'sale' ? (
              <div className="right">
                <Select
                  options={operatorOptions}
                  placeholder=" "
                  onChange={(v) => onChange(`[${i}].operator`, v.value)}
                  value={operatorOptions.find((o) => o.value === c.operator)}
                />
              </div>
            ) : null}
            {c.condition ? (
              <div className="delete" onClick={() => onDeleteCondition(c.id)}>
                <DeleteIcon />
              </div>
            ) : null}
          </div>
          <div className="row">
            {c.condition === 'sale'
              ? (
                <div className="inputs">
                  <Input
                    placeholder="From"
                    type="number"
                    onChange={(ev) => onChange(`[${i}].$gte`, +ev.target.value)}
                    value={c.$gte || ''}
                  />
                  <Input
                    placeholder="To"
                    type="number"
                    onChange={(ev) => onChange(`[${i}].$lte`, +ev.target.value)}
                    value={c.$lte}
                  />
                </div>
              ) : null}
            {['leadSourceId', 'responsiblePerson', 'contactId'].includes(c.condition) ? (
              <Select
                isSearchable={false}
                options={options[c.condition]}
                getOptionValue={(o) => o.id}
                getOptionLabel={(o) => getLabelKey(c.condition, o)}
                placeholder={conditions.find((d) => d.value === c.condition)?.label}
                isMulti
                closeMenuOnSelect={false}
                onChange={(val) => onChange(`[${i}].$in`, val.map((v) => v.id))}
                value={options[c.condition].filter((f) => c.$in?.includes(f.id))}
                menuPlacement="top"
              />
            ) : null}
          </div>
        </div>
      ))}
      {condition.length < 4 && condition[condition.length - 1].condition
        ? (
          <div onClick={() => onChange(`[${condition.length}]`, { id: _.uniqueId() })} className="addConditionButton">
            <AddIcon />
            Add a condition
          </div>
        ) : null}
    </div>
  );
}

export default Conditions;

Conditions.propTypes = {
  onChange: PropTypes.func.isRequired,
  onDeleteCondition: PropTypes.func.isRequired,
  condition: PropTypes.array.isRequired,
};
