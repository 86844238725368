const statisticsReadPermissions = [
  'leads_statistic_readonly',
  'job_statistic_readonly',
  'invoice_statistic_readonly',
  'user_statistic_readonly',
  'sales_statistic_readonly',
  'installers_statistic_readonly',
];
const statisticsPermissions = [
  'leads_statistic',
  'job_statistic',
  'invoice_statistic',
  'user_statistic',
  'sales_statistic',
  'installers_statistic',
];

const pipelinePermissions = [
  'get_pipelines',
  'create_pipelines',
  'delete_pipelines',
  'order_pipeline',

];

const pipelineReadPermissions = [
  'get_pipelines_readonly',
  'create_pipelines_readonly',
  'delete_pipelines_readonly',
  'order_pipeline_readonly',
];

const contactsPermissions = [
  'get_contacts',
  'create_contact',
  'delete_contact',

];
const contactsReadPermissions = [
  'get_contacts_readonly',
  'create_contact_readonly',
  'delete_contact_readonly',
];

const leadsPermissions = [
  'get_leads',
  'create_lead',
  'delete_lead',
  'change_lead_status',

];
const leadsReadPermissions = [
  'get_leads_readonly',
  'create_lead_readonly',
  'delete_lead_readonly ',
  'change_lead_status_readonly',

];

const tasksPermissions = [
  'get_tasks',
  'create_task',
  'delete_task',
];

const tasksReadPermissions = [
  'get_tasks_readonly',
  'create_task_readonly',
  'delete_task_readonly',
];

const jobsPermissions = [
  'get_jobs',
  'create_job',
  'delete_job',
  'change_job_status',
];
const jobsReadPermissions = [
  'get_jobs_readonly',
  'create_job_readonly',
  'delete_job',
  'delete_job_readonly',
  'change_job_status_readonly',
];

const invoicePermissions = [
  'generate_invoice',
  'get_invoices',
  'delete_invoice',
  'register_payment',
  'download_invoice',
  'send_email_invoice',
  'calculate_profit',
  'payment_list',
  'delete_payment',
];
const invoiceReadPermissions = [
  'generate_invoice_readonly',
  'get_invoices_readonly',
  'delete_invoice_readonly',
  'register_payment_readonly',
  'download_invoice_readonly',
  'send_email_invoice_readonly',
  'calculate_profit_readonly',
  'payment_list_readonly',
  'delete_payment_readonly',
];

const automationPermissions = [
  'create_automation',
  'create_automation_lead',
  'create_automation_email',
  'create_automation_job',
  'get_automation',
  'delete_automation',

];

const automationReadPermissions = [
  'create_automation_readonly',
  'create_automation_lead_readonly',
  'create_automation_email_readonly',
  'create_automation_job_readonly',
  'get_automation_readonly',
  'delete_automation_readonly',

];

const leadSourcesPermissions = [
  'get_leadSources',
  'create_leadSource',
  'delete_leadSource',

];

const leadSourcesReadPermissions = [
  'get_leadSources_readonly',
  'create_leadSource_readonly',
  'delete_leadSource_readonly',

];

const templatesPermissions = [
  'get_templates',
  'create_template',
  'delete_template',

];

const templatesReadPermissions = [
  'get_templates_readonly',
  'create_template_readonly',
  'delete_template_readonly',

];

const usersPermissions = [
  'create_user',
  'users_list',
  'get_permissions',
  'permission_groups_list',
  'create_permissions_group',
  'delete_permissions_group',
  'delete_user',
  'resend_email',
];

const usersReadPermissions = [
  'create_user_readonly',
  'users_list_readonly',
  'get_permissions_readonly',
  'permission_groups_list_readonly',
  'create_permissions_group_readonly',
  'delete_permissions_group_readonly',
  'delete_user_readonly',
  'resend_email_readonly',
];
export {
  statisticsReadPermissions,
  statisticsPermissions,
  pipelinePermissions,
  pipelineReadPermissions,
  contactsPermissions,
  contactsReadPermissions,
  leadsPermissions,
  leadsReadPermissions,
  tasksPermissions,
  tasksReadPermissions,
  jobsReadPermissions,
  jobsPermissions,
  templatesReadPermissions,
  templatesPermissions,
  automationReadPermissions,
  automationPermissions,
  leadSourcesReadPermissions,
  leadSourcesPermissions,
  invoiceReadPermissions,
  invoicePermissions,
  usersPermissions,
  usersReadPermissions,
};
