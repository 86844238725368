import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { ReactComponent as AttentionSvg } from '../../assets/icons/attention.svg';
import { ReactComponent as DeleteSvg } from '../../assets/icons/delete.svg';
import Button from '../form/Button';

const DeleteModal = ({
  open, onClose, onDelete, zIndex, className, text, loading,
}) => (
  <Modal
    open={open}
    onClose={onClose}
    zIndex={zIndex}
    className={`delete__modal__wrapper ${className}`}
  >
    <div className="delete__modal__text__wrapper">
      <AttentionSvg />

      <p>{text}</p>
    </div>

    <div className="delete__modal__buttons__wrapper">
      <Button
        title="Cancel"
        onClick={onClose}
        outlined
      />

      <Button
        title={(
          <>
            <DeleteSvg />

            Confirm
          </>
          )}
        onClick={onDelete}
        outlined
        loading={loading}
      />
    </div>
  </Modal>
);

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  className: PropTypes.string,
  text: PropTypes.string,
  zIndex: PropTypes.number,
  loading: PropTypes.bool,
};

DeleteModal.defaultProps = {
  className: '',
  text: 'Are you sure want to delete the item?',
  zIndex: 1000,
  onClose: null,
  loading: false,
  onDelete: () => {},
};
export default DeleteModal;
