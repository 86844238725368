import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NotificationAvatar } from '../../assets/icons/notification_bill.svg';
import { ReactComponent as CircleIcon } from '../../assets/icons/circle_outline.svg';
import {
  deleteNotificationRequest,
  getNotificationListRequest,
  seenNotificationRequest,
} from '../../store/actions/notification';
import Loader from '../loader/Loader';

function NotificationInfo({
  type, scrollLoad, onClose, firstLoad,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationList = useSelector((store) => store.notification.notificationList);
  const totalPage = useSelector((store) => store.notification.totalPage);
  const [deleteLoad, setDeleteLoad] = useState('');

  const deleteNotification = useCallback(async (e, id) => {
    e.stopPropagation();
    setDeleteLoad(id);

    await dispatch(deleteNotificationRequest({ id }));

    if (notificationList.length < 19 && totalPage > 1) {
      await dispatch(getNotificationListRequest({ page: 1 }));
    }
    setDeleteLoad('');
  }, [notificationList, totalPage]);

  const seenNotificationPage = useCallback((id, redirectType) => {
    if (!deleteLoad) {
      dispatch(seenNotificationRequest({ id }));

      navigate(`/${redirectType}`);
      onClose();
    }
  }, [deleteLoad]);

  const markAllReadClick = useCallback(() => {
    dispatch(seenNotificationRequest());
  }, []);

  const notificationData = useMemo(() => (!type
    ? notificationList.slice(0, 4)
    : notificationList), [notificationList]);

  return (
    <>
      <div className="notification_header">
        <h2>Notifications</h2>

        {!!notificationList.length && (
        <div
          className="mark_all_read_wrapper"
          onClick={markAllReadClick}
        >
          <p>Mark all as read</p>

          <CircleIcon />
        </div>
        )}
      </div>

      {firstLoad
        ? <Loader />
        : !firstLoad && !notificationList.length
          ? <div className="notification_no_data">No Notification</div>
          : notificationData.map((n) => (
            <div className="notification_body" key={n.id} onClick={() => seenNotificationPage(n.id, n.type)}>
              <div className="notification_avatar">
                <div className="avatar">
                  <NotificationAvatar />
                </div>

                {!n.seen && <span className="dot" />}
              </div>

              <div className="notification_info">
                <div dangerouslySetInnerHTML={{ __html: n.data.text }} />

                <span className="date">
                  {moment.utc(n.createdAt).local().calendar({
                    sameDay: 'LT',
                    nextDay: 'DD.MM  LT',
                    nextWeek: 'DD.MM  LT',
                    lastDay: 'DD.MM  LT',
                    lastWeek: 'DD.MM  LT',
                    sameElse: 'DD.MM  LT',
                  })}
                </span>

                <button
                  type="button"
                  onClick={(e) => deleteNotification(e, n.id)}
                  className={`delete_btn ${deleteLoad === n.id
                    ? 'disabled'
                    : deleteLoad
                      ? 'disabled_another'
                      : ''}`}
                  disabled={!!deleteLoad}
                >
                  {deleteLoad === n.id ? <Loader size={12} /> : 'Delete'}
                </button>

              </div>
            </div>
          ))}

      {scrollLoad && <Loader />}
    </>
  );
}

NotificationInfo.propTypes = {
  type: PropTypes.bool,
  scrollLoad: PropTypes.bool,
  firstLoad: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

NotificationInfo.defaultProps = {
  type: false,
  scrollLoad: false,
  firstLoad: false,
};

export default NotificationInfo;
