import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import { addTaskRequest, getTasksRequest } from '../actions/tasks';
import { changePipelineElementStatusLocal } from '../actions/pipelines';

const initialState = {
  tasks: [],
  tasksGrouped: {},
  tasksPages: 0,
};
export default createReducer(initialState, (builder) => {
  builder
    .addCase(addTaskRequest.fulfilled, () => {
    })
    .addCase(getTasksRequest.fulfilled, (state, action) => {
      const { data: { totalPages, tasks }, path } = action.payload;
      if (!path) {
        state.tasks = tasks;
        state.tasksPages = totalPages;
        state.tasksGrouped = _.groupBy(_.orderBy(tasks, 'order'), 'status');
      }
    })
    .addCase(changePipelineElementStatusLocal, (state, action) => {
      const {
        id,
        status,
        pipeline,
        data,
        sourceStatus,
      } = action.payload;
      if (pipeline === 'task') {
        state.tasksGrouped[status] = data;
        if (sourceStatus) {
          state.tasksGrouped[sourceStatus] = state.tasksGrouped[sourceStatus].filter((j) => j.id !== +id);
        }
      }
    });
});
