import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({
  size, color, bgColor, className, borderWidth,
}) => (
  <div
    style={{
      width: size,
      height: size,
      borderWidth,
      borderTopColor: color,
      '--loader-border-bg-color': bgColor,
    }}
    className={`loader ${className}`}
  />
);

Loader.propTypes = {
  size: PropTypes.number,
  borderWidth: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  bgColor: PropTypes.string,
};

Loader.defaultProps = {
  size: 25,
  borderWidth: 4,
  color: '#292929',
  bgColor: 'rgba(0, 0, 0, 0.25)',
  className: '',
};

export default Loader;
