import React from 'react';
import moment from 'moment/moment';
import { ReactComponent as Dashboard } from '../assets/icons/dashboard.svg';
import { ReactComponent as Leads } from '../assets/icons/leads.svg';
import { ReactComponent as Jobs } from '../assets/icons/jobs.svg';
import { ReactComponent as Invoices } from '../assets/icons/invoices.svg';
import { ReactComponent as Contacts } from '../assets/icons/contacts.svg';
import { ReactComponent as Tasks } from '../assets/icons/tasks.svg';
import { ReactComponent as Settings } from '../assets/icons/settings.svg';
import { ReactComponent as GoToIcon } from '../assets/icons/goto.svg';
import { ReactComponent as GmailIcon } from '../assets/icons/gmail.svg';
import { ReactComponent as CallIcon } from '../assets/icons/phone_new.svg';
import { ReactComponent as ArchiveIcon } from '../assets/icons/archive.svg';
import {
  contactsPermissions, contactsReadPermissions,
  invoicePermissions, invoiceReadPermissions,
  jobsPermissions, jobsReadPermissions, leadSourcesPermissions, leadSourcesReadPermissions,
  leadsPermissions, leadsReadPermissions,
  statisticsPermissions,
  statisticsReadPermissions, tasksPermissions, tasksReadPermissions,
  templatesPermissions,
  templatesReadPermissions, usersPermissions, usersReadPermissions,
} from './permissions';

const settingsSubMenu = [
  {
    label: 'Email templates',
    id: 1,
    path: '/settings/email-templates',
    permissions: [...templatesPermissions, ...templatesReadPermissions],
  },
  {
    label: 'Notification templates',
    id: 3,
    path: '/settings/notification-templates',
    permissions: [...templatesPermissions, ...templatesReadPermissions],

  },
  {
    label: 'Notification settings',
    id: 2,
    path: '/settings/notification-settings',
    permissions: [...templatesPermissions, ...templatesReadPermissions],

  },
  {
    label: 'Lead sources',
    id: 4,
    path: '/settings/lead-sources',
    permissions: [...leadSourcesPermissions, ...leadSourcesReadPermissions],
  },
  {
    label: 'Users',
    id: 5,
    path: '/settings/users',
    permissions: [...usersPermissions, ...usersReadPermissions],
  },
  {
    label: 'Permissions',
    id: 6,
    path: '/settings/permissions',
    permissions: [...usersPermissions, ...usersReadPermissions],
  },
  {
    label: 'GoTo',
    id: 7,
    path: '/settings/goto',
    icon: <GoToIcon className="gotoIcon" />,
    permissions: ['goto_authorize', 'goto_authorize_readonly'],

  },
  {
    label: 'Connect Gmail',
    id: 8,
    path: '/settings/google/auth',
    permissions: 'none',
    icon: <GmailIcon className="gotoIcon" />,
  },
];

const menuItemsList = [
  {
    label: 'Dashboard',
    id: 1,
    icon: <Dashboard />,
    path: '/dashboard',
    permissions: [...statisticsPermissions, ...statisticsReadPermissions],
  },
  {
    label: 'Leads',
    id: 2,
    icon: <Leads />,
    path: '/leads',
    permissions: [...leadsPermissions, ...leadsReadPermissions],
  },
  {
    label: 'Jobs',
    id: 3,
    icon: <Jobs />,
    path: '/jobs',
    permissions: [...jobsPermissions, ...jobsReadPermissions],
  },
  {
    label: 'Invoices',
    id: 4,
    icon: <Invoices />,
    path: `/invoices?invoiceDate=${moment().subtract(14, 'day').format('yyyy-MM-DD')},${moment()
      .format('yyyy-MM-DD')}&first=1`,
    permissions: [...invoicePermissions, ...invoiceReadPermissions],
  },
  {
    label: 'Customers',
    id: 5,
    icon: <Contacts />,
    path: '/contacts',
    permissions: [...contactsPermissions, ...contactsReadPermissions],
  },
  {
    label: 'Tasks',
    id: 6,
    icon: <Tasks />,
    path: '/tasks',
    permissions: [...tasksPermissions, ...tasksReadPermissions],
  },
  {
    label: 'Call logs',
    id: 9,
    icon: <CallIcon />,
    path: '/call-reports',
  },
  {
    label: 'Settings',
    id: 10,
    icon: <Settings />,
    path: '/settings',
    subMenu: settingsSubMenu,
    permissions: [...templatesReadPermissions, ...templatesPermissions, ...usersPermissions,
      ...usersReadPermissions, leadSourcesPermissions, ...leadSourcesReadPermissions],
  },
  {
    label: 'Archive',
    id: 22,
    icon: <ArchiveIcon />,
    path: '/archive',
  },
];
export default menuItemsList;
