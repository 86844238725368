import { createReducer } from '@reduxjs/toolkit';
import {
  checkUnreadNotificationRequest,
  clearNotificationList,
  deleteNotificationRequest,
  getNotificationListRequest,
  getNotificationSettingRequest,
  getNotificationTemplatesRequest,
  seenNotificationRequest,
  unreadNotification,
} from '../actions/notification';

const initialState = {
  notificationList: [],
  totalPage: 1,
  currentPage: 1,
  unreadExist: false,
  notificationTemplates: [],
  notificationTemplatesTotal: 1,
  notificationSettings: {},
};
export default createReducer(initialState, (builder) => {
  builder
    .addCase(getNotificationListRequest.fulfilled, (state, action) => {
      state.notificationList = action.meta.arg?.scroll
        ? [...state.notificationList, ...action.payload.notifications]
        : action.payload.notifications;
      state.totalPage = action.payload.totalPages;
      state.currentPage = action.meta.arg.page;
    })
    .addCase(clearNotificationList, (state) => {
      state.notificationList = [];
      state.totalPage = 1;
      state.currentPage = 1;
    })
    .addCase(deleteNotificationRequest.fulfilled, (state, action) => {
      state.notificationList = state.notificationList.filter((n) => n.id !== action.meta.arg.id);
      state.unreadExist = !state.notificationList.every((n) => (action.meta.arg.id === n.id ? true : !!n.seen));
    })
    .addCase(seenNotificationRequest.pending, (state, action) => {
      if (!action.meta.arg?.id) {
        state.unreadExist = false;
        state.notificationList = state.notificationList.map((n) => ({ ...n, seen: new Date() }));
      } else {
        state.notificationList = state.notificationList.map((n) => (action.meta.arg?.id === n.id
          ? { ...n, seen: new Date() }
          : n));

        state.unreadExist = !state.notificationList.every((n) => (action.meta.arg?.id === n.id ? true : !!n.seen));
      }
    })
    .addCase(checkUnreadNotificationRequest.fulfilled, (state, { payload }) => {
      state.unreadExist = payload.unreadExist;
    })
    .addCase(unreadNotification, (state) => {
      state.unreadExist = true;
    })
    .addCase(getNotificationTemplatesRequest.fulfilled, (state, action) => {
      const { templates, totalPages } = action.payload.data;
      state.notificationTemplates = templates;
      state.notificationTemplatesTotal = totalPages;
    })
    .addCase(getNotificationSettingRequest.fulfilled, (state, action) => {
      const { settings } = action.payload.data;
      const [data] = settings;
      state.notificationSettings = data || {};
    });
});
