import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  addJobRequest,
  calcJobProfitRequest,
  getJobsRequest,
  getSingleJobRequest,
  removeJobData,
  setJobData,
  unsetJobFormErrors,
} from '../actions/jobs';
import Utils from '../../helpers/Utils';
import { changePipelineElementStatusLocal } from '../actions/pipelines';
import { additionalDataKey, idKey } from './leads';

const initialState = {
  jobs: [],
  jobsGrouped: {},
  jobsPages: 0,
  jobData: {
    tasks: [],
    notes: [],
    emails: [],
    details: {},
  },
  errors: {},
  singleJob: {},
  jobActivity: {},
  singleJobData: { details: {} },
  profit: null,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(getJobsRequest.fulfilled, (state, action) => {
      const { data: { totalPages, jobs }, path } = action.payload;
      if (!path) {
        state.jobs = jobs;
        state.jobsGrouped = _.groupBy(_.orderBy(jobs, 'order'), 'status');
        state.jobsPages = totalPages;
      }
    })
    .addCase(unsetJobFormErrors, (state, action) => {
      _.unset(state.errors, action.payload.key);
    })
    .addCase(setJobData, (state, action) => {
      const {
        key,
        value,
        index,
      } = action.payload;
      const jobData = _.cloneDeep(state.jobData);
      if (index?.toString()) {
        jobData[key].splice(index, 1);
      } else {
        _.set(jobData, key, value);
      }
      if (key.includes('quotation') && jobData.quotation && jobData.quotation.detail) {
        jobData.sale = _.sum(jobData.quotation.detail.filter((d) => d.qty || d.price)
          .map((d) => (+d.qty || 1) * (+d.price || 0)))?.toFixed(2);
      }
      state.jobData = jobData;
    })
    .addCase(addJobRequest.pending, (state) => {
      state.errors = {};
    })
    .addCase(addJobRequest.rejected, (state, action) => {
      const {
        errors,
        message,
      } = action.payload.data;
      if (errors) {
        state.errors = Utils.normalizeErrors(action.payload.data.errors);
      } else {
        toast.error(message);
      }
    })
    .addCase(getSingleJobRequest.fulfilled, (state, action) => {
      const job = action.payload.jobs[0] || { tasks: [], emails: [] };
      state.singleJobData = job;
      let { details, quotation } = job;
      if (_.isEmpty(details.additionalData)) {
        details = { ...details };
      } else {
        details = {
          ...details,
          additionalData: details.additionalData.map((d) => ({
            ...d, [additionalDataKey]: _.uniqueId('additionalDataKey'),
          })),
        };
      }
      if (_.isEmpty(quotation.detail)) {
        quotation = {};
      } else {
        quotation = {
          detail: quotation.detail.map((d) => ({
            ...d, [additionalDataKey]: _.uniqueId('additionalDataKey'),
          })),
        };
      }

      state.jobData = {
        address: job.address,
        company: job.company,
        contact: job.contactId,
        storageFeeStartDate: job.storageFeeStartDate,
        storageFee: job.storageFee,
        invoices: job.invoices,
        tasks: job.tasks.map((t) => ({
          type: t.type,
          dueDate: t.dueDate,
          note: t.note,
          status: t.status,
          [idKey]: t.id,
          responsiblePerson: t.responsiblePerson,
        })),
        responsiblePerson: job.responsiblePerson,
        status: job.status,
        sale: job.sale || _.sum(quotation.detail.map((d) => (+d.qty || 0) * (+d.price || 0)))?.toFixed(2),
        phone: job.phone,
        notes: job.notes,
        emails: job.emails.map((e) => ({
          to: e.to,
          subject: e.subject,
          templateId: e.templateId,
          text: e.text,
        })),
        email: job.email,
        details,
        quotation,
      };
      state.jobActivity = _.groupBy(job.activity, (l) => moment(l.createdAt).format('MM.DD.YYYY'));
    })
    .addCase(changePipelineElementStatusLocal, (state, action) => {
      const {
        id,
        status,
        pipeline,
        data,
        sourceStatus,
      } = action.payload;
      if (pipeline === 'job') {
        state.jobsGrouped[status] = data;
        if (sourceStatus) {
          state.jobsGrouped[sourceStatus] = state.jobsGrouped[sourceStatus].filter((j) => j.id !== +id);
        }
      }
    })
    .addCase(removeJobData, (state) => {
      state.jobData = {
        tasks: [],
        notes: [],
        emails: [],
        details: { additionalData: [{}] },
      };
      state.jobActivity = {};
      state.errors = {};
      state.profit = null;
    })
    .addCase(calcJobProfitRequest.fulfilled, (state, action) => {
      state.profit = action.payload.profit;
    });
});
