import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import _ from 'lodash';

const DatePicker = ({
  date, label, setDate, className, error, placeholder, ...props
}) => {
  const [id] = useState(_.uniqueId('input'));

  const onChange = (newDate) => {
    setDate(newDate ? moment(newDate).format('yyyy-MM-DD') : '');
  };

  return (
    <div className={classNames(`date__picker__wrapper ${className}`, { error: !!error })}>
      <label htmlFor={`d_p_${id}`}>
        {label}
      </label>

      <ReactDatePicker
        selected={date ? new Date(date) : null}
        id={`d_p_${id}`}
        placeholderText={placeholder}
        onChange={onChange}
        showMonthDropdown
        showYearDropdown
        showIcon
        dateFormat="MM/dd/yyyy"
        {...props}
      />

      {!!error && <span className="errorText">{error}</span>}
    </div>
  );
};

DatePicker.propTypes = {
  date: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  setDate: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

DatePicker.defaultProps = {
  date: null,
  label: '',
  error: '',
  className: '',
  placeholder: 'mm/dd/yyyy',
};

export default DatePicker;
