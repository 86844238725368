import axios from 'axios';
import fileDownload from 'js-file-download';
import Account from './helpers/Account';
import Utils from './helpers/Utils';

const { REACT_APP_API_URL } = process.env;

const api = axios.create({
  baseURL: REACT_APP_API_URL,
});
api.interceptors.request.use((config) => {
  const token = Account.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, (error) => Promise.reject(error));

api.interceptors.response.use((config) => config, (error) => {
  if (error.response.status === 401) {
    Account.delete();
    window.location.reload();
  }
  // if (error.response.status === 503){
  //   window.location.href = '/service-not-available'
  // }
  return Promise.reject(error);
});

class Api {
  static login(data) {
    return api.post('/users/login', data);
  }

  static getLeads(params) {
    return api.get('/leads', { params });
  }

  static addLead(data) {
    return api.post('/leads', Utils.deleteEmptyKeys(data), { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  static addTask(data) {
    return api.post('/tasks', data);
  }

  static getPipelines(params) {
    return api.get('/pipelines', { params });
  }

  static createPipeline(data) {
    return api.post('/pipelines/create', data);
  }

  static getContacts(params) {
    return api.get('/contacts', { params });
  }

  static createContact(data) {
    return api.post('/contacts/create', data);
  }

  static deleteContact(id) {
    return api.delete(`/contacts/${id}`);
  }

  static getUsers(params) {
    return api.get('/users', { params });
  }

  static createUser(data) {
    return api.post('/users/create', data);
  }

  static deleteUser(id) {
    return api.delete(`/users/${id}`);
  }

  static getUserPermissions({
    page,
    limit = 10,
    s,
  }) {
    return api.get('/users/permissions', {
      params: {
        page,
        limit,
        s,
      },
    });
  }

  static getUserPermissionsGroup({
    page,
    limit = 10,
    s,
    orderBy,
    id,
  }) {
    return api.get('/users/permissions-group', {
      params: {
        page,
        limit,
        s,
        orderBy,
        id,
      },
    });
  }

  static createPermissionGroup(data) {
    return api.post('/users/permissions-group/create', data);
  }

  static deletePermissionGroup(id) {
    return api.delete(`/users/permission-group/${id}`);
  }

  static orderPipeline(data) {
    return api.post('/pipelines/order', data);
  }

  static resetPassword(email) {
    return api.post('/users/reset-password', { email });
  }

  static setPassword({
    code,
    password,
    confirmPassword,
  }) {
    return api.post('/users/set-password', {
      code,
      password,
      confirmPassword,
    });
  }

  static resendNewPasswordEmail(id) {
    return api.post(`/users/resend-email/${id}`);
  }

  static createAutomation(data) {
    return api.post('/automation/create', data);
  }

  static deletePipeline(id) {
    return api.delete(`/pipelines/${id}`);
  }

  static getLeadSources(params) {
    return api.get('/lead-source', { params });
  }

  static createLeadSource(data) {
    return api.post('/lead-source', data);
  }

  static deleteLeadSource(id) {
    return api.delete(`/lead-source/${id}`);
  }

  static deleteLead(id) {
    return api.delete(`/leads/${id}`);
  }

  static getJobs(params) {
    return api.get('/jobs', { params });
  }

  static deleteJob(id) {
    return api.delete(`/jobs/${id}`);
  }

  static getInvoices({
    page,
    limit = 10,
    s,
    order,
    id,
    invoiceDate,
    dueDate,
    total,
  }) {
    return api.get('/jobs/invoice', {
      params: {
        page,
        limit,
        s,
        order,
        id,
        invoiceDate,
        dueDate,
        total,
      },
    });
  }

  static createInvoice(data) {
    return api.post('/jobs/invoice', data);
  }

  static deleteInvoice(id) {
    return api.delete(`/jobs/invoice/${id}`);
  }

  static async downloadInvoice({
    jobId,
    invoiceId,
  }) {
    const {
      data,
      headers,
    } = await api.post('/jobs/invoice/download', {
      jobId,
      invoiceId,
    }, {
      responseType: 'blob',
    });

    const [, fileName] = (headers['content-disposition'] || '').match(/\s+filename=(.+)$/) || ['', 'invoice.pdf'];

    fileDownload(data, fileName);

    return data;
  }

  static sendInvoiceEmail(data) {
    return api.post('/jobs/invoice/send-email', data);
  }

  static createInvoicePayment(data) {
    return api.post('/jobs/invoice/pay', data);
  }

  static deletePaymentItem({
    invoiceId,
    paymentId,
  }) {
    return api.delete(`/jobs/invoice/payment/${invoiceId}/${paymentId}`);
  }

  static addJob(data) {
    return api.post('/jobs', Utils.deleteEmptyKeys(data), { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  static getTasks(params) {
    return api.get('/tasks', { params });
  }

  static deleteTask(id) {
    return api.delete(`/tasks/${id}`);
  }

  static getSingleLead(id) {
    return api.get('/leads', { params: { id } });
  }

  static getSingleJob(id) {
    return api.get('/jobs', { params: { id } });
  }

  static getSingleTask(id) {
    return api.get('/tasks', { params: { id } });
  }

  static changePipelineElementStatus(data) {
    const {
      pipeline,
      ...restData
    } = data;
    return api.post(`/${pipeline}s/change-status`, restData);
  }

  static getEmailTemplates({
    page,
    limit = 10,
    s,
    order,
    id,
  }) {
    return api.get('/templates', {
      params: {
        page,
        limit,
        s,
        order,
        id,
      },
    });
  }

  static createEmailTemplate(data) {
    return api.post('/templates/', data);
  }

  static deleteEmailTemplate(id) {
    return api.delete(`/templates/${id}`);
  }

  static makeCall(data) {
    return api.post('/calls/make-call', data);
  }

  static hangupCall(data) {
    return api.post('/calls/hangup', data);
  }

  static manageCall(data) {
    return api.post('/calls/mute', data);
  }

  static answerCall(data) {
    return api.post('/calls/answer', data);
  }

  static rejectCall(data) {
    return api.post('/calls/reject', data);
  }

  static setFirebaseToken(token) {
    return api.post('/firebase-token', { token });
  }

  static deleteFirebaseToken(token) {
    return api.delete('/firebase-token', {
      params: {
        token,
      },
    });
  }

  static getNotificationList({ page, limit }) {
    return api.get('/notifications', {
      params: { page, limit },
    });
  }

  static seenNotification(data) {
    return api.put('/notifications/seen', { id: data?.id });
  }

  static deleteNotification({ id }) {
    return api.delete(`/notifications/${id}`);
  }

  static checkUnreadNotification() {
    return api.get('/notifications/check-unread');
  }

  static getAutomationsList(type) {
    return api.get('/automation', { params: { type } });
  }

  static authorizeGoto(code) {
    return api.post('/calls/token', { code });
  }

  static leadsStatistic(date) {
    return api.get('/leads/statistic', {
      params: { startDate: date?.startDate, endDate: date?.endDate, id: date?.id },
    });
  }

  static jobsStatistics({ startDate, endDate, id }) {
    return api.get('/jobs/statistic', {
      params: { startDate, endDate, id },
    });
  }

  static invoiceStatistic({ startDate, endDate, id }) {
    return api.get('/jobs/statistic/invoices', {
      params: { startDate, endDate, id },
    });
  }

  static getEmployeeProductivity() {
    return api.get('/jobs/statistic/users');
  }

  static salesStatistic({ startDate, endDate, id }) {
    return api.get('/jobs/statistic/sales', {
      params: { startDate, endDate, id },
    });
  }

  static installersStatistic({ startDate, endDate, id }) {
    return api.get('/jobs/statistic/installers', {
      params: { startDate, endDate, id },
    });
  }

  static getDashboardSettings() {
    return api.get('/jobs/dashboard/settings');
  }

  static updateDashboardSettings(data) {
    return api.post('/jobs/dashboard/settings', data);
  }

  static deleteAutomation(id) {
    return api.delete(`/automation/${id}`);
  }

  static getProfile() {
    return api.get('/users/profile');
  }

  static deleteFiles(files) {
    return api.delete('/users/file/delete', { data: { files: [files] } });
  }

  static calcJobProfit(data) {
    return api.post('/jobs/calculate', data);
  }

  static globalSearch(params) {
    return api.get('/leads/global-search', { params });
  }

  static addActivityNote(data) {
    return api.post('/notes', data);
  }

  static getActivityNotes(params) {
    return api.get('/notes', { params });
  }

  static sendJobEmail(data) {
    return api.post('/jobs/send-email', data);
  }

  static sendLeadEmail(data) {
    return api.post('/leads/send-email', data);
  }

  static deleteActivityNote(id) {
    return api.delete(`/notes/${id}`);
  }

  static deleteLeadNote(id) {
    return api.delete(`/leads/notes/${id}`);
  }

  static deleteJobNote(id) {
    return api.delete(`/jobs/notes/${id}`);
  }

  static connectGmail(data) {
    return api.post('/users/google-oauth', data);
  }

  static googleAddressAutocomplete(s) {
    return api.get('/users/autocomplete', { params: { s } });
  }

  static googleAddressByPlaceId(placeId) {
    return api.get('/users/google-place', { params: { placeId } });
  }

  static getUserContactsFromGoogle(s) {
    return api.get('/users/google-contacts', { params: { s } });
  }

  static getPipelineStatuses(params) {
    return api.get('/pipelines/statuses', { params });
  }

  static getNotificationTemplates(params) {
    return api.get('/notifications/templates', { params });
  }

  static deleteNotificationTemplate(id) {
    return api.delete(`/notifications/templates/${id}`);
  }

  static createNotificationTemplate(data) {
    return api.post('/notifications/templates', data);
  }

  static createNotificationSetting(data) {
    return api.post('/notifications/settings', data);
  }

  static getNotificationSetting(params) {
    return api.get('/notifications/settings', { params });
  }

  static getActiveCalls(callId) {
    return api.post('/calls/active', { callId });
  }

  static getCalls(params) {
    return api.get('/calls', { params });
  }

  static archivePipelineElement({ pipeline, id }) {
    return api.post(`/${pipeline}s/archive/${id}`);
  }

  static unsubscribeFromEmails(email) {
    return api.post('/users/unsubscribe', { email });
  }

  static getEmails(id, pipeline, params) {
    return api.get(`/${pipeline}s/emails/${id}`, { params });
  }

  static getSingleEmails(id, pipeline) {
    return api.get(`/${pipeline}s/emails/message/${id}`);
  }
}

export default Api;
