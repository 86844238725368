import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getContactsRequest = createAsyncThunk('contacts/get', async (payload = {}) => {
  const { path, ...reqData } = payload;
  const { data } = await Api.getContacts(reqData);
  return { data, path };
});
export const getSingleContactRequest = createAsyncThunk('contacts/getSingleContactRequest', async (payload) => {
  const { data } = await Api.getContacts(payload);
  return data;
});
