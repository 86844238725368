import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Wrapper from '../../components/Wrapper';
import TableWithPagination from '../../components/tables/TableWithPagination';
import Button from '../../components/form/Button';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import RightModal from '../../components/modal/RightModal';
import Input from '../../components/form/Input';
import Api from '../../Api';
import Utils from '../../helpers/Utils';
import { getLeadSourcesRequest, getSingleLeadSourceRequest } from '../../store/actions/leadSources';
import { useQuery } from '../../helpers/hooks/useQuery';
import HasPermission from '../../components/HasPermission';
import { leadSourcesPermissions } from '../../data/permissions';
import { getSingleContactRequest } from '../../store/actions/contacts';

const tableHeader = [
  {
    title: 'Code',
    path: 'code',
  },
  {
    title: 'Name',
    placeholder: 'Name',
    path: 'name',
    required: true,
  },
];
const LeadSources = () => {
  const { query, setQuery } = useQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sources, totalPages } = useSelector((state) => state.leadSources);
  const [searchParams, setSearchParams] = useSearchParams();

  const timeout = useRef();

  const [filters, setFilters] = useState({
    page: +query.page || 1,
    limit: +query.limit || 10,
  });
  const { page, limit } = filters;

  const [data, setData] = useState({
    name: '',
  });

  const { name } = data;

  const [error, setError] = useState('');

  const [loading, loadingToggle] = useState(true);
  const [saveLoading, saveLoadingToggle] = useState(false);
  const sourceId = searchParams.get('id');

  useEffect(() => {
    (async () => {
      await dispatch(getLeadSourcesRequest(filters));
      loadingToggle(false);
    })();

    return () => clearTimeout(timeout.current);
  }, []);

  useEffect(() => {
    setQuery({ ...filters, ...Object.fromEntries(searchParams) });
  }, [filters, searchParams]);

  const changeSource = useCallback((path, value) => {
    setData((prev) => ({ ...prev, [path]: value }));

    setError('');
  }, []);

  const onChangePage = useCallback(async (type, value) => {
    const requestData = { ...filters };

    requestData[type] = value;

    if (type === 'limit') {
      requestData.page = 1;
    }

    setFilters(requestData);

    await dispatch(getLeadSourcesRequest(requestData));
  }, [filters]);

  useEffect(() => {
    if (sourceId) {
      (async () => {
        const { payload: { leadSources } } = await dispatch(getSingleLeadSourceRequest({ id: sourceId }));
        const d = {
          name: leadSources[0].name,
          code: leadSources[0].code,
          id: +sourceId,
        };
        setData({ ...d });
      })();
    }
  }, [sourceId]);

  const closeSourceModal = useCallback(() => {
    const q = { ...Object.fromEntries(searchParams) };
    delete q.create;
    delete q.id;
    setSearchParams(q);

    timeout.current = setTimeout(() => {
      setData({ name: '' });
      setError('');
    }, 350);
  }, [searchParams]);

  const saveContact = useCallback(async (e) => {
    e.preventDefault();

    let hasError = false;

    if (!name.trim()) {
      setError('Name is required');

      hasError = true;
    }

    if (!hasError) {
      saveLoadingToggle(true);

      try {
        const requestData = Utils.deleteEmptyKeys(Utils.trimObjValues(data));
        delete requestData.code;

        await Api.createLeadSource(requestData);
        await dispatch(getLeadSourcesRequest(filters));

        closeSourceModal();

        toast.success(<span>
          <b>Success!</b>

          {` ${data.id ? 'Lead source is updated' : 'New lead source is created'}`}
        </span>);
      } catch (err) {
        toast.error('Please correct these fields');

        setError(err.response.data.message);
      }

      saveLoadingToggle(false);
    } else {
      toast.error('Please correct these fields');
    }
  }, [data, filters]);

  const deleteSource = useCallback(async (source) => {
    await Api.deleteLeadSource(source.id);

    const requestData = { ...filters };
    requestData.page = sources.length === 1 && page > 1 ? page - 1 : page;

    await dispatch(getLeadSourcesRequest(requestData));
    setFilters(requestData);

    toast.success('Lead source deleted');
  }, [sources, filters]);

  return (
    <Wrapper title="Lead sources">
      <div className="contacts__content">
        <div className="contacts__buttons__wrapper">
          <HasPermission read="create_leadSource_readonly" edit="create_leadSource">
            <Button
              title={(
                <>
                  <AddIcon />

                  Create
                </>
              )}
              onClick={() => navigate('?create=lead-source')}
            />
          </HasPermission>
        </div>
        <HasPermission edit={leadSourcesPermissions}>
          <TableWithPagination
            data={sources}
            header={tableHeader}
            page={page}
            limit={limit}
            firstLoad={loading}
            totalPages={totalPages}
            onChangePage={onChangePage}
            onEditClick={(d) => navigate(`?create=lead-source&id=${d.id}`)}
            onDeleteClick={deleteSource}
            deleteModalText="Are you sure you want to delete the lead source?"
            deletePermission="delete_leadSource"
            readDeletePermission="delete_leadSource_readonly"
            editPermission="create_leadSource"
            readEditPermission="create_leadSource_readonly"
          />
        </HasPermission>
      </div>

      <RightModal
        open={searchParams.get('create') === 'lead-source'}
        onClose={closeSourceModal}
        headerText={data?.id ? `Edit source ${data.code}` : 'Add new source'}
      >
        <form onSubmit={saveContact}>
          <div className="new__contact__input__wrapper">
            <Input
              label="Name *"
              placeholder="Name"
              value={name}
              onChange={({ target: { value } }) => changeSource('name', value)}
              error={error}
            />
          </div>

          <div className="new__contact__buttons__wrapper">
            <Button
              title={`${data?.id ? 'Edit' : 'Add new'} source`}
              loading={saveLoading}
              type="submit"
            />

            <Button
              title="Cancel"
              noBorder
              type="button"
              onClick={closeSourceModal}
            />
          </div>
        </form>
      </RightModal>
    </Wrapper>
  );
};

export default LeadSources;
