import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function Textarea(props) {
  const {
    label, onChange, value, error, placeholder, ...restProps
  } = props;
  const [id] = useState(_.uniqueId('input'));
  return (
    <div className={classNames('textareaWrapper', { error: !!error })}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <textarea onChange={onChange} value={value} placeholder={placeholder} {...restProps} />
      {error ? <span className="errorText">{error}</span> : null}
    </div>
  );
}

export default Textarea;

Textarea.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Textarea.defaultProps = {
  label: '',
  value: '',
  placeholder: '',
  onChange: () => {
  },
  error: false,
};
