import React from 'react';
import PropTypes from 'prop-types';

function AutomationWrapper(props) {
  const { onSubmit, children } = props;
  return (
    <form
      className="addAutomation"
      onSubmit={onSubmit}
    >
      <div className="triangle" />
      <div className="contentContainer">
        {children}
      </div>
    </form>
  );
}

export default AutomationWrapper;

AutomationWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
