import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone_new.svg';
import { ReactComponent as OutgoingCallIcon } from '../../assets/icons/outgoing.svg';
import { ReactComponent as IncomingCallIcon } from '../../assets/icons/incoming.svg';
import Utils from '../../helpers/Utils';

function Calls(props) {
  const { calls } = props;

  const getCallIconByType = useCallback((type) => ({
    outgoing: <OutgoingCallIcon />,
    incoming: <IncomingCallIcon />,
  })[type], []);

  if (_.isEmpty(calls)) {
    return (
      <div className="activity">
        <div className="noActivity">
          <p>No calls yet</p>
        </div>
      </div>
    );
  }

  return (
    <div className="calls">
      {calls.map((call) => (
        <div className="item" key={call._id}>
          <div className="phoneIcon">
            <PhoneIcon />
          </div>
          <div className="info">
            <div className="callerInfo">
              <div>
                <p className="name">{call.callerName}</p>
                <p className="phone">{call.type === 'outgoing' ? call.reachedNumber : call.phoneNumber}</p>
              </div>
              <div>
                <p className="number">Reached number: </p>
                <div className="reachedNumber">
                  {!call.calleeName.startsWith('+') ? <p className="phone">{call.calleeName}</p> : null}
                  <p className="phone">{call.type === 'outgoing' ? call.phoneNumber : call.reachedNumber}</p>
                </div>
              </div>
            </div>
            <div className="callInfo">
              <div>
                <p className="date">{moment(call.createdAt).format('MM.DD.YYYY hh:mm')}</p>
              </div>
              <div className="durationInfo">
                <div className="type">
                  {getCallIconByType(call.type)}
                  <span>{_.upperFirst(call.type)}</span>
                </div>
                <div className="duration">
                  <p>Call duration: </p>
                  <p>{Utils.formatDuration(call.duration / 1000)}</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Calls;

Calls.propTypes = {
  calls: PropTypes.array.isRequired,
};
