import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Loader from '../loader/Loader';

function Button(props) {
  const {
    title, type, outlined, loading, disabled, noBorder, className, ...restProps
  } = props;
  return (
    <button
      type={type}
      disabled={loading || disabled}
      className={classNames(`mainButton ${className}`, { outlined, noBorder })}
      {...restProps}
    >
      {!loading ? title : <Loader />}
    </button>
  );
}

export default Button;

Button.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  outlined: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  noBorder: PropTypes.bool,
  className: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  outlined: false,
  loading: false,
  disabled: false,
  noBorder: false,
  className: '',
};
