import { createReducer } from '@reduxjs/toolkit';
import {getAutomationsListRequest } from '../actions/automation';

const initialState = {
    automationList: { job: [] },
};
export default createReducer(initialState, (builder) => {
  builder
    .addCase(getAutomationsListRequest.fulfilled, (state, action) => {
      const { data, type } = action.payload;
      state.automationList = { ...state.automationList, [type]: data.automations };
    });
});
