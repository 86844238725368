import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import Select from '../form/Select';
import Textarea from '../form/Textarea';
import Button from '../form/Button';
import { dueDates, pipelineTriggers, taskTypes } from '../../data/options';
import DatePicker from '../form/DatePicker';
import { createAutomationRequest, getAutomationsListRequest } from '../../store/actions/automation';
import Utils from '../../helpers/Utils';
import Conditions from './Conditions';
import ExecuteDropdown from './ExecuteDropdown';
import AutomationWrapper from './AutomationWrapper';

function AddTask(props) {
  const { pipeline } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [execute, setExecute] = useState(false);
  const [form, setForm] = useState({
    pipeline,
    action: 'createTask',
    actionStatus: searchParams.get('status'),
    task: {
      status: searchParams.get('status'),
      dueDate: moment().format('YYYY-MM-DD'),
    },
  });

  const [condition, setCondition] = useState([{
    id: _.uniqueId(),
  }]);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const users = useSelector((state) => state.users.usersList);
  const automationList = useSelector((state) => state.automation.automationList[pipeline]);
  const automationId = searchParams.get('_id');


  useEffect(() => {
    const automation = automationList?.find((a) => a.id === +automationId);
    if (automation) {
      const {
        color, dateType, type, dueDate, responsiblePerson, note,
      } = automation.data;
      const data = {
        when: automation.when,
        id: automationId,
        task: {
          color,
          dateType,
          type,
          dueDate,
          responsiblePerson,
          note,
          date: dueDates.find((due) => due.dueDate === dateType)?.value,
        },
      };
      if (!_.isEmpty(automation.condition)) {
        const con = [];
        _.forEach(automation.condition, (val, op) => {
          val.forEach((cd) => {
            con.push({
              operator: op,
              condition: Object.keys(cd)[0],
              id: _.uniqueId(),
              ...Object.values(cd)[0],
            });
          });
        });
        setCondition(con);
      }

      setForm({ ...form, ...data });
    }
  }, [automationId, automationList]);


  const handleClose = useCallback(() => {
    const query = { ...Object.fromEntries(searchParams) };
    delete query.action;
    delete query.id;
    delete query._id;
    delete query.status;
    setSearchParams(query, { replace: true });
  }, [searchParams]);

  const handleConditionChange = useCallback((path, val) => {
    _.set(condition, path, val);
    setCondition([...condition]);
  }, [condition]);

  const handleDeleteCondition = useCallback((id) => {
    let con = condition.filter((c) => c.id !== id);
    if (!con.length) {
      con = [{ id: _.uniqueId() }];
    }
    setCondition(con);
  }, [condition]);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const con = {};
    condition.forEach((c) => {
      if (c.operator === '$or') {
        con.$or = [...(con.$or || []), { [c.condition]: { $in: c.$in } }];
      }
      if (c.operator === '$and') {
        con.$and = [
          ...(con.$and || []),
          { [c.condition]: c.condition === 'sale' ? { $gte: c.$gte, $lte: c.$lte } : { $in: c.$in } },
        ];
      }
    });
    form.condition = con;
    const { payload: { data } } = await dispatch(createAutomationRequest(form));

    if (data.status === 'error') {
      setErrors(Utils.normalizeErrors(data.errors));
    }
    if (data.status === 'ok') {
      setSearchParams({});
      toast.success(`Automation successfully ${form.id ? 'updated' : 'added'}.`);
      await dispatch(getAutomationsListRequest(pipeline));
    }
    setLoading(false);
  }, [form, searchParams, condition, pipeline]);

  return (
    <div className="addTaskContainer addAutomationContainer">

      <AutomationWrapper onSubmit={handleSubmit}>
        <div>
          <Select
            placeholder="Type"
            options={taskTypes}
            onChange={(v) => handleChange('task.type', v?.value)}
            value={taskTypes.find((t) => t.value === _.get(form, 'task.type', ''))}
            error={errors.type}
            isClearable
          />
          <div>
            <div onClick={() => setExecute(!execute)}>
              <Select
                placeholder="Execute"
                isDisabled
                error={errors.dateType || errors.when ? 'Execute is required.' : undefined}
                value={pipelineTriggers.find((p) => p.value === form.when)}
              />
            </div>
            {execute ? (
              <ExecuteDropdown
                form={form}
                onChange={handleChange}
                onClose={() => setExecute(false)}
                automation="task"
              />
            ) : null}
          </div>
          <DatePicker
            placeholder="Deadline"
            setDate={(d) => handleChange('task.dueDate', moment(d).format('YYYY-MM-DD'))}
            date={form.task.dueDate}
          />
          <Select
            placeholder="For"
            options={users}
            getOptionValue={(o) => o.id}
            getOptionLabel={(o) => `${o.firstName} ${o.lastName}`}
            onChange={(v) => handleChange('task.responsiblePerson', v?.id)}
            value={users.find((u) => u.id === _.get(form, 'task.responsiblePerson', ''))}
            isClearable
          />
          <Textarea
            placeholder="Comment"
            onChange={(ev) => handleChange('task.note', ev.target.value)}
            value={_.get(form, 'task.note')}
          />
          <Conditions
            condition={condition}
            onChange={handleConditionChange}
            onDeleteCondition={handleDeleteCondition}
          />

          <div className="actions">
            <Button title="Save" type="submit" loading={loading} />
            <Button title="Cancel" noBorder onClick={handleClose} />
          </div>
        </div>
      </AutomationWrapper>
    </div>
  );
}

export default AddTask;

AddTask.propTypes = {
  pipeline: PropTypes.string,
};

AddTask.defaultProps = {
  pipeline: 'lead',
};
