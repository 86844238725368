import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Wrapper from '../../components/Wrapper';
import Loader from '../../components/loader/Loader';
import Utils from '../../helpers/Utils';
import Api from '../../Api';
import Input from '../../components/form/Input';
import Button from '../../components/form/Button';
import Select from '../../components/form/Select';
import Editor, { addTextInHtmlString } from '../../components/form/JoditReact';

const options = [
  {
    label: 'Lead',
    value: 'lead',
  },
  {
    label: 'Job',
    value: 'job',
  },
  {
    label: 'Customer',
    value: 'customer',
  },
];

const subOptions = {
  lead: [
    {
      label: 'Address',
      value: ' {{lead.address}}',
    },
    {
      label: 'Company',
      value: ' {{lead.company}}',
    },
    {
      label: 'Email',
      value: ' {{lead.email}}',
    },
    {
      label: 'Phone',
      value: ' {{lead.phone}}',
    },
    {
      label: 'Sale',
      value: ' {{lead.sale}}',
    },
    {
      label: 'Status',
      value: ' {{lead.status}}',
    },
    {
      label: 'Responsible person name',
      value: ' {{lead.responsiblePersonName}}',
    },
    {
      label: 'Responsible person lastname',
      value: ' {{lead.responsiblePersonLastName}}',
    },
  ],
  job: [
    {
      label: 'Additional data',
      value: ' {{job.additionalData}}',
    },
    {
      label: 'Address',
      value: ' {{job.address}}',
    },
    {
      label: 'Company',
      value: ' {{job.company}}',
    },
    {
      label: 'Email',
      value: ' {{job.email}}',
    },
    {
      label: 'Phone',
      value: ' {{job.phone}}',
    },
    {
      label: 'Sale',
      value: ' {{job.sale}}',
    },
    {
      label: 'Status',
      value: ' {{job.status}}',
    },
  ],
  customer: [
    {
      label: 'Address',
      value: ' {{contact.address}}',
    },
    {
      label: 'Company',
      value: ' {{contact.company}}',
    },
    {
      label: 'Email',
      value: ' {{contact.email}}',
    },
    {
      label: 'Name',
      value: ' {{contact.name}}',
    },
    {
      label: 'Phone',
      value: ' {{contact.phone}}',
    },
  ],
};

const defaultTemplate = {
  name: '',
  subject: '',
  html: '',
};

const TemplateAdd = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateInfo, setTemplateInfo] = useState(defaultTemplate);
  const [errors, setErrors] = useState({});

  const [fieldOptions, setFieldsOption] = useState({
    option: '',
    subOption: '',
  });

  const { option, subOption } = fieldOptions;

  const [loading, loadingToggle] = useState(true);
  const [saveLoading, saveLoadingToggle] = useState(false);

  useEffect(() => {
    (async () => {
      if (id !== 'add') {
        const { data } = await Api.getEmailTemplates({ id });

        const template = data.templates?.[0];

        if (!template) {
          navigate('/404');
        } else {
          setTemplateInfo({
            name: template.name || '',
            subject: template.subject || '',
            html: template.html || '',
            id: template.id,
          });
        }
      }

      loadingToggle(false);
    })();
  }, []);

  const changeTemplate = useCallback((path, value) => {
    setTemplateInfo((prev) => ({ ...prev, [path]: value }));

    setErrors((prev) => ({ ...prev, [path]: '' }));
  }, []);

  const changeOptions = useCallback((path, value) => {
    setFieldsOption((prev) => {
      const newOptions = { ...prev, [path]: value };

      if (path === 'option') {
        newOptions.subOption = '';
      } else {
        setTemplateInfo((p) => ({ ...p, html: addTextInHtmlString(p.html || '', value) }));
        setErrors((p) => ({ ...p, html: '' }));
      }

      return newOptions;
    });
  }, []);

  const saveTemplate = useCallback(async (e) => {
    e.preventDefault();

    let hasError = false;

    _.forEach(templateInfo, (val, path) => {
      if (path !== 'id' && !val.trim()) {
        setErrors((prev) => ({
          ...prev,
          [path]: `${path === 'html' ? 'Template text' : _.upperFirst(path)} is required`,
        }));

        hasError = true;
      }
    });

    if (!hasError) {
      saveLoadingToggle(true);

      try {
        await Api.createEmailTemplate(Utils.trimObjValues(templateInfo));

        toast.success(<span>
          <b>Success!</b>

          {` ${id !== 'add' ? 'Template is updated' : 'New template is created'}`}
        </span>);

        if (id === 'add') {
          navigate('/settings/email-templates');
        }
      } catch (err) {
        toast.error('Please correct these fields');

        if (err.response.data.errors) {
          setErrors(Utils.normalizeErrors(err.response.data.errors));
        } else {
          setErrors((prev) => ({
            ...prev,
            name: err.response.data.message,
          }));
        }
      }

      saveLoadingToggle(false);
    } else {
      toast.error('Please correct these fields');
    }
  }, [templateInfo]);

  return (
    <Wrapper title={`${id === 'add' ? 'Create' : 'Edit'} template`}>
      <div className="email__template__content">
        {loading
          ? (
            <div className="loader__wrapper">
              <Loader size={60} borderWidth={5} />
            </div>
          )
          : (
            <div className="email__template__wrapper">
              <form className="email__template__left__block" onSubmit={saveTemplate}>
                <div className="email__template__left__top">
                  <div className="email__template__new">
                    {id === 'add' ? 'New' : 'Edit'}
                  </div>

                  <Input
                    label=""
                    placeholder="Template"
                    value={templateInfo.name}
                    error={errors.name}
                    onChange={({ target }) => changeTemplate('name', target.value)}
                  />

                  <Button
                    title="Cancel"
                    noBorder
                    type="button"
                    onClick={() => navigate('/settings/templates')}
                  />

                  <Button
                    title={id === 'add' ? 'Create' : 'Save'}
                    loading={saveLoading}
                    type="submit"
                  />

                </div>

                <div className="email__template__left__bottom">
                  <Input
                    label="Subject"
                    value={templateInfo.subject}
                    className="h_40"
                    placeholder="Subject"
                    error={errors.subject}
                    onChange={({ target }) => changeTemplate('subject', target.value)}
                  />

                  <Editor
                    preview
                    value={templateInfo.html}
                    onChange={(value) => changeTemplate('html', value)}
                    error={errors.html}
                  />
                </div>
              </form>

              <div className="email__template__right__block">
                <Select
                  label="Fields"
                  placeholder="Fields"
                  options={options}
                  value={options.find((o) => o.value === option)}
                  onChange={(o) => changeOptions('option', o.value)}
                  isSearchable={false}
                />

                {option && (
                  <Select
                    label={`${_.upperFirst(option)} fields`}
                    placeholder={`${_.upperFirst(option)} fields`}
                    options={subOptions[option]}
                    value={subOptions[option].find((o) => o.value === subOption)}
                    onChange={(o) => changeOptions('subOption', o.value)}
                    isSearchable={false}
                  />
                )}
              </div>
            </div>
          )}
      </div>
    </Wrapper>
  );
};

export default TemplateAdd;
