import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Loader from '../components/loader/Loader';
import Pagination from '../components/pagination/Pagination';
import { getCallsRequest } from '../store/actions/call';
import Wrapper from '../components/Wrapper';
import { ReactComponent as OutgoingCallIcon } from '../assets/icons/outgoing.svg';
import { ReactComponent as IncomingCallIcon } from '../assets/icons/incoming.svg';
import { ReactComponent as AddIcon } from '../assets/icons/add.svg';
import Utils from '../helpers/Utils';

function CallReports() {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const callsList = useSelector((state) => state.call.callsList);
  const callsPages = useSelector((state) => state.call.callsPages);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getCallsRequest({ page, limit }));
      setLoading(false);
    })();
  }, [page, limit]);

  const getCallIconByType = useCallback((type) => ({
    outgoing: <OutgoingCallIcon className="out" />,
    incoming: <IncomingCallIcon className="in" />,
  })[type], []);

  return (
    <Wrapper title="Call reports">
      <div className="leadsList callReports">
        <div className="table__wrapper leadsTable">
          {loading ? (
            <div className="loader__wrapper">
              <Loader size={60} borderWidth={4} />
            </div>
          ) : null}

          {!loading && !_.isEmpty(callsList) ? (
            <table
              style={{ minWidth: 165 }}
              className="table"
            >
              <thead className="thead">
              <tr className="tr">
                <th className="th">From</th>
                <th className="th">To</th>
                <th className="th">Call Type</th>
                <th className="th">Duration</th>
                <th className="th">Date</th>
                <th className="th">Actions</th>
              </tr>
              </thead>

              <tbody className="tbody">
              {callsList.map((d) => (
                <tr
                  className="tr"
                  key={d.callId}
                >
                  <td
                    className="td"
                  >
                    {d.callerName || '-'}
                  </td>
                  <td
                    className="td"
                  >
                    {d.calleeName || '-'}
                  </td>
                  <td
                    className="td"
                  >
                    <div className="callType">
                        <span>
                          {getCallIconByType(d.type)}
                        </span>
                      <span>
                          {_.startCase(d.type)}
                        </span>
                    </div>
                  </td>
                  <td
                    className="td"
                  >
                    {Utils.formatDuration(d.duration / 1000 || 0)}
                  </td>
                  <td
                    className="td"
                  >
                    {moment(d.createdAt).format('MM/DD/YYYY') || '-'}
                  </td>
                  <td
                    className="td"
                  >
                    {!d.leadExist ? (
                      <Link
                        to={`/leads/create?from=call&phone=${d.type !== 'incoming'
                          ? d.reachedNumber.replace('+1', '')
                          : d.phoneNumber.replace('+1', '')}&val=${
                          d.type === 'incoming'
                            ? d.callerName
                            : d.calleeName
                        }&create=contact&from=lead`}
                        className="createLeadButton"
                      >
                        <AddIcon />
                        <span> Create new lead</span>
                      </Link>
                    ) : null}
                  </td>
                  {/* <td */}
                  {/*   className="td" */}
                  {/* > */}
                  {/*   {Utils.formatPhoneNumber(d.type === 'outgoing' ? d.reachedNumber : d.phoneNumber)} */}
                  {/* </td> */}
                  {/* <td */}
                  {/*   className="td" */}
                  {/* > */}
                  {/*   {Utils.formatPhoneNumber(d.type === 'outgoing' ? d.phoneNumber : d.reachedNumber)} */}
                  {/* </td> */}
                </tr>
              ))}
              </tbody>
            </table>
          ) : null}
          {_.isEmpty(callsList) && !loading ? <div className="noData">No call reports yet.</div> : null}
        </div>
        {!_.isEmpty(callsList) ? (
          <Pagination
            page={page}
            totalPages={callsPages}
            limit={limit}
            onChange={(k, v) => {
              if (k === 'page') {
                setPage(v);
              } else {
                setLimit(v);
                setPage(1);
              }
            }}
          />
        ) : null}

      </div>
    </Wrapper>
  );
}

export default CallReports;
