import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { renderToString } from 'react-dom/server';
import { ReactComponent as JobIcon } from '../../assets/icons/job.svg';
import classNames from 'classnames';

const toolbar = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }],
  [{ header: [1, 2, 3] }],

  [{ color: [] }],
  [{ font: [] }],
  [{ align: [] }],

  ['clean'],
];

//
// const icons = ReactQuill.Quill.import('ui/icons');
//
// icons.italic = renderToString(<JobIcon />);

function Editor(props) {
  const {
    value, onChange, error, placeholder,
  } = props;
  return (
    <div className={classNames('editor', { error: !!error })}>
      <ReactQuill theme="snow" modules={{ toolbar }} value={value} onChange={onChange} placeholder={placeholder} />
      {error ? <span className="errorText">{error}</span> : null}
    </div>
  );
}

export default Editor;

Editor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
};

Editor.defaultProps = {
  placeholder: '',
  error: '',
  value: '',
};
