import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { loginRequest } from '../store/actions/account';
import WrapperLogout from '../components/logout/WrapperLogout';
import Input from '../components/form/Input';
import Button from '../components/form/Button';
import { ReactComponent as EyeIcon } from '../assets/icons/eye.svg';
import Checkbox from '../components/form/Checkbox';
import Utils from '../helpers/Utils';

function Login() {
  const dispatch = useDispatch();

  const [form, setForm] = useState({ login: '', password: '' });
  const [type, setType] = useState('password');
  const [loading, setLoading] = useState(false);
  const [remember, setRemember] = useState(true);
  const [errors, setErrors] = useState({});

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const { payload: { data } } = await dispatch(loginRequest({ form, remember }));
    if (data.status === 'error') {
      if (data.errors) {
        setErrors(Utils.normalizeErrors(data.errors));
      } else {
        toast.error(data.message);
      }
    }
    setLoading(false);
  }, [form, remember]);

  const handleChange = useCallback((key, value) => {
    form[key] = value;
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  return (
    <WrapperLogout>
      <div className="login">
        <div>
          <form onSubmit={handleSubmit}>
            <p className="title">
              Welcome back!
            </p>
            <p className="desc">
              Log in to your Fusion Windows account
            </p>
            <Input
              label="Email"
              value={form.login}
              name="login"
              onChange={(ev) => {
                handleChange('login', ev.target.value);
              }}
              placeholder="info@fusionwindows.com"
              error={errors.login}
            />
            <Input
              value={form.password}
              label="Password"
              type={type}
              name="password"
              placeholder="******"
              onChange={(ev) => {
                handleChange('password', ev.target.value);
              }}
              rightIcon={(
                <EyeIcon
                  onClick={() => setType(type === 'password' ? 'text' : 'password')}
                  className={type}
                />
              )}
              error={errors.password}
            />
            <Checkbox
              label="Remember me"
              checked={remember}
              onChange={() => {
                setRemember(!remember);
              }}
            />
            <Button title="Log in" type="submit" loading={loading} />
          </form>
          <Link to="/forgot-password" className="forgotPassBlock">
            Forgot password?
          </Link>
        </div>
      </div>
    </WrapperLogout>
  );
}

export default Login;
