import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow_down.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/remove.svg';
import { LeftMenuContext } from '../Wrapper';

const RightModal = ({
  open, onClose, children, className, zIndex, modalWidth, headerText, openFromBelow,
}) => {
  const timeout = useRef();
  const isLeftMenuOpen = useContext(LeftMenuContext);
  const [showModal, modalShowToggle] = useState(false);

  const handleEsc = useCallback((event) => {
    if (event.keyCode === 27) onClose();
  }, []);

  useEffect(() => {
    if (!open) {
      timeout.current = setTimeout(() => modalShowToggle(open), 350);
    } else {
      modalShowToggle(open);
      window.addEventListener('keydown', handleEsc);
    }
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [open]);

  useEffect(() => () => {
    clearTimeout(timeout.current);
  }, []);

  return (
    showModal && createPortal(
      <div
        id="modal"
        className={classnames(
          `modal__container ${className}`,
          {
            bottom: openFromBelow,
            leftPosition: isLeftMenuOpen,
          },
        )}
        style={{
          zIndex,
          '--modal-width': `${modalWidth}`,
        }}
      >
        <div
          role="button"
          tabIndex="0"
          className="backdrop"
          onClick={onClose}
        />

        <div
          className={classnames(`right__modal__wrapper ${className}`, { show_modal: open, close_modal: !open })}
        >
          <button
            className={classnames('open_close', { close: !open })}
            type="button"
            onClick={onClose}
          >
            {openFromBelow
              ? <CloseIcon />
              : <ArrowIcon />}
          </button>

          {headerText && (
          <h2 className="right__modal__wrapper__header">
            {headerText}
          </h2>
          )}

          <div className="right__modal__content">
            {children}
          </div>
        </div>
      </div>,
      document.getElementById('root') || document.body,
    )
  );
};

RightModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  headerText: PropTypes.string,
  zIndex: PropTypes.number,
  modalWidth: PropTypes.string,
  openFromBelow: PropTypes.bool,
};

RightModal.defaultProps = {
  className: '',
  headerText: '',
  zIndex: 900,
  modalWidth: '480px',
  onClose: null,
  openFromBelow: false,
};

export default RightModal;
