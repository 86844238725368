import moment from 'moment';

const taskTypes = [
  { label: 'Call', value: 'call' },
  { label: 'Meeting', value: 'meeting' },
  { label: 'Follow Up', value: 'followUp' },
  { label: 'Email', value: 'email' },
];
const dueDates = [
  { label: 'Immediately', dueDate: 'immediately', value: moment().format('YYYY-MM-DD hh:mm a') },
  { label: 'In 1 day', dueDate: '1day', value: moment().add(1, 'day').format('YYYY-MM-DD hh:mm a') },
  { label: 'In 3 days', dueDate: '3days', value: moment().add(3, 'days').format('YYYY-MM-DD hh:mm a') },
  { label: 'In 1 week', dueDate: '1week', value: moment().add(1, 'week').format('YYYY-MM-DD hh:mm a') },
  { label: 'In 2 weeks', dueDate: '2weeks', value: moment().add(2, 'week').format('YYYY-MM-DD hh:mm a') },
  { label: 'In 4 weeks', dueDate: '4weeks', value: moment().add(3, 'week').format('YYYY-MM-DD hh:mm a') },
  { label: 'Custom', dueDate: 'custom', value: moment().format('YYYY-MM-DD hh:mm a') },

];

const conditions = [
  { label: 'Responsible person', value: 'responsiblePerson', type: 'select' },
  { label: 'Lead source', value: 'leadSourceId', type: 'select' },
  { label: 'Sale', value: 'sale', type: 'input' },
  { label: 'Customer', value: 'contactId', type: 'select' },

];

const statusesLead = [
  { label: 'In Progress', value: 'inProgress' },
  { label: 'Closed Won', value: 'closedWon' },
  { label: 'Closed Lost', value: 'closedLost' },
  { label: 'Assigned Lead', value: 'assignedLead' },
  { label: 'Sold', value: 'sold' },
  { label: 'Contact Mode', value: 'contactMode' },
  { label: 'Offer Made', value: 'offerMade' },

];

const statusesJob = [
  { label: 'Sold', value: 'sold' },
  { label: 'Ordered', value: 'ordered' },
  { label: 'Delivered', value: 'delivered' },
  { label: 'Installed', value: 'installed' },
];

const statusesTask = [
  { label: 'Not Started', value: 'notStarted' },
  { label: 'In Progress', value: 'inProgress' },
  { label: 'Completed', value: 'completed' },
];
const pipelineTriggers = [
  { label: 'When moved in this stage', value: 'moved' },
  { label: 'When created in this stage', value: 'created' },
];

const sentToOptions = [
  { label: 'Responsible person', value: 'responsiblePerson' },
  { label: 'Customer', value: 'contact' },
];

const today = moment().format('YYYY-MM-DD');
const yesterday = moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD');
const weekStartDay = moment().subtract(7, 'day').format('YYYY-MM-DD');
const thisMonthStartDay = moment().startOf('month').format('YYYY-MM-DD');
const lastMonthStartDay = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
const lastMonthEndDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
const defaultDates = [
  { label: 'Today', value: [today, today] },
  { label: 'Yesterday', value: [yesterday, yesterday] },
  { label: 'Last 7 days', value: [weekStartDay, today] },
  { label: 'This Month', value: [thisMonthStartDay, today] },
  { label: 'Last Month', value: [lastMonthStartDay, lastMonthEndDate] },

];

export {
  taskTypes,
  dueDates,
  conditions,
  statusesLead,
  statusesJob,
  statusesTask,
  pipelineTriggers,
  defaultDates,
  sentToOptions,
};
