import React from 'react';
import Wrapper from '../../components/Wrapper';
import PipelineTabs from '../../components/pipeline/PipelineTabs';
import Automations from '../../components/automation/Automations';
import AutomationsList from '../../components/automation/AutomationsList';
import HasPermission from '../../components/HasPermission';

const tabs = [
  {
    path: '/leads',
    tab: 'pipeline',
  },
  {
    path: '/leads/list',
    tab: 'list',
  },
];

function LeadAutomations() {
  return (
    <Wrapper title="Leads automations">
      <div className="automationsList">
        <PipelineTabs tabs={tabs} />
        <HasPermission read="get_automation_readonly" edit="get_automation">
          <AutomationsList pipeline="lead" />
        </HasPermission>
      </div>
      <Automations pipeline="lead" />
    </Wrapper>
  );
}

export default LeadAutomations;
