import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment/moment';
import DeleteModal from '../modal/DeleteModal';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';

const header = [
  {
    title: 'Amount',
    path: 'amount',
  },
  {
    title: 'Date',
    path: 'date',
  },
  {
    title: 'Payment method',
    path: 'paymentMethod',
  },
];
const PaymentHistory = ({ data, deleteHistory }) => {
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [loading, loadingToggle] = useState(null);

  const deletePayment = (id) => {
    setDeleteModalData(id);
  };

  const deletePaymentItem = useCallback(async () => {
    loadingToggle(true);
    await deleteHistory(deleteModalData);
    loadingToggle(false);

    setDeleteModalData(null);
  }, [deleteModalData]);

  return (
    <div className="table__wrapper invoice__payment__history__wrapper">
      <div className="invoice__payment__modal__table__wrapper">
        <table className="table">
          <thead className="thead">
            <tr className="tr">
              {header.map((h) => (
                <th className="th" key={h.title}>{h.title}</th>
              ))}

              {data.status !== 'paid' && <th className="th actions__wrapper">Actions</th>}
            </tr>
          </thead>

          <tbody className="tbody">
            {data.payment.map((d) => (
              <tr
                key={d.id}
                className="tr"
              >
                {header.map((h) => (
                  <td
                    className="td"
                    key={h.title}
                  >
                    {h.path === 'amount'
                      ? `$${d[h.path]}`

                      : h.path === 'date'
                        ? moment(d[h.path]).format('MM/DD/YYYY')

                        : _.startCase(d[h.path])}
                  </td>
                ))}

                {data.status !== 'paid' && (
                <td
                  className="td actions__wrapper"
                >
                  <DeleteIcon onClick={() => deletePayment(d.id)} />
                </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <DeleteModal
        open={!!deleteModalData}
        text="Are you sure want to delete this payment?"
        onClose={() => setDeleteModalData(null)}
        onDelete={deletePaymentItem}
        loading={loading}
      />
    </div>
  );
};

PaymentHistory.propTypes = {
  data: PropTypes.object.isRequired,
  deleteHistory: PropTypes.func.isRequired,
};

export default PaymentHistory;
