import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import moment from 'moment';
import _ from 'lodash';
import RangeDatePicker from '../../components/form/RangeDatePicker';
import Select from '../../components/form/Select';
import { defaultDates } from '../../data/options';
import Api from '../../Api';
import Loader from '../../components/loader/Loader';
import { useQuery } from '../../helpers/hooks/useQuery';
import Utils from '../../helpers/Utils';

const options = {
  series: [],
  options: {
    chart: {
      type: 'bar',
      height: 430,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      marker: {
        show: true,
      },
      x: {
        show: true,
      },
      y: {
        formatter: (price) => `$ ${Utils.formatPrice(price)}`,
        title: {
          formatter: () => '',
        },
      },
    },
    legend: {
      show: false,
    },

    colors: ['#356966', '#FF7070'],
    xaxis: {
      categories: [],
    },
  },

};

const option = {
  series: [],
  options: {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    colors: [],
    dataLabels: {
      enabled: false,
      background: {
        enabled: false,
        dropShadow: {
          enabled: false,
        },
      },
    },
    stroke: {
      curve: 'smooth',
    },

    markers: {
      size: 1,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      dropShadow: {
        enabled: false,
      },
      categories: [],
    },

    legend: {
      show: false,
    },
  },

};

const thisMonthStartDay = moment().startOf('month').format('YYYY-MM-DD');
const thisMonthEndDate = moment().format('YYYY-MM-DD');

const InvoiceStatistics = ({ type }) => {
  const chartRef = useRef();
  const [chart, setChart] = useState(type === 'invoices' ? options : option);
  const [hideSeries, setHideSeries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [requestData, setRequestData] = useState([thisMonthStartDay, thisMonthEndDate]);
  const { query } = useQuery();

  const onChangeDate = useCallback((date) => {
    setRequestData(date);
  }, []);

  const showHideSeries = useCallback((name) => {
    setHideSeries((prev) => {
      if (prev.includes(name)) {
        chartRef.current.chart.showSeries(name);
        return prev.filter((p) => p !== name);
      }
      chartRef.current.chart.hideSeries(name);
      return [...prev, name];
    });
  }, [chartRef.current]);

  const normalizeSeries = useCallback((series) => series.filter((r) => r.name !== 'salesRevenue').map((r) => {
    if (r.name === 'profitMargin') {
      r.name = 'Profit';
    }
    if (r.name === 'totalRevenue') {
      r.name = 'Revenue';
    }
    return r;
  }), []);

  useEffect(() => {
    (async () => {
      const date = { startDate: requestData[0], endDate: requestData[1], id: query.id };

      if (requestData[1]) {
        if (type === 'invoices') {
          setLoading(true);
          const { data: { result } } = await Api.invoiceStatistic(date);

          setChart((prev) => ({
            ...prev,
            series: normalizeSeries(result.series),
            options: {
              ...prev.options,
              xaxis: {
                ...prev.options.xaxis,
                categories: result.categories,
              },
              yaxis: {
                labels: {
                  formatter: (price) => Utils.formatPrice(price),
                },
              },
            },
          }));
          setLoading(false);
        } else if (type === 'installers') {
          setLoading(true);

          const { data } = await Api.installersStatistic(date);

          setChart((prev) => ({
            ...prev,
            series: normalizeSeries(data.result.series),
            options: {
              ...prev.options,
              colors: ['#356966', '#F90'],
              xaxis: {
                ...prev.options.xaxis,
                categories: data.result.categories,
              },
              yaxis: {
                labels: {
                  formatter: (price) => Utils.formatPrice(price),
                },
              },
            },
          }));
          setLoading(false);
        } else {
          setLoading(true);
          const { data } = await Api.salesStatistic(date);

          setChart((prev) => ({
            ...prev,
            series: normalizeSeries(data.result.series),
            options: {
              ...prev.options,
              colors: ['#356966', '#FF718B', '#FFD831'],
              stroke: {
                ...prev.options.stroke,
                dashArray: [5, 5, 5],
              },
              xaxis: {
                ...prev.options.xaxis,
                categories: data.result.categories,
              },
              yaxis: {
                labels: {
                  formatter: (price) => Utils.formatPrice(price),
                },
              },
              tooltip: {
                y: {
                  formatter: (price) => `$ ${Utils.formatPrice(price)}`,
                  title: {
                    formatter: (name) => _.startCase(name),
                  },
                },
              },
            },
          }));
          setLoading(false);
        }
      }
    })();
  }, [requestData, query]);

  return (
    <div className="statistics_wrapper">
      <div className="statistics_header">
        <h2>
          {type === 'invoices'
            ? 'Invoices'
            : type === 'salesReport'
              ? 'Sales report'
              : 'Installers'}
        </h2>

        <div className={`statistics_header_right_block ${type === 'salesReport' ? 'salesReport' : ''}`}>
          {!loading && (
            <div className="statistics_header_legend_wrapper">
              {normalizeSeries(chart.series).map((c, i) => (
                <div
                  className={`statistics_header_legend ${hideSeries.includes(c.name) ? 'hide' : ''}`}
                  key={c.name}
                  onClick={() => showHideSeries(c.name)}
                >
                  <span style={{
                    '--button-color': chart.options.colors[i],
                  }}
                  />
                  <p>{_.startCase(c.name)}</p>
                </div>

              ))}
            </div>
          )}

          <div className="calendar">
            <RangeDatePicker
              date={requestData}
              setDate={(date) => onChangeDate(date)}
            />

            <Select
              options={defaultDates}
              onChange={(date) => onChangeDate(date.value)}
              value={defaultDates.filter((d) => JSON.stringify(d.value) === JSON.stringify(requestData))}
            />

          </div>
        </div>
      </div>

      {loading
        ? <div className="dashboard_loader_wrapper"><Loader className="loader_dashboard" /></div>
        : (
          <Chart
            height="435px"
            width="100%"
            type={type === 'invoices' ? 'bar' : 'line'}
            ref={chartRef}
            options={chart.options}
            series={normalizeSeries(chart.series)}
          />
        )}
    </div>
  );
};

InvoiceStatistics.propTypes = {
  type: PropTypes.string.isRequired,
};

export default InvoiceStatistics;
