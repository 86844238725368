import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Wrapper from '../../components/Wrapper';
import LeadStatistic from './LeadStatistic';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings_new.svg';
import JobStatistics from './JobStatistics';
import Avatar from '../../assets/images/no_img_user.svg';
import Api from '../../Api';
import Loader from '../../components/loader/Loader';
import InvoiceStatistics from './InvoiceStatistics';
import ToggleButton from '../../components/form/ToggleButton';
import Button from '../../components/form/Button';
import { useQuery } from '../../helpers/hooks/useQuery';
import Account from '../../helpers/Account';
import HasPermission from '../../components/HasPermission';
import usePermission from '../../helpers/hooks/usePermission';

const Dashboard = () => {
  const [showMenuData, setShowMenuData] = useState({});
  const [showCharts, setShowCharts] = useState([]);
  const [leadStatistics, setLeadStatistics] = useState({});
  const [employee, setEmployee] = useState([]);
  const [firstLoading, setFirstLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { query, setQuery } = useQuery();

  const isSuperAdmin = Account.get()?.role?.slug === 'superadmin';

  const leadStatisticsAllow = usePermission(['leads_statistic', 'leads_statistic_readonly']);
  const installersStatisticsAllow = usePermission(['installers_statistic_readonly', 'installers_statistic']);
  const getApiList = useMemo(() => {
    const apis = [];
    if (leadStatisticsAllow) {
      apis.push(Api.leadsStatistic());
    }
    if (installersStatisticsAllow) {
      apis.push(Api.getEmployeeProductivity());
    }
    return apis;
  }, []);

  useEffect(() => {
    (async () => {
      setFirstLoading(true);

      const [lead, user, settings] = await Promise.all([
        ...getApiList,
        Api.getDashboardSettings(),
      ]);

      setShowMenuData({ id: settings.data.id, settings: settings.data.settings });
      setShowCharts(settings.data.settings);
      setEmployee(user.data.users);
      setLeadStatistics(lead.data);
      setFirstLoading(false);
    })().catch(console.error);
  }, []);

  const changeChartShowButton = useCallback((path, checked) => {
    setShowMenuData((prev) => ({
      ...prev,
      settings: prev.settings.map((p) => (p.path === path ? { ...p, show: checked } : p)),
    }));
  }, []);

  const filterCharts = useMemo(() => showCharts.filter((d) => d.show).map((d) => d.path), [showCharts]);

  const getSingleUserChart = useCallback(async (id) => {
    if (!loading) {
      setLoading(true);
      let requestData = {};

      if (+id === +query.id) {
        requestData = {};
      } else {
        requestData = { id };
      }
      setQuery(requestData);

      const { data } = await Api.leadsStatistic(requestData);

      setLeadStatistics(data);

      setLoading(false);
    }
  }, [query, loading]);

  const saveSetting = useCallback(async () => {
    setBtnLoad(true);
    const { data } = await Api.updateDashboardSettings(showMenuData);

    setShowMenuData((prev) => ({ ...prev, settings: data.settings }));
    setShowCharts(data.settings);

    setShowMenu(false);

    setBtnLoad(false);
  }, [showMenuData]);

  const cancelSetting = useCallback(() => {
    setShowMenuData((prev) => ({ ...prev, settings: showCharts }));

    setShowMenu(false);
  }, [showCharts]);

  return (
    <Wrapper title="Dashboard">
      {firstLoading
        ? (
          <div className="dashboard_wrapper_loader">
            <Loader className="loader_dashboard" size={60} borderWidth={5} />
          </div>
        )
        : (
          <div className="dashboard_wrapper">
            <div className="dashboard_header_title">
              {filterCharts.includes('totalLeads') || filterCharts.includes('leadsConverted')
                ? <p>Lead statistics</p>
                : <div />}

              <div className="dashboard_header_title_menu_wrapper">
                <div
                  id="menu"
                  className="dashboard_header_title_icon"
                  onClick={() => setShowMenu((prev) => !prev)}
                >
                  <SettingsIcon />

                </div>

                {showMenu && (
                  <>
                    <div className="overlay" onClick={() => setShowMenu(false)} />
                    <div className="dashboard_header_menu_wrapper" id="menu_item">
                      {showMenuData.settings.map((s) => (
                        <div className="dashboard_header_menu_item" key={s.path}>
                          <ToggleButton
                            onChange={(checked) => changeChartShowButton(s.path, checked)}
                            label={s.label}
                            checked={s.show}
                          />
                        </div>
                      ))}

                      <div className="dashboard_header_menu_button_wrapper">
                        <Button
                          title="Save"
                          type="button"
                          loading={btnLoad}
                          className="dashboard_header_menu_button"
                          onClick={saveSetting}
                        />

                        <Button
                          title="Cancel"
                          type="button"
                          className="dashboard_header_menu_button"
                          noBorder
                          onClick={cancelSetting}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

            </div>

            {(filterCharts.includes('totalLeads')
                || filterCharts.includes('leadsConverted')
                || isSuperAdmin)
              && (
              <div className="dashboard_header_wrapper">
                <div className="dashboard_header_left">
                  <div className="dashboard_lead_statistics_wrapper">
                    {filterCharts.includes('totalLeads') && (
                    <LeadStatistic
                      data={leadStatistics.leadsStatistic}
                      type="leadsStatistic"
                      loading={loading}
                    />
                    )}

                    {filterCharts.includes('leadsConverted') && (
                    <LeadStatistic
                      data={leadStatistics.totalLeadsConvertedJobs}
                      type="totalLeadsConvertedJobs"
                      loading={loading}
                    />
                    )}
                  </div>

                  {filterCharts.includes('totalNumber') && (
                  <div>
                    <p className="job_statistics_title">Job statistics</p>

                    <JobStatistics />
                    <HasPermission edit="sales_statistic" read="sales_statistic_readonly">

                      {filterCharts.includes('salesReport') && (
                          <InvoiceStatistics type="salesReport" />
                      )}
                    </HasPermission>
                  </div>
                  )}
                </div>

                {isSuperAdmin && (
                <div className="dashboard_header_right">
                  <h2 className="dashboard_header_right_title">Employee productivity</h2>

                  {employee.map((e) => (
                    <div
                      className={`dashboard_header_right_user_wrapper ${+query.id === e.id ? 'active' : ''}`}
                      key={e.id}
                      onClick={() => getSingleUserChart(e.id)}
                    >
                      <div className="dashboard_header_right_user_avatar">
                        <img src={Avatar} alt="avatar" />
                      </div>

                      <div className="dashboard_header_right_user_info">
                        <p>{`${e.firstName} ${e.lastName}`}</p>

                        <p>{`${e.sales}  ${+e.sales > 1 ? 'sales' : 'sale'}`}</p>
                      </div>
                    </div>

                  ))}

                </div>
                )}
              </div>
              )}

            <div className="dashboard_body">

              {(filterCharts.includes('invoices') || filterCharts.includes('installers')) && (
                <div className="chart_block">
                  <p className="dashboard_body_title">Invoice statistics</p>

                  {filterCharts.includes('invoices')
                    ? (
                      <HasPermission edit="invoice_statistic" read="invoice_statistic_readonly">
                        <InvoiceStatistics type="invoices" />
                      </HasPermission>
                    )
                    : null}

                  {filterCharts.includes('installers')
                    ? (
                      <HasPermission edit="installers_statistic" read="installers_statistic_readonly">
                        <InvoiceStatistics type="installers" />
                      </HasPermission>
                    )
                    : null}
                </div>
              )}

            </div>

          </div>
        )}

    </Wrapper>
  );
};

export default Dashboard;
