import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Wrapper from '../../components/Wrapper';
import Tabs from '../../components/pipeline/Tabs';
import Button from '../../components/form/Button';
import { getPipelinesRequest } from '../../store/actions/pipelines';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings_new.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import TasksList from '../../components/tasks/TasksList';
import AddTaskModal from '../../components/tasks/AddTaskModal';
import { getUsersRequest } from '../../store/actions/users';
import { getLeadsRequest } from '../../store/actions/leads';
import { getJobsRequest } from '../../store/actions/jobs';
import HasPermission from '../../components/HasPermission';
import { tasksPermissions } from '../../data/permissions';
import TasksPipeline from '../../components/tasks/TasksPipeline';

const tabs = [
  { path: '/tasks', tab: 'pipeline' },
  { path: '/tasks/list', tab: 'list' },
];

function Tasks() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { tab } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getPipelinesRequest({ type: 'task' }));
    dispatch(getUsersRequest());
    dispatch(getLeadsRequest());
    dispatch(getJobsRequest());
  }, []);

  return (
    <Wrapper title="Tasks">
      <div className="leads">
        <Tabs tabs={tabs}>
          <div className="actions">
            <HasPermission
              edit={['get_pipelines', 'create_pipelines', 'delete_pipelines', 'order_pipeline']}
              read={['get_pipelines_readonly',
                'create_pipelines_readonly',
                'delete_pipelines_readonly',
                'order_pipeline_readonly']}
            >
              <div className="settings" onClick={() => navigate('/tasks/pipeline/edit')}>
                <SettingsIcon />
              </div>
            </HasPermission>
            <HasPermission edit="create_task" exact>
              <Button
                title={(
                  <span>
                    <AddIcon className="icon" />
                    Create
                  </span>
                )}
                onClick={() => navigate('?create=task')}
              />
            </HasPermission>
          </div>
        </Tabs>
        <HasPermission edit={tasksPermissions}>
          {tab === 'pipeline' || !tab
            ? <TasksPipeline />
            : null}
        </HasPermission>
        <HasPermission edit={tasksPermissions}>
          {tab === 'list' ? (
            <TasksList
              page={page}
              onPageChange={(k, v) => {
                if (k === 'page') {
                  setPage(v);
                } else {
                  setLimit(v);
                  setPage(1);
                }
              }}
              limit={limit}
            />
          ) : null}
        </HasPermission>
      </div>
      <AddTaskModal />
    </Wrapper>
  );
}

export default Tasks;
