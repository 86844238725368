import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { hangupCallRequest, makeCallRequest, manageCallRequest } from '../../store/actions/call';
import CallDetails from './CallDetails';
import beep from '../../assets/mp3/beep.mp3';

let mediaStream;
let peerConnection;
const peerConnectionConfig = {
  iceServers: [
    { urls: 'stun:stun.stunprotocol.org:3478' },
    { urls: 'stun:stun.l.googleAuth.com:19302' },
  ],
};

function OutgoingCall(props) {
  const { callInfo, callInfo: { phone, name, leadId }, onEnd } = props;

  const audio = useRef();

  const dispatch = useDispatch();
  const calls = useSelector((state) => state.call.calls);
  const callStatus = useSelector((state) => state.call.callStatus);

  const outgoingCalls = useMemo(() => {
    let list = _.filter(calls, (call) => call.appCallType === 'outgoing');
    if (!_.isEmpty(list)) {
      list = list.map((l) => ({
        ...l,
        callId: l.callId || l?.content?.callId,
      }));
    }

    return list;
  }, [calls]);

  useEffect(() => {
    init();
  }, [callInfo]);

  useEffect(() => {
    const call = outgoingCalls.find((c) => c.appCallStatus === 'ended');
    if (call) {
      onEnd(call.callId);
    }
  }, [outgoingCalls]);

  const init = useCallback(async () => {
    if (!audio.current) {
      return;
    }
    if (!navigator.mediaDevices.getUserMedia) {
      alert('Your browser does not support getUserMedia API');
      return;
    }
    mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    peerConnection = new RTCPeerConnection(peerConnectionConfig);
    peerConnection.onicecandidate = () => {
      if (audio.current) {
        audio.current.src = beep;
      }
    };

    peerConnection.ontrack = (ev) => {
      if (audio.current) {
        audio.current.srcObject = ev.streams[0];
      }
    };

    mediaStream.getTracks().forEach((track) => {
      peerConnection.addTrack(track, mediaStream);
    });

    const offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(offer);

    // +13024687080

    const { payload: { data } = {} } = await dispatch(makeCallRequest({ phoneNumber: phone, sdp: offer.sdp, leadId }));
    if (data.status === 'ok') {
      const signal = {
        sdp: data.sdp,
        type: offer.type,
      };
      await peerConnection.setRemoteDescription(new RTCSessionDescription(signal));

      const answer = await peerConnection.createAnswer();
      await peerConnection.setLocalDescription(answer);
    } else {
      toast.error(data.message);
    }
  }, [audio, phone, leadId]);

  const handleHangupCall = useCallback(async (callId) => {
    await dispatch(hangupCallRequest({ callId }));
    onEnd();
  }, []);

  const handleManageCall = useCallback(async (type, callId) => {
    await dispatch(manageCallRequest({ type, callId }));
  }, []);


  return (
    <div className="outgoingCall">
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio autoPlay ref={audio} />
      {outgoingCalls.map((c) => (
        <CallDetails
          ringing={callStatus === 'ringing'}
          calling={callStatus === 'calling'}
          onHangup={handleHangupCall}
          onManage={handleManageCall}
          callDetails={{ ...c, phone, name }}
          key={c.callId}
        />
      ))}
    </div>
  );
}

export default OutgoingCall;

OutgoingCall.propTypes = {
  callInfo: PropTypes.object,
  onEnd: PropTypes.func.isRequired,
};

OutgoingCall.defaultProps = {
  callInfo: {},
};
