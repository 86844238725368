import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { NavLink, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Wrapper from '../../components/Wrapper';
import TableWithPagination from '../../components/tables/TableWithPagination';
import Api from '../../Api';
import { useQuery } from '../../helpers/hooks/useQuery';
import { getEmailTemplatesRequest } from '../../store/actions/templates';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import HasPermission from '../../components/HasPermission';
import { leadSourcesPermissions, templatesPermissions } from '../../data/permissions';

const htmlComponent = (data) => (
  <p className="template__html" dangerouslySetInnerHTML={{ __html: data.html }} />
);

const tableHeader = [
  {
    title: 'Name',
    path: 'name',
  },
  {
    title: 'Email body',
    path: 'html',
    innerElement: htmlComponent,
  },
];

const Templates = () => {
  const navigate = useNavigate();
  const { query, setQuery } = useQuery();

  const dispatch = useDispatch();
  const { list, totalPages } = useSelector((state) => state.templates.email);

  const timeout = useRef();

  const [filters, setFilters] = useState({
    page: +query.page || 1,
    limit: +query.limit || 10,
  });
  const { page, limit } = filters;

  const [loading, loadingToggle] = useState(true);

  useEffect(() => {
    (async () => {
      await dispatch(getEmailTemplatesRequest(filters));
      loadingToggle(false);
    })();

    return () => clearTimeout(timeout.current);
  }, []);

  useEffect(() => {
    setQuery(filters);
  }, [filters]);

  const onChangePage = useCallback(async (type, value) => {
    const requestData = { ...filters };

    requestData[type] = value;

    if (type === 'limit') {
      requestData.page = 1;
    }

    setFilters(requestData);

    await dispatch(getEmailTemplatesRequest(requestData));
  }, [filters]);

  const editTemplate = useCallback((template) => {
    navigate(`/settings/email-templates/${template.id}`);
  }, []);

  const deleteContact = useCallback(async (template) => {
    await Api.deleteEmailTemplate(template.id);

    const requestData = { ...filters };
    requestData.page = list.length === 1 && page > 1 ? page - 1 : page;

    await dispatch(getEmailTemplatesRequest(requestData));
    setFilters(requestData);

    toast.success('Template deleted');
  }, [list, filters]);

  return (
    <Wrapper title="Email templates">
      <div className="contacts__content">
        <div className="contacts__buttons__wrapper">
          <HasPermission read="create_template_readonly" edit="create_template">
            <NavLink
              to="/settings/email-templates/add"
              className={classNames('mainButton')}
            >
              <AddIcon />

              Create
            </NavLink>
          </HasPermission>
        </div>
        <HasPermission edit={templatesPermissions}>
          <TableWithPagination
            data={list}
            header={tableHeader}
            tableClassName="templates__table__wrapper"
            page={page}
            limit={limit}
            firstLoad={loading}
            deleteModalText="Are you sure you want to delete the template?"
            totalPages={totalPages}
            onChangePage={onChangePage}
            onEditClick={editTemplate}
            onDeleteClick={deleteContact}
            deletePermission="delete_template"
            readDeletePermission="delete_template_readonly"
            editPermission="create_template"
            readEditPermission="create_template_readonly"
          />
        </HasPermission>
      </div>
    </Wrapper>
  );
};

export default Templates;
